import { PropsWithChildren } from "react";
import { StrapiItineratorPage } from "~/strapi";
import { Flex } from "@chakra-ui/react";
import { Footer } from "./Footer/Footer";
import { Header } from "./NavHeader/Header";
import { Seo } from "./Seo";
import { LayoutOptions } from "~/types";
import { get } from "lodash";

export const Layout = ({
  children,
  pageData,
  layoutOptions,
}: PropsWithChildren<{
  pageData: StrapiItineratorPage;
  layoutOptions?: LayoutOptions;
}>) => {
  const { seo } = pageData || {};
  const showFooter = get(layoutOptions, "showFooter", true);
  return (
    <>
      <Seo seo={seo} />
      <Header />
      <Flex direction="column">{children}</Flex>
      {showFooter && pageData && <Footer />}
    </>
  );
};
