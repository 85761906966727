import { Flex, FlexProps, Link, Tooltip, useToast } from "@chakra-ui/react";
import { useCopyToClipboard } from "usehooks-ts";
import { Facebook, Instagram, Phone, Share, Twitter } from "~/images/svgs";
import { NewsletterIconButton, useGlobalContext } from "~/providers";
import { StrapiGlobalNavigationComponent } from "~/strapi";
import { RecursiveRequired } from "~/types";

type StrapiSocialType = RecursiveRequired<
  StrapiGlobalNavigationComponent,
  1
>["socialMedia"][0]["social"];
export const SocialIconsNav = (props: FlexProps) => {
  const share = useShare();
  const { navigation } = useGlobalContext();
  const size = 6;
  const getIcon = (social: StrapiSocialType) => {
    switch (social) {
      case "facebook":
        return Facebook;
      case "twitter":
        return Twitter;
      case "instagram":
        return Instagram;
      default:
        return false;
    }
  };
  return (
    <Flex alignItems="center" gap={6} {...props}>
      {navigation.socialMedia?.map((sm) => {
        let Icon = getIcon(sm.social);
        if (!Icon) return null;
        return (
          <Tooltip key={sm.id} label={`Go to ${sm.social}`}>
            <Link href={sm.link} target="_blank" color="gray.900">
              <Icon boxSize={size} />
            </Link>
          </Tooltip>
        );
      })}
      <NewsletterIconButton />
      <Tooltip label="Copy link">
        <Link
          color="gray.900"
          onClick={() => {
            share({ url: document.location.href });
          }}
        >
          <Share boxSize={size} />
        </Link>
      </Tooltip>
    </Flex>
  );
};

const useShare = () => {
  const [value, copy] = useCopyToClipboard();
  const toast = useToast();
  const share = async (data: ShareData & { url: string }) => {
    if (!!navigator.share && !!navigator.canShare && navigator.canShare(data)) {
      navigator.share(data).then(
        () => {},
        (e) => {
          console.warn(e);
        }
      );
    } else {
      copy(data.url).then((success) => {
        toast({
          title: success
            ? "Share link copied to clipboard."
            : "Failed to copy to clipboard.",
          description: "",
          position: "top",
          colorScheme: success ? "sage" : "red",
        });
      });
    }
  };
  return share;
};
