import { Button, Flex, FlexProps } from "@chakra-ui/react";
import { Fragment } from "react";
import { StrapiComponent, isComponentType } from "./helpers";

import { Link } from "@chakra-ui/next-js";
import {
  BlocksProps,
  StrapiBlocksButtonComponent,
  StrapiBlocksImageComponent,
  StrapiBlocksImageWithTextComponent,
  StrapiBlocksMarkdownComponent,
  StrapiBlocksPairedImagesComponent,
  StrapiBlocksQuoteComponent,
  StrapiBlocksVideoComponent,
} from "~/strapi";
import { ImageBlock } from "./ImageBlock";
import { ImageWithTextBlock } from "./ImageWithTextBlock";
import { MarkdownBlock } from "./MarkdownBlock";
import { PairedImagesBlock } from "./PariedImagesBlock";
import { QuoteBlock } from "./QuoteBlock";
import { VideoBlock } from "./VideoBlock";
import { ButtonBlock } from "./ButtonBlock";

type Props<Block extends StrapiComponent> = FlexProps & {
  blocks?: Block[];
  blocks_props?: BlocksProps;
};

export const BlockManager = <Block extends StrapiComponent>({
  blocks,
  blocks_props,
  ...flex_props
}: Props<Block>) => {
  if (!blocks?.length) return null;
  return (
    <Flex direction={"column"} gap={12} {...flex_props}>
      {blocks.map((block) => {
        return (
          <Fragment key={`${block.id} ${block.__component}`}>
            {getBlockComponent(block, blocks_props)}
          </Fragment>
        );
      })}
    </Flex>
  );
};

const getBlockComponent = (block: StrapiComponent, props: BlocksProps = {}) => {
  const {
    image_props,
    markdown_props,
    video_props,
    quote_props,
    image_with_text_props,
    paired_images_props,
    button_props,
  } = props;

  let component: JSX.Element | null = null;
  if (isComponentType<StrapiBlocksMarkdownComponent>("blocks.markdown", block))
    component = <MarkdownBlock block={block} {...markdown_props} />;
  else if (isComponentType<StrapiBlocksVideoComponent>("blocks.video", block))
    component = <VideoBlock block={block} {...video_props} />;
  else if (isComponentType<StrapiBlocksQuoteComponent>("blocks.quote", block))
    component = <QuoteBlock block={block} {...quote_props} />;
  else if (isComponentType<StrapiBlocksImageComponent>("blocks.image", block))
    component = <ImageBlock block={block} {...image_props} />;
  else if (
    isComponentType<StrapiBlocksImageWithTextComponent>(
      "blocks.image-with-text",
      block
    )
  )
    component = <ImageWithTextBlock block={block} {...image_with_text_props} />;
  else if (
    isComponentType<StrapiBlocksPairedImagesComponent>(
      "blocks.paired-images",
      block
    )
  )
    component = <PairedImagesBlock block={block} {...paired_images_props} />;
  else if (isComponentType<StrapiBlocksButtonComponent>("blocks.button", block))
    component = <ButtonBlock block={block} {...button_props} />;
  return component;
};
