export type RoadPathProps = {
  roadColor?: string;
  textColor?: string;
};
export const RoadPath = ({
  roadColor = "#f5d522",
  textColor = "black",
}: RoadPathProps) => {
  return (
    <>
      <g id="Roads">
        <path
          fill={roadColor}
          d="M1481.2,65.201c-3.181,2.518-5.301,4.241-7.421,5.831c-2.12,1.723-4.108,3.578-7.024,6.759
		c-0.663,0.795-1.458,1.59-2.12,2.385s-1.325,1.723-1.988,2.518c-0.663,0.795-1.193,1.723-1.855,2.65
		c-0.265,0.398-0.53,0.928-0.928,1.325c-0.265,0.398-0.53,0.928-0.795,1.458c-0.398,1.06-0.928,2.385-1.193,3.843
		s-0.663,2.915-0.53,4.108l-0.265,0.928c0,0.795,0,1.723-0.132,2.518l0,0c0.132,1.193,0,2.518,0,3.048l0,0v1.325l-0.133-0.265
		c0,1.988-0.132,4.108-0.265,5.831l0.265-0.133c-0.53,3.843-0.132,5.301-0.663,8.879l0,0c0.133,2.253,0,3.843-0.53,5.301
		c-0.265,0.663-0.663,1.325-1.06,1.723c-0.398,0.53-1.06,1.06-1.723,1.325c0.265-0.133-0.132-0.133-0.132-0.133s0,0-0.132,0h-0.265
		c-0.132,0-0.265,0-0.53,0c-0.398-0.133-0.928-0.265-1.325-0.265c-0.928-0.265-1.723-0.53-2.253-0.53
		c0.265,0.795,0.53,1.458,0.795,2.12c0.265,0.53,0.398,1.06,0.53,1.458c0,0.133,0,0.133,0,0.265v0.133
		c0,0.133,0.132,0.133,0.132,0.133c-0.265,0.53-0.398,0.663-0.53,0.663c-0.133,0-0.133,0-0.265,0
		c0.132,0.133-0.53,0.928-1.458,1.193c0.133,0,0.265-0.133,0.398-0.133c-0.663,0.265-1.723,0.265-2.783,0.133
		c0.132,0.133,0.398,0.265,0.53,0.53c-1.59-0.398-0.265-0.53-1.988-0.795l-0.132,0.265c-2.385-0.795-3.843-1.723-6.361-2.253
		c-5.301-0.663-10.867,0.398-15.903,1.723c-2.518,0.663-5.036,1.325-7.421,2.12c-1.193,0.398-2.518,0.795-3.711,1.723
		c-0.53,0.398-1.193,0.928-1.723,1.458c-0.53,0.53-0.928,1.193-1.06,1.988c-0.133,0.133-0.265,0.265-0.398,0.53
		c-0.265,0.133-0.265,1.06-0.132,1.988c0.265,0.928,0.795,1.855,0.795,2.12c0.398,0.398,0.795,0.795,1.06,1.06
		c0.265,0.265,0.795,0.795,0.265,0.53c0.795,0.663,0.795,0.53,1.193,0.795c0.133,0.265,0.133,0.398,0.133,0.663
		c0.132,0.53,0.132,1.325,0.132,2.12c0,0.928-0.132,1.855-0.132,3.048c0.132,1.193,0.53,2.65,1.59,3.578
		c0.53,0.53,1.193,0.795,1.723,1.06c0.663,0.265,1.193,0.398,1.723,0.53c1.325,0.265,2.915,0.398,4.108,0.795
		c0.53,0.133,1.06,0.53,1.59,0.928c0.53,0.53,1.06,1.193,1.59,1.988c1.06,1.59,2.65,3.181,4.241,4.241s3.048,1.855,4.373,2.385
		c3.711,1.458,7.819,2.65,11.795,3.976c3.976,1.325,7.686,2.915,10.602,4.903c0.133,0.133,0.663,0.53,0.663,0.53
		c0.795,0.795,1.59,1.59,2.12,2.385c0.53,0.928,1.06,1.855,1.59,3.048c0,0,0.265,0.53,0.663,1.458
		c0.53,0.928,1.458,2.12,2.783,3.048c1.193,0.928,2.65,1.855,3.711,2.65c1.06,0.795,1.855,1.855,2.253,2.915
		c0.928,2.12-0.398,3.313,0.265,3.313c0.132,0,0.398-0.133,0.663-0.398s0.663-0.795,0.795-1.723c0.133-0.795,0-1.988-0.53-3.181
		c-0.265-0.663-0.663-1.193-1.06-1.855c-0.53-0.663-1.06-1.193-1.723-1.855h0.265c-1.59-1.325-2.915-2.12-3.711-3.048
		c-0.928-0.795-1.458-1.723-2.12-2.915c0.132-0.265-0.133-1.06-0.663-1.855c-0.53-0.795-1.193-1.59-1.458-2.12h0.132
		c-0.928-0.928-1.855-1.855-2.915-2.783c-1.06-0.928-2.12-1.59-3.181-2.12c0.132,0,0.265-0.133,0.663,0.133
		c-1.59-0.795-3.843-1.59-3.843-1.988c-1.988-0.795-4.373-1.458-6.626-1.988s-4.108-1.06-5.433-1.193h0.133
		c-1.458-0.133-1.855-0.53-3.313-0.928c-0.132-0.133-0.132-0.133-0.132-0.133c-0.795-0.133-1.855-0.663-2.783-1.193
		c-0.928-0.53-1.855-1.06-2.385-1.458v-0.133c-0.795-0.53-1.59-1.193-2.253-1.988c0.265,0.265,0.53,0.53,0.795,0.795
		c-0.265,0-0.663-0.398-1.06-1.06c-0.398-0.663-0.927-1.59-1.723-2.518c-0.663-0.928-1.723-1.855-2.915-2.12
		c-1.193-0.398-2.385-0.265-3.578-0.53c-1.06,0.133,0.928-0.265-0.663-0.265c0-0.265,0.795-0.133,0.398-0.265
		c-0.795,0-1.458-0.133-1.988-0.265c-0.53-0.133-1.193-0.398-1.723-0.795l0.132-0.133c-0.795-0.398-0.928-1.458-0.795-2.385
		c0.132-0.928,0.265-1.988,0.132-2.915c0-1.458-0.132-2.12-0.265-2.65c0-0.265-0.132-0.663-0.398-1.06
		c-0.265-0.398-0.795-0.928-1.458-1.193c-0.265-0.265-0.398-0.53-0.53-0.795c-0.265-0.265-0.265-0.265-0.53-0.398
		c-0.133-0.133-0.265-0.133-0.265-0.53c0.132,0,0.132-0.265,0.132-0.398c0-0.265,0.133-0.398,0.133-0.133
		c0.132-0.928,0.53-1.855,1.458-2.65c0.928-0.795,2.385-1.458,4.241-1.723c0.663-0.53,2.65-0.795,3.181-1.193
		c0.132,0.133,3.711-0.928,1.723-0.133c-0.133,0.133-0.53,0.133-0.53,0.133l-0.265,0.265c2.253-0.133,3.843-1.193,5.831-1.458
		c-0.663,0-1.458,0.265-0.928,0c0.663-0.133,1.325-0.265,1.988-0.265l-0.398,0.265c0.928-0.133,1.988-0.265,2.915-0.265
		l-0.398-0.133c1.458,0,1.988,0.265,3.578-0.133c0.663-0.133-1.325-0.265,0.132-0.398c1.855,0.133,4.771,0.398,6.891,1.06
		c-0.132-0.265,0.398-0.133,1.06,0v0.265c1.325,0.265,1.59,0.398,1.723,0.53c0.265,0.133,0.398,0.398,1.855,0.53l-0.265,0.133
		c0.265-0.133,0.53-0.133,0.928-0.265c1.458,0,1.855,0,2.253,0c0.398,0,1.193,0.133,2.65-1.06c-0.53,0.398-0.795,0.398-0.663,0.133
		c0.133-0.133,0.663-0.53,1.193-1.06c0,0.133-0.132,0.265-0.132,0.398c0.663-0.663,0.795-0.928,1.193-1.458
		c-0.133,0.265-0.133,0.398,0,0.265c0.132-0.133,0.398-0.53,0.663-1.193c0,0.398-0.133,0.795-0.265,1.06
		c0.132-0.265,0.132-0.53,0.265-0.663c0-0.133,0.132-0.265,0.132-0.398c0-0.133,0-0.265,0-0.265c0-0.133-0.132-0.265-0.132-0.398
		c0,0,0,0,0-0.133c0-0.133,0,0,0.132,0c0.133,0,0.133,0,0.133,0c0.132,0,0.132,0,0.663-0.133c1.06-0.265,1.723-0.795,2.12-0.795
		c0,0,0.928-0.53,1.59-1.59c0.795-0.928,1.193-2.12,1.193-2.65c-0.132,0.795,0.663-1.193,0.663-0.53c0-0.53,0.133-1.458,0.265-2.385
		c0.132-0.928,0.132-1.855,0.265-2.385v0.265c0.398-3.711,0.132-6.361,0.53-9.807c-0.265,0.133,0-1.59-0.265-1.193
		c-0.133-3.446,0.928-6.361,0.132-7.686c0-1.59,0.265-1.855,0.398-2.65v0.398c0.133-2.915,0.265-3.711,0.795-5.698
		c0.265-1.06-0.265-1.325,0.663-2.915c-0.132,0.663,0.132-0.133,0.53-0.663c-0.265,0.398-0.265,0.53,0.265-0.133
		c0-0.133,0.133-0.265,0.133-0.53l0,0c0.663-1.06,0.398-0.928,0.132-0.398c0,0,0,0-0.265,0.133c0.928-0.928,1.988-2.915,2.253-2.915
		c2.12-2.915,3.711-5.036,5.698-7.289c0.398-0.133-0.133,0.398-0.663,1.06l1.458-1.59c0.133,0.133-0.795,0.928-1.193,1.458
		c1.988-1.59,3.446-3.976,5.964-5.831l0.398-0.53l0.132-0.133c-0.132-0.133,0.398-0.53,0.663-0.928
		c0.398-0.133,0.265,0.265,1.458-0.663c1.06-0.663,2.518-2.253,3.711-3.313c-0.663,0.53-0.53,0.265-0.265,0
		c0.53-0.53,1.06-0.663,1.458-1.06l0.265,0.133c-0.398,0,1.325-1.458,1.458-1.723c0.398-0.133-0.795,0.928-0.928,1.06
		c2.385-1.988,1.193-0.928,3.446-2.915c1.06-1.193-0.398,0,0.132-0.663c1.06-0.928,1.988-1.988,3.313-2.915l-0.53,0.133
		c1.855-1.988,3.048-3.048,4.506-4.241c3.181-3.976,3.976-6.759,4.241-7.951c0.265-1.325,0-1.325-0.53-0.795s-1.06,1.59-1.723,2.385
		c-0.663,0.795-1.193,1.59-1.193,1.59c-2.385,2.783-4.506,4.771-6.759,6.891C1487.296,59.9,1484.778,62.153,1481.2,65.201z"
        />
        <path
          fill={roadColor}
          d="M1681.441,79.248l0.133,0.928c0.398,0.663,0.795,1.59,0.927,2.385h-0.132
		c0.53,1.06,0.928,2.253,1.193,2.783h-0.132l0.53,1.325l-0.132-0.133c0.795,1.723,1.59,3.711,2.12,5.433l0.132-0.265
		c1.193,3.711,2.12,4.903,3.048,8.349h-0.133c1.06,1.988,1.59,3.578,1.59,5.036c0,0.795,0,1.458-0.265,2.12
		c-0.133,0.53-0.398,1.06-0.795,1.458c0.53,0.928,1.06,1.855,1.723,2.783c0.795-0.663,1.193-1.325,1.458-1.59
		c0,0,0.663-0.928,0.928-2.12c0.265-1.193,0.133-2.385,0-2.915c0.265,0.795,0.133-1.325,0.398-0.795
		c-0.133-0.53-0.53-1.458-0.795-2.253c-0.265-0.928-0.663-1.723-0.663-2.253l0.132,0.133c-1.06-3.578-2.518-5.964-3.446-9.144
		c-0.132,0.265-0.663-1.458-0.795-0.928c-1.458-3.181-1.723-6.229-3.048-7.024c-0.663-1.458-0.398-1.855-0.663-2.518l0.132,0.265
		c-1.06-2.65-1.325-3.446-1.59-5.566c-0.265-0.928-0.663-1.193-0.663-3.048c0.133,0.53,0.133-0.133,0.133-0.795
		c0,0.398,0,0.53,0.265-0.265c0-0.133,0-0.265,0-0.53l0,0c0.133-1.193,0-1.06,0-0.398c0,0,0,0-0.132,0.133
		c0.398-1.193,0.663-3.446,0.928-3.578c0.795-3.578,1.193-6.096,2.253-9.012c0.265-0.265,0.132,0.398-0.132,1.325l0.663-1.988
		c0.132,0.133-0.265,1.193-0.53,1.855c1.193-2.253,1.59-5.036,3.048-7.686l0.132-0.663l0.132-0.133
		c-0.132,0,0.133-0.663,0.265-1.193c0.398-0.265,0.398,0.265,1.06-1.193c0.663-1.06,1.325-3.048,1.988-4.506
		c-0.398,0.795-0.398,0.398-0.265,0.133c0.398-0.663,0.663-0.928,0.928-1.59h0.265c-0.398,0.133,0.663-1.855,0.663-2.12
		c0.398-0.265-0.398,1.193-0.398,1.325c1.325-2.783,0.663-1.325,1.988-4.108c0.53-1.59-0.398,0.133-0.132-0.663
		c0.53-1.325,1.06-2.65,1.855-4.108l-0.398,0.398c0.928-2.518,1.59-3.976,2.385-5.698c1.193-4.903,0.928-7.819,0.663-9.012
		c-0.265-1.325-0.53-1.193-0.795-0.53c-0.265,0.663-0.398,1.855-0.663,2.915c-0.265,1.06-0.53,1.988-0.53,1.988
		c-1.06,3.578-2.253,6.229-3.313,9.012c-1.193,2.915-2.518,5.963-4.506,10.204c-1.855,3.711-3.181,5.963-4.373,8.349
		c-1.193,2.385-2.385,4.903-3.578,9.144c-0.265,1.06-0.663,1.988-0.927,3.048c-0.265,1.06-0.53,2.12-0.795,3.181
		s-0.398,2.12-0.53,3.181c-0.132,0.53-0.132,1.06-0.265,1.59c0,0.53-0.133,1.193-0.133,1.723c0.133,1.06,0.133,2.518,0.398,3.976
		S1681.043,78.188,1681.441,79.248z"
        />
        <path
          fill={roadColor}
          d="M1078.333,224.492c0.397,0.398,0.795,0.795,0.928,1.06c0.133,0.265,0.265,0.53,0.265,0.795
		c0.132,0.53,0,1.325,0.132,2.385c0.265,2.12,1.458,4.506,3.976,6.229c1.193,0.928,2.518,1.59,3.711,2.12
		c1.193,0.53,2.385,1.06,3.446,1.458c1.193,0.133,2.518,1.458,3.446,2.12c0,0.133,0.132,0.265,0.132,0.398
		c0.265,0.265,0.53,0.53,0.663,0.928l0,0c0.398,0.398,0.398,1.06,0.53,1.325l0,0c0,0.265,0.132,0.398,0.132,0.663l-0.132-0.133
		c0.265,0.928,0,1.723-0.53,2.12l0.265,0.133c-0.53,0.398-1.193,0.795-1.855,1.06c-0.398,0.133-0.663,0.265-1.193,0.53
		c-0.265,0.133-0.398,0.133-0.663,0.265c-0.265,0.133-0.663,0.265-0.928,0.53v-0.133c-1.193,1.325-1.855,2.12-2.65,2.915
		c-0.795,0.795-1.59,1.723-2.12,3.181c0.132-0.398-0.132,0.133-0.265,0c-0.663,1.193-1.325,2.518-0.928,2.783
		c-0.398,0.928-0.53,0.663-0.663,0.663c0.132,0.133-0.132,0.928-0.398,1.723v-0.398c-0.265,0.53-0.397,1.325-0.265,2.253l0.132,0.53
		l0.265-0.53c0.132,1.193-0.398,0.398,0,1.59l0.265-0.133c0.398,1.59,0.398,2.65,0.53,3.976c0.132,0.663,0.265,1.325,0.265,1.988
		c0,0.663,0,1.458,0,2.253c0,1.59-0.132,3.578,0.795,5.433c0.53,0.928,1.193,1.59,1.723,2.253c0.53,0.53,1.06,1.06,1.325,1.59
		c0.663,1.06,0.928,2.518,0.928,3.843l-0.265,0.265c-0.265,0.133,0.663,1.59,0.398,1.855l0.265,0.663c0,0.265,0,0.663-0.132,0.265
		c0.132,0.663,0.132,0.663,0.397,1.193c0,0.265,0,0.53,0,0.795c0.265,1.458,1.06,2.783,2.12,3.711
		c0.53,0.53,0.928,0.795,1.325,1.193c0.265,0.398,0.53,0.663,0.663,1.193c0,0.53,0.133,1.193,0.133,1.855
		c0,0.663-0.265,1.458-0.663,2.253c-0.398,0.795-0.795,1.458-1.458,1.855c-0.265,0.265-0.663,0.398-0.928,0.663h-0.133
		c-0.132,0-0.132,0.133-0.265,0.133l-0.53,0.133c-0.398,0.133-0.663,0.133-1.06,0.265c-0.132,0.53-0.132,1.193-0.132,1.59
		c0,0.53-0.132,1.06-0.132,1.59c0,0.53-0.133,0.928-0.133,1.325l-0.132,0.663l-0.133,0.265l-0.132,0.265c0,0,0,0.265-0.132,0.133
		c-0.265-0.133-0.265-0.133-0.53-0.133h-0.265c0,0-0.265,0-0.398,0c-0.265,0-0.53,0.133-0.795,0.133
		c-1.06,0.265-1.988,0.928-2.65,1.59c-1.458,1.325-2.385,2.783-3.048,4.373c-0.133,0.133-0.265,0.53-0.133,0.53
		c-0.663,1.325-1.325,2.783-1.988,4.108c0,0-0.53,1.325-1.193,2.915c-0.663,1.723-1.458,3.843-1.855,5.433
		c-0.397,1.59-0.663,2.915-0.132,2.65c0.398-0.133,1.59-1.59,3.711-5.168v0.133c1.06-2.253,1.723-3.578,2.12-5.301
		c0.53,0,0.928-1.855,1.193-2.253l0,0c0.132-0.795,0.53-1.458,0.928-1.988c0.398-0.663,0.795-1.193,1.06-1.855
		c0,0,0.132,0.133,0,0.398c0.398-0.795,1.193-1.855,1.458-1.723c0.397-0.53,1.06-0.928,1.59-1.193c0.133,0,0.265-0.133,0.398-0.133
		h0.132c0.133,0,0.265,0,0.53,0c0.132,0,0.265,0,0.53,0l0.663,0.133c0.398,0.133,0.795,0.133,1.193,0.265
		c0.265-1.458,0.398-3.313,0.398-3.976v0.133c-0.133-0.928,0.132-1.193,0.132-2.253c0,0,0-0.133,0.132-0.133
		c0-0.133,0-0.265,0-0.398s0-0.133,0-0.265s0-0.398,0.133-0.53c0.132,0,0.397,0,0.53-0.133c0,0,0.265-0.133,0.398-0.133
		s0.265-0.133,0.398-0.265l0.132,0.133c0.795-0.398,1.325-0.928,1.855-1.458c-0.265,0.133-0.398,0.398-0.663,0.53
		c0-0.265,0.265-0.53,0.795-0.928c0.398-0.398,1.06-0.928,1.325-1.855c0.397-0.663,0.397-1.59,0.265-2.385
		c-0.132-0.795-0.265-1.458-0.265-2.253c-0.265-0.663,0.397,0.53,0.132-0.53c0.265-0.133,0.265,0.53,0.398,0.133
		c-0.398-1.06-0.265-1.988-1.325-3.048l0.132-0.133c-0.663-0.53-1.193-1.06-1.59-1.59c-0.265-0.53-0.53-0.928-0.795-1.193
		c-0.398-0.663-0.265-0.928-0.265-1.193s0-0.53-0.265-1.325l0.132-0.663c0-0.663-0.397-0.398-0.397-1.193c0.132,0,0-1.06,0.265-0.53
		c0-0.928-0.132-1.855-0.53-3.048c-0.398-1.193-0.795-2.253-1.59-3.711c-0.663-0.398-1.458-1.325-1.855-1.458
		c0-0.133-0.265-0.53-0.53-0.795c-0.132-0.265-0.265-0.398,0.132-0.133c0,0,0.133,0.133,0.133,0.265h0.265
		c-0.265-0.53-0.398-0.795-0.53-1.193c-0.265-0.398-0.397-0.795-0.397-1.325c0,0.398,0,0.795-0.133,0.53c0-0.265,0-0.663,0-1.06
		l0.133,0.265c0-0.53,0.132-1.193,0.132-1.723l-0.265,0.265c0.132-0.928,0.53-1.193,0.265-2.385
		c-0.132-0.53-0.265,0.928-0.265-0.133c0-0.663,0.132-1.59,0-2.518c-0.133-0.928-0.398-1.855-0.663-2.65
		c-0.133,0.265-0.265-0.133-0.53-0.53l0.132-0.133c-0.398-0.663-0.265-0.795-0.265-1.06c0.132-0.133,0.132-0.265-0.133-0.928
		l0.133,0.133c-0.133-0.133-0.265-0.133-0.53-0.265l0,0c-0.132-0.133,0-0.265,0.132-0.398c0.133-0.133,0.265-0.265,0.265-1.06
		c-0.265,0.53-0.53,0.133-0.133-0.53l0.133,0.265c0.132-0.398,0.132-0.53,0.265-0.795c0,0.265,0.133,0.265,0.398-0.265
		c0,0.133,0,0.265,0,0.53c0-0.398,0.132-0.795,0.265-1.193c0.663-0.663,1.193-2.12,1.723-2.253c0,0,0.265-0.398,0.663-0.795
		c0.265-0.398,0.663-0.928,0.663-1.193c-0.132,0.398,0.663-0.398,0.663,0c0.132-0.53,1.06-1.723,1.59-1.855v0.133
		c0.132-0.133,0.398-0.265,0.53-0.398c0.132,0,0.132-0.133,0.398-0.265c0.397-0.133,0.928-0.398,1.325-0.53
		c0.928-0.398,1.988-0.928,3.181-1.988c-0.265,0,0.795-1.193,0.265-1.06c0.663-1.325,0.928-2.65,0.928-3.711
		s-0.265-1.855-0.795-2.12c-0.397-1.06-0.397-1.325-0.663-1.855l0.133,0.265c-0.663-0.928-1.193-1.458-1.59-1.855
		c-0.398-0.398-0.928-0.795-1.458-1.193c-0.663-0.398-1.06,0-2.12-0.795c0.398,0,0-0.133-0.398-0.398
		c0.265,0.133,0.398,0.133-0.132-0.265c-0.133,0-0.265,0-0.398,0l0,0c-0.795-0.265-0.663-0.133-0.265,0c0,0,0,0,0,0.133
		c-0.663-0.53-1.988-0.928-1.988-1.193c-2.12-0.928-3.578-1.458-4.903-2.65c0-0.265,0.265,0,0.795,0.265
		c-0.265-0.265-0.663-0.53-0.928-0.795c0.132-0.133,0.53,0.398,0.928,0.663c-0.398-0.663-0.928-1.06-1.458-1.59
		c-0.398-0.53-0.795-1.193-0.795-2.12l-0.265-0.398v-0.133c-0.132,0-0.265-0.398-0.265-0.663c0.132-0.265,0.53,0,0.265-1.06
		c0-0.53-0.265-1.193-0.795-1.723c-0.53-0.53-1.06-0.928-1.458-1.193c0.398,0.398,0.265,0.398,0,0.265
		c-0.398-0.265-0.53-0.663-0.795-0.795l0.132-0.265c0,0.265-1.06-0.53-1.193-0.663c0-0.265,0.663,0.398,0.795,0.265
		c-1.325-1.193-0.663-0.53-1.723-1.855c-0.663-0.663-0.133,0.265-0.53,0c-0.53-0.663-1.06-1.325-1.458-2.253v0.398
		c-0.795-1.458-1.06-2.385-1.06-3.578c-1.06-2.915-1.06-4.771-1.193-5.698c-0.133-0.928-0.398-0.928-0.663-0.53
		c-0.265,0.398-0.53,1.325-0.663,2.12c0,0.398-0.133,0.795-0.133,1.06c0,0.265,0,0.398,0,0.398c-0.132,2.783,0.53,4.903,1.59,6.891
		c0.53,1.06,1.193,1.988,1.988,2.915c0.398,0.53,0.928,0.928,1.458,1.458C1077.14,223.565,1077.802,224.095,1078.333,224.492z"
        />
        <path
          fill={roadColor}
          d="M994.578,270.875c0.53-0.265,0.928-0.53,1.325-0.663c0.398-0.53,0.928-1.193,1.325-1.59
		c0.265-0.398,0.663-0.795,0.928-1.325c0.663-0.795,1.193-1.59,1.723-2.385c1.06-1.458,1.855-2.783,2.783-4.241
		c0.795-1.458,1.723-2.783,2.518-4.506c0.398-0.795,0.928-1.723,1.325-2.783c0.398-1.06,0.795-2.12,1.325-3.313
		c0.928-2.385,1.988-4.771,2.916-7.289s1.723-5.168,1.988-7.951c0-1.325,0-3.181-0.53-5.168l-0.265-0.663l-0.132-0.398v-0.133
		v-0.133v-0.133c0,0,0,0,0.132-0.133c0.133-0.133,0.133-0.265,0.265-0.398c0.133-0.265,0.265-0.53,0.398-0.795
		c0.265-0.265,0.53-0.398,0.663-0.53c0.398-0.663,1.193-1.325,1.988-1.723v0.133c0.928-0.795,2.253-1.325,2.916-1.458v0.133
		c0.53-0.133,0.928-0.265,1.458-0.398l-0.265,0.133c0.265-0.133,0.398-0.133,0.795-0.133c0.398-0.133,0.663-0.133,1.06-0.265
		c0.663-0.133,1.458-0.53,2.12-0.795c1.325-0.663,2.518-1.458,3.446-2.385h-0.265c0.53-0.53,0.928-0.928,1.325-1.458
		c0.133-0.265,0.265-0.53,0.53-0.795c0.132-0.133,0.265-0.398,0.398-0.53c0.398-0.795,0.928-1.458,1.325-2.12
		c0.398-0.663,0.795-1.325,1.325-1.855c0.53-0.663,1.193-1.458,1.723-1.855v0.133c0.265-0.398,0.53-0.53,0.795-0.663
		c0.265-0.133,0.663,0,1.193,0.133c0.928,0.265,2.12,0.795,3.313,1.06c2.385,0.53,5.168,0.398,7.951-1.06
		c-0.663,0.398,0.398,0,0.265,0c1.325-0.663,2.518-1.458,3.313-1.988c0.795-0.53,1.325-0.928,1.325-1.06
		c1.59-0.663,1.325-0.265,1.59-0.133c0-0.133,1.325-0.53,2.65-0.265c-0.265,0-0.397,0-0.663,0c1.193,0.133,2.518,0.53,3.843,0.928
		c-0.265-0.265-0.398-0.398-0.663-0.663c1.988,0.928,0.265,0.53,2.253,1.458l0.133-0.265c2.783,1.458,4.241,2.915,7.554,3.711
		c0.928,0.133,1.855,0.265,2.783,0.133c0.53,0,0.928-0.133,1.458-0.265c0.265-0.133,0.53-0.133,0.663-0.265
		c0.132,0,0.265-0.133,0.397-0.133l0.53-0.265l0.928-0.53c0.132-0.133,0.265-0.133,0.397-0.265l0.133-0.53
		c0.132-0.265,0.132-0.663,0.132-1.06l0.132-0.663l0.398-2.385l-2.518,0.928c0.53,0.398,1.325,0.663,1.988,0.53
		c0.663,0,1.193-0.265,1.59-0.53c0.928-0.663,1.458-1.458,1.723-2.65c0.265-1.59-0.265-2.783-0.663-3.843
		c-0.133-0.53-0.398-0.928-0.398-1.325c-0.132-0.398-0.132-0.795-0.132-1.193c0-0.398,0.132-0.53,0.265-0.795
		c0.132-0.133,0.265-0.265,0.795-0.53c0.398-0.265,1.06-0.53,1.59-1.06c0.53-0.53,0.795-1.193,1.06-1.59l0.265-0.795l0.265-0.53
		l0.663-1.325c0.53-0.795,1.06-1.723,1.59-2.518c1.06-1.723,2.12-3.313,3.181-4.903l0.53-0.265c0.398,0,1.325-3.048,1.855-3.181
		l0.663-1.193c0.265-0.265,0.795-0.928,0.398-0.265c0.663-1.06,0.663-1.193,1.06-1.988l0.928-1.06
		c1.325-2.12,2.518-4.108,3.711-6.229l0.397-0.795l0.265-0.398l0.132-0.265v-0.133c0-0.133,0.132-0.265,0.132-0.265l0.133-0.133
		l0.398-0.265c1.06-0.53,2.12-1.193,3.313-1.723c2.12-0.928,4.771-2.253,7.686-3.843c1.458-0.795,2.916-1.59,4.373-2.385
		c1.59-0.795,3.048-1.723,4.506-2.518l0.397-0.53l0.265-0.265c0.265-0.133,0.398-0.398,0.53-0.53
		c0.397-0.398,0.795-0.795,1.193-1.193c0.795-0.663,1.59-1.325,2.253-1.855c3.711-2.915,8.481-5.036,13.385-6.229
		c2.518-0.53,5.036-1.06,7.421-1.458c2.518-0.398,4.903-0.53,7.421-0.795c0.265,0,1.193-0.133,1.193-0.133
		c0.795,0,1.59-0.133,2.253-0.133l2.253-0.265c1.59-0.133,3.048-0.53,4.638-0.795c0,0,0.132,0,0.53-0.133
		c0.265-0.133,0.795-0.133,1.325-0.398c0.53-0.133,1.193-0.265,1.988-0.53c0.795-0.265,1.59-0.53,2.385-0.795
		c3.446-1.193,7.554-3.446,10.337-5.698c2.783-2.253,4.108-4.506,3.313-4.241c-0.398,0.133-1.193,0.795-2.783,1.855
		c-1.59,1.06-4.241,2.65-7.951,3.976l0.132-0.133c-4.373,1.855-7.289,2.518-10.602,3.578c-0.265-0.133-1.325,0-2.385,0.265
		c-1.06,0.133-2.253,0.398-2.783,0.398h0.132c-1.458,0.398-2.916,0.265-4.506,0.398c-1.59,0-3.181,0-4.638,0.265
		c0.132,0,0.132-0.133,0.795-0.265c-1.06,0.133-2.385,0.265-3.313,0.398c-1.06,0.133-1.855,0.133-1.855,0.133
		c-2.518,0.53-5.566,1.193-8.349,2.12c-2.783,0.928-5.168,2.12-6.494,3.048l0.132-0.133c-1.59,1.193-2.12,1.193-3.843,2.385
		c-0.132,0-0.265,0.133-0.265,0.133c-0.795,0.663-1.988,1.59-3.181,2.518c-1.193,0.928-2.253,1.855-2.783,2.518h-0.265l-0.265,0.265
		l-0.132,0.133l-2.651,1.458l1.325-0.663c-0.265,0.663-2.783,1.193-5.301,2.783c-2.385,1.193-4.373,3.446-7.024,4.506
		c-1.06,0.795,0.928-0.928-0.795,0.265c-0.132-0.133,0.795-0.53,0.265-0.53c-1.723,1.06-3.048,1.325-5.036,2.518l-0.132-0.133
		c-0.132,0.133-0.265,0.133-0.53,0.398c-0.265,0.133-0.53,0.265-0.795,0.398c-0.265,0.133-0.265,0.53-0.398,0.663
		c-0.132,0.265-0.265,0.53-0.265,0.663c-0.398,0.663-0.663,1.193-1.06,1.855c-0.795,1.193-1.458,2.12-1.855,3.181
		c-1.59,2.915-1.59,1.59-3.181,4.638l-0.928,0.928c-0.795,1.193-0.265,1.06-1.193,2.385c-0.132-0.133-1.193,1.723-0.795,0.795
		c-1.06,1.458-1.988,3.181-3.048,4.903c-0.53,0.928-1.06,1.855-1.59,2.783c-0.663,0.928-1.193,1.988-1.855,3.048
		c-0.132,0.663-0.398,1.458-0.663,1.855c-0.132,0.265-0.265,0.265-0.398,0.53c-0.132,0.133-0.397,0.265-0.53,0.398
		c0,0-0.265,0.133-0.795,0.398c-0.53,0.265-1.193,0.928-1.458,1.59c-0.265,0.663-0.398,1.06-0.398,1.193
		c0,0.133-0.133-0.265,0.265-1.325c0.133-0.398,0.663-0.928,0.663-0.928c0-0.133,0.133-0.398,0.265-0.53
		c-1.06,0.795-1.59,1.988-1.59,2.915c-0.132,0.928,0.132,1.855,0.265,2.518c0.265,0.795,0.53,1.325,0.795,1.855
		s0.398,1.06,0.265,1.325c0.133-0.398-0.265-1.325,0.133-0.795c0.132,0.398,0.132,0.795,0,1.06c0,0.133,0,0.133-0.133,0.265
		c0-0.133,0-0.133-0.132-0.133c0,0.133-0.265,0.265-0.398,0.398c-0.132,0-0.132-0.133,0,0.265c0,0.265-0.132,0.265,0,0.133
		l-2.65,0.795l0,0l0,0l2.65-0.795c0,0,0,0,0,0.133l0,0l0,0v-0.133l-2.518,0.795c-0.265,0.663-0.398,1.325-0.53,1.855
		c-0.132,0.265-0.132,0.53-0.265,0.928c0,0.133,0,0.133,0,0.133l0,0v0.133c0,0.133,0.132,0.133,0.265,0.133c0,0,0,0,0.133,0
		c0.132,0,0,0,0,0.133c0,0,0,0.133-0.133,0.133c0,0-0.132,0-0.132,0.133c-0.132,0,0,0-0.132,0c-0.265,0.133-0.53,0.133-0.795,0.133
		c-0.53,0.133-1.193,0.133-1.855,0c-1.325-0.133-2.783-0.663-4.108-1.325c0.133,0.265-0.53,0-1.325-0.265v-0.795
		c-1.59-0.663-1.723-0.795-1.988-1.06c-0.265-0.265-0.398-0.53-2.12-1.06h0.398h-1.06c-1.59-0.398-1.988-0.53-2.518-0.795
		c-0.53-0.133-1.06-0.53-3.048-0.133c0.795-0.133,0.928,0.133,0.663,0.133c-0.265,0.133-0.928,0.133-1.855,0.265l0.398-0.265
		c-1.06,0.133-1.325,0.398-1.988,0.53c0.53-0.265,0.133-0.398-1.193,0.265c0.265-0.265,0.53-0.398,0.795-0.663
		c-0.928,0.53-1.59,1.06-2.253,1.458c-0.928,0.398-1.988,1.06-2.783,1.458c-0.928,0.398-1.723,0.663-2.12,0.53
		c0,0-0.928,0.133-1.855,0.265c-1.06,0.133-2.12,0-2.518,0.133c0.663-0.133-1.193-0.53-0.663-0.53c-0.53,0-1.325-0.133-2.518-0.53
		c-0.663-0.133-1.325-0.265-2.12-0.265c-0.795,0-1.458,0.265-1.988,0.398c0.132-0.133,0.265-0.133,0.397-0.133
		c-0.928,0.398-1.59,0.928-1.988,1.325c-0.53,0.53-0.795,0.928-1.193,1.325c-0.663,0.795-1.325,1.723-1.855,2.518
		s-1.06,1.723-1.59,2.518c-0.265,0.53-0.53,0.795-0.663,1.193c-0.265,0.265-0.53,0.663-0.928,0.928c0.133,0-0.133,0.398-0.398,0.663
		c-0.265,0.265-0.53,0.53-0.265,0.53c-0.663,0.663-1.59,1.06-2.385,1.193c-0.795,0.265-1.458,0.265-2.518,0.398
		c-1.855,0.265-3.181,0.928-3.711,1.723c-1.855,1.06-2.253,1.193-3.181,1.988l0.398-0.398c-1.59,1.458-2.253,2.518-2.783,3.578
		c-0.133,0.265-0.398,0.663-0.53,1.06c-0.133,0.398-0.53,0.795-0.133,1.193c0.133,0.133,0.133,0.398,0.265,0.53
		c0.133,0.265,0.133,0.398,0.265,0.53c0,0.265,0.133,0.53,0.265,0.795c0.265,0.53,0.398,0.928,0.53,1.325
		c0.133,0.53,0.265,1.06,0.133,2.253c-0.133-0.795-0.133,0.133-0.265,1.06c0-0.53,0-0.795-0.265,0.398c0,0.133,0,0.398,0,0.663l0,0
		c-0.265,1.458-0.132,1.193,0,0.53c0,0,0,0,0.133-0.265c-0.53,1.458-1.193,4.108-1.59,4.241c-1.59,4.241-2.65,7.421-3.976,10.999
		c-0.265,0.265-0.133-0.53,0.265-1.59c-0.265,0.795-0.663,1.723-1.06,2.518c-0.133-0.133,0.53-1.458,0.795-2.253
		c-1.458,2.783-2.518,6.096-4.638,9.277l-0.265,0.795l-0.132,0.133c0.132,0-0.265,0.795-0.53,1.325
		c-0.398,0.398-0.265-0.265-1.325,1.325c-0.398,0.53-0.928,1.325-1.458,2.12c-0.53,0.795-1.06,1.59-1.458,2.385
		c-0.132,0-0.132,0.133-0.265,0.133c0.132,0,0.132-0.133,0.265-0.133c0.133-0.133,0.133,0,0.133,0.133c0,0,0,0.133-0.133,0.133
		c-0.132,0-0.265,0.133-0.53,0.265s-0.398,0.133-0.53,0.265c-0.265,0.133-0.663,0.265-0.928,0.53l-0.265-0.133
		c0.398,0-1.988,1.325-2.385,1.59c-0.53,0.133,1.193-0.795,1.325-1.06c-3.048,1.723-2.253,1.193-4.638,2.518
		c0.132-0.133-0.133,0.398-0.398,1.193c-0.53,1.988,0.398-0.265,0.265,0.795c-0.663,1.723-1.06,3.313-1.988,5.168l0.398-0.53
		c-0.53,1.59-0.928,2.783-1.458,3.976c-0.398,1.193-0.795,1.988-1.458,3.313c0,0.133-0.132,0.265,0,0.265
		c0.398,0.265,0.663,0.663,0.928,0.928c0.265,0.265,0.398,0.53,0.663,0.795c0.398,0.53,0.663,1.193,0.795,1.723
		c0.398,1.193,0.398,2.253,0.398,3.181c-0.133,1.855-0.928,3.181-1.325,3.843c-0.133,0.133-0.265,0.398-0.265,0.53
		c-0.265-0.133-0.398-0.398-0.663-0.53c0.265,0.265,0.663,0.663,1.06,1.06c0.928-0.663,2.253-2.253,2.783-4.108
		c0.265-0.928,0.398-1.723,0.398-2.385s0-1.06,0-1.06c-0.132-1.193-0.398-2.253-0.795-3.181c-0.265-0.53-0.53-0.928-0.795-1.325
		c-0.132-0.133,0,0,0-0.133l0,0l0,0l0,0l0,0l0.133-0.398c0.265-0.53,0.398-1.06,0.663-1.59c0.398-1.06,0.795-2.12,1.193-3.181
		c0.398-1.06,0.795-2.12,1.193-3.313c0.265-0.53,0.398-1.193,0.663-1.723c0.133-0.265,0.265-0.53,0.265-0.928
		c0.132-0.265,0-0.133,0-0.133v-0.133c0,0,0,0,0.132-0.133c0.265-0.133,0.53-0.265,0.795-0.398c0.53-0.265,1.06-0.53,1.59-0.928
		C992.193,272.2,993.253,271.538,994.578,270.875z"
        />
        <path
          fill={roadColor}
          d="M1019.493,335.016c-3.181,2.783-4.771,5.168-5.831,7.686c-0.53,1.325-0.928,2.65-1.193,4.373
		c-0.265,1.59-0.132,3.313-0.265,5.036c-0.133,0.795-0.265,1.458-0.53,1.988c-0.265,0.53-0.663,0.795-1.458,1.325
		c-0.795,0.398-1.855,1.193-2.65,1.855c-0.795,0.663-1.59,1.458-2.253,2.253c-1.06,1.59-3.181,4.241-4.108,6.096l-0.663,0.53
		c-0.265,0.663-0.663,1.325-1.193,1.855l0,0c-0.53,0.928-1.325,1.723-1.59,2.12l0,0l-0.795,0.928l0.133-0.265
		c-0.53,0.663-1.193,1.193-1.855,1.723c-0.663,0.398-1.458,0.928-1.855,0.928c0,0,0.132,0.133,0.132,0.265c-0.265,0-0.398,0-0.53,0
		c-0.133,0-0.265-0.133-0.398-0.265c-0.265-0.265-0.663-0.663-1.06-1.06c-0.398-0.53-0.663-0.928-1.193-1.59
		c-0.53-0.663-1.193-1.325-2.12-1.855h0.132c-1.193-0.53-2.253-0.663-3.181-0.928c-0.928-0.265-1.723-0.53-2.385-0.663
		c-0.663-0.265-1.193-0.663-1.723-1.06c-0.265-0.265-0.53-0.398-0.663-0.663c-0.133-0.133-0.265-0.133-0.265-0.398
		c-0.133-0.398-0.265-0.928-0.398-1.325c0.265,0.928,0-0.53,0.132-0.53v-0.133c-0.795,0-1.723,0-2.385,0c-0.928,0-1.193,0-1.59,0
		c-0.795,0-1.325,0.133-1.325,0.398c-1.458,0-1.193-0.133-1.325-0.265c0,0.133-1.325,0.265-2.385,0.398l0.53-0.133
		c-1.06,0-2.253,0.133-3.313,0.265l0.663,0.265c-1.723,0-0.53-0.398-2.12-0.133v0.265c-2.385,0.265-3.976,0-6.361,0.398
		c-2.385,0.663-4.903,1.59-7.156,3.181c-1.06,0.663-2.12,1.59-3.048,2.518c-0.928,0.928-1.723,1.723-2.65,2.518
		c-1.59,1.458-3.843,1.855-5.036,0.795c-1.325-1.06-1.723-3.578-2.253-5.698l0.133-0.398c0.265-0.265-1.193-2.12-0.928-2.518
		l-0.398-0.928c0-0.265,0-0.928,0.133-0.398c-0.133-0.928-0.265-0.928-0.398-1.59c0-0.265,0-0.663,0.132-1.06
		c-0.132-1.723-0.132-3.578-0.663-5.566c-0.265-0.928-0.663-1.988-1.458-2.915c-0.663-0.928-1.59-1.723-2.518-2.253
		c-0.795-0.663-1.855-1.193-2.916-1.723c-0.53-0.265-1.06-0.53-1.59-0.795l-0.398-0.265l-0.265-0.133c-0.133-0.133,0-0.265,0-0.398
		c0,0,0.133-0.133,0.133-0.265c0.132-0.133,0.398-0.398,0.663-0.53c0.398-0.398,1.06-0.795,1.59-1.325
		c0.265-0.265,0.663-0.663,0.928-1.06c0.133-0.265,0.265-0.53,0.265-0.663c0-0.265,0.132-0.53,0.132-0.795
		c0-0.663-0.132-1.325-0.398-1.723c-0.265-0.53-0.53-0.928-0.795-1.193c-0.663-0.663-1.458-1.193-2.253-1.325
		c-0.265,0-0.398,0-0.53,0c-0.133,0.133-0.133-0.133-0.133-0.133l-0.132-0.265l-0.133-0.53l-0.265-0.663
		c-0.398-0.928-0.795-1.723-1.193-2.65c-0.795-1.59-1.723-3.446-2.65-5.036c-0.53-0.795-1.06-1.723-1.59-2.518
		c-0.265-0.398-0.663-0.795-0.928-1.193c-0.265-0.398-0.795-0.795-1.325-1.193c-1.06-0.663-2.253-1.06-3.181-1.325
		c-1.06-0.133-1.988-0.133-3.048-0.133c-0.265,0-0.928,0.133-0.928,0.133c-0.53,0-1.06-0.398-1.193-0.795c0-0.133,0-0.265,0-0.398
		v-0.795c0-0.663,0-1.193,0-1.855c0,0,0-0.53-0.133-1.458s-0.398-2.12-0.928-3.578c-0.265-0.663-0.53-1.458-1.06-2.253
		c-0.398-0.663-0.795-1.193-1.193-1.855c-0.663-1.193-1.325-2.518-1.458-3.578c-0.53-2.12,0.265-3.578-0.265-3.446
		c-0.265,0.133-0.928,0.53-1.325,2.12c-0.398,1.59,0,4.108,1.458,7.156l-0.132-0.133c0.53,0.928,0.795,1.458,1.06,2.12
		c0.265,0.663,0.398,1.325,0.53,1.855c0.265,1.193,0.53,2.385,0.795,3.578c-0.265,0.133-0.265,0.928-0.265,1.723
		c0,0.53,0.133,1.325,0.265,1.855c0.265,0.53,0.53,0.928,0.663,1.193h-0.133c0.663,0.53,1.325,1.06,2.253,1.193
		c0.265,0,0.398,0.133,0.663,0.133h0.53h0.795c1.06,0.133,2.12,0.265,3.048,0.398c-0.133,0-0.133,0.133-0.53,0
		c0.663,0.133,1.325,0.398,1.855,0.663c0.265,0.133,0.398,0.265,0.53,0.53c0.132,0.133,0.265,0.265,0.132,0.398
		c1.193,1.325,2.385,3.048,3.578,4.903c1.06,1.723,2.12,3.446,2.783,4.373l-0.133-0.133c0.398,0.53,0.53,0.928,0.663,1.325
		c0.133,0.265,0.265,1.193,0.53,2.385c0,0.133,0,0.398,0,0.398c0,0.133,0.132,0.133,0.132,0.265c0.663-0.133,1.59-0.398,1.988-0.265
		c0.265,0,0.53,0.265,0.795,0.53c0.265,0.265,0.398,0.53,0.53,0.795c0.133,0.265,0.133,0.398,0.133,0.663v0.133h-0.133
		c0,0.133-0.132,0.265-0.132,0.265s0,0.133-0.133,0.133c0,0-0.133,0.133-0.265,0.265c-0.265,0.265-0.663,0.663-1.06,0.928
		l0.928-0.663c0,0.265-0.398,0.53-1.193,1.06c-0.133,0.133-0.398,0.265-0.53,0.398c-0.265,0.133-0.398,0.265-0.663,0.663
		c-0.53,0.53-0.928,1.325-0.928,2.12c0,0.133,0,0.398,0,0.53c0,0.133,0,0.398,0.133,0.53c0,0.133,0,0.398,0.265,0.53
		c0.132,0.133,0.265,0.133,0.398,0.265c0.265,0.265,0.663,0.398,1.06,0.53c0.398,0.133,0.663,0.265,0.928,0.265
		c1.06,0.265,2.12,0.53,3.048,1.06c1.06,0.265-0.928,0,0.398,0.398c-0.133,0.265-0.663-0.133-0.398,0.133
		c1.325,0.53,1.988,1.325,3.048,2.385l-0.662-0.662c0.663,0.663,0.928,1.723,1.06,2.65c0.133,0.928,0.133,1.855,0.398,2.65
		c0.398,2.518-0.53,1.723,0.265,4.373v1.06c0.265,1.193,0.53,0.663,0.663,1.988c-0.133,0,0.265,1.59,0,0.928
		c0.265,1.458,0.795,2.65,1.325,4.241c0.265,0.795,0.663,1.59,1.193,2.518c0.53,0.795,1.325,1.855,2.65,2.518
		c0.663,0.133,1.458,0.265,2.385,0.133c0.795,0,1.59-0.265,1.855-0.53c0.133,0,1.193-0.265,1.855-0.663s1.06-0.663,0.133,0.133
		c-0.133,0.133-0.53,0.265-0.53,0.265l-0.265,0.265c2.65-0.928,3.446-2.783,4.903-4.108c-0.398,0.265-1.06,0.928-0.795,0.398
		c0.398-0.398,0.928-0.795,1.325-1.193l-0.133,0.398c0.663-0.53,1.458-1.06,2.12-1.458h-0.398c0.53-0.265,1.06-0.398,1.458-0.663
		c0.398-0.133,0.795-0.398,1.458-0.795c0.53-0.398-1.193,0.265,0-0.398c1.59-0.663,4.241-1.193,6.494-1.458
		c-0.265-0.133,0.398-0.265,0.928-0.398l0.133,0.133c2.385-0.663,0.928,0.265,3.313-0.133l-0.265,0.133l0.663-0.53
		c2.253-0.53,1.193,0.265,3.711-0.265c-0.928,0-0.53-0.398,0.795-0.398l-0.265,0.133c0.663,0,0.928-0.133,1.325-0.133
		c-0.398,0.133-0.265,0.265,0.795,0.265l-0.663,0.265l1.855-0.398c0.133,0,0.398,0,0.53,0h0.265c0.133,0,0.398,0,0.398,0
		c0,0.133,0,0.133,0.132,0.265c0.133,0.398,0.265,0.663,0.398,0.928c0,0,0.663,0.928,1.59,1.59c0.928,0.663,1.988,1.06,2.385,1.06
		c-0.795,0,1.193,0.663,0.53,0.663c0.928,0,3.313,0.398,3.843,0.928h-0.133c1.325,0.663,1.723,1.06,2.65,2.385
		c0.53,0.663,0.928,1.325,1.855,1.988c0.398,0.398,1.06,0.663,1.723,0.795s1.458,0.133,1.988,0c-0.133-0.133,0.398-0.265,0.795-0.53
		c0.398-0.265,0.663-0.398,0.398-0.398c3.181-1.988,5.566-3.843,5.566-5.301c0.928-1.325,1.193-1.325,1.723-1.988l-0.132,0.265
		c1.59-2.385,1.988-3.181,3.181-4.771c0.53-0.795,0.265-1.325,1.458-2.65c-0.265,0.53,0.133,0,0.663-0.53
		c-0.265,0.265-0.265,0.398,0.398-0.133c0-0.133,0.132-0.265,0.265-0.398l0,0c0.795-0.795,0.663-0.663,0.265-0.398c0,0,0,0-0.265,0
		c0.928-0.663,2.518-1.855,2.783-1.723c0.795-0.398,1.59-0.928,2.253-1.723c0.663-0.795,1.06-1.59,1.193-2.385
		c0.398-1.59,0.265-3.048,0.265-4.506c0.133-0.265,0.265,0.398,0.265,1.193v-2.12c0.265,0,0.132,1.193,0.132,1.855
		c0.265-1.325,0.133-2.518,0.133-3.711s0.265-2.518,0.928-3.711l0.133-0.53l0.133-0.133c-0.133,0,0-0.663,0.265-1.06
		c0.398-0.265,0.265,0.265,1.06-0.928c0.663-0.795,1.723-2.385,2.783-3.446c-0.53,0.53-0.53,0.265-0.265,0
		c0.53-0.53,0.928-0.53,1.325-0.928l0.265,0.133c-0.398,0,1.325-1.325,1.458-1.458c0.398,0-0.795,0.663-0.795,0.928
		c1.06-0.795,1.458-0.928,1.723-1.193c0.265-0.133,0.53-0.398,1.855-0.928c1.325-0.795-0.397-0.133,0.265-0.663
		c1.193-0.53,2.385-1.193,3.843-1.458l-0.53-0.133c2.385-0.795,3.976-0.928,5.566-0.928c4.638-0.795,7.289-0.928,8.614-1.193
		c1.325-0.265,1.193-0.53,0.53-0.663c-0.663-0.265-1.988-0.53-3.048-0.663c-1.06-0.133-2.12-0.133-2.12-0.133
		c-3.843-0.265-6.891,0.133-9.939,1.193C1026.384,330.378,1023.071,331.835,1019.493,335.016z"
        />
        <path
          fill={roadColor}
          d="M2002.674,162.207c-1.988,1.723-3.976,3.446-6.096,5.168c-1.723,1.325-3.446,2.65-5.036,3.976
		c-1.59,1.193-3.313,2.253-5.301,3.181c-3.711,1.723-7.951,2.915-12.192,4.108c-2.12,0.663-4.241,1.193-6.361,1.988
		c-1.988,0.663-3.976,1.06-6.229,1.458c-1.06,0.265-2.253,0.53-3.446,0.928c-0.53,0.133-1.193,0.398-1.723,0.663l-1.458,0.795
		c-0.928,0.53-1.855,1.06-2.915,1.458l-3.048,1.193c-4.108,1.458-8.349,2.385-12.722,2.518c-4.373,0.133-8.746-0.398-12.987-1.723
		c-4.241-1.193-8.349-3.048-12.192-5.433c-0.928-0.53-1.855-1.193-2.916-1.723c-0.927-0.663-1.855-1.193-2.783-1.855
		c-1.855-1.325-3.578-2.65-5.566-3.976c-1.855-1.193-3.048-1.855-3.711-2.253s-0.928-0.53-0.795-0.53
		c0.265,0.133,1.723,0.795,2.915,1.458c1.193,0.663,2.12,1.193,1.458,0.663c-0.398-0.265-1.06-0.795-2.518-1.59
		c-0.663-0.398-1.59-0.928-2.65-1.458c-0.53-0.265-1.06-0.53-1.723-0.928c-0.663-0.265-1.325-0.53-2.12-0.928
		c-0.265-0.398,2.518,0.795,4.638,1.855c2.12,1.06,3.446,1.855,0.53,0c-11.397-6.096-22.794-7.686-33.926-5.301
		c-2.783,0.663-5.566,1.325-8.481,2.518c-2.783,1.193-5.433,2.783-8.084,4.506c-2.651,1.59-5.433,3.313-8.349,3.976
		c-3.048,0.795-6.096,0.133-9.144-1.988l-0.795-0.928c-0.927-0.663-1.855-1.458-2.783-2.385c-0.928-0.928-1.855-1.723-2.783-2.518
		c-1.855-1.723-3.843-3.313-5.831-4.903c-3.843-3.048-7.819-5.964-11.794-8.746c-1.988-1.325-3.976-2.65-5.831-3.976
		c-0.928-0.663-1.988-1.325-2.915-1.855c-1.06-0.663-1.855-1.325-3.181-1.855c-2.385-1.193-4.903-1.59-7.289-1.723
		c-2.385-0.133-4.771,0.133-7.156,0.663c-8.216,2.518-16.3,3.048-23.722,1.988c-7.421-1.06-14.18-3.711-20.143-6.626
		c-9.144-4.771-14.71-9.012-18.818-12.06c-2.12-1.59-3.843-2.915-5.433-4.108c-0.795-0.663-1.59-1.193-2.253-1.723l-0.53-0.398
		c-0.132-0.133-0.265-0.265-0.398-0.265c-0.265-0.265-0.53-0.53-0.795-0.795c-1.06-1.193-1.988-2.783-3.181-4.373
		c-0.53-0.795-1.193-1.723-1.723-2.783c-0.265-0.53-0.663-1.06-1.06-1.59c-0.133-0.265-0.398-0.53-0.53-0.795
		c-0.265-0.265-0.265-0.53-0.663-0.928c-1.193-1.59-3.446-2.915-5.964-2.65c-1.193,0.133-2.385,0.53-3.578,1.193
		c-1.06,0.795-1.988,1.855-2.518,3.313c-0.133,0.398-0.265,0.663-0.265,1.06c-0.132,0.265-0.132,0.795-0.132,0.928
		c0,0.53-0.133,1.06-0.133,1.458c-0.132,1.06-0.265,2.12-0.398,3.181c-0.265,2.253-0.663,4.638-1.325,7.289
		c-1.458,5.301-3.446,10.337-6.096,15.108c-2.12,4.108-3.578,6.759-4.771,8.879c-1.193,2.12-1.988,3.578-2.915,5.168
		c-1.855,3.048-3.711,6.361-9.807,14.577c0.265-0.53-3.578,4.638-6.759,8.746c-3.181,4.108-5.566,7.024-1.988,2.253l-2.65,3.446
		c-0.928,1.193-1.855,2.253-2.783,3.578c-1.855,2.518-3.446,5.301-4.771,8.216c-1.193,2.915-2.12,6.096-2.518,9.409
		c-0.398,3.181-0.265,6.494,0.132,9.674c0.398,3.181,1.325,6.361,2.385,9.409c1.06,2.915,2.12,5.698,3.181,8.614
		c2.12,5.698,4.108,11.662,5.831,17.758c0.398,1.59,0.927,3.048,1.325,4.638c0.398,1.59,0.795,2.915,0.928,4.373
		c0.265,2.783-0.265,5.433-1.723,7.554c-0.795,1.06-1.855,1.988-3.048,2.783s-2.518,1.325-4.108,1.988
		c-3.181,1.325-6.228,2.783-9.276,3.843c-3.048,0.928-6.494,1.723-9.409,1.59c-2.915-0.133-5.433-1.325-7.289-3.976
		c-0.928-1.193-1.723-2.65-2.385-4.241c-0.398-0.795-0.663-1.723-1.06-2.518c-0.398-0.928-0.795-1.855-1.193-2.783
		c-1.855-3.578-4.373-7.024-7.421-9.939c-2.783-2.385-6.891-5.036-11.927-6.759c-2.518-0.928-5.168-1.59-7.819-2.12
		c-2.518-0.53-4.903-1.06-7.289-1.988c-9.542-3.446-17.493-9.939-21.336-12.987c-0.265-0.265-3.313-2.915-7.156-6.626
		c-3.843-3.578-8.746-8.349-13.517-11.662c-2.385-1.59-4.638-2.915-6.494-3.711c-1.855-0.795-3.313-1.193-3.711-1.325
		c-0.398-0.133,0.132-0.133,1.855,0.53c1.723,0.53,4.771,1.723,8.481,4.373c2.65,1.723,6.759,5.301,6.361,5.168
		c1.193,0.795,2.253,1.723,3.446,2.65c-3.181-3.048-6.494-6.096-10.337-8.614c-1.855-1.325-3.843-2.385-5.964-3.313
		c-1.06-0.53-2.12-0.928-3.313-1.193c-0.928-0.265-1.855-0.53-2.915-0.795c-3.446-1.06-6.626-3.048-9.542-5.301
		c-2.916-2.253-5.433-5.168-8.084-8.084c-2.651-2.915-5.301-5.964-8.349-8.879c-1.458-1.325-3.048-2.783-4.506-4.108
		c-1.59-1.325-3.048-2.783-5.301-4.108c-1.193-0.663-2.518-1.193-4.108-1.325c-1.59-0.133-3.181,0.265-4.373,0.795
		c-2.385,1.193-4.108,2.65-5.831,4.108c-3.181,2.915-6.228,5.831-9.409,9.012c1.855-1.855,3.843-3.711,5.831-5.566
		c1.855-1.723,3.711-3.446,5.301-4.638c0.795-0.663,1.458-1.06,1.988-1.458c0.53-0.265,0.928-0.53,0.928-0.53
		c0.265,0-0.795,0.398-2.783,1.988c-2.253,1.855-4.241,3.711-6.228,5.566c-1.988,1.855-3.843,3.711-5.831,5.698
		c-3.843,3.843-7.554,7.819-11.132,11.794l4.373-5.168c-7.554,8.084-14.71,16.3-21.734,24.649c-0.795,0.928-1.193,2.12-1.458,3.048
		c-0.265,0.928-0.265,1.723-0.265,2.518c0,1.59,0.265,3.048,0.53,4.506s0.795,2.783,1.325,4.108l1.59,3.578l-1.193-3.048
		c-0.53-1.193-0.795-2.385-1.06-3.578c0.265,0.795,0.398,1.59,0.795,2.253c0.133,0.398,0.265,0.795,0.398,1.06
		c0.132,0.398,0.265,0.663,0.398,1.06c0.53,1.193,1.06,2.385,1.458,3.446c0.927,2.253,1.59,4.373,2.253,6.494
		c1.193,4.108,1.988,8.084,2.518,12.06c0.927,7.951,1.193,16.433-0.795,29.287c-0.53,2.65-0.53,2.783-0.265,1.723
		c0.133-0.53,0.398-1.193,0.53-2.12c0.133-0.928,0.398-1.988,0.53-2.915c0.265-1.988,0.53-3.446,0.53-3.048
		c0,0.265-0.133,0.928-0.265,2.385c-0.132,0.663-0.265,1.59-0.398,2.65c-0.133,1.193-0.398,2.253-0.795,3.711
		c-1.06,3.446-2.915,7.024-5.301,10.337c-2.12,2.783-6.228,4.903-10.204,7.686c-7.819,5.301-16.565,10.867-24.119,18.686
		c-3.711,3.843-7.156,8.481-9.542,13.517c-1.193,2.518-2.253,5.168-3.048,7.819l-0.53,1.988l-0.265,1.06l-0.265,0.663
		c-0.265,0.928-0.795,1.855-1.458,2.65c-2.651,3.578-7.024,6.626-11.132,7.819c-1.988,0.53-3.711,0.398-5.036-0.53
		c-0.795-0.398-1.325-1.06-1.988-1.855l-1.06-1.458c-0.398-0.663-0.927-1.193-1.458-1.723c0.663,0.928,0.663,0.928,0.132,0.398
		c-0.53-0.53-1.59-1.59-3.048-2.783c-0.663-0.663-1.458-1.193-2.12-1.988c-0.795-0.795-1.59-1.59-2.385-2.518
		c-1.723-1.988-4.108-3.843-6.361-5.566l-0.928-1.06c-2.253-1.723-4.638-2.783-6.626-3.843c-1.06-0.398-2.12-0.795-3.048-1.193
		c-0.53-0.133-0.928-0.398-1.458-0.53c-0.398-0.133-0.927-0.265-1.325-0.398c-1.723-0.53-3.313-1.06-4.771-1.193
		c-1.458-0.265-2.65-0.53-3.711-0.663c-4.373-0.53-6.494-0.398-8.481-0.398c-2.12,0.133-4.108,0.133-8.481,1.193
		c-1.06,0.133-2.253,0.663-3.578,1.06c-0.663,0.265-1.458,0.53-2.12,0.663c-0.795,0.265-1.458,0.663-2.385,1.06
		c-0.795,0.398-1.723,0.795-2.65,1.193c-0.928,0.53-1.855,1.06-2.783,1.59c-1.06,0.53-1.988,1.193-3.048,1.988
		c-0.53,0.398-1.06,0.795-1.59,1.193c-0.265,0.265-0.53,0.398-0.795,0.663c-0.265,0.265-0.53,0.398-0.795,0.663l0.663-0.663
		l0.795-0.663l1.59-1.325l-2.385,1.855l-2.12,2.12l-1.06,1.06l-0.928,0.928c-0.53,0.663-1.193,1.193-1.855,1.723
		c-3.446,2.65-6.759,4.108-9.542,4.903s-5.168,0.795-7.024,0.663c-1.855-0.133-3.313-0.663-4.506-1.193
		c-0.265-0.133-0.663-0.265-0.927-0.398c-0.398-0.265-0.795-0.398-1.193-0.53c-0.795-0.265-1.458-0.53-2.12-0.795
		c-2.783-1.06-5.698-1.855-10.072-3.446c-2.385-0.928-3.843-1.59-5.964-4.373c-0.53-0.663-1.193-1.59-2.253-2.518
		c-0.265-0.265-0.53-0.53-0.928-0.663c-0.398-0.265-0.795-0.398-1.193-0.663c-0.398-0.133-0.795-0.265-1.325-0.398
		c-0.53-0.133-0.795-0.133-1.193-0.133c-1.325-0.133-2.915,0-4.373,0.398c-1.59,0.398-2.915,1.06-4.108,1.723
		c0.928-0.53,1.988-1.06,2.915-1.325c1.06-0.398,1.988-0.53,2.783-0.663c0.795-0.133,1.59,0,2.12,0c0.265,0,0.53,0,0.795,0.133
		c0.265,0,0.398,0,0.663,0.133c0.928,0.265,1.325,0.53,1.193,0.398c-0.132,0-0.795-0.265-1.723-0.265
		c-0.795,0-1.988-0.133-3.446,0.133c-0.795,0.133-1.59,0.398-2.385,0.663c-0.795,0.265-1.59,0.795-2.385,1.193
		c-1.59,0.928-3.048,1.723-4.771,1.988c-0.398,0-0.928,0.133-1.458,0.133c-0.265,0-0.398,0-0.53,0s-0.398-0.133-0.53-0.133
		c-0.795-0.265-1.855-0.795-2.915-1.458c1.458,0.795,3.048,1.458,3.843,1.325c-0.663,0-1.723-0.398-2.65-0.928
		c-0.928-0.53-1.988-1.193-2.915-1.59c-1.06-0.53-2.12-1.06-3.446-1.193c-0.265,0-0.663-0.133-0.928-0.133s-0.663,0-1.06,0h-0.53
		c-0.132,0-0.265,0-0.398,0.133l-0.663,0.133c-3.446,0.795-6.228,1.723-9.939,3.181c3.181-1.458,4.108-1.723,2.12-1.193
		c-1.988,0.663-7.024,2.518-14.18,7.421c3.181-2.253,6.494-4.373,9.939-6.229c-2.518,1.193-4.903,2.65-7.289,4.108
		c-1.193,0.795-2.253,1.458-3.446,2.253c-1.06,0.795-2.12,1.458-3.181,2.12c-2.12,1.325-4.241,2.518-6.494,3.313
		c-1.193,0.398-2.12,0.663-3.446,0.928l-3.843,0.663c-3.843,0.53-8.216,0.928-12.59,1.59c-4.506,0.795-9.276,1.723-13.915,3.578
		c-0.53,0.265-1.193,0.398-1.723,0.663l-1.06,0.53l-0.265,0.133c0,0.133-0.265,0.133-0.265,0.265
		c-0.265,0.133-0.397,0.265-0.53,0.53c-0.132,0.133-0.397,0.398-0.53,0.53l-0.398,0.663l-0.53,0.928
		c-0.265,0.53-0.663,1.06-0.928,1.59c-0.53,1.06-1.06,2.12-1.59,3.181l-0.795,1.458l-0.398,0.663l-0.265,0.398
		c-0.133,0.133-0.133,0.265-0.265,0.265c-0.132,0-0.265,0-0.397,0c-0.133,0-0.133,0-0.265,0c-0.265,0-0.398-0.133-0.663-0.133
		c-0.398-0.133-0.928-0.265-1.458-0.398c-1.06-0.265-2.12-0.663-3.313-0.928c-0.265,0-0.663-0.133-1.06-0.133s-0.795,0-1.325,0
		c-0.398,0-0.928,0.133-1.325,0.265c-0.398,0.133-0.795,0.265-1.193,0.53c-0.53,0.398-1.06,0.663-1.59,1.06
		c-0.53,0.265-0.928,0.663-1.325,0.928c-0.928,0.663-1.723,1.325-2.65,1.988c-1.723,1.325-3.181,2.518-4.506,3.578
		c-2.65,2.253-4.771,3.976-6.229,5.036c0,0-0.132,0.133-0.398,0.398c-0.265,0.265-0.795,0.663-1.193,0.928
		c-0.928,0.663-2.385,1.458-4.241,1.59c-1.855,0.133-4.241-0.398-6.096-1.988c-0.398-0.398-0.795-0.928-1.193-1.325
		c-0.53-0.663-1.06-1.458-1.59-2.253c-1.06-1.458-2.385-2.916-3.578-4.373c-2.518-2.783-5.301-5.566-7.951-8.084
		c-1.325-1.325-2.65-2.518-3.843-3.711c-0.398-0.398-0.795-0.795-1.193-1.193c-0.132-0.133,0-0.265-0.132-0.265v-0.265v-0.53
		c0-3.048,0.53-5.964,0.928-8.481c0.265-1.855,0.53-3.446,0.663-4.903c-0.132,1.325-0.132,1.193,0.133,0.265
		c0.265-1.06,0.663-3.578,0.795-6.229c0-1.325,0-2.783,0-3.976c-0.132-1.325-0.265-2.253-0.397-3.048
		c-0.133-0.795-0.133-1.193-0.133-1.193s0.265,0.53,0.398,1.59c-0.398-1.855-0.928-3.976-1.855-6.626
		c-0.928-2.518-2.518-5.566-5.301-7.951c-1.325-1.325-3.048-2.253-4.903-2.915c-0.928-0.265-1.855-0.663-2.783-0.795
		c-0.928-0.265-1.855-0.265-2.916-0.265c-0.928,0-1.855,0-2.783,0c-0.928,0.133-1.855,0.133-2.783,0.265
		c-0.928,0.133-1.723,0.398-2.651,0.53c-0.795,0.133-1.59,0.265-2.385,0.398c-3.181,0.53-6.361,0.53-9.276,0
		c-3.048-0.53-5.831-1.59-8.349-3.048c-2.518-1.458-4.638-3.048-6.626-4.771c-0.928-0.795-1.723-1.59-2.783-2.518
		c-0.928-0.795-1.855-1.59-2.65-2.253c0.928,0.663,1.723,1.325,2.518,1.988c-3.048-2.65-6.229-5.036-9.939-6.626
		c-1.855-0.795-3.976-1.458-6.229-1.59c-0.928,0-1.723,0-2.65,0c-0.795,0-1.59-0.133-2.385-0.265
		c-6.229-0.795-11.794-3.843-16.83-7.686c-1.325-0.928-2.518-1.988-3.711-3.048l-0.928-0.795c-0.53-0.398-1.06-0.795-1.59-1.06
		c-1.06-0.53-2.12-0.928-3.048-1.193c-1.988-0.398-3.711-0.53-5.301-0.663s-3.181-0.133-4.506-0.398
		c-0.265,0-0.663-0.133-1.06-0.265l-1.06-0.265l-2.253-0.53c-1.458-0.398-3.048-0.663-4.506-1.06
		c-1.458-0.265-3.048-0.795-4.638-1.193c-1.59-0.265-3.181-0.663-4.771-0.928c-6.361-1.06-12.855-1.458-19.216-0.795
		c-6.361,0.663-12.855,2.253-18.818,5.566c-1.458,0.795-2.915,1.723-4.241,2.65c-0.663,0.398-1.325,1.06-1.988,1.59
		c-0.663,0.53-1.325,1.06-1.988,1.723l-1.855,1.855l-0.398,0.53c-0.132,0-0.132,0.133-0.265,0.265
		c-0.133,0.133-0.398,0.265-0.53,0.398c-0.928,0.53-2.12,1.06-3.446,1.325c-1.325,0.265-2.783,0.53-4.373,0.663
		c-1.59,0.133-3.181,0.265-4.903,0.265c-3.445,0.265-7.024,1.06-10.469,2.385c0.663-0.133-0.663,0.398-2.783,1.458
		c-2.12,1.193-5.036,3.181-7.289,5.566c-1.193,1.193-2.12,2.518-2.916,3.711c-0.663,1.193-1.325,2.253-1.723,3.048
		c-0.663,1.59-0.928,2.12,0,0.398c-1.59,3.446-2.518,7.024-3.048,10.602c0,0.928-0.133,1.855-0.265,2.65
		c0,0.795-0.132,1.59-0.132,2.385c-0.133,1.59-0.398,3.048-0.795,4.506c-0.398,1.325-0.928,2.65-1.723,3.843
		c-0.795,1.06-1.723,1.988-2.783,2.915c-2.253,1.59-5.036,2.518-7.951,3.181c-2.916,0.663-6.229,1.325-9.277,2.783
		c-1.59,0.795-3.048,1.723-4.373,3.048c-1.325,1.458-2.12,3.313-2.385,5.036c-1.193,6.891,1.325,12.192,1.325,16.963
		c0.133,2.518,0,4.903-0.132,7.156c-0.133,1.193-0.265,2.385-0.53,3.313c-0.265,1.06-0.53,1.988-0.928,2.783
		c-1.59,3.578-4.506,6.096-7.686,7.289c-1.59,0.663-3.181,0.795-4.638,0.928c-1.59,0-2.916-0.265-4.241-0.663
		c-3.181-1.06-5.964-1.855-8.481-2.518c-12.192-3.843-20.143-4.373-26.239-4.373c-3.048,0.133-5.831,0.265-8.481,0.663
		c-1.325,0.133-2.783,0.53-4.241,0.795c-0.663,0.133-1.458,0.398-2.253,0.663c-0.663,0.265-1.458,0.398-2.253,0.663l2.916-0.928
		c0.398-0.133,0.795-0.265,1.325-0.398c-1.06,0.265-2.12,0.663-3.181,0.928c-1.458,0.398-2.783,0.795-3.976,0.928
		c-2.518,0.133-3.578-0.663-4.506-2.783c-0.795-2.12-1.193-4.771-1.325-7.024c-0.132-2.385,0-4.771,0-7.156s0-4.771-0.132-7.024
		c-0.133-2.12-0.265-4.241-0.265-6.229c-0.133-2.12-0.133-4.108-0.398-6.361c-0.133-1.06-0.265-2.12-0.795-3.313
		c-0.133-0.53-0.53-1.325-1.06-1.988c-0.398-0.663-1.193-1.06-1.855-1.458l-0.795-0.265c-0.133,0-0.398-0.133-0.53-0.265
		l-1.193-0.398c-0.795-0.133-1.59-0.398-2.253-0.398c-1.59-0.265-3.048-0.265-4.638-0.265c-3.048,0-6.096,0.53-9.277,1.325
		c-1.59,0.398-3.048,0.795-4.771,1.59c-1.59,0.663-3.181,1.59-4.771,2.783c-2.916,2.385-5.433,5.566-7.024,9.409
		c-0.928,2.253-1.458,4.241-1.723,6.229c-0.133,0.928-0.265,1.855-0.398,2.783c-0.133,0.795-0.133,1.59-0.265,2.385
		c-0.398,3.048-1.193,5.698-2.518,8.614c-0.663,1.458-1.59,2.916-2.783,4.373c-1.193,1.458-2.65,2.915-4.506,4.241
		c-1.855,1.325-4.241,2.518-7.024,3.313c-2.783,0.795-6.096,1.193-9.807,0.663c1.193,0.133,1.988,0.133,2.65,0.133
		c0.663,0,0.795,0,0.795,0s-0.265,0-0.663,0s-1.06,0-1.855-0.133c-1.59-0.133-3.843-0.53-6.229-1.193
		c-2.12-0.663-4.241-1.59-6.361-2.783c2.518,1.325,4.108,1.855,3.843,1.723c-0.133-0.133-0.928-0.398-2.518-1.325
		c-1.59-0.795-3.711-2.12-6.626-4.506c-1.855-1.325-5.036-3.313-8.216-4.241c-0.133,0-0.795-0.265-0.398-0.133
		c-5.168-1.59-8.879-1.325-11.264-1.325c-2.385,0.133-3.578,0.265-3.711,0.265c-0.398,0.133,2.65-0.265,7.421-0.133
		c-0.53,0,0.265,0,3.313,0.53c-5.301-0.928-11.794-0.133-17.891,0.398c-3.048,0.265-6.096,0.133-9.277-0.133
		c-1.59-0.133-3.048-0.265-4.638-0.53c-1.59-0.133-3.181-0.265-4.771-0.265c-6.361,0-11.927,1.855-15.77,3.446
		c-3.843,1.59-6.361,2.783-6.891,2.915c-6.626,3.313-12.855,6.229-19.481,7.819c-3.313,0.795-6.494,1.458-9.807,1.723
		c-3.181,0.398-6.494,0.53-9.674,0.53c-6.361,0-12.59-0.795-18.421-2.253c-1.458-0.398-2.916-0.795-4.373-1.193
		s-2.915-0.795-4.373-1.325c-2.916-0.795-5.831-1.723-8.614-2.65c-5.698-1.855-11.132-3.976-16.3-6.626
		c-2.518-1.325-5.036-2.783-7.289-4.506c-1.193-0.928-2.385-1.723-3.446-2.65l-1.723-1.458c-0.663-0.53-1.193-1.06-1.855-1.458
		c-1.325-0.928-2.783-1.723-4.506-2.12c-1.59-0.398-3.446-0.398-5.168,0.133c-1.723,0.53-3.048,1.458-4.373,2.518
		c-1.193,1.06-2.253,2.253-3.181,3.446c-0.928,1.193-1.855,2.253-2.783,3.446l-1.458,1.723l-0.663,0.795l-0.663,0.663
		c-1.855,1.723-4.771,2.253-7.289,1.458c-0.53-0.265-0.928-0.398-0.928-0.53c-0.133-0.133,0-0.133,0.133,0
		c0.398,0.133,1.458,0.663,3.048,0.663c0.795,0,1.723-0.133,2.65-0.53c0.928-0.398,1.855-1.06,2.65-1.988
		c1.855-2.385,3.976-5.036,6.229-7.819l-5.566,6.759l-0.663,0.795c-0.265,0.265-0.398,0.398-0.663,0.663
		c-0.133,0.265-0.398,0.398-0.663,0.53c-0.265,0.133-0.398,0.398-0.663,0.398c-1.06,0.53-2.12,0.795-3.313,0.795
		c-2.385,0-4.638-1.325-5.831-3.181c-0.265-0.53-0.53-1.06-0.795-1.59l-0.133-0.795c-0.133-0.265-0.133-0.663-0.133-1.06
		l-0.795-4.373c0.133,0,0.398,1.325,0.795,3.313c0.133,0.53,0.133,1.06,0.265,1.59c0,0.265,0.133,0.53,0.133,0.795
		c0,0.265,0.133,0.53,0.265,0.663c0.398,0.928,1.06,1.988,1.988,2.65c1.855,1.59,4.903,1.988,7.289,0.795
		c1.193-0.663,1.988-1.325,3.048-2.65c0.928-1.193,1.855-2.253,2.65-3.313l-2.385,2.915l-1.193,1.458
		c-0.398,0.398-0.663,0.663-1.193,0.928c-1.59,1.193-3.711,1.458-5.566,0.928c-1.855-0.663-3.578-1.988-4.241-3.843
		c-0.265-0.398-0.265-0.928-0.398-1.458l-0.265-1.988c-0.265-1.325-0.53-2.65-0.663-3.976c-0.53-2.65-1.06-5.433-1.855-8.084
		c-0.265-0.663-0.398-1.325-0.795-2.12l-0.795-1.855c-0.53-1.193-0.928-2.518-1.458-3.711c-1.855-5.036-3.313-10.469-5.698-15.638
		c-1.193-2.65-2.518-5.301-4.108-7.686c-1.723-2.518-3.578-4.638-5.831-6.759c-1.06-1.06-2.253-1.988-3.578-2.915
		s-2.65-1.723-4.241-2.253c-0.795-0.265-1.723-0.398-2.65-0.53l-2.12-0.133c-1.325-0.133-2.783-0.133-4.241-0.398
		c-11.264-1.325-21.999-6.494-33.661-10.204c-1.458-0.398-2.915-1.06-4.373-1.723c-1.325-0.663-2.65-1.59-3.843-2.518l-0.928-0.928
		c-0.265-0.265-0.53-0.663-0.795-1.06c0,0-0.398-0.398-1.06-1.193c-0.265-0.398-0.663-0.928-1.06-1.458
		c-0.265-0.663-0.663-1.325-1.06-2.12c-0.663-1.59-1.325-3.446-1.59-5.301c-0.265-0.928-0.265-1.855-0.398-2.915
		c-0.133-1.06-0.265-1.988-0.398-2.915c-0.53-3.446-0.265-3.446-0.663-5.433c-0.133-0.53-0.265-1.193-0.53-1.988
		c-0.133-0.398-0.265-0.928-0.398-1.458c-0.265-0.53-0.398-1.193-0.663-1.855c-0.265-0.663-0.53-1.458-0.928-2.253
		c-0.398-0.795-0.795-1.723-1.325-2.65c-1.06-1.988-2.518-4.241-4.638-6.626c-1.59-1.988-3.711-3.843-6.229-5.566
		c-2.518-1.723-5.566-3.048-8.746-3.976c-0.795-0.265-1.59-0.398-2.518-0.663c-0.795-0.133-1.59-0.398-2.385-0.53
		c-0.795-0.133-1.59-0.398-2.385-0.663c-0.795-0.265-1.59-0.398-2.385-0.663c-3.181-1.06-6.361-2.253-9.409-3.843
		c-6.096-3.181-11.927-7.421-17.095-12.192c-1.325-1.193-2.518-2.385-3.711-3.578c-0.663-0.663-1.193-1.193-1.855-1.855
		s-1.325-1.193-1.988-1.855c-2.253-1.988-4.771-3.711-7.289-5.036c2.385,1.325,3.976,2.518,4.903,3.313
		c1.193,0.928,1.458,1.325,1.458,1.325c-0.133,0-2.12-1.723-4.771-3.313c-5.301-3.313-10.204-4.638-14.71-5.301
		c-4.506-0.663-8.746-0.663-13.517,0l-0.928-0.265c-0.398,0.133-1.59,0.265-3.181,0.53c-1.325,0.265-3.048,0.53-4.903,0.663
		c-0.928,0-1.988,0.133-3.048,0.133s-2.253,0.265-3.446,0.398c-4.638,0.663-9.674,2.518-12.855,5.301
		c-3.313,2.783-4.638,5.963-5.168,7.024c-0.795,1.59-0.663,1.325-0.133-0.133c0.663-1.458,1.988-4.108,4.373-6.361
		c2.253-2.253,5.566-3.976,8.216-4.903c0.663-0.265,1.325-0.398,1.988-0.663c0.663-0.133,1.193-0.265,1.723-0.398
		c1.06-0.265,1.723-0.398,2.12-0.398c-3.181,0.398-5.964,1.193-8.481,2.253c-2.518,1.193-4.506,2.65-6.096,4.241
		c-3.181,3.313-4.506,6.626-5.301,9.277c-1.458,5.433-1.458,8.614-1.458,11.662c0,3.048,0.133,6.096,0,11.264
		c-0.133,2.65-0.133,5.698-0.928,9.542c-0.265,0.928-0.398,1.988-0.663,3.048s-0.53,2.253-0.663,3.578
		c-0.398,2.518-0.795,5.301-1.06,8.349c-0.398,6.626,0.133,9.542,0.265,10.602c0.133,0.928,0,0,0-1.458c0-1.458,0-3.313,0-4.108
		c0-0.663,0.133-0.265,0.265,2.783c0.265,5.831,1.325,11.529,3.048,16.963c0.928,2.783,1.855,5.433,3.048,8.084
		c1.06,2.518,1.458,5.301,1.325,8.084c-0.133,2.783-0.663,5.698-1.855,8.216c-0.53,1.325-1.325,2.385-2.385,3.578
		c-0.928,1.193-1.988,2.253-2.916,3.446c-3.976,4.638-8.216,9.144-12.855,13.782c1.988-1.855,3.976-3.711,5.964-5.698
		c-7.156,7.819-14.18,14.577-20.673,20.541c-3.313,2.915-6.361,5.964-9.939,7.686c-1.855,0.928-3.711,1.458-5.566,1.59
		c-1.988,0.133-3.843-0.265-5.566-0.928c-1.723-0.663-3.446-1.59-4.903-2.65c-1.59-1.06-2.915-2.253-4.241-3.446
		c-2.783-2.385-5.301-4.903-7.819-7.421c-5.036-5.036-9.807-10.072-14.842-15.24c-7.024-7.024-13.65-13.782-20.806-19.216
		c-7.024-5.433-14.577-9.409-21.999-11.794c-7.554-2.253-14.445-4.638-20.673-7.289c-6.361-2.65-11.927-5.698-16.83-9.012
		c-9.807-6.759-16.433-14.577-20.806-21.866c-1.193-1.855-2.12-3.446-3.313-4.771c-1.193-1.325-2.518-2.518-3.711-3.578
		c-2.518-2.12-5.036-3.843-7.156-5.301c-2.12-1.458-4.108-2.915-5.698-4.108c-1.59-1.193-2.783-2.253-3.843-3.048
		c-2.12-1.59-3.313-2.518-3.578-2.65c-0.795-0.398,1.59,1.855,5.831,5.831c2.253,1.988,5.168,4.241,8.216,6.494
		c3.048,2.385,6.361,4.903,8.879,8.084c0.265,0.398,0.53,0.795,0.795,1.193l0.795,1.325c0.663,1.06,1.193,2.12,1.855,3.181
		c0.663,1.06,1.325,2.12,1.988,3.181c0.663,1.06,1.325,2.12,2.12,3.048c2.915,3.976,6.494,7.686,10.204,10.867
		c1.855,1.59,3.711,3.048,5.566,4.373s3.711,2.65,5.566,3.711c3.578,2.253,7.024,3.976,9.939,5.301
		c2.915,1.325,5.301,2.385,7.024,3.048c1.723,0.663,2.65,1.06,2.65,1.06c3.711,1.458,7.421,2.65,11.132,3.843l1.325,0.398
		l1.325,0.398c0.795,0.265,1.723,0.53,2.518,0.928c1.59,0.663,3.313,1.325,4.771,2.12c6.229,3.048,11.927,7.156,16.963,11.662
		c5.168,4.506,9.807,9.409,14.577,14.18c4.771,4.771,9.409,9.542,13.915,14.18c2.385,2.385,4.638,4.638,7.024,6.891
		c1.193,1.06,2.385,2.253,3.578,3.313c1.325,1.06,2.65,2.253,4.108,3.181c2.915,1.988,6.229,3.843,10.204,4.373
		c3.976,0.663,8.084-0.53,11.264-2.253c0.795-0.398,1.59-0.928,2.385-1.325c0.663-0.53,1.458-1.06,2.12-1.59l1.988-1.59l1.723-1.59
		c2.385-2.12,4.638-4.241,6.891-6.361c4.638-4.373,9.144-8.746,13.517-13.517c4.373-4.638,8.614-9.409,12.855-14.577
		c0.265-0.265,0.53-0.663,0.795-0.928c0.265-0.398,0.663-0.928,0.928-1.325c0.53-0.928,0.928-1.723,1.325-2.65
		c0.663-1.855,1.193-3.578,1.458-5.433c0.53-3.711,0.663-7.289-0.398-11.264c-0.663-2.253-1.325-3.446-1.988-4.903
		c-0.53-1.458-1.193-3.048-1.59-4.506c-0.928-3.048-1.723-6.229-2.253-9.542c-1.06-6.494-1.06-13.252,0-20.143
		c0.133-0.795,0.265-1.723,0.398-2.518c0.133-0.928,0.265-1.723,0.53-2.65c0.398-1.59,0.928-3.711,1.06-5.698
		c0.53-3.976,0.663-7.819,0.663-11.794c0-3.843-0.133-7.819,0.133-11.397c0.265-3.711,1.06-7.289,2.783-10.337
		c0.795-1.458,1.855-2.915,3.181-3.976c0.663-0.663,1.325-1.06,2.12-1.59c0.398-0.265,0.795-0.398,1.193-0.663
		c0.398-0.265,0.795-0.398,1.193-0.53c3.446-1.458,7.289-1.988,11.529-2.12c4.771-0.133,9.144-1.193,13.252-1.458
		c4.373-0.265,8.614-0.133,12.855,0.795c3.711,0.928,7.156,2.385,10.072,4.241c1.59,0.928,2.915,2.12,4.241,3.181
		c0.663,0.53,1.325,1.193,1.855,1.855c0.663,0.663,1.325,1.325,1.988,2.12c5.433,5.433,11.132,10.204,17.228,13.915
		c6.096,3.843,12.59,6.494,18.951,8.084c0.795,0.265,1.59,0.398,2.385,0.53c0.795,0.265,1.458,0.398,2.12,0.53l1.06,0.265
		c0.265,0.133,0.663,0.265,1.06,0.398c0.663,0.265,1.325,0.398,1.988,0.795c2.518,1.06,4.771,2.65,6.759,4.373
		c1.06,0.928,1.855,1.855,2.783,2.783c0.795,1.06,1.59,2.12,2.385,3.181c1.458,2.253,2.65,4.638,3.446,7.024
		c0.53,1.193,0.795,2.518,1.193,3.711c0.265,1.325,0.663,2.518,0.795,3.843c0.265,1.325,0.398,2.518,0.53,3.843l0.265,2.12
		c0.133,0.663,0.265,1.458,0.398,2.253c0.53,2.916,1.458,5.831,3.048,8.481c1.458,2.65,3.578,5.036,5.963,7.024
		c2.385,1.855,5.168,3.181,7.819,4.108c1.458,0.53,2.518,0.795,3.843,1.325l3.711,1.325c4.903,1.723,9.939,3.711,15.108,5.301
		c5.301,1.59,10.867,2.915,16.565,3.313l2.253,0.133h0.53h0.265c0.133,0,0.398,0,0.53,0.133c0.795,0.265,1.855,0.663,2.783,1.193
		c1.988,1.193,3.843,3.048,5.566,5.036c0.928,1.06,1.723,1.988,2.518,3.181c0.795,1.06,1.458,2.12,2.12,3.446
		c1.325,2.385,2.385,5.036,3.446,7.819c1.06,2.783,1.988,5.698,2.915,8.746c0.53,1.458,1.06,3.048,1.59,4.638
		c0.265,0.795,0.663,1.59,0.928,2.385c0.265,0.663,0.663,1.458,0.928,2.253c0.928,2.65,1.59,5.831,2.253,9.144
		c0.265,1.723,0.53,3.313,0.795,5.036c0,0.398,0.265,1.06,0.398,1.59c0.133,0.663,0.398,1.193,0.663,1.723
		c0.53,1.193,1.193,2.253,1.988,3.181c3.313,3.843,9.011,5.301,13.915,3.181c0.53-0.265,1.193-0.53,1.723-0.928
		c0.53-0.398,1.06-0.663,1.59-1.193l0.663-0.663l0.398-0.398c0.133-0.133,0.133-0.133,0.265-0.265l0.928-1.193
		c1.325-1.59,2.518-3.048,3.843-4.638c1.06-1.325,2.12-2.65,3.313-3.446s2.385-1.325,3.446-1.325c1.06,0,2.385,0.53,3.843,1.458
		c0.265,0.265,0.663,0.53,1.06,0.795l1.06,0.928c0.795,0.663,1.723,1.458,2.518,2.12c6.759,5.433,14.577,9.144,22.529,12.06
		c3.976,1.458,7.951,2.65,11.927,3.843l5.964,1.723c1.988,0.53,4.108,1.193,6.229,1.59c16.83,3.711,35.118,2.518,51.021-4.506
		c7.951-3.446,14.842-7.819,22.264-9.409c1.855-0.398,3.711-0.663,5.566-0.663c0.928,0,1.855,0,2.783,0
		c0.928,0.133,1.723,0.133,2.916,0.265c4.241,0.53,8.481,0.795,12.855,0.663c1.06,0,2.253-0.133,3.313-0.265l3.048-0.398
		c1.988-0.265,3.976-0.398,5.831-0.398c3.843-0.133,7.421,0.265,10.602,1.59c1.59,0.663,3.181,1.59,4.638,2.518l2.385,1.723
		c0.928,0.663,1.723,1.193,2.65,1.855c3.711,2.253,7.686,4.108,12.06,5.036c3.181,0.663,6.626,0.928,10.337,0.53
		c1.855-0.133,3.711-0.53,5.566-1.193c1.855-0.53,3.711-1.325,5.566-2.385c1.723-0.928,3.446-2.253,5.036-3.711
		s3.048-3.048,4.241-4.903c2.385-3.578,4.108-7.686,4.903-12.06c0.265-1.06,0.398-2.12,0.53-3.313l0.133-1.458
		c0-0.398,0.132-0.928,0.265-1.325c0.265-1.855,0.928-3.578,1.59-5.301c1.458-3.313,3.711-6.229,6.759-8.084
		c1.458-0.928,3.181-1.59,5.036-2.12c1.855-0.53,3.711-0.928,5.566-1.193s3.711-0.398,5.566-0.265c0.928,0,1.723,0.133,2.65,0.398
		c0.398,0.133,0.795,0.133,1.193,0.265c0.265,0,0.398,0.133,0.53,0.133l0.265,0.133l0,0c0.133,0,0.133,0.133,0.265,0.265
		c0,0,0,0,0.132,0.133c0,0.133,0.133,0.265,0.133,0.53c0.265,0.795,0.398,1.855,0.53,2.915c0.265,2.12,0.265,4.241,0.398,6.494
		c0,1.06,0.133,2.253,0.133,3.313c0.133,1.06,0.133,2.12,0.265,3.181c0.132,1.988,0.132,3.976,0,5.964
		c-0.133,2.253-0.265,4.638,0,7.156c0,1.325,0.265,2.518,0.398,3.711s0.398,2.518,0.928,3.843c0.133,0.398,0.398,0.928,0.663,1.325
		s0.53,0.928,0.795,1.325c0.265,0.53,0.795,0.928,1.06,1.325c0.398,0.398,0.928,0.795,1.458,1.06
		c2.253,1.325,4.638,1.458,6.626,1.193c0.53,0,1.06-0.133,1.458-0.265c0.398-0.133,0.928-0.133,1.458-0.265
		c0.795-0.265,1.723-0.53,2.518-0.795c0.795-0.265,1.458-0.53,2.253-0.663l2.253-0.53c3.048-0.663,6.229-1.06,9.277-1.193
		c3.048,0,6.229,0.133,9.144,0.53c-0.265,0-0.53,0-0.795,0c4.373,0.398,8.879,1.325,13.252,2.518
		c2.253,0.663,4.373,1.325,6.494,1.988c1.06,0.398,2.12,0.663,3.048,1.06c0.53,0.133,1.06,0.398,1.59,0.53
		c0.663,0.265,1.193,0.398,1.855,0.53c5.036,1.325,10.072,0.398,13.65-1.59c3.711-1.988,5.964-4.903,7.289-7.156l-0.398,0.795
		c1.193-1.855,1.988-3.976,2.518-6.096c0.265-1.06,0.398-1.988,0.53-3.048c0.132-0.928,0.265-1.988,0.265-2.915
		c0.265-3.976,0.132-7.951-0.663-11.927c0.265,1.325,0.53,2.65,0.663,4.108c-0.133-1.06-0.265-2.12-0.398-3.181l-0.663-2.915
		c-0.398-1.855-0.663-3.711-0.663-5.433c0-1.723,0.132-3.313,0.795-4.373c0.53-1.06,1.59-1.988,3.181-2.65
		c1.59-0.663,3.313-1.325,5.168-1.723l2.916-0.663c1.06-0.265,2.12-0.53,3.048-0.795c2.12-0.663,4.108-1.59,6.096-2.783
		c1.988-1.325,3.711-3.048,5.036-5.036c1.325-1.988,1.988-4.241,2.518-6.361c0.53-2.12,0.663-4.241,0.795-6.361
		c0.133-3.578,0.928-7.156,2.253-10.337c1.325-3.181,3.313-5.964,5.831-8.084s5.433-3.711,8.614-4.638
		c-0.663,0.133-1.458,0.133-2.253,0.398c3.313-1.325,6.759-1.988,10.072-2.12c0.795-0.133,1.723-0.133,2.65-0.133
		s1.855-0.133,2.783-0.265c1.855-0.265,3.711-0.53,5.433-1.193c1.855-0.663,3.711-1.458,5.301-3.181c0.53-0.53,1.06-1.06,1.59-1.59
		l0.795-0.795l0.795-0.663c0.53-0.398,1.06-0.928,1.59-1.325c0.663-0.398,1.193-0.795,1.723-1.193
		c1.06-0.795,2.385-1.325,3.578-2.12c4.903-2.385,10.072-3.578,15.108-4.108c5.036-0.53,10.072-0.398,14.975,0.265
		c1.193,0.133,2.385,0.398,3.711,0.53c1.193,0.265,2.385,0.53,3.578,0.795c1.193,0.265,2.385,0.663,3.711,0.928
		c1.325,0.265,2.518,0.663,3.843,0.928c1.325,0.265,2.518,0.663,3.843,0.928l1.988,0.398c0.663,0.133,1.458,0.265,2.12,0.398
		c2.783,0.398,5.566,0.265,7.819,0.795c1.193,0.265,2.12,0.663,2.65,1.06c1.06,0.928,2.12,1.855,3.313,2.783
		c2.253,1.855,4.771,3.711,7.554,5.301c2.783,1.59,5.964,3.048,9.277,3.976c1.723,0.398,3.445,0.795,5.301,0.928
		c0.928,0.133,1.855,0.133,2.783,0.133h0.663h0.398h0.265c0.265,0,0.663,0,1.06,0.133c-0.265,0-0.398,0-0.663,0
		c1.59,0.133,3.446,0.663,5.036,1.458c1.723,0.795,3.313,1.855,4.903,2.915c0.795,0.53,1.59,1.193,2.253,1.723
		c0.663,0.663,1.325,1.193,2.253,1.988c1.59,1.458,3.181,2.65,4.903,3.976c3.313,2.385,7.024,4.373,10.999,5.433
		c3.976,1.193,8.084,1.325,11.927,1.06c0.928-0.133,1.988-0.265,2.916-0.398c0.928-0.133,1.855-0.398,2.783-0.53
		c1.59-0.265,3.313-0.53,4.903-0.53c3.181-0.133,6.096,0.663,8.216,2.518c2.12,1.855,3.578,4.638,4.373,7.686
		c0.397,1.458,0.795,3.048,1.06,4.638c0.265,1.59,0.398,3.048,0.265,4.638c-0.133,3.181-0.663,6.361-1.325,9.807
		c-0.265,1.723-0.663,3.446-0.928,5.301c-0.132,0.928-0.265,1.855-0.265,2.783c0,0.398,0,0.928-0.133,1.458v0.663v0.398v0.53
		c0,0.663,0,1.458,0.133,2.253l1.458,1.59l1.988,1.988c1.325,1.193,2.518,2.518,3.843,3.711c2.518,2.518,5.036,5.036,7.289,7.686
		c1.193,1.325,2.253,2.65,3.313,4.108c0.53,0.663,0.928,1.325,1.723,2.385c0.397,0.53,0.795,0.928,1.193,1.325
		c0.398,0.398,0.928,0.795,1.458,1.06c3.976,2.783,9.276,3.181,13.782,1.06c1.06-0.53,2.12-1.193,3.048-1.988
		c0.795-0.663,1.59-1.325,2.253-1.988c1.458-1.325,3.048-2.65,4.638-3.976c1.59-1.325,3.048-2.65,4.771-3.976
		c0.795-0.663,1.59-1.325,2.518-1.855l1.325-0.928l1.193-0.795c0.132-0.133,0.265-0.133,0.397-0.133c0.133,0,0.133,0,0.265,0
		c0.132,0,0.265,0,0.53,0c0.53,0.133,1.193,0.265,1.855,0.398c-1.458-0.398-1.855-0.398-1.855-0.398s0.398,0.133,1.325,0.398
		c0.928,0.265,2.12,0.663,3.181,0.928c0.928,0.265,2.385,0.398,1.988,0.398c1.855,0.265,3.711-0.265,4.903-1.723
		c0.265-0.398,0.53-0.663,0.795-1.193l0.265-0.53l0.265-0.398l0.795-1.458c0.928-1.988,1.855-3.843,2.783-5.433
		c0.265-0.53,0.265-0.398,0.397-0.53c0.133-0.133,0.133,0,0.663-0.265c0.928-0.398,1.855-0.663,2.65-1.06
		c1.855-0.663,3.711-1.193,5.566-1.59c7.686-1.855,15.24-2.385,21.866-3.578c0.397,0,0.795-0.133,1.193-0.133
		c0.397-0.133,0.928-0.133,1.325-0.265c0.928-0.133,1.723-0.398,2.518-0.663c1.59-0.53,3.048-1.193,4.241-1.855
		c2.518-1.325,4.638-2.783,6.229-3.976c3.181-2.253,5.036-3.446,5.036-3.711c5.168-2.915,9.144-4.373,11.927-5.301
		c1.458-0.398,2.518-0.663,3.445-0.928c0.398-0.133,0.928-0.133,1.193-0.265c0.265,0,0.398,0,0.53,0
		c0.663,0.133,1.06,0.265,1.325,0.398c0.265,0.133,0.53,0.398,1.06,0.663c0-0.133,0.928,0.398,3.181,1.59
		c0.53,0.265,1.193,0.663,1.988,0.928c0.795,0.265,1.855,0.53,2.915,0.53c1.855,0,3.976-0.398,5.964-1.458
		c1.988-0.928,3.578-1.988,5.168-2.385c0.795-0.133,1.723-0.265,2.65-0.133c0.53,0,0.663,0.133,0.928,0.265s0.53,0.398,0.928,0.663
		c0.663,0.663,1.325,1.59,2.12,2.65c0.795,1.06,1.855,1.988,2.915,2.783c1.193,0.795,2.385,1.458,3.578,1.855
		c1.193,0.53,2.385,0.928,3.446,1.325c-1.458-0.53-2.783-0.928-4.241-1.59c-1.06-0.398-2.12-1.06-3.048-1.723
		c0.663,0.53,1.325,0.928,2.12,1.325c1.193,0.53,2.253,1.06,3.313,1.458c2.253,0.795,4.373,1.458,6.626,2.253
		c1.06,0.398,2.12,0.663,3.181,1.06c0.53,0.133,1.06,0.398,1.458,0.53c0.265,0.133,0.53,0.133,0.663,0.265l0.795,0.398
		c2.253,1.06,4.771,1.59,7.289,1.723c5.036,0.265,9.939-1.06,14.18-3.313c2.12-1.193,4.241-2.518,6.096-3.976l2.65-2.385l2.518-2.12
		l-4.108,3.181c-1.458,1.193-3.048,2.253-4.638,3.181c1.59-1.06,2.916-1.988,4.108-3.048c1.193-1.06,1.988-1.723,2.916-2.518
		c1.59-1.458,3.048-2.253,3.976-2.915c1.988-1.325,2.783-1.59,3.578-1.988c0.398-0.133,0.795-0.398,1.193-0.663
		c0.265-0.133,0.53-0.265,0.928-0.53c0.398-0.133,0.795-0.398,1.325-0.53c0.53-0.265,1.06-0.53,1.855-0.795
		c0.398-0.133,0.663-0.398,1.193-0.53c0.53-0.133,0.928-0.265,1.325-0.398c0.928-0.265,1.988-0.795,3.313-1.06
		c0.663-0.133,1.325-0.265,1.988-0.53c0.795-0.133,1.458-0.398,2.385-0.398l-0.398-0.53c4.771-0.795,9.542-0.663,13.782,0.133
		c1.06,0.265,2.12,0.398,3.181,0.663c1.06,0.265,2.12,0.663,3.048,0.928c0.928,0.265,1.988,0.795,2.916,1.193l1.458,0.663
		c0.398,0.265,0.928,0.53,1.325,0.795c0.928,0.53,1.723,0.928,2.651,1.458c0.795,0.53,1.59,1.193,2.385,1.723
		c0.398,0.265,0.795,0.53,1.193,0.928l1.06,0.928c0.795,0.663,1.458,1.325,2.253,1.988c0.663,0.663,1.325,1.458,1.988,2.12
		l0.928,1.06l1.06,1.193c1.458,1.59,3.048,2.783,4.241,4.108c0.663,0.663,1.193,1.325,1.723,1.988l0.928,1.325
		c0.265,0.53,0.663,0.928,1.06,1.325c1.59,1.723,3.843,3.048,6.361,3.446c2.518,0.398,4.903-0.133,7.024-0.928
		c2.12-0.795,3.976-1.855,5.831-3.181c1.855-1.325,3.578-2.783,5.168-4.506c1.59-1.723,3.048-3.976,3.711-6.626
		c1.06-4.108,2.65-8.216,4.903-12.192c2.65-4.638,6.229-8.879,10.204-12.59c4.108-3.711,8.614-7.156,13.252-10.337
		c2.385-1.59,4.771-3.181,7.156-4.903c2.253-1.59,4.771-3.048,7.289-4.903c1.193-0.928,2.518-2.12,3.578-3.446
		s1.855-2.518,2.65-3.843c1.59-2.65,2.915-5.433,3.711-8.614c0.265-0.795,0.398-1.59,0.53-2.385
		c0.132-0.795,0.265-1.458,0.398-2.253c0.265-1.458,0.398-2.915,0.663-4.373c0.398-2.915,0.663-5.964,0.663-8.879
		c0.265-5.964-0.132-11.927-1.06-17.89c-0.928-5.964-2.651-11.794-5.036-17.36c-0.53-1.458-1.193-2.783-1.855-4.108l-0.398-0.928
		c-0.132-0.265-0.265-0.53-0.398-0.928c-0.265-0.663-0.398-1.193-0.53-1.855c-0.398-1.193-0.53-2.518-0.663-3.711
		c-0.132-1.193,0.132-2.253,0.398-2.385c9.542-11.264,19.216-22.661,29.685-32.998c2.65-2.65,5.301-5.168,7.951-7.686
		c1.325-1.193,2.651-2.385,3.976-3.048c0.663-0.398,1.325-0.663,1.855-0.795c0.53,0,1.06-0.133,1.59,0.133
		c1.193,0.398,2.518,1.458,3.843,2.518c1.325,1.06,2.65,2.385,4.108,3.711s2.65,2.518,3.843,3.843
		c1.193,1.325,2.518,2.65,3.711,3.976c2.385,2.783,4.903,5.566,7.686,8.349c2.783,2.65,5.831,5.168,9.409,7.024
		c1.723,0.928,3.578,1.723,5.433,2.253c1.988,0.663,3.578,0.928,5.036,1.59c3.181,1.325,6.096,3.313,8.879,5.433
		c2.783,2.253,5.433,4.638,8.216,7.156c2.65,2.518,5.433,5.036,8.216,7.421c5.566,4.903,11.662,9.409,18.553,12.722
		c3.446,1.723,7.024,3.048,10.734,3.976l5.301,1.193c1.723,0.398,3.313,0.928,4.903,1.59c3.181,1.193,6.229,2.783,8.879,5.036
		c2.518,2.12,4.903,4.638,6.494,7.554c0.398,0.663,0.795,1.458,1.193,2.253c0.398,0.795,0.663,1.458,1.06,2.385l1.06,2.518
		c0.398,0.928,0.795,1.723,1.325,2.65c1.06,1.723,2.253,3.313,3.843,4.771c1.59,1.325,3.578,2.385,5.566,2.783
		c1.988,0.53,4.108,0.53,5.964,0.265c1.988-0.133,3.843-0.53,5.566-1.06c3.711-0.928,7.024-2.385,10.337-3.843
		c1.59-0.795,3.048-1.325,5.036-2.253c1.723-0.928,3.446-2.12,4.903-3.578c1.458-1.458,2.518-3.313,3.181-5.301
		c0.663-1.988,0.928-3.976,0.795-5.964c0-1.06-0.133-1.988-0.398-2.915l-0.265-1.458l-0.398-1.325
		c-0.398-1.723-0.928-3.446-1.458-5.036c-0.928-3.313-1.988-6.759-3.048-10.072c-1.06-3.313-2.253-6.626-3.446-9.807
		c-0.663-1.59-1.193-3.313-1.855-4.903l-0.795-2.385l-0.795-2.385c-1.855-6.229-2.12-12.722-0.265-18.686
		c0.663-1.988,1.59-3.711,2.651-5.566c0.398-0.53,1.723-2.915,4.108-6.096c2.518-3.313,5.566-7.554,8.746-11.927
		c6.361-8.746,12.325-18.288,14.047-20.541c3.048-5.036,5.831-10.204,8.481-15.638c2.518-4.506,5.698-14.842,2.915-6.096
		c1.458-3.976,2.253-7.024,2.783-9.542c0.53-2.518,0.928-4.506,1.06-6.229c0.265-1.723,0.398-3.578,0.663-5.433
		c0-0.53,0.265-0.928,0.663-1.325c0.398-0.398,1.06-0.663,1.723-0.663c0.398,0,0.663,0.133,0.928,0.398
		c0.265,0.133,0.398,0.398,0.795,0.795l2.385,3.711c0.795,1.193,1.59,2.518,2.385,3.711c0.928,1.193,1.723,2.518,2.915,3.711
		c0.265,0.265,0.398,0.398,0.663,0.663c0.265,0.265,0.663,0.53,0.795,0.663l3.578,2.65c1.59,1.193,3.181,2.385,4.771,3.578
		c3.181,2.253,6.361,4.638,9.674,6.759c6.626,4.241,13.517,8.084,21.071,10.469c7.554,2.385,15.505,3.446,23.324,2.65
		c1.988-0.133,3.843-0.663,5.831-0.928c1.855-0.398,3.711-0.928,5.698-1.458l1.193-0.398c0.398-0.133,0.795-0.133,1.193-0.265
		c0.795-0.133,1.59-0.265,2.385-0.265c1.59-0.133,3.313,0,4.903,0.398c0.795,0.133,1.458,0.398,2.253,0.663
		c0.265,0.133,0.663,0.265,0.928,0.53c0.265,0.133,0.795,0.53,1.193,0.663c1.59,1.06,3.181,1.988,4.771,3.048
		c3.181,1.988,6.228,4.108,9.276,6.361c3.048,2.12,6.096,4.506,8.879,6.891c1.458,1.193,2.915,2.385,4.241,3.711
		c1.458,1.193,2.651,2.518,4.373,3.976c1.59,1.325,3.445,2.65,5.566,3.446c2.12,0.928,4.638,1.325,6.891,1.06
		c2.385-0.265,4.506-1.06,6.494-1.988c1.988-0.928,3.711-1.988,5.433-3.048s3.446-2.12,5.168-2.915
		c1.723-0.928,3.313-1.59,5.301-2.253c3.711-1.193,7.554-1.988,11.529-2.253c0.53,0,1.193,0,1.855,0c0.53,0,1.193,0,1.855,0
		c1.193,0,2.385,0.133,3.711,0.265c2.518,0.398,5.036,0.795,7.554,1.723c1.325,0.398,2.518,0.928,3.711,1.325
		c1.193,0.53,2.518,1.06,3.711,1.723c2.518,1.193,4.903,2.783,7.289,4.373c1.193,0.928,2.385,1.723,3.711,2.65
		c1.193,0.928,2.518,1.855,3.976,2.65c1.325,0.928,2.783,1.723,4.241,2.518c1.458,0.795,2.915,1.59,4.506,2.253
		c3.048,1.458,6.361,2.518,9.674,3.446c3.445,0.795,7.024,1.193,10.602,1.193s7.156-0.53,10.734-1.458
		c1.723-0.53,3.578-0.928,5.168-1.723c0.795-0.265,1.723-0.663,2.518-1.06l2.518-1.193l1.193-0.53
		c0.265-0.133,0.53-0.265,0.928-0.398c0.663-0.265,1.325-0.398,2.12-0.53c1.59-0.265,3.313-0.53,5.301-0.928
		c0.53-0.133,0.928-0.265,1.458-0.398l1.325-0.398l2.518-0.795c1.723-0.53,3.446-0.928,5.168-1.458
		c3.446-0.928,7.024-1.988,10.602-3.578c1.723-0.795,3.578-1.723,5.168-2.783l1.193-0.928l1.06-0.795l2.253-1.723
		c1.855-1.458,3.578-2.915,5.301-4.373v-4.771H2002.674z"
        />
      </g>
      <g id="Hope">
        <path
          fill={textColor}
          d="M8.879,246.491c0,0.265,0,0.53,0,0.795l-0.133,0.133c-0.398,0.133-0.928,0.133-1.325,0.133
		c-0.265,0-0.398,0-0.663-0.133c0-0.133,0-0.398,0-0.53c0-0.265-0.133-0.398-0.133-0.663c0-0.398,0.133-0.663,0.133-0.928
		c0-0.53-0.398-0.398-0.133-1.06c-0.265-0.265-0.265-0.265-0.53-0.265c-0.265,0-0.53-0.133-0.663-0.133
		c-0.398,0.133-1.458,0.133-1.855,0.133s-0.663-0.133-0.928-0.133s-0.53,0.265-0.663,0.398c-0.133,0.398,0,0.795,0,1.193
		c0,0.663-0.265,1.325-0.398,1.988c-0.133,0-0.133,0.133-0.265,0.133c-0.265,0.133-0.795,0-1.193,0C0,247.419,0,247.286,0,247.154
		c0-0.53,0.133-0.795,0.133-1.325c0-0.398-0.133-0.795-0.133-1.193c0-1.723,0.133-3.313,0.133-4.903c0-0.265,0-0.663,0-0.928
		c0-0.133-0.133-0.398-0.133-0.53c0-0.133,0.133-0.265,0.265-0.265s1.325,0,1.458,0.133c0.265,0.398,0.265,0.795,0.265,1.193v0.398
		c0,0.133,0,0.133,0,0.265s0,0.133,0.133,0.265v0.265c0,0.133,0,0.265,0,0.398c0,0.398,0.133,0.795,0.265,1.193l0.265,0.53h0.133
		h0.265h0.133c0.133,0,0.398,0,0.53-0.133h0.133c0.53,0,2.65-0.133,2.915-0.265c0.133,0,0.265-0.133,0.398-0.398
		c0.133-0.53,0-0.928,0-1.458c0-0.265,0.133-0.663,0-0.928c0.133-0.53-0.133-1.06,0.265-1.325c0.133,0,0.53,0,0.663,0
		c0.265,0,1.193,0,1.325,0c0,0.133,0.133,0.133,0.133,0.265c0,0.398,0,0.663,0,1.06c0,0.398-0.133,0.663-0.133,1.06
		c0,0.398,0.133,0.795,0.133,1.325c0,1.193,0,2.385,0,3.578C8.746,245.829,8.879,246.226,8.879,246.491z"
        />
        <path
          fill={textColor}
          d="M12.325,242.118c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53s-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C12.192,243.046,12.325,242.648,12.325,242.118z M17.228,246.359c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59s0-0.928-0.133-1.325c-0.265-0.265-0.53-1.325-0.795-1.458
		c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265c-0.53,0-1.325,0.133-1.855,0.265
		c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385c0,0.133-0.133,0.265-0.133,0.53
		c0,0.398,0.53,1.59,0.795,1.723C16.168,245.696,16.3,246.359,17.228,246.359z"
        />
        <path
          fill={textColor}
          d="M27.83,247.419L27.83,247.419c-0.265,0.133-0.398,0.133-0.663,0.133c-0.398,0-0.795,0-1.325,0
		c-0.133-0.265-0.133-0.663-0.133-1.06s0.133-0.795,0.133-1.06c0-0.133-0.133-0.265-0.133-0.53v-1.193
		c0-1.06,0.133-2.12,0.133-3.181c0-0.398,0-0.663,0-1.06s-0.133-1.06,0.133-1.325h0.133c0.265,0,0.265,0,0.53-0.133
		c0.133,0,0.265,0.133,0.53,0.133c0.398,0,0.795-0.133,1.325-0.133c0.133,0,1.59,0,1.59,0c0.133,0.133,0.398,0.133,0.53,0.133
		c0.398,0,0.795-0.133,1.193-0.133c0.133,0,0.265,0.133,0.53,0.133c0.133,0,0.133,0,0.265,0c0.265,0,0.398,0.133,0.663,0.133
		c0.398,0.398,1.06,0.265,1.458,0.663c0.133,0.133,0.265,0.133,0.398,0.265c0.265,0.53,0.398,1.193,0.398,1.723
		c0,0.53-0.133,0.928-0.265,1.325c0,0.133-0.133,0.265-0.133,0.398c-0.133,0.795-1.325,1.06-1.988,1.06c-0.398,0-0.928,0-1.325,0
		c-0.53,0.265-2.385,0.133-3.048,0.133c-0.265,0-0.398,0.133-0.53,0.265c-0.133,0.265-0.133,1.06-0.265,1.325
		c0.133,0,0.133,0.133,0.133,0.265v1.723H27.83z M27.962,242.25L27.962,242.25c0.133,0,0.398-0.133,0.398-0.265h0.398
		c0.265,0,0.53,0,0.663,0.133h0.133c0.133-0.133,2.12-0.133,2.518-0.133c0.133,0,0.265,0,0.398,0.133H32.6
		c0.398-0.398,1.193-0.265,1.193-1.193c0-1.193-2.12-1.458-3.048-1.458c-0.53,0-1.193,0.133-1.855,0.133
		c-1.59,0-0.928,0.53-1.193,1.59L27.962,242.25z"
        />
        <path
          fill={textColor}
          d="M47.973,246.094c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.133,0.53,0.133,0.795v0.133l-0.133,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C45.985,245.961,47.708,245.961,47.973,246.094L47.973,246.094z"
        />
      </g>
      <g id="Cranbrook">
        <path
          fill={textColor}
          d="M1331.715,190.037c-0.663,0.398-0.795,1.325-0.795,1.988c0,0.928,1.06,1.723,1.988,1.723
		c0.398,0,1.06,0.265,1.59,0.265c1.325,0,2.783,0,3.976-0.265c0.265,0.133,0.398,0.265,0.398,0.53c0,0.663,0,1.325,0,1.988h-0.132
		c-0.132,0-0.265-0.133-0.398-0.133c-0.133,0-0.265,0-0.265,0.133h-0.132c-0.265-0.133-0.398,0-0.663,0
		c-0.133,0-0.265-0.133-0.398-0.133c-0.398,0-0.795,0.133-1.193,0.133c-0.663,0-1.59-0.133-2.253-0.265
		c-0.132-0.133-0.132-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.398,0.133c-0.132,0-0.398-0.133-0.663-0.133h-0.132
		c-1.855-1.06-3.048-2.253-3.048-4.771c0-2.915,2.253-3.578,4.506-4.506c0.663,0.133,1.59,0,2.253,0.133
		c0.398-0.133,0.795-0.133,1.325-0.133c0.53,0,1.193,0,1.59-0.265c0.133,0.133,0.133,0.265,0.133,0.398
		c0,0.265-0.133,0.53-0.133,0.795c0,0.398,0,0.663,0.133,1.06v0.265c-0.265,0-0.265-0.133-0.663-0.133h-0.795
		c-0.663,0-1.193,0.133-1.193,0.133c-0.265,0-0.53,0-0.795,0c-0.132,0-1.458,0-1.458,0l-0.795,0.398
		c-1.193,0.398-0.398,0-0.928,0.53C1331.98,189.904,1331.847,190.037,1331.715,190.037z"
        />
        <path
          fill={textColor}
          d="M1344.967,195.735c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.663,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.132,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.132,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.132,0.133,0,0.265,0,0.398c-0.132,0.398-0.132,0.663-0.132,1.06
		c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H1344.967z M1350.4,188.977c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.927,0.133,1.458,0.133c1.06,0,2.915,0,2.915-0.663
		C1350.533,189.507,1350.4,189.242,1350.4,188.977z"
        />
        <path
          fill={textColor}
          d="M1363.122,196.265c-0.132,0-0.265,0-0.398,0c-0.265-0.398-0.132-0.398-0.132-0.795
		c0-0.53-0.133-1.193-0.133-1.723c0-0.795-0.132-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.133,0.133-0.133,1.193-0.133,1.458s0.133,0.398,0.133,0.663c0,0.663,0,1.06-0.795,1.06c-0.398,0-0.663-0.133-0.928-0.133
		c-0.132,0-0.265-0.133-0.265-0.265c0-0.398,0.132-0.663,0.132-1.06c0-0.265-0.132-0.53-0.132-0.663
		c0-0.265,0.265-0.663,0.132-0.928c0-0.663,0.265-1.193,0.265-1.855c0.132-0.398,0.265-1.325,0.53-1.723
		c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06c0,1.06-0.132,2.253-0.132,3.313
		c0,1.723-0.132,3.313-0.132,4.903c0,0.265-0.133,0.398-0.398,0.398H1363.122z M1362.195,191.229c0.265,0,0.398-0.133,0.663-0.398
		c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133c-0.132-0.133-0.265-0.398-0.398-0.53
		c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398
		c0.133,0.265,0.265,0.265,0.53,0.265H1362.195z"
        />
        <path
          fill={textColor}
          d="M1369.351,186.856c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.927,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
		c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.927-1.193-1.458-1.855c-0.398-0.663-1.06-1.06-1.458-1.723
		c-0.132-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
		C1369.219,187.121,1369.219,186.989,1369.351,186.856L1369.351,186.856z"
        />
        <path
          fill={textColor}
          d="M1382.603,196.53L1382.603,196.53c-0.132-0.928-0.132-1.723-0.132-2.518
		c0-1.988-0.132-4.108-0.132-6.096c0-0.928,0.265-0.795,1.06-0.795c0.265,0,0.53,0.133,0.663,0.133c0.53,0,1.193-0.133,1.855-0.133
		c0.928,0,4.108,0.265,4.771,0.928c0.265,0.398,0.53,0.795,0.795,1.193c0.265,0.265,0.265,0.663,0.265,1.06
		c0,0.795-0.398,1.06-0.398,1.325c0,0.398,0.795,1.06,0.795,2.385c0,2.518-1.988,2.783-4.108,2.783
		c-0.795,0-1.723-0.265-2.518-0.265c-0.663,0-1.325,0.133-1.988,0.133h-0.928V196.53z M1389.494,190.832
		c0.398,0,0.53-0.265,0.53-0.663c0-0.265,0-0.53-0.265-0.663c-0.53-0.398-2.783-0.53-3.446-0.53s-1.458,0-2.12,0.133
		c0,0.133,0,0.265-0.133,0.53c0.398,0.928-0.663,1.59,1.06,1.59C1386.446,191.229,1388.302,191.097,1389.494,190.832z
		 M1384.989,194.808c0.53,0,1.193,0,1.723,0c1.06,0,2.253,0.133,3.313,0c0.265-0.265,0.53-0.265,0.53-0.663
		c-0.133-0.265,0-0.663-0.133-0.795c-1.193-0.928-0.927-0.663-2.518-0.663c-0.928,0-1.988,0.133-2.915,0.133
		c-0.265,0-0.398,0.133-0.53,0.265c0,0.398-0.132,0.795-0.132,1.193c0,0.133,0,0.398,0.132,0.53
		C1384.591,194.808,1384.724,194.808,1384.989,194.808z"
        />
        <path
          fill={textColor}
          d="M1397.711,195.735c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.663,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.927,0.928,0.927,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		s-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
		c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H1397.711z
		 M1403.144,188.977c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133
		c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
		c1.06,0,2.915,0,2.915-0.663C1403.277,189.507,1403.144,189.242,1403.144,188.977z"
        />
        <path
          fill={textColor}
          d="M1408.445,190.699c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.915-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.132,0-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C1408.18,191.76,1408.445,191.229,1408.445,190.699z M1413.348,195.073c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.927-1.193c0.265-0.265,0.53-1.193,0.53-1.59s0-0.928-0.132-1.325c-0.265-0.265-0.53-1.325-0.795-1.458
		c-0.265-0.265-0.663-0.398-0.927-0.663c-0.398-0.133-0.663-0.265-0.928-0.265c-0.663,0-1.325,0.133-1.855,0.265
		c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385c0,0.133-0.133,0.265-0.133,0.53
		c0,0.398,0.53,1.59,0.795,1.723C1412.288,194.277,1412.288,195.073,1413.348,195.073z"
        />
        <path
          fill={textColor}
          d="M1421.432,190.699c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.915-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.132,0-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C1421.167,191.76,1421.432,191.229,1421.432,190.699z M1426.336,195.073c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59s0-0.928-0.133-1.325c-0.265-0.265-0.53-1.325-0.795-1.458
		c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265c-0.663,0-1.325,0.133-1.855,0.265
		c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385c0,0.133-0.132,0.265-0.132,0.53
		c0,0.398,0.53,1.59,0.795,1.723C1425.275,194.277,1425.408,195.073,1426.336,195.073z"
        />
        <path
          fill={textColor}
          d="M1436.937,193.35c-0.132,0-0.265,0-0.265,0.133l0.133,0.133c-0.265,0.53,0.132-0.265-0.133,1.06
		c0,0.53,0,1.06,0,1.59c0,0.398-0.927,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.132-0.133,0.132-0.265
		v-0.133c-0.132-0.265-0.132-0.53-0.132-0.928v-1.193c0-0.265,0.132-0.53,0.132-0.795c0-0.795-0.132-1.723-0.132-2.65
		c0-0.398,0-0.663,0.132-1.193c-0.132-0.265-0.265-0.53-0.265-0.795v0.133c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
		c0.133,0.133,0.133,0.133,0.133,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.132,0.53-0.132,1.06
		c0,0.133-0.132,0.265-0.132,0.53c0,0.265,0.132,0.398,0.132,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
		c0.398-0.398,1.193-1.06,1.458-1.458c0.132-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.927-0.133h0.663l0.133,0.133
		c0,0.133-0.795,0.928-0.928,1.06c-0.53,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
		c0.795,1.06,2.915,3.843,3.843,5.168l-0.132,0.133c-0.133,0-0.398,0-0.53,0c-0.53,0-0.928,0-1.458,0h-0.398
		c-0.132-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.132-0.265
		C1437.865,192.422,1437.202,193.217,1436.937,193.35L1436.937,193.35z"
        />
      </g>
      <g id="Creston">
        <path
          fill={textColor}
          d="M1285.862,317.921c-0.663,0.398-0.795,1.325-0.795,1.988c0,0.928,1.06,1.723,1.988,1.723
		c0.398,0,1.06,0.265,1.59,0.265c1.325,0,2.783,0,3.976-0.265c0.265,0.133,0.398,0.265,0.398,0.53c0,0.663,0,1.325,0,1.988h-0.133
		c-0.132,0-0.265-0.133-0.398-0.133s-0.265,0-0.265,0.133h-0.132c-0.265-0.133-0.398,0-0.663,0c-0.133,0-0.265-0.133-0.398-0.133
		c-0.398,0-0.795,0.133-1.193,0.133c-0.663,0-1.59-0.133-2.253-0.265c-0.133-0.133-0.133-0.133-0.265-0.133
		c-0.133,0-0.265,0.133-0.398,0.133c-0.132,0-0.398-0.133-0.663-0.133h-0.132c-1.855-1.06-3.048-2.253-3.048-4.771
		c0-2.915,2.253-3.578,4.506-4.506c0.663,0.133,1.59,0,2.253,0.133c0.398-0.133,0.795-0.133,1.325-0.133s1.193,0,1.59-0.265
		c0.132,0.133,0.132,0.265,0.132,0.398c0,0.265-0.132,0.53-0.132,0.795c0,0.398,0,0.663,0.132,1.06v0.265
		c-0.265,0-0.265-0.133-0.663-0.133h-0.795c-0.663,0-1.193,0.133-1.193,0.133c-0.265,0-0.53,0-0.795,0c-0.132,0-1.458,0-1.458,0
		l-0.795,0.398c-1.193,0.398-0.398,0-0.928,0.53C1286.127,317.656,1285.995,317.921,1285.862,317.921z"
        />
        <path
          fill={textColor}
          d="M1299.114,323.619c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.663,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.132,0.133,0,0.265,0,0.398c-0.132,0.398-0.132,0.663-0.132,1.06
		c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H1299.114z M1304.548,316.86c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.651-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.916,0,2.916-0.663
		C1304.68,317.39,1304.548,317.125,1304.548,316.86z"
        />
        <path
          fill={textColor}
          d="M1319.258,322.691c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.132-0.928-0.132-2.12-0.132-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.132-0.133-0.132-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.132,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.132,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133c0.132,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.132,0.53,0.132,0.795v0.133l-0.132,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133s-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C1317.27,322.559,1318.993,322.559,1319.258,322.691
		L1319.258,322.691z"
        />
        <path
          fill={textColor}
          d="M1327.739,319.643c-0.398,0-1.06,0.133-1.458,0c-0.132,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.133-1.193
		c0.132-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
		c0.132,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.132,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
		c-0.133,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.915-0.133
		c-0.132,0.133-0.265,0.133-0.398,0.133s-0.398,0-0.53,0c-0.133,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.132,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.132,0.265,0.265,0.795,0.265,1.06c0,0.265-0.133,0.53-0.265,0.795c0,0.133,0.132,0.265,0.132,0.398s-1.193,1.723-1.458,2.12
		c-0.132,0.133-0.265,0.133-0.398,0.133h-0.133c-0.132-0.133-0.132-0.133-0.265-0.133c-0.132,0-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.132,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.133
		c-0.398,0-0.927,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.132-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.133-0.265,0.133-0.398s0-0.265,0-0.398c0-0.795-0.133-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.651-0.133,2.915-0.398c0.133-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.132-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.132-0.133h-0.133c-0.132,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h-0.132c-0.265,0-0.53,0.133-0.795,0.133C1328.269,319.776,1328.004,319.643,1327.739,319.643z"
        />
        <path
          fill={textColor}
          d="M1340.329,320.704c0-0.663,0-3.446-0.265-3.843c-0.132-0.398-0.398-0.398-0.663-0.398
		c-0.53,0-1.06,0-1.59,0c-0.398,0-0.663-0.265-1.06-0.398v-0.133c0-0.53,0-0.928,0-1.458c0-0.133,0.132-0.265,0.132-0.265
		c0.795,0,1.723,0.133,2.518,0.133c0.265,0,0.53,0,0.795,0.133c0.265,0,0.53,0,0.663,0c0.398,0,0.795,0.133,1.193,0.133
		c0.265,0,0.53-0.133,0.795-0.133c0.928,0,1.855,0,2.783,0c0.132,0,0.265,0,0.265,0.133c0,0.265,0,0.53,0,0.795
		c0,0.265,0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.385,0.133c-0.398,0-1.06-0.133-1.193,0.398
		c-0.265,0.928-0.265,4.373-0.265,5.301c0,0.133-0.132,0.265-0.132,0.53c0,0.265,0.132,0.53,0.132,0.795
		c0,0.663,0,0.795-0.663,0.795c-0.398,0-0.795-0.133-1.193-0.133c-0.132-0.398-0.132-0.795-0.132-1.193
		C1340.196,322.029,1340.329,321.366,1340.329,320.704z"
        />
        <path
          fill={textColor}
          d="M1349.208,318.583c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.132,0.133-0.132,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53s-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C1349.075,319.643,1349.208,319.113,1349.208,318.583z M1354.111,322.824c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.132-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.927-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193s-0.398,1.855-0.398,2.385c0,0.133-0.132,0.265-0.132,0.53
		c0,0.398,0.53,1.59,0.795,1.723C1353.051,322.161,1353.183,322.824,1354.111,322.824z"
        />
        <path
          fill={textColor}
          d="M1362.99,314.74c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0c0.398,0.398,0.663,1.06,0.928,1.458
		c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325c0.265,0.265,0.53,0.398,0.663,0.663l0.132,0.133
		c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.132-2.253,0.132-3.313c0-0.53,0-1.193-0.132-1.723v-0.133c0.265,0,0.663,0,0.928,0
		s0.53,0.133,0.663,0.398c0.265,0.795,0.132,1.855,0.132,2.783c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325
		c-0.132,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265c-0.265,0-0.53-0.133-0.663-0.398
		c-0.398-0.663-0.928-1.193-1.458-1.855c-0.53-0.663-1.06-1.06-1.458-1.723c-0.132-0.265-1.193-1.723-1.325-1.723
		c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133c0,0.265,0,0.53-0.265,0.663c-0.132,0-0.928,0-1.193,0
		c-0.53,0-0.927-0.133-0.927-0.663c0-2.915,0-5.831,0-8.614C1362.858,315.005,1362.858,314.873,1362.99,314.74L1362.99,314.74z"
        />
      </g>
      <g id="Sparwood">
        <path
          fill={textColor}
          d="M1647.648,93.031c-0.398,0-1.06,0.133-1.458,0c-0.132,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.132-1.193
		c0.133-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
		c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.132,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133s-0.265,0.133-0.398,0.133
		c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.915-0.133c-0.133,0.133-0.265,0.133-0.398,0.133c-0.132,0-0.398,0-0.53,0
		c-0.133,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06c0,0.133,0,0.53,0.132,0.53c0.663,0.398,4.506,0.398,5.566,0.398
		c0.265,0,0.53,0,0.927,0c0.53,0,1.193,1.06,1.458,1.59c0.132,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795
		c0,0.133,0.132,0.265,0.132,0.398c0,0.133-1.193,1.723-1.458,2.12c-0.133,0.133-0.265,0.133-0.398,0.133h-0.132
		c-0.132-0.133-0.132-0.133-0.265-0.133c-0.132,0-0.265,0.133-0.53,0.133c-1.06,0-1.988-0.133-3.048-0.133h-0.265
		c-0.132,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.133c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133
		c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133c0-0.133,0.132-0.265,0.132-0.398c0-0.133,0-0.265,0-0.398
		c0-0.795-0.132-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.651-0.133,2.915-0.398
		c0.133-0.133,0.265-0.265,0.265-0.398c0-0.398-0.132-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.132-0.133h-0.133
		c-0.132,0-0.265,0-0.265,0c-0.132,0-0.398,0-0.53-0.133h-0.133c-0.265,0-0.53,0.133-0.795,0.133
		C1648.177,93.163,1647.913,93.031,1647.648,93.031z"
        />
        <path
          fill={textColor}
          d="M1658.779,97.404L1658.779,97.404c-0.265,0.133-0.398,0.133-0.663,0.133c-0.398,0-0.795,0-1.325,0
		c-0.133-0.265-0.133-0.663-0.133-1.06c0-0.398,0.133-0.795,0.133-1.06c0-0.133-0.133-0.265-0.133-0.53v-1.193
		c0-1.06,0.133-2.12,0.133-3.181c0-0.398,0-0.663,0-1.06c0-0.398-0.133-1.06,0.132-1.325h0.132c0.265,0,0.265,0,0.53-0.133
		c0.132,0,0.265,0.133,0.53,0.133c0.398,0,0.795-0.133,1.325-0.133c0.132,0,1.59,0,1.59,0c0.132,0.133,0.398,0.133,0.53,0.133
		c0.398,0,0.795-0.133,1.193-0.133c0.132,0,0.265,0.133,0.53,0.133c0.133,0,0.133,0,0.265,0c0.265,0,0.398,0.133,0.663,0.133
		c0.398,0.398,1.06,0.265,1.458,0.663c0.132,0.133,0.265,0.133,0.398,0.265c0.265,0.53,0.398,1.193,0.398,1.723
		s-0.132,0.928-0.265,1.325c0,0.133-0.133,0.265-0.133,0.398c-0.132,0.795-1.325,1.06-1.988,1.06c-0.398,0-0.928,0-1.325,0
		c-0.53,0.265-2.385,0.133-3.048,0.133c-0.265,0-0.398,0.133-0.53,0.265c-0.132,0.265-0.132,1.06-0.265,1.325
		c0.132,0,0.132,0.133,0.132,0.265v1.723H1658.779z M1658.912,92.235L1658.912,92.235c0.132,0,0.398-0.133,0.398-0.265h0.398
		c0.265,0,0.53,0,0.663,0.133h0.133c0.132-0.133,2.12-0.133,2.518-0.133c0.132,0,0.265,0,0.398,0.133h0.133
		c0.398-0.398,1.193-0.265,1.193-1.193c0-1.193-2.12-1.458-3.048-1.458c-0.53,0-1.193,0.133-1.855,0.133
		c-1.59,0-0.928,0.53-1.193,1.59L1658.912,92.235z"
        />
        <path
          fill={textColor}
          d="M1676.935,97.669c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.132-0.398-0.132-0.795
		c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.132,0.133-0.132,1.193-0.132,1.458c0,0.265,0.132,0.398,0.132,0.663c0,0.663,0,1.06-0.795,1.06
		c-0.398,0-0.663-0.133-0.928-0.133c-0.132,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
		c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
		c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
		c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.132,3.313-0.132,4.903c0,0.265-0.132,0.398-0.398,0.398H1676.935z M1676.007,92.633
		c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
		c-0.132-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.927,0.398
		c-0.398,0.265-1.193,1.06-1.458,1.325l-0.132,0.398c0.132,0.265,0.265,0.265,0.53,0.265L1676.007,92.633L1676.007,92.633z"
        />
        <path
          fill={textColor}
          d="M1684.886,97.006c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.663,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.927,0.928,0.927,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.132,0.133,0,0.265,0,0.398c-0.132,0.398-0.132,0.663-0.132,1.06
		c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H1684.886z M1690.32,90.248c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.651-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.915,0,2.915-0.663
		C1690.452,90.778,1690.32,90.513,1690.32,90.248z"
        />
        <path
          fill={textColor}
          d="M1697.873,88.392c0,0.663-0.132,1.458-0.132,2.253c0,0.53,0,1.06,0,1.723c0,0.398,0,1.06,0,1.325
		c0,0.133,0,0.265,0.132,0.265c0,0,0.133,0,0.133-0.133c0.398-0.928,1.06-2.253,1.458-3.181c0.132-0.133,0.398-0.795,0.53-1.06
		l0.132-0.133c0,0,0,0,0.133,0c0.132,0,0.132,0.133,0.132,0.265c0.265,0.663,0.795,1.325,1.06,2.12
		c0.133,0.265,0.265,0.398,0.398,0.663c0.265,0.265,0.398,0.663,0.795,0.795c-0.132-0.795-0.132-1.988-0.132-2.783
		c0-0.663-0.132-1.458-0.132-2.12c0-0.53,1.325-0.53,1.723-0.53c0.265,0,0.795,0,0.795,0.398c0,0.663,0,1.325,0,1.988
		c0,0.663,0,3.313,0,3.711c-0.133,0.398,0,1.723,0,2.12c0,0.398,0,1.06-0.53,1.193c-0.53,0-1.06,0.133-1.59,0.133
		c-0.795,0-1.458-2.253-2.253-2.783c0-0.133-0.265-0.53-0.265-0.663s0-0.133,0-0.133c0-0.133,0-0.133-0.132-0.133
		c-0.133,0.265-0.265,0.53-0.265,0.663c0,0.265-0.132,0.398-0.398,0.53c-0.398,0.928-0.928,2.783-2.253,2.783
		c-0.265,0-0.53,0.133-0.928,0.133c-0.398,0-0.53,0-0.53-0.398c0-0.398,0.133-0.795,0-1.325c0.133-0.398,0-0.795,0-1.193
		c0-0.133,0.133-0.265,0.133-0.53c-0.133-0.133-0.265-0.398-0.265-0.663c0-0.133,0.132-0.265,0.132-0.398
		c0-0.398-0.132-0.795-0.132-1.193c0.132-0.133,0.132-0.265,0.132-0.398c0-0.398-0.132-0.795,0-1.193
		c-0.132-0.795-0.132-1.59-0.265-2.385c0-0.53,1.325-0.398,1.59-0.53C1697.608,87.862,1697.873,88.26,1697.873,88.392z"
        />
        <path
          fill={textColor}
          d="M1708.873,91.97c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.132,0.133-0.132,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53s-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C1708.74,93.031,1708.873,92.5,1708.873,91.97z M1713.776,96.344c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.132-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.927,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.132,0.265-0.132,0.53c0,0.398,0.53,1.59,0.795,1.723C1712.848,95.548,1712.848,96.344,1713.776,96.344z"
        />
        <path
          fill={textColor}
          d="M1721.86,91.97c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.132,0.133-0.132,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.132,0-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C1721.727,93.031,1721.86,92.5,1721.86,91.97z M1726.763,96.344c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.132-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.927-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.133,0.265-0.133,0.53c0,0.398,0.53,1.59,0.795,1.723C1725.836,95.548,1725.836,96.344,1726.763,96.344z"
        />
        <path
          fill={textColor}
          d="M1735.377,94.753c0-0.398,0.132-0.663,0.132-1.06c-0.132-0.133-0.132-0.398-0.132-0.53
		c0-1.458-0.265-2.915-0.265-4.373c0-0.133,0.132-0.265,0.265-0.265c0.132,0,0.265,0,0.398,0c0.53,0,1.06,0,1.59-0.133
		c0.928-0.133,2.12-0.133,3.048-0.133c1.06,0,1.988,0.398,2.783,0.928c0.663,0.398,0.663,0.928,1.193,1.458
		c0.398,0.398,0.53,1.193,0.53,1.723s-0.132,1.06,0,1.59c-0.132,0.265-0.132,0.663-0.132,0.928c0,0.133,0,0.133-0.133,0.265
		c-0.132,0.133-0.265,0.398-0.398,0.53c-0.398,0.663-1.59,1.06-2.385,1.458c-0.795,0-1.723,0.265-2.518,0.265
		c-0.265,0-0.398,0.133-0.663,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.53,0.265-1.193,0.265-1.855,0.265
		c-0.132,0-0.398,0-0.398-0.133C1735.377,96.476,1735.377,95.548,1735.377,94.753z M1740.28,95.548c1.59,0,2.518-1.193,2.518-2.65
		c0-1.06-0.398-1.59-1.06-2.385c-0.265,0-0.398-0.133-0.663-0.265h-0.132c-0.265,0-0.53-0.133-0.795-0.133
		c-0.133,0-0.133,0-0.265-0.133l-0.265-0.398c-0.132,0-0.663-0.133-0.663-0.133c-1.06,0-1.855,0.265-1.855,1.458
		c0,0.398,0.132,2.385,0.265,2.783c0,0.265-0.133,0.398-0.133,0.663c0,0.398,0,0.663,0.133,1.06c0.132,0,0.132,0.133,0.265,0.133
		C1738.558,95.681,1739.485,95.548,1740.28,95.548z"
        />
      </g>
      <g id="Fernie">
        <path
          fill={textColor}
          d="M1665.273,201.964v0.133c0,0.53,0.132,1.06,0.265,1.59c0.398,0,0.133,0,0.928,0
		c0.133,0,0.663,0.133,0.795,0.133c0.132,0,0.265-0.133,0.53-0.133c0.265,0,0.398-0.133,0.663-0.133c0.53,0,1.06,0,1.59-0.133
		c0.133,0.133,0.133,0.133,0.133,0.265c0,0.663,0,1.193-0.398,1.723l-0.265,0.133c-0.265,0-0.398-0.133-0.663-0.133
		c-0.265,0-0.928,0.133-1.458,0.133s-2.253-0.133-2.12,0.53c0,0.133,0,0.265,0,0.398c0,0.398-0.132,0.795-0.132,1.325
		c0,0.265,0.132,0.398,0.132,0.663c0,0.133,0,0.398,0,0.53c-0.398,0.133-0.928,0.133-1.193,0.133c-0.265,0-0.398-0.133-0.53-0.133
		c-0.133-0.265,0-0.53,0-0.928c0-0.133,0-0.265,0-0.398c0.132-1.325,0.132-2.783,0.132-4.108c0-0.795-0.132-1.59-0.132-2.385
		l0.132-0.265c-0.132-0.133-0.265-0.398-0.265-0.53c0-0.265,0-0.53,0.133-0.795c0.132,0,0.265,0,0.53,0c0.398,0,0.928,0,1.325,0.133
		c0.265-0.133,0.663-0.133,0.927-0.133h1.59c0.133,0,0.398,0.133,0.53,0.133c0.53,0,1.193-0.133,1.723-0.133
		c0.132,0,0.265,0.133,0.398,0.133c0.133-0.133,0.265-0.133,0.398-0.133c0.132,0,0.265,0,0.53,0.133
		c0.133,0,0.398-0.133,0.663-0.133c0.398,0,0.398,1.193,0.398,1.458c0,0.265-0.133,0.53-0.398,0.663h-0.132
		c-0.398-0.265-1.06-0.133-1.59-0.133C1669.381,201.434,1666.068,201.566,1665.273,201.964L1665.273,201.964z"
        />
        <path
          fill={textColor}
          d="M1685.284,207.265c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.132-0.928-0.132-2.12-0.132-3.048c0-0.53-0.132-1.193-0.132-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.927-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.132,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.132,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.132,0.53,0.132,0.795v0.133l-0.132,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133s-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C1683.296,207.132,1685.019,207.132,1685.284,207.265
		L1685.284,207.265z"
        />
        <path
          fill={textColor}
          d="M1691.38,208.192c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.132,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.132,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.132,0.133,0,0.265,0,0.398c-0.132,0.398-0.132,0.663-0.132,1.06
		c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H1691.38z M1696.813,201.434c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.651-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.927,0.133,1.458,0.133c1.06,0,2.915,0,2.915-0.663
		C1697.078,201.964,1696.946,201.699,1696.813,201.434z"
        />
        <path
          fill={textColor}
          d="M1702.909,199.313c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.927,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
		c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.927-1.193-1.458-1.855s-1.06-1.06-1.458-1.723
		c-0.132-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
		C1702.777,199.578,1702.777,199.446,1702.909,199.313L1702.909,199.313z"
        />
        <path
          fill={textColor}
          d="M1719.077,205.277L1719.077,205.277c0-1.06-0.133-2.12-0.133-3.181c0-1.325-0.398-0.928-1.59-0.928
		c-0.53,0-1.06,0.133-1.325,0.133c-0.132,0-0.132,0-0.265-0.133c-0.132-0.133-0.265-0.133-0.53-0.133
		c-0.133-0.265-0.133-1.193-0.133-1.458c0-0.133,0-0.265,0.133-0.265c0.265,0,0.398,0,0.53,0c0.928,0,1.855,0,2.783,0.265
		c0.265-0.133,0.53-0.133,0.795-0.133c0.398,0,0.795,0.133,1.325,0.133c0.265,0,0.53-0.133,0.663-0.133c0.53,0,2.915,0,3.048,0.265
		c0,0.265,0,0.53,0,0.795s0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.253,0.133c-0.132,0-1.193,0-1.325,0.133
		c-0.132,0.265-0.132,3.976-0.132,4.638c0,0.795,0.795,1.193,1.458,1.193c0.265,0,0.663-0.133,0.928-0.133s0.53,0.133,0.663,0.265
		c0,0.133,0.133,0,0.133,0v0.265c0,0.398,0.132,0.795,0.132,1.193c0,0.133,0,0.265-0.132,0.265c-0.53,0-0.928-0.133-1.458-0.265
		c-1.988,0-3.976,0-5.964,0c-0.663,0-1.193,0-1.193-0.795c0-1.06,0.663-0.928,1.458-0.928c0.53,0,0.928,0,1.325,0
		c0.132,0,0.398,0,0.53,0c0.132,0,0.265-0.133,0.398-0.265c0.133-0.398,0.133-0.795,0.133-1.193
		C1719.077,205.542,1719.077,205.409,1719.077,205.277z"
        />
        <path
          fill={textColor}
          d="M1737.497,207.265c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.132-0.928-0.132-2.12-0.132-3.048c0-0.53-0.132-1.193-0.132-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.927-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.132,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.132,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.132,0.53,0.132,0.795v0.133l-0.132,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C1735.51,207.132,1737.232,207.132,1737.497,207.265
		L1737.497,207.265z"
        />
      </g>
      <g id="Kimberly">
        <path
          fill={textColor}
          d="M1282.284,141.136c-0.132,0-0.265,0-0.265,0.133l0.132,0.133c-0.265,0.53,0.132-0.265-0.132,1.06
		c0,0.53,0,1.06,0,1.59c0,0.398-0.928,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.133-0.133,0.133-0.265
		v-0.133c-0.133-0.265-0.133-0.53-0.133-0.928v-1.193c0-0.265,0.133-0.53,0.133-0.795c0-0.795-0.133-1.723-0.133-2.65
		c0-0.398,0-0.663,0.133-1.193c-0.133-0.265-0.265-0.53-0.265-0.795v-0.133c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
		c0.132,0.133,0.132,0.133,0.132,0.265c0,0.398-0.132,0.795-0.132,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.133,0.53-0.133,1.06
		c0,0.133-0.132,0.265-0.132,0.53s0.132,0.398,0.132,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
		c0.398-0.398,1.193-1.06,1.458-1.458c0.133-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.928-0.133h0.663l0.132,0.133
		c0,0.133-0.795,0.928-0.928,1.06c-0.663,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
		c0.795,1.06,2.915,3.843,3.843,5.168l-0.132,0.133c-0.132,0-0.398,0-0.53,0c-0.53,0-0.928,0-1.458,0h-0.398
		c-0.132-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.132-0.265
		C1283.212,140.076,1282.417,140.871,1282.284,141.136L1282.284,141.136z"
        />
        <path
          fill={textColor}
          d="M1296.331,140.606L1296.331,140.606c0-1.06-0.132-2.12-0.132-3.181c0-1.325-0.398-0.928-1.59-0.928
		c-0.53,0-1.06,0.133-1.325,0.133c-0.132,0-0.132,0-0.265-0.133c-0.133-0.133-0.265-0.133-0.53-0.133
		c-0.132-0.265-0.132-1.193-0.132-1.458c0-0.133,0-0.265,0.132-0.265c0.265,0,0.398,0,0.53,0c0.928,0,1.855,0,2.783,0.265
		c0.265-0.133,0.53-0.133,0.795-0.133c0.398,0,0.795,0.133,1.325,0.133c0.265,0,0.53-0.133,0.663-0.133c0.53,0,2.915,0,3.048,0.265
		c0,0.265,0,0.53,0,0.795s0,0.53-0.132,0.663c-0.663,0.265-1.59,0.133-2.253,0.133c-0.133,0-1.193,0-1.325,0.133
		c-0.133,0.265-0.133,3.976-0.133,4.638c0,0.795,0.795,1.193,1.458,1.193c0.265,0,0.663-0.133,0.928-0.133
		c0.265,0,0.53,0.133,0.663,0.265c0,0.133,0.132,0,0.132,0v0.265c0,0.398,0.132,0.795,0.132,1.193c0,0.133,0,0.265-0.132,0.265
		c-0.53,0-0.928-0.133-1.458-0.265c-1.988,0-3.976,0-5.963,0c-0.663,0-1.193,0-1.193-0.795c0-1.06,0.663-0.928,1.458-0.928
		c0.53,0,0.928,0,1.325,0c0.133,0,0.398,0,0.53,0c0.133,0,0.265-0.133,0.398-0.265c0.132-0.398,0.132-0.795,0.132-1.193
		C1296.331,140.738,1296.331,140.606,1296.331,140.606z"
        />
        <path
          fill={textColor}
          d="M1306.005,141.136c-0.133-0.265-0.265-0.53-0.133-0.795c-0.132-0.265-0.132-0.53-0.132-0.663
		c0-0.663,0.132-1.325,0.132-1.988s-0.132-1.325-0.132-1.988c0-0.133,0-0.398,0.132-0.53c-0.132-0.133-0.265-0.398-0.265-0.53
		c0-0.265,0.663-0.265,0.795-0.265c0.53,0,1.06,0.133,1.723,0.133c0.132,0,0.265,0.133,0.265,0.265
		c0.265,1.06,0.927,1.988,1.59,2.783v0.265v0.133l0.133,0.133c0.132-0.133,0.398-0.398,0.53-0.398
		c0.265-0.53,0.663-1.855,1.193-1.988v-0.133c0-0.265,0.265-0.53,0.265-0.795c0-0.133,0.133-0.133,0.265-0.133
		c0.663,0,1.59,0.133,2.385,0.133c0.132,0,0.398,0,0.398,0.265v0.265c-0.132,1.06-0.132,2.12-0.132,3.181
		c0,0.53-0.133,0.928,0,1.458c0,0.133-0.133,0.265-0.133,0.398c0,0.795,0.133,1.59,0.133,2.518c0,1.458,0.132,1.06-1.723,1.325
		c-0.132,0-0.265-0.133-0.265-0.265c0-0.133,0-0.398,0.133-0.663c0.132-0.928,0-2.12,0-3.048c0-0.53-0.133-1.06-0.133-1.59l0,0
		v-0.133c0-0.133,0-0.928-0.132-1.06h-0.132c-0.133,0-0.398,0.663-0.398,0.795l-0.398,0.928c0,0.265-1.325,2.915-1.458,3.048
		c-0.132,0-0.132-0.133-0.265-0.265c-0.133-0.53-1.325-2.518-1.723-3.048c-0.132-0.265-0.265-0.53-0.398-0.795
		c-0.133-0.133-0.265-0.53-0.398-0.663h-0.132l-0.133,0.133c0,0.133,0,0.398,0,0.53c0,0.133-0.132,0.265-0.132,0.398
		c0,0.53,0.132,0.928,0.132,1.458c0,0.53,0.133,1.193,0.133,1.723c0,0.53,0,1.193,0,1.855c0,0.133-0.133,0.398-0.133,0.398
		c-0.132,0-0.265,0-0.398,0c-0.265,0-0.398-0.133-0.53-0.133c-0.265,0-0.53,0.133-0.795,0.133c0-0.133-0.133-0.265-0.133-0.398
		c0-0.795,0-1.458,0-2.253L1306.005,141.136z"
        />
        <path
          fill={textColor}
          d="M1319.125,144.317L1319.125,144.317c-0.133-0.928-0.133-1.723-0.133-2.518
		c0-1.988-0.132-4.108-0.132-6.096c0-0.928,0.265-0.795,1.06-0.795c0.265,0,0.53,0.133,0.663,0.133c0.53,0,1.193-0.133,1.855-0.133
		c0.928,0,4.108,0.265,4.771,0.928c0.265,0.398,0.53,0.795,0.795,1.193c0.265,0.265,0.265,0.663,0.265,1.06
		c0,0.795-0.398,1.06-0.398,1.325c0,0.398,0.795,1.06,0.795,2.385c0,2.518-1.988,2.783-4.108,2.783
		c-0.795,0-1.723-0.265-2.518-0.265c-0.663,0-1.325,0.133-1.988,0.133h-0.927V144.317z M1326.149,138.486
		c0.398,0,0.53-0.265,0.53-0.663c0-0.265,0-0.53-0.265-0.663c-0.53-0.398-2.783-0.53-3.446-0.53s-1.458,0-2.12,0.133
		c0,0.133,0,0.265-0.132,0.53c0.398,0.928-0.663,1.59,1.06,1.59C1322.968,138.883,1324.956,138.751,1326.149,138.486z
		 M1321.51,142.594c0.53,0,1.193,0,1.723,0c1.06,0,2.253,0.133,3.313,0c0.265-0.265,0.53-0.265,0.53-0.663
		c-0.132-0.265,0-0.663-0.132-0.795c-1.193-0.928-0.928-0.663-2.518-0.663c-0.927,0-1.988,0.133-2.915,0.133
		c-0.265,0-0.398,0.133-0.53,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0.133,0.53
		C1321.113,142.461,1321.378,142.594,1321.51,142.594z"
        />
        <path
          fill={textColor}
          d="M1341.256,142.461c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.915-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.132-0.928-0.132-2.12-0.132-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.132-0.133-0.132-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133s0.663-0.133,0.928-0.133
		c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.132,1.193-0.265,1.458h-0.663c-0.265,0-0.53,0-0.795,0.265
		c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265c-0.132,0.133-0.132,0.795-0.132,0.928
		c0.132,0.133,0,0.398,0.132,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265c0.663,0,1.458,0,2.12-0.133
		c0.265,0,0.53-0.133,0.663-0.133c0.132,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663c0,0.265,0.133,0.53,0.133,0.795v0.133
		l-0.133,0.133c-0.265,0.133-0.663,0.133-0.927,0.133c-0.663,0-1.59,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133
		s-0.663,0-0.795,0.265c0,0.133-0.132,0.398-0.132,0.53c0,0.265,0.132,0.53,0.265,0.795c0.53,0.398,0.928,0.265,1.59,0.265
		c0.927,0,1.59,0.133,2.385,0C1339.268,142.329,1340.991,142.329,1341.256,142.461L1341.256,142.461z"
        />
        <path
          fill={textColor}
          d="M1347.485,143.389c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.132,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.132,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.132,0.133,0,0.265,0,0.398c-0.132,0.398-0.132,0.663-0.132,1.06
		c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H1347.485z M1352.918,136.63c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.651-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.927,0.133,1.458,0.133c1.06,0,2.915,0,2.915-0.663
		C1353.051,137.293,1352.918,136.895,1352.918,136.63z"
        />
        <path
          fill={textColor}
          d="M1358.484,140.871c0-1.59,0.132-3.048,0.132-4.638c0-0.265,0.132-0.398,0.132-0.663
		c0-0.398-0.132-0.795-0.132-1.193v-0.133c0.132-0.133,0.795-0.133,1.06-0.133c0.398,0,0.663,0.133,1.06,0.265
		c0,0.133,0,0.133,0.132,0.265c-0.132,0.398,0,0.928,0,1.325c0,0.133,0.133,0.265,0.133,0.53c0,0.133-0.133,0.398-0.133,0.53
		c0,0.133,0,0.265,0.133,0.398v0.133c-0.133,0.265-0.133,0.398-0.133,0.663c0,0-0.132,0.133-0.132,0.265
		c0,0.133,0.132,0.265,0.132,0.398c0,0.133,0,0.53,0.133,0.663c-0.133,0.398,0,0.795,0,1.193c0,0.265-0.133,0.53-0.133,0.663
		c0,0.928,0.53,0.663,1.193,0.663c0.795,0,1.59,0.133,2.385,0.133c0.132,0,0.398,0,0.53-0.133c0.398,0.133,0.795,0.133,1.325,0.133
		c0.928,0,1.458-0.265,1.458,0.663c0,0.265-0.132,1.325-0.398,1.325c-0.398,0-0.663-0.265-1.06-0.265c-0.132,0-0.398,0-0.53,0
		c-0.265-0.133-0.53,0-0.795,0c-0.132,0-0.265-0.133-0.53-0.133c-0.663,0-1.325,0.133-1.988,0.133c-0.265,0-0.663,0-0.928,0
		c-0.132,0-0.53,0.133-0.53,0.133c-0.265,0-0.53-0.133-0.795-0.133c-0.53,0-0.928,0.133-1.325,0.265
		c-0.398-0.133-0.398-0.398-0.398-0.663c0-0.663,0.132-1.193,0.132-1.855C1358.484,141.401,1358.484,141.136,1358.484,140.871z"
        />
        <path
          fill={textColor}
          d="M1380.615,142.461c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.132-0.928-0.132-2.12-0.132-3.048c0-0.53-0.132-1.193-0.132-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.132,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.132,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.132,0.53,0.132,0.795v0.133l-0.132,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133s-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C1378.628,142.329,1380.35,142.329,1380.615,142.461
		L1380.615,142.461z"
        />
        <path
          fill={textColor}
          d="M1388.037,139.943c-0.265-0.53-0.53-1.06-0.795-1.59c-0.663-0.928-1.458-1.855-1.988-2.915
		c-0.133-0.265-0.265-0.663-0.663-0.795c0-0.398,0.132-0.265,0.398-0.265c0.663,0,1.193-0.133,1.855,0l0.265-0.265h0.132
		c0.663,0.795,0.928,2.12,1.59,2.783c0.265,0.265,0.398,0.53,0.53,0.795c0.132-0.398,0.398-0.795,0.663-1.193
		c0-0.265,0-0.398,0.132-0.53c0.398-0.53,1.06-1.723,1.193-1.723c0.265,0,1.59,0,1.855,0.133v0.265
		c-0.265,0.398-0.398,1.06-0.663,1.458c-0.53,0.398-0.53,1.193-0.928,1.59c-0.265,0.265-0.663,0.663-0.795,0.928
		c-0.133,0.53-0.398,0.53-0.795,1.325c0.133,0.663,0.265,1.855,0.265,2.518c0,0.265,0,0.663-0.132,0.928l-0.133,0.133
		c-0.265,0-0.53-0.265-0.928-0.265c-0.397,0-0.663,0.265-0.927,0.265c-0.53,0-0.398-0.795-0.398-1.193
		C1387.772,141.666,1387.904,140.738,1388.037,139.943z"
        />
      </g>
      <g id="Nelson">
        <path
          fill={textColor}
          d="M1091.85,198.651c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.397,0,0.265-0.928,0.265-1.193c0-1.06,0.132-2.253,0.132-3.313
		c0-0.53,0-1.193-0.132-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.132,1.855,0.132,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.132,0.133-0.265,0.133-0.398,0.133c-0.397,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.397-0.663-0.928-1.193-1.458-1.855c-0.398-0.663-1.06-1.06-1.458-1.723
		c-0.132-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
		C1091.717,198.783,1091.717,198.783,1091.85,198.651L1091.85,198.651z"
        />
        <path
          fill={textColor}
          d="M1113.848,206.469c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.916-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.132-0.928-0.132-2.12-0.132-3.048c0-0.53-0.132-1.193-0.132-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133s0.663-0.133,0.928-0.133
		c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398c0,0.398-0.133,1.193-0.265,1.458h-0.663c-0.265,0-0.53,0-0.795,0.265
		c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265c-0.132,0.133-0.132,0.795-0.132,0.928
		c0.132,0.133,0,0.398,0.132,0.663c0.132,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265c0.663,0,1.458,0,2.12-0.133
		c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663s0.132,0.53,0.132,0.795v0.133l-0.132,0.133
		c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133s-0.663,0-0.795,0.265
		c0,0.133-0.132,0.398-0.132,0.53c0,0.265,0.132,0.53,0.265,0.795c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0
		C1111.861,206.337,1113.584,206.337,1113.848,206.469L1113.848,206.469z"
        />
        <path
          fill={textColor}
          d="M1117.824,204.879c0-1.59,0.132-3.048,0.132-4.638c0-0.265,0.132-0.398,0.132-0.663
		c0-0.398-0.132-0.795-0.132-1.193v-0.133c0.132-0.133,0.795-0.133,1.06-0.133c0.398,0,0.663,0.133,1.06,0.265
		c0,0.133,0,0.133,0.132,0.265c-0.132,0.398,0,0.928,0,1.325c0,0.133,0.132,0.265,0.132,0.53c0,0.133-0.132,0.398-0.132,0.53
		c0,0.133,0,0.265,0.132,0.398v0.133c-0.132,0.265-0.132,0.398-0.132,0.663c0,0-0.132,0.133-0.132,0.265
		c0,0.133,0.132,0.265,0.132,0.398c0,0.133,0,0.53,0.132,0.663c-0.132,0.398,0,0.795,0,1.193c0,0.265-0.132,0.53-0.132,0.663
		c0,0.928,0.53,0.663,1.193,0.663c0.795,0,1.59,0.133,2.385,0.133c0.132,0,0.398,0,0.53-0.133c0.398,0.133,0.795,0.133,1.325,0.133
		c0.928,0,1.458-0.265,1.458,0.663c0,0.265-0.133,1.325-0.398,1.325c-0.398,0-0.663-0.265-1.06-0.265c-0.132,0-0.398,0-0.53,0
		c-0.265-0.133-0.53,0-0.795,0c-0.133,0-0.265-0.133-0.53-0.133c-0.663,0-1.325,0.133-1.988,0.133c-0.265,0-0.663,0-0.928,0
		c-0.132,0-0.53,0.133-0.53,0.133c-0.265,0-0.53-0.133-0.795-0.133c-0.53,0-0.928,0.133-1.325,0.265
		c-0.398-0.133-0.398-0.398-0.398-0.663c0-0.663,0.132-1.193,0.132-1.855C1117.824,205.409,1117.824,205.144,1117.824,204.879z"
        />
        <path
          fill={textColor}
          d="M1135.449,203.554c-0.397,0-1.06,0.133-1.458,0c-0.133,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.133-1.193
		c0.132-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
		c0.132,0,0.397,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.397,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.132,0.133,0.265,0,0.53,0c0.397,0.53,0.265,1.06,0.53,1.723h-0.265c-0.132,0-0.265-0.133-0.398-0.133
		c-0.132,0-0.265,0.133-0.398,0.133c-0.397,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.916-0.133
		c-0.133,0.133-0.265,0.133-0.398,0.133s-0.398,0-0.53,0c-0.132,0-0.265,0-0.397,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.132,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.132,0.265,0.265,0.795,0.265,1.06s-0.132,0.53-0.265,0.795c0,0.133,0.132,0.265,0.132,0.398c0,0.133-1.193,1.723-1.458,2.12
		c-0.132,0.133-0.265,0.133-0.397,0.133h-0.133c-0.132-0.133-0.132-0.133-0.265-0.133c-0.132,0-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.133,0.133-0.265,0.133-0.398,0.133h-0.132h-0.265h-0.132
		c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.132-0.265,0.132-0.398c0-0.133,0-0.265,0-0.398c0-0.795-0.132-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.397,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.915-0.398c0.133-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.133-1.193-0.398-1.458c-0.132,0-0.132,0.133-0.265,0.133l-0.133-0.133h-0.132c-0.132,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h-0.133c-0.265,0-0.53,0.133-0.795,0.133C1135.847,203.554,1135.715,203.554,1135.449,203.554z"
        />
        <path
          fill={textColor}
          d="M1143.931,202.494c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.916-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53s-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C1143.798,203.421,1143.931,203.024,1143.931,202.494z M1148.834,206.734c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59s0-0.928-0.133-1.325c-0.265-0.265-0.53-1.325-0.795-1.458
		c-0.265-0.265-0.663-0.398-0.928-0.663c-0.397-0.133-0.663-0.265-0.928-0.265c-0.663,0-1.325,0.133-1.855,0.265
		c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.397,1.855-0.397,2.385c0,0.133-0.133,0.265-0.133,0.53
		c0,0.398,0.53,1.59,0.795,1.723C1147.907,206.072,1147.907,206.734,1148.834,206.734z"
        />
        <path
          fill={textColor}
          d="M1157.846,198.651c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.397,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.132,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
		c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.132,1.855,0.132,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.132,0.133-0.265,0.133-0.397,0.133c-0.398,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855s-1.06-1.06-1.458-1.723
		c-0.132-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.132,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
		C1157.581,198.783,1157.713,198.783,1157.846,198.651L1157.846,198.651z"
        />
      </g>
      <g id="Castlegar">
        <path
          fill={textColor}
          d="M932.028,268.755c-0.663,0.398-0.795,1.325-0.795,1.988c0,0.928,1.06,1.723,1.988,1.723
		c0.398,0,1.06,0.265,1.59,0.265c1.325,0,2.783,0,3.976-0.265c0.265,0.133,0.398,0.265,0.398,0.53c0,0.663,0,1.325,0,1.988h-0.133
		c-0.132,0-0.265-0.133-0.398-0.133s-0.265,0-0.265,0.133h-0.133c-0.265-0.133-0.398,0-0.663,0c-0.133,0-0.265-0.133-0.398-0.133
		c-0.398,0-0.795,0.133-1.193,0.133c-0.663,0-1.59-0.133-2.253-0.265c-0.133-0.133-0.133-0.133-0.265-0.133
		c-0.133,0-0.265,0.133-0.398,0.133c-0.132,0-0.398-0.133-0.663-0.133h-0.133c-1.855-1.06-3.048-2.253-3.048-4.771
		c0-2.915,2.253-3.578,4.506-4.506c0.663,0.133,1.59,0,2.253,0.133c0.398-0.133,0.795-0.133,1.325-0.133c0.53,0,1.193,0,1.59-0.265
		c0.133,0.133,0.133,0.265,0.133,0.398c0,0.265-0.133,0.53-0.133,0.795c0,0.398,0,0.663,0.133,1.06v0.265
		c-0.265,0-0.265-0.133-0.663-0.133h-0.795c-0.663,0-1.193,0.133-1.193,0.133c-0.265,0-0.53,0-0.795,0c-0.133,0-1.458,0-1.458,0
		l-0.795,0.398c-1.193,0.398-0.398,0-0.928,0.53C932.293,268.622,932.161,268.755,932.028,268.755z"
        />
        <path
          fill={textColor}
          d="M950.316,274.983c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.133-0.398-0.133-0.795
		c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.132,0.133-0.132,1.193-0.132,1.458c0,0.265,0.132,0.398,0.132,0.663c0,0.663,0,1.06-0.795,1.06
		c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
		c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
		c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
		c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.133,3.313-0.133,4.903c0,0.265-0.132,0.398-0.398,0.398H950.316z M949.256,269.948
		c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
		c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
		c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398c0.133,0.265,0.265,0.265,0.53,0.265H949.256z"
        />
        <path
          fill={textColor}
          d="M960.52,270.478c-0.398,0-1.06,0.133-1.458,0c-0.132,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.133-1.193
		c0.132-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
		c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.132,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
		c-0.133,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.915-0.133
		c-0.133,0.133-0.265,0.133-0.398,0.133c-0.132,0-0.398,0-0.53,0c-0.133,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.133,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795c0,0.133,0.133,0.265,0.133,0.398c0,0.133-1.193,1.723-1.458,2.12
		c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133c-0.133-0.133-0.133-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.133
		c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.132-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.132-0.265,0.132-0.398s0-0.265,0-0.398c0-0.795-0.132-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.916-0.398c0.132-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.133-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.132-0.133h-0.133c-0.133,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h0.265c-0.265,0-0.53,0.133-0.795,0.133C961.05,270.61,960.785,270.478,960.52,270.478z"
        />
        <path
          fill={textColor}
          d="M973.242,271.538c0-0.663,0-3.446-0.265-3.843c-0.133-0.398-0.398-0.398-0.663-0.398
		c-0.53,0-1.06,0-1.59,0c-0.398,0-0.663-0.265-1.06-0.398v-0.133c0-0.53,0-0.928,0-1.458c0-0.133,0.132-0.265,0.132-0.265
		c0.795,0,1.723,0.133,2.518,0.133c0.265,0,0.53,0,0.795,0.133c0.265,0,0.53,0,0.663,0c0.398,0,0.795,0.133,1.193,0.133
		c0.265,0,0.53-0.133,0.795-0.133c0.928,0,1.855,0,2.783,0c0.133,0,0.265,0,0.265,0.133c0,0.265,0,0.53,0,0.795
		c0,0.265,0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.385,0.133c-0.398,0-1.06-0.133-1.193,0.398
		c-0.265,0.928-0.265,4.373-0.265,5.301c0,0.133-0.132,0.265-0.132,0.53c0,0.265,0.132,0.53,0.132,0.795
		c0,0.663,0,0.795-0.663,0.795c-0.398,0-0.795-0.133-1.193-0.133c-0.133-0.398-0.133-0.795-0.133-1.193
		C972.977,272.863,973.242,272.2,973.242,271.538z"
        />
        <path
          fill={textColor}
          d="M982.386,271.935c0-1.59,0.132-3.048,0.132-4.638c0-0.265,0.133-0.398,0.133-0.663
		c0-0.398-0.133-0.795-0.133-1.193v-0.133c0.133-0.133,0.795-0.133,1.06-0.133c0.398,0,0.663,0.133,1.06,0.265
		c0,0.133,0,0.133,0.132,0.265c-0.132,0.398,0,0.928,0,1.325c0,0.133,0.133,0.265,0.133,0.53c0,0.133-0.133,0.398-0.133,0.53
		c0,0.133,0,0.265,0.133,0.398v0.133c-0.133,0.265-0.133,0.398-0.133,0.663c0,0-0.132,0.133-0.132,0.265
		c0,0.133,0.132,0.265,0.132,0.398s0,0.53,0.133,0.663c-0.133,0.398,0,0.795,0,1.193c0,0.265-0.133,0.53-0.133,0.663
		c0,0.928,0.53,0.663,1.193,0.663c0.795,0,1.59,0.133,2.385,0.133c0.133,0,0.398,0,0.53-0.133c0.398,0.133,0.795,0.133,1.325,0.133
		c0.928,0,1.458-0.265,1.458,0.663c0,0.265-0.133,1.325-0.398,1.325c-0.398,0-0.663-0.265-1.06-0.265c-0.133,0-0.398,0-0.53,0
		c-0.265-0.133-0.53,0-0.795,0c-0.133,0-0.265-0.133-0.53-0.133c-0.663,0-1.325,0.133-1.988,0.133c-0.265,0-0.663,0-0.928,0
		c-0.133,0-0.53,0.133-0.53,0.133c-0.265,0-0.53-0.133-0.795-0.133c-0.53,0-0.928,0.133-1.325,0.265
		c-0.398-0.133-0.398-0.398-0.398-0.663c0-0.663,0.133-1.193,0.133-1.855C982.519,272.333,982.386,272.2,982.386,271.935z"
        />
        <path
          fill={textColor}
          d="M1004.518,273.526c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.132-0.133-0.132-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133s0.663-0.133,0.928-0.133
		c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398c0,0.398-0.133,1.193-0.265,1.458h-0.663c-0.265,0-0.53,0-0.795,0.265
		c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265c-0.133,0.133-0.133,0.795-0.133,0.928
		c0.133,0.133,0,0.398,0.133,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265c0.663,0,1.458,0,2.12-0.133
		c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663s0.132,0.53,0.132,0.795v0.133l-0.132,0.133
		c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265
		c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0
		C1002.53,273.393,1004.253,273.393,1004.518,273.526L1004.518,273.526z"
        />
        <path
          fill={textColor}
          d="M1007.831,269.948c0-0.53,0.265-1.723,0.795-1.988c0-0.398,0.398-0.53,0.53-0.795
		c0.265-0.398,0.53-0.53,0.795-0.795c1.325-0.795,2.65-0.795,4.108-0.795c1.06,0,2.253-0.133,3.313-0.133
		c0.265,0,0.265,0.398,0.265,0.53c0,0.398-0.132,0.928-0.132,1.325c0,0.133-0.133,0.265-0.265,0.398c-0.133,0-0.398,0-0.53,0
		c-0.398,0-0.795,0-1.193,0c-0.398,0-0.795-0.133-1.325-0.133c-0.132,0-0.398,0-0.53-0.133c-0.398,0.133-0.795,0-1.193,0
		c-1.458,0-3.048,0.663-3.048,2.12c0,0.265,0,1.193,0.133,1.458c0.132,0.265,0.53,1.06,0.795,1.325
		c0.53,0.663,1.325,0.795,2.12,0.795c0.53,0,1.193-0.265,1.723-0.265c0.265,0,0.53,0.133,0.795,0.133s0.928-0.133,0.928-0.53
		c0-0.265-0.133-0.663-0.265-0.928c-0.133-0.53-0.795-0.53-1.193-0.53c-0.53,0-0.928,0.265-1.458,0.265
		c-0.133,0-0.265-0.133-0.265-0.265c0-0.133,0.133-1.193,0.265-1.325c0.133-0.133,0.53-0.133,0.795-0.133
		c0.795,0,1.723,0.133,2.65,0.133c0.265,0,0.53-0.133,0.663-0.133h0.133c0.132,0.398,0.265,1.06,0.265,1.59
		c0,0.265-0.132,0.663-0.132,0.928c0,0.398,0.132,0.795,0.132,1.193c0,0.265,0,0.398,0,0.53c0,0.265,0.133,0.398,0.133,0.663
		c0,0.53-1.193,0.398-1.458,0.398c-0.265,0-0.53,0.133-0.663,0.133c-0.53-0.133-1.325,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133
		c-0.663,0-1.988-0.265-2.65-0.53c-0.53-0.265-0.928-0.795-1.458-1.193C1008.361,272.2,1007.831,271.14,1007.831,269.948z"
        />
        <path
          fill={textColor}
          d="M1028.902,274.983c-0.132,0-0.265,0-0.398,0c-0.265-0.398-0.132-0.398-0.132-0.795
		c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.132,0.133-0.132,1.193-0.132,1.458c0,0.265,0.132,0.398,0.132,0.663c0,0.663,0,1.06-0.795,1.06
		c-0.398,0-0.663-0.133-0.928-0.133c-0.132,0-0.265-0.133-0.265-0.265c0-0.398,0.132-0.663,0.132-1.06
		c0-0.265-0.132-0.53-0.132-0.663c0-0.265,0.265-0.663,0.132-0.928c0-0.663,0.265-1.193,0.265-1.855
		c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
		c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.132,3.313-0.132,4.903c0,0.265-0.132,0.398-0.398,0.398H1028.902z M1027.974,269.948
		c0.265,0,0.397-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
		c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
		c-0.397,0.265-1.193,1.06-1.458,1.325l-0.132,0.398c0.132,0.265,0.265,0.265,0.53,0.265H1027.974z"
        />
        <path
          fill={textColor}
          d="M1036.853,274.453c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.397,0,0.795,0,1.325,0s1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.132,0.133-0.397,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06
		c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H1036.853z M1042.286,267.695c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.916,0,2.916-0.663
		C1042.419,268.225,1042.286,267.96,1042.286,267.695z"
        />
      </g>
      <g id="Rossland">
        <path
          fill={textColor}
          d="M897.837,399.952c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		s-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
		c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H897.837z
		 M903.271,393.193c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133
		c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
		c1.06,0,2.916,0,2.916-0.663C903.403,393.856,903.271,393.458,903.271,393.193z"
        />
        <path
          fill={textColor}
          d="M908.439,395.048c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.132,0-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C908.307,395.976,908.439,395.446,908.439,395.048z M913.342,399.289c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.133-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.133,0.265-0.133,0.53c0,0.398,0.53,1.59,0.795,1.723C912.415,398.627,912.415,399.289,913.342,399.289z"
        />
        <path
          fill={textColor}
          d="M926.33,395.976c-0.398,0-1.06,0.133-1.458,0c-0.133,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.132-1.193
		c0.133-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0s0.663,0,1.06-0.133
		c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.133,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
		c-0.132,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.916-0.133
		C925.799,392,925.667,392,925.534,392s-0.398,0-0.53,0c-0.133,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.133,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795c0,0.133,0.133,0.265,0.133,0.398c0,0.133-1.193,1.723-1.458,2.12
		c-0.133,0.133-0.265,0.133-0.398,0.133h-0.132c-0.133-0.133-0.133-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.132,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.132
		c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.133-0.265,0.133-0.398c0-0.133,0-0.265,0-0.398c0-0.795-0.133-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.915-0.398c0.133-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.132-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.133-0.133h-0.132c-0.133,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h-0.132c-0.265,0-0.53,0.133-0.795,0.133C926.86,396.109,926.595,395.976,926.33,395.976z"
        />
        <path
          fill={textColor}
          d="M939.847,395.976c-0.398,0-1.06,0.133-1.458,0c-0.133,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.132-1.193
		c0.133-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0s0.663,0,1.06-0.133
		c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.133,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
		c-0.132,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.916-0.133
		C939.317,392,939.184,392,939.052,392s-0.398,0-0.53,0c-0.132,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.133,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795c0,0.133,0.133,0.265,0.133,0.398c0,0.133-1.193,1.723-1.458,2.12
		c-0.133,0.133-0.265,0.133-0.398,0.133h-0.132c-0.133-0.133-0.133-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.132,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.132
		c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.133-0.265,0.133-0.398c0-0.133,0-0.265,0-0.398c0-0.795-0.133-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.915-0.398c0.133-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.132-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.133-0.133h-0.132c-0.133,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h-0.132c-0.265,0-0.53,0.133-0.795,0.133C940.377,396.109,940.112,395.976,939.847,395.976z"
        />
        <path
          fill={textColor}
          d="M948.726,397.434c0-1.59,0.132-3.048,0.132-4.638c0-0.265,0.133-0.398,0.133-0.663
		c0-0.398-0.133-0.795-0.133-1.193v-0.133c0.133-0.133,0.795-0.133,1.06-0.133c0.398,0,0.663,0.133,1.06,0.265
		c0,0.133,0,0.133,0.133,0.265c-0.133,0.398,0,0.928,0,1.325c0,0.133,0.132,0.265,0.132,0.53c0,0.133-0.132,0.398-0.132,0.53
		c0,0.133,0,0.265,0.132,0.398v0.133c-0.132,0.265-0.132,0.398-0.132,0.663c0,0-0.133,0.133-0.133,0.265s0.133,0.265,0.133,0.398
		c0,0.133,0,0.53,0.132,0.663c-0.132,0.398,0,0.795,0,1.193c0,0.265-0.132,0.53-0.132,0.663c0,0.928,0.53,0.663,1.193,0.663
		c0.795,0,1.59,0.133,2.385,0.133c0.133,0,0.398,0,0.53-0.133c0.398,0.133,0.795,0.133,1.325,0.133c0.928,0,1.458-0.265,1.458,0.663
		c0,0.265-0.132,1.325-0.398,1.325c-0.398,0-0.663-0.265-1.06-0.265c-0.132,0-0.398,0-0.53,0c-0.265-0.133-0.53,0-0.795,0
		c-0.133,0-0.265-0.133-0.53-0.133c-0.663,0-1.325,0.133-1.988,0.133c-0.265,0-0.663,0-0.928,0c-0.133,0-0.53,0.133-0.53,0.133
		c-0.265,0-0.53-0.133-0.795-0.133c-0.53,0-0.928,0.133-1.325,0.265c-0.398-0.133-0.398-0.398-0.398-0.663
		c0-0.663,0.133-1.193,0.133-1.855C948.858,397.964,948.726,397.699,948.726,397.434z"
        />
        <path
          fill={textColor}
          d="M969.002,400.614c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.133-0.398-0.133-0.795
		c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.132,0.133-0.132,1.193-0.132,1.458c0,0.265,0.132,0.398,0.132,0.663c0,0.663,0,1.06-0.795,1.06
		c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
		c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
		c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
		c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.133,3.313-0.133,4.903c0,0.265-0.132,0.398-0.398,0.398H969.002z M968.074,395.579
		c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
		c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
		c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398c0.133,0.265,0.265,0.265,0.53,0.265L968.074,395.579L968.074,395.579z"
        />
        <path
          fill={textColor}
          d="M975.23,391.073c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0c0.398,0.398,0.663,1.06,0.928,1.458
		c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133
		c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0
		s0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325
		c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265c-0.265,0-0.53-0.133-0.663-0.398
		c-0.398-0.663-0.928-1.193-1.458-1.855c-0.398-0.663-1.06-1.06-1.458-1.723c-0.133-0.265-1.193-1.723-1.325-1.723
		c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133c0,0.265,0,0.53-0.265,0.663c-0.132,0-0.928,0-1.193,0
		c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614C975.098,391.338,975.098,391.338,975.23,391.073L975.23,391.073z"
        />
        <path
          fill={textColor}
          d="M988.217,397.699c0-0.398,0.132-0.663,0.132-1.06c-0.132-0.133-0.132-0.398-0.132-0.53
		c0-1.458-0.265-2.915-0.265-4.373c0-0.133,0.133-0.265,0.265-0.265c0.132,0,0.265,0,0.398,0c0.53,0,1.06,0,1.59-0.133
		c0.928-0.133,2.12-0.133,3.048-0.133c1.06,0,1.988,0.398,2.783,0.928c0.663,0.398,0.663,0.928,1.193,1.458
		c0.398,0.398,0.53,1.193,0.53,1.723c0,0.53-0.132,1.06,0,1.59c-0.132,0.265-0.132,0.663-0.132,0.928c0,0.133,0,0.133-0.133,0.265
		c-0.133,0.133-0.265,0.398-0.398,0.53c-0.398,0.663-1.59,1.06-2.385,1.458c-0.795,0-1.723,0.265-2.518,0.265
		c-0.265,0-0.398,0.133-0.663,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.53,0.265-1.193,0.265-1.855,0.265
		c-0.132,0-0.398,0-0.398-0.133C988.085,399.422,988.217,398.627,988.217,397.699z M993.121,398.627c1.59,0,2.518-1.193,2.518-2.65
		c0-1.06-0.398-1.59-1.06-2.385c-0.265,0-0.398-0.133-0.663-0.265h-0.133c-0.265,0-0.53-0.133-0.795-0.133
		c-0.133,0-0.133,0-0.265-0.133l-0.265-0.398c-0.133,0-0.53-0.133-0.663-0.133c-1.06,0-1.855,0.265-1.855,1.458
		c0,0.398,0.133,2.385,0.265,2.783c0,0.265-0.133,0.398-0.133,0.663c0,0.398,0,0.663,0.133,1.06c0.132,0,0.132,0.133,0.265,0.133
		C991.398,398.627,992.193,398.627,993.121,398.627z"
        />
      </g>
      <g id="Greenwood">
        <path
          fill={textColor}
          d="M660.623,350.521c0-0.53,0.265-1.723,0.795-1.988c0-0.398,0.398-0.53,0.53-0.795
		c0.265-0.398,0.53-0.53,0.795-0.795c1.325-0.795,2.65-0.795,4.108-0.795c1.06,0,2.253-0.133,3.313-0.133
		c0.265,0,0.265,0.398,0.265,0.53c0,0.398-0.132,0.928-0.132,1.325c0,0.133-0.133,0.265-0.265,0.398c-0.133,0-0.398,0-0.53,0
		c-0.398,0-0.795,0-1.193,0c-0.398,0-0.795-0.133-1.325-0.133c-0.132,0-0.398,0-0.53-0.133c-0.398,0.133-0.795,0-1.193,0
		c-1.458,0-3.048,0.663-3.048,2.12c0,0.265,0,1.193,0.133,1.458c0.132,0.265,0.53,1.06,0.795,1.325
		c0.53,0.663,1.325,0.795,2.12,0.795c0.53,0,1.193-0.265,1.723-0.265c0.265,0,0.53,0.133,0.795,0.133s0.928-0.133,0.928-0.53
		c0-0.265-0.133-0.663-0.265-0.928c-0.133-0.53-0.795-0.53-1.193-0.53c-0.53,0-0.928,0.265-1.458,0.265
		c-0.133,0-0.265-0.133-0.265-0.265s0.133-1.193,0.265-1.325c0.133-0.133,0.53-0.133,0.795-0.133c0.795,0,1.723,0.133,2.65,0.133
		c0.265,0,0.53-0.133,0.663-0.133h0.133c0.132,0.398,0.265,1.06,0.265,1.59c0,0.265-0.132,0.663-0.132,0.928
		c0,0.398,0.132,0.795,0.132,1.193c0,0.265,0,0.398,0,0.53c0,0.265,0.133,0.398,0.133,0.663c0,0.53-1.193,0.398-1.458,0.398
		c-0.265,0-0.53,0.133-0.663,0.133c-0.663-0.133-1.325,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133c-0.663,0-1.988-0.265-2.65-0.53
		c-0.53-0.265-0.928-0.795-1.458-1.193C661.153,352.774,660.623,351.714,660.623,350.521z"
        />
        <path
          fill={textColor}
          d="M676.658,354.894c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06
		c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H676.658z M682.091,348.136c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.916,0,2.916-0.663
		C682.224,348.798,682.091,348.401,682.091,348.136z"
        />
        <path
          fill={textColor}
          d="M696.801,353.967c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.915-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.132-0.133-0.132-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133s0.663-0.133,0.928-0.133
		c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.132,1.193-0.265,1.458h-0.663c-0.265,0-0.53,0-0.795,0.265
		c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265c-0.133,0.133-0.133,0.795-0.133,0.928
		c0.133,0.133,0,0.398,0.133,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265c0.663,0,1.458,0,2.12-0.133
		c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663c0,0.265,0.133,0.53,0.133,0.795v0.133l-0.133,0.133
		c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265
		c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0
		C694.814,353.834,696.536,353.834,696.801,353.967L696.801,353.967z"
        />
        <path
          fill={textColor}
          d="M709.921,353.967c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.132-1.193-0.132-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265
		c-0.132,0.133-0.132,0.795-0.132,0.928c0.132,0.133,0,0.398,0.132,0.663c0.133,0,0.265,0,0.53,0s0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133c0.133,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.133,0.53,0.133,0.795v0.133l-0.133,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133s-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C707.933,353.834,709.656,353.834,709.921,353.967L709.921,353.967z
		"
        />
        <path
          fill={textColor}
          d="M714.427,346.148c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.132-2.253,0.132-3.313
		c0-0.53,0-1.193-0.132-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.53-0.663-1.06-1.06-1.458-1.723
		c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.132,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.916,0-5.831,0-8.614
		C714.294,346.28,714.294,346.28,714.427,346.148L714.427,346.148z"
        />
        <path
          fill={textColor}
          d="M729.137,346.28c0,0.663-0.133,1.458-0.133,2.253c0,0.53,0,1.06,0,1.723c0,0.398,0,1.06,0,1.325
		c0,0.133,0,0.265,0.133,0.265c0,0,0.133,0,0.133-0.133c0.398-0.928,1.06-2.253,1.458-3.181c0.132-0.133,0.398-0.795,0.53-1.06
		l0.133-0.133c0,0,0,0,0.133,0c0.132,0,0.132,0.133,0.132,0.265c0.265,0.663,0.795,1.325,1.06,2.12
		c0.133,0.265,0.265,0.398,0.398,0.663c0.265,0.265,0.398,0.663,0.795,0.795c-0.132-0.795-0.132-1.988-0.132-2.783
		c0-0.663-0.133-1.458-0.133-2.12c0-0.53,1.325-0.53,1.723-0.53c0.265,0,0.795,0,0.795,0.398c0,0.663,0,1.325,0,1.988
		c0,0.663,0,3.313,0,3.711c-0.133,0.398,0,1.723,0,2.12c0,0.398,0,1.06-0.53,1.193c-0.53,0-1.06,0.133-1.59,0.133
		c-0.795,0-1.458-2.253-2.253-2.783c0-0.133-0.265-0.53-0.265-0.663s0-0.133,0-0.133c0-0.133,0-0.133-0.133-0.133
		c-0.132,0.265-0.265,0.53-0.265,0.663c0,0.265-0.133,0.398-0.398,0.53c-0.398,0.928-0.928,2.783-2.253,2.783
		c-0.265,0-0.53,0.133-0.928,0.133c-0.398,0-0.53,0-0.53-0.398s0.133-0.795,0-1.325c0.133-0.398,0-0.795,0-1.193
		c0-0.133,0.133-0.265,0.133-0.53c-0.133-0.133-0.265-0.398-0.265-0.663c0-0.133,0.133-0.265,0.133-0.398
		c0-0.398-0.133-0.795-0.133-1.193c0.133-0.133,0.133-0.265,0.133-0.398c0-0.398-0.133-0.795,0-1.193
		c-0.133-0.795-0.133-1.59-0.265-2.385c0-0.53,1.325-0.398,1.59-0.53C728.872,345.75,729.137,346.148,729.137,346.28z"
        />
        <path
          fill={textColor}
          d="M740.268,349.991c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.132,0.133-0.132,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53s-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C740.004,350.919,740.268,350.521,740.268,349.991z M745.039,354.232c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.132-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193s-0.398,1.855-0.398,2.385c0,0.133-0.132,0.265-0.132,0.53
		c0,0.398,0.53,1.59,0.795,1.723C744.112,353.569,744.112,354.232,745.039,354.232z"
        />
        <path
          fill={textColor}
          d="M753.256,349.991c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.916-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.132,0.133-0.132,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53s-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C752.991,350.919,753.256,350.521,753.256,349.991z M758.159,354.232c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.132-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.663,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193s-0.398,1.855-0.398,2.385c0,0.133-0.133,0.265-0.133,0.53
		c0,0.398,0.53,1.59,0.795,1.723C757.099,353.569,757.231,354.232,758.159,354.232z"
        />
        <path
          fill={textColor}
          d="M766.773,352.641c0-0.398,0.133-0.663,0.133-1.06c-0.133-0.133-0.133-0.398-0.133-0.53
		c0-1.458-0.265-2.915-0.265-4.373c0-0.133,0.133-0.265,0.265-0.265c0.133,0,0.265,0,0.398,0c0.53,0,1.06,0,1.59-0.133
		c0.928-0.133,2.12-0.133,3.048-0.133c1.06,0,1.988,0.398,2.783,0.928c0.663,0.398,0.663,0.928,1.193,1.458
		c0.398,0.398,0.53,1.193,0.53,1.723c0,0.53-0.133,1.06,0,1.59c-0.133,0.265-0.133,0.663-0.133,0.928c0,0.133,0,0.133-0.133,0.265
		c-0.133,0.133-0.265,0.398-0.398,0.53c-0.398,0.663-1.59,1.06-2.385,1.458c-0.795,0-1.723,0.265-2.518,0.265
		c-0.265,0-0.398,0.133-0.663,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.53,0.265-1.193,0.265-1.855,0.265
		c-0.133,0-0.398,0-0.398-0.133C766.64,354.364,766.773,353.569,766.773,352.641z M771.676,353.569c1.59,0,2.518-1.193,2.518-2.65
		c0-1.06-0.398-1.59-1.06-2.385c-0.265,0-0.398-0.133-0.663-0.265h-0.132c-0.265,0-0.53-0.133-0.795-0.133
		c-0.133,0-0.133,0-0.265-0.133l-0.265-0.398c-0.132,0-0.663-0.133-0.663-0.133c-1.06,0-1.855,0.265-1.855,1.458
		c0,0.398,0.133,2.385,0.265,2.783c0,0.265-0.133,0.398-0.133,0.663c0,0.398,0,0.663,0.133,1.06c0.133,0,0.133,0.133,0.265,0.133
		C769.821,353.569,770.749,353.569,771.676,353.569z"
        />
      </g>
      <g id="GrandForks">
        <line
          fill="none"
          stroke={textColor}
          strokeLinecap="round"
          strokeMiterlimit={10}
          x1="755.641"
          y1="401.409"
          x2="755.641"
          y2="441.034"
        />
        <path
          fill={textColor}
          d="M689.38,452.43c0-0.53,0.265-1.723,0.795-1.988c0-0.398,0.398-0.53,0.53-0.795
		c0.265-0.398,0.53-0.53,0.795-0.795c1.325-0.795,2.65-0.795,4.108-0.795c1.06,0,2.253-0.133,3.313-0.133
		c0.265,0,0.265,0.398,0.265,0.53c0,0.398-0.132,0.928-0.132,1.325c0,0.133-0.133,0.265-0.265,0.398c-0.133,0-0.398,0-0.53,0
		c-0.398,0-0.795,0-1.193,0s-0.795-0.133-1.325-0.133c-0.133,0-0.398,0-0.53-0.133c-0.398,0.133-0.795,0-1.193,0
		c-1.458,0-3.048,0.663-3.048,2.12c0,0.265,0,1.193,0.132,1.458c0.133,0.265,0.53,1.06,0.795,1.325
		c0.53,0.663,1.325,0.795,2.12,0.795c0.53,0,1.193-0.265,1.723-0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.928-0.133,0.928-0.53c0-0.265-0.132-0.663-0.265-0.928c-0.133-0.53-0.795-0.53-1.193-0.53
		c-0.53,0-0.928,0.265-1.458,0.265c-0.133,0-0.265-0.133-0.265-0.265c0-0.133,0.133-1.193,0.265-1.325
		c0.133-0.133,0.53-0.133,0.795-0.133c0.795,0,1.723,0.133,2.65,0.133c0.265,0,0.53-0.133,0.663-0.133h0.133
		c0.132,0.398,0.265,1.06,0.265,1.59c0,0.265-0.132,0.663-0.132,0.928c0,0.398,0.132,0.795,0.132,1.193c0,0.265,0,0.398,0,0.53
		c0,0.265,0.133,0.398,0.133,0.663c0,0.53-1.193,0.398-1.458,0.398c-0.265,0-0.53,0.133-0.663,0.133c-0.663-0.133-1.325,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133c-0.663,0-1.988-0.265-2.65-0.53c-0.53-0.265-0.928-0.795-1.458-1.193
		C689.91,454.683,689.38,453.623,689.38,452.43z"
        />
        <path
          fill={textColor}
          d="M705.415,456.804c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.132,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06
		c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H705.415z M710.849,450.045c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.916,0,2.916-0.663
		C710.981,450.708,710.849,450.31,710.849,450.045z"
        />
        <path
          fill={textColor}
          d="M723.703,457.466c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.133-0.398-0.133-0.795
		c0-0.53-0.133-1.193-0.133-1.723c0-0.795-0.132-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.133,0.133-0.133,1.193-0.133,1.458c0,0.265,0.133,0.398,0.133,0.663c0,0.663,0,1.06-0.795,1.06
		c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
		c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
		c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
		c0,1.06-0.132,2.253-0.132,3.313c0,1.723-0.133,3.313-0.133,4.903c0,0.265-0.133,0.398-0.398,0.398H723.703z M722.643,452.43
		c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
		c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
		c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398c0.133,0.265,0.265,0.265,0.53,0.265L722.643,452.43L722.643,452.43z"
        />
        <path
          fill={textColor}
          d="M729.932,448.057c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
		c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.132,1.855,0.132,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.132,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.53-0.663-1.06-1.06-1.458-1.723
		c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
		C729.667,448.19,729.799,448.19,729.932,448.057L729.932,448.057z"
        />
        <path
          fill={textColor}
          d="M742.786,454.551c0-0.398,0.132-0.663,0.132-1.06c-0.132-0.133-0.132-0.398-0.132-0.53
		c0-1.458-0.265-2.915-0.265-4.373c0-0.133,0.133-0.265,0.265-0.265c0.132,0,0.265,0,0.398,0c0.53,0,1.06,0,1.59-0.133
		c0.928-0.133,2.12-0.133,3.048-0.133c1.06,0,1.988,0.398,2.783,0.928c0.663,0.398,0.663,0.928,1.193,1.458
		c0.398,0.398,0.53,1.193,0.53,1.723c0,0.53-0.132,1.06,0,1.59c-0.132,0.265-0.132,0.663-0.132,0.928c0,0.133,0,0.133-0.133,0.265
		s-0.265,0.398-0.398,0.53c-0.398,0.663-1.59,1.06-2.385,1.458c-0.795,0-1.723,0.265-2.518,0.265c-0.265,0-0.398,0.133-0.663,0.133
		c-0.398,0-0.795-0.133-1.193-0.133c-0.53,0.265-1.193,0.265-1.855,0.265c-0.132,0-0.398,0-0.398-0.133
		C742.786,456.274,742.786,455.479,742.786,454.551z M747.69,455.479c1.59,0,2.518-1.193,2.518-2.65c0-1.06-0.398-1.59-1.06-2.385
		c-0.265,0-0.398-0.133-0.663-0.265h-0.133c-0.265,0-0.53-0.133-0.795-0.133c-0.132,0-0.132,0-0.265-0.133l-0.265-0.398
		c-0.133,0-0.663-0.133-0.663-0.133c-1.06,0-1.855,0.265-1.855,1.458c0,0.398,0.132,2.385,0.265,2.783
		c0,0.265-0.133,0.398-0.133,0.663c0,0.398,0,0.663,0.133,1.06c0.132,0,0.132,0.133,0.265,0.133
		C745.967,455.479,746.895,455.479,747.69,455.479z"
        />
        <path
          fill={textColor}
          d="M765.845,450.575v0.133c0,0.53,0.133,1.06,0.265,1.59c0.398,0,0.133,0,0.928,0
		c0.133,0,0.663,0.133,0.795,0.133s0.265-0.133,0.53-0.133c0.265,0,0.398-0.133,0.663-0.133c0.53,0,1.06,0,1.59-0.133
		c0.132,0.133,0.132,0.133,0.132,0.265c0,0.663,0,1.193-0.398,1.723l-0.265,0.133c-0.265,0-0.398-0.133-0.663-0.133
		s-0.928,0.133-1.458,0.133c-0.53,0-2.253-0.133-2.12,0.53c0,0.133,0,0.265,0,0.398c0,0.398-0.133,0.795-0.133,1.325
		c0,0.265,0.133,0.398,0.133,0.663c0,0.133,0,0.398,0,0.53c-0.398,0.133-0.928,0.133-1.193,0.133s-0.398-0.133-0.53-0.133
		c-0.132-0.265,0-0.53,0-0.928c0-0.133,0-0.265,0-0.398c0.133-1.325,0.133-2.783,0.133-4.108c0-0.795-0.133-1.59-0.133-2.385
		l0.133-0.265c-0.133-0.133-0.265-0.398-0.265-0.53c0-0.265,0-0.53,0.133-0.795c0.133,0,0.265,0,0.53,0c0.398,0,0.928,0,1.325,0.133
		c0.265-0.133,0.663-0.133,0.928-0.133h1.59c0.133,0,0.398,0.133,0.53,0.133c0.53,0,1.193-0.133,1.723-0.133
		c0.133,0,0.265,0.133,0.398,0.133c0.133-0.133,0.265-0.133,0.398-0.133c0.133,0,0.265,0,0.53,0.133
		c0.133,0,0.398-0.133,0.663-0.133c0.398,0,0.398,1.193,0.398,1.458c0,0.265-0.133,0.53-0.398,0.663h-0.133
		c-0.398-0.265-1.06-0.133-1.59-0.133C769.953,450.178,766.773,450.31,765.845,450.575L765.845,450.575z"
        />
        <path
          fill={textColor}
          d="M776.447,451.9c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.132,0-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C776.182,452.828,776.447,452.43,776.447,451.9z M781.35,456.141c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.133-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.133,0.265-0.133,0.53c0,0.398,0.53,1.59,0.795,1.723C780.29,455.479,780.423,456.141,781.35,456.141z"
        />
        <path
          fill={textColor}
          d="M791.952,456.804c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.132,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06
		c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H791.952z M797.386,450.045c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.916,0,2.916-0.663
		C797.518,450.708,797.386,450.31,797.386,450.045z"
        />
        <path
          fill={textColor}
          d="M805.204,454.551c-0.133,0-0.265,0-0.265,0.133l0.133,0.133c-0.265,0.53,0.133-0.265-0.133,1.06
		c0,0.53,0,1.06,0,1.59c0,0.398-0.928,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.133-0.133,0.133-0.265
		v-0.133c-0.133-0.265-0.133-0.53-0.133-0.928v-1.193c0-0.265,0.133-0.53,0.133-0.795c0-0.795-0.133-1.723-0.133-2.65
		c0-0.398,0-0.663,0.133-1.193c-0.133-0.265-0.265-0.53-0.265-0.795l0,0c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
		c0.133,0.133,0.133,0.133,0.133,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.132,0.53-0.132,1.06
		c0,0.133-0.133,0.265-0.133,0.53c0,0.265,0.133,0.398,0.133,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
		c0.398-0.398,1.193-1.06,1.458-1.458c0.132-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.928-0.133h0.663l0.132,0.133
		c0,0.133-0.795,0.928-0.928,1.06c-0.53,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
		c0.795,1.06,2.916,3.843,3.843,5.168l-0.133,0.133c-0.133,0-0.398,0-0.53,0c-0.53,0-0.928,0-1.458,0h-0.398
		c-0.133-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.132-0.265
		C806.132,453.491,805.337,454.286,805.204,454.551L805.204,454.551z"
        />
        <path
          fill={textColor}
          d="M820.312,452.828c-0.398,0-1.06,0.133-1.458,0c-0.132,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.133-1.193
		c0.132-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
		c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.132,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
		c-0.132,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.915-0.133
		c-0.133,0.133-0.265,0.133-0.398,0.133s-0.398,0-0.53,0c-0.133,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.133,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795c0,0.133,0.133,0.265,0.133,0.398c0,0.133-1.193,1.723-1.458,2.12
		c-0.133,0.133-0.265,0.133-0.398,0.133h-0.132c-0.133-0.133-0.133-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.132,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.132
		c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.132-0.265,0.132-0.398c0-0.133,0-0.265,0-0.398c0-0.795-0.132-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.916-0.398c0.132-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.132-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.133-0.133h-0.132c-0.133,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h-0.132c-0.265,0-0.53,0.133-0.795,0.133C820.842,452.961,820.577,452.828,820.312,452.828z"
        />
      </g>
      <g id="Trail">
        <path
          fill={textColor}
          d="M1033.142,356.219c0-0.663,0-3.446-0.265-3.843c-0.132-0.398-0.398-0.398-0.663-0.398
		c-0.53,0-1.06,0-1.59,0c-0.397,0-0.663-0.265-1.06-0.398v-0.133c0-0.53,0-0.928,0-1.458c0-0.133,0.132-0.265,0.132-0.265
		c0.795,0,1.723,0.133,2.518,0.133c0.265,0,0.53,0,0.795,0.133c0.265,0,0.53,0,0.663,0c0.397,0,0.795,0.133,1.193,0.133
		c0.265,0,0.53-0.133,0.795-0.133c0.928,0,1.855,0,2.783,0c0.132,0,0.265,0,0.265,0.133c0,0.265,0,0.53,0,0.795
		c0,0.265,0,0.53-0.132,0.663c-0.663,0.265-1.59,0.133-2.385,0.133c-0.398,0-1.06-0.133-1.193,0.398
		c-0.265,0.928-0.265,4.373-0.265,5.301c0,0.133-0.132,0.265-0.132,0.53c0,0.265,0.132,0.53,0.132,0.795
		c0,0.663,0,0.795-0.663,0.795c-0.397,0-0.795-0.133-1.193-0.133c-0.133-0.398-0.133-0.795-0.133-1.193
		C1033.01,357.545,1033.142,356.882,1033.142,356.219z"
        />
        <path
          fill={textColor}
          d="M1044.539,359.002c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.397,0,0.795,0,1.325,0s1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.132,0.133-0.397,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06
		c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H1044.539z M1049.973,352.244c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.916,0,2.916-0.663
		C1050.105,352.906,1049.973,352.509,1049.973,352.244z"
        />
        <path
          fill={textColor}
          d="M1062.827,359.665c-0.132,0-0.265,0-0.398,0c-0.265-0.398-0.132-0.398-0.132-0.795
		c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.132,0.133-0.132,1.193-0.132,1.458c0,0.265,0.132,0.398,0.132,0.663c0,0.663,0,1.06-0.795,1.06
		c-0.398,0-0.663-0.133-0.928-0.133c-0.132,0-0.265-0.133-0.265-0.265c0-0.398,0.132-0.663,0.132-1.06
		c0-0.265-0.132-0.53-0.132-0.663c0-0.265,0.265-0.663,0.132-0.928c0-0.663,0.265-1.193,0.265-1.855
		c0.132-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
		c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.132,3.313-0.132,4.903c0,0.265-0.133,0.398-0.398,0.398H1062.827z M1061.767,354.629
		c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.132-0.663-0.132-1.06c0-0.133,0-0.265,0-0.398c0.132-0.133,0.265,0,0.265-0.133
		c-0.132-0.133-0.265-0.398-0.397-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
		c-0.398,0.265-1.193,1.06-1.458,1.325l-0.132,0.398c0.132,0.265,0.265,0.265,0.53,0.265L1061.767,354.629L1061.767,354.629z"
        />
        <path
          fill={textColor}
          d="M1071.971,356.219L1071.971,356.219c0-1.06-0.132-2.12-0.132-3.181c0-1.325-0.398-0.928-1.59-0.928
		c-0.53,0-1.06,0.133-1.325,0.133c-0.132,0-0.132,0-0.265-0.133c-0.133-0.133-0.265-0.133-0.53-0.133
		c-0.133-0.265-0.133-1.193-0.133-1.458c0-0.133,0-0.265,0.133-0.265c0.265,0,0.398,0,0.663,0c0.928,0,1.855,0,2.783,0.265
		c0.265-0.133,0.53-0.133,0.795-0.133c0.398,0,0.795,0.133,1.325,0.133c0.265,0,0.53-0.133,0.663-0.133c0.53,0,2.916,0,3.048,0.265
		c0,0.265,0,0.53,0,0.795c0,0.265,0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.253,0.133c-0.133,0-1.193,0-1.325,0.133
		c-0.132,0.265-0.132,3.976-0.132,4.638c0,0.795,0.795,1.193,1.458,1.193c0.265,0,0.663-0.133,0.928-0.133s0.53,0.133,0.663,0.265
		c0,0.133,0.133,0,0.133,0v0.265c0,0.398,0.132,0.795,0.132,1.193c0,0.133,0,0.265-0.132,0.265c-0.53,0-0.928-0.133-1.458-0.265
		c-1.988,0-3.976,0-5.964,0c-0.663,0-1.193,0-1.193-0.795c0-1.06,0.663-0.928,1.458-0.928c0.53,0,0.928,0,1.325,0
		c0.132,0,0.398,0,0.53,0c0.132,0,0.265-0.133,0.397-0.265c0.133-0.398,0.133-0.795,0.133-1.193
		C1071.971,356.352,1071.971,356.352,1071.971,356.219z"
        />
        <path
          fill={textColor}
          d="M1081.248,356.484c0-1.59,0.132-3.048,0.132-4.638c0-0.265,0.132-0.398,0.132-0.663
		c0-0.398-0.132-0.795-0.132-1.193v-0.133c0.132-0.133,0.795-0.133,1.06-0.133c0.397,0,0.663,0.133,1.06,0.265
		c0,0.133,0,0.133,0.132,0.265c-0.132,0.398,0,0.928,0,1.325c0,0.133,0.132,0.265,0.132,0.53c0,0.133-0.132,0.398-0.132,0.53
		s0,0.265,0.132,0.398v0.133c-0.132,0.265-0.132,0.398-0.132,0.663c0,0-0.132,0.133-0.132,0.265c0,0.133,0.132,0.265,0.132,0.398
		c0,0.133,0,0.53,0.132,0.663c-0.132,0.398,0,0.795,0,1.193c0,0.265-0.132,0.53-0.132,0.663c0,0.928,0.53,0.663,1.193,0.663
		c0.795,0,1.59,0.133,2.385,0.133c0.132,0,0.398,0,0.53-0.133c0.397,0.133,0.795,0.133,1.325,0.133c0.928,0,1.458-0.265,1.458,0.663
		c0,0.265-0.132,1.325-0.397,1.325c-0.398,0-0.663-0.265-1.06-0.265c-0.133,0-0.398,0-0.53,0c-0.265-0.133-0.53,0-0.795,0
		c-0.133,0-0.265-0.133-0.53-0.133c-0.663,0-1.325,0.133-1.988,0.133c-0.265,0-0.663,0-0.928,0c-0.132,0-0.53,0.133-0.53,0.133
		c-0.265,0-0.53-0.133-0.795-0.133c-0.53,0-0.928,0.133-1.325,0.265c-0.398-0.133-0.398-0.398-0.398-0.663
		c0-0.663,0.132-1.193,0.132-1.855C1081.248,357.015,1081.248,356.75,1081.248,356.484z"
        />
      </g>
      <g id="ChristinaLake">
        <g id="Christina">
          <path
            fill={textColor}
            d="M779.098,414.794c-0.663,0.398-0.795,1.325-0.795,1.988c0,0.928,1.06,1.723,1.988,1.723
			c0.398,0,1.06,0.265,1.59,0.265c1.325,0,2.783,0,3.976-0.265c0.265,0.133,0.398,0.265,0.398,0.53c0,0.663,0,1.325,0,1.988h-0.133
			c-0.132,0-0.265-0.133-0.398-0.133s-0.265,0-0.265,0.133h-0.133c-0.265-0.133-0.398,0-0.663,0c-0.132,0-0.265-0.133-0.398-0.133
			c-0.398,0-0.795,0.133-1.193,0.133c-0.663,0-1.59-0.133-2.253-0.265c-0.133-0.133-0.133-0.133-0.265-0.133
			c-0.133,0-0.265,0.133-0.398,0.133c-0.132,0-0.398-0.133-0.663-0.133h-0.132c-1.855-1.06-3.048-2.253-3.048-4.771
			c0-2.915,2.253-3.578,4.506-4.506c0.663,0.133,1.59,0,2.253,0.133c0.398-0.133,0.795-0.133,1.325-0.133c0.53,0,1.193,0,1.59-0.265
			c0.133,0.133,0.133,0.265,0.133,0.398c0,0.265-0.133,0.53-0.133,0.795c0,0.398,0,0.663,0.133,1.06v0.265
			c-0.265,0-0.265-0.133-0.663-0.133h-0.795c-0.663,0-1.193,0.133-1.193,0.133c-0.265,0-0.53,0-0.795,0c-0.133,0-1.458,0-1.458,0
			l-0.795,0.398c-1.193,0.398-0.398,0-0.928,0.53C779.363,414.662,779.23,414.794,779.098,414.794z"
          />
          <path
            fill={textColor}
            d="M799.506,419.963c0,0.265,0,0.53,0,0.795l-0.133,0.133c-0.398,0.133-0.928,0.133-1.325,0.133
			c-0.265,0-0.398,0-0.53-0.133c0-0.133,0-0.398,0-0.53c0-0.265-0.132-0.398-0.132-0.663c0-0.398,0.132-0.663,0.132-0.928
			c0-0.53-0.398-0.398-0.132-1.06c-0.265-0.265-0.265-0.265-0.663-0.265c-0.265,0-0.53-0.133-0.663-0.133
			c-0.398,0.133-1.458,0.133-1.855,0.133c-0.398,0-0.663-0.133-0.928-0.133c-0.265,0-0.53,0.265-0.663,0.398
			c-0.133,0.398,0,0.795,0,1.193c0,0.663-0.265,1.325-0.398,1.988c-0.133,0-0.133,0.133-0.265,0.133c-0.265,0.133-0.795,0-1.193,0
			c-0.132-0.133-0.132-0.265-0.132-0.398c0-0.53,0.132-0.795,0.132-1.325c0-0.398-0.132-0.795-0.132-1.193
			c0-1.723,0.132-3.313,0.132-4.903c0-0.265,0-0.663,0-0.928c0-0.133-0.132-0.398-0.132-0.53s0.132-0.265,0.265-0.265
			c0.133,0,1.325,0,1.458,0.133c0.265,0.398,0.265,0.795,0.265,1.193v0.398c0,0.133,0,0.133,0,0.265s0,0.133,0.133,0.265v0.265
			c0,0.133,0,0.265,0,0.398c0,0.398,0.133,0.795,0.265,1.193l0.265,0.53h0.133h0.265h0.133c0.132,0,0.398,0,0.53-0.133h0.133
			c0.53,0,2.65-0.133,2.915-0.265c0.133,0,0.265-0.133,0.398-0.398c0.133-0.53,0-0.928,0-1.458c0-0.265,0.133-0.663,0-0.928
			c0.133-0.53-0.133-1.06,0.265-1.325c0.132,0,0.53,0,0.663,0c0.265,0,1.193,0,1.325,0c0,0.133,0.133,0.133,0.133,0.265
			c0,0.398,0,0.663,0,1.06s-0.133,0.663-0.133,1.06s0.133,0.795,0.133,1.325c0,1.193,0,2.385,0,3.578
			C799.373,419.3,799.506,419.565,799.506,419.963z"
          />
          <path
            fill={textColor}
            d="M805.469,420.493c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
			c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
			c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
			c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
			c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
			c-0.265,0-0.53,0.133-0.663,0.133c-0.132,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
			c-1.325-1.325-1.723-1.325-2.253-1.325c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06
			c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H805.469z M810.903,413.734c-0.265-0.133-0.53-0.398-0.795-0.53
			c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
			c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.916,0,2.916-0.663
			C811.035,414.264,810.903,413.999,810.903,413.734z"
          />
          <path
            fill={textColor}
            d="M819.914,417.577L819.914,417.577c0-1.06-0.133-2.12-0.133-3.181c0-1.325-0.398-0.928-1.59-0.928
			c-0.53,0-1.06,0.133-1.325,0.133c-0.133,0-0.133,0-0.265-0.133c-0.133-0.133-0.265-0.133-0.53-0.133
			c-0.132-0.265-0.132-1.193-0.132-1.458c0-0.133,0-0.265,0.132-0.265c0.265,0,0.398,0,0.663,0c0.928,0,1.855,0,2.783,0.265
			c0.265-0.133,0.53-0.133,0.795-0.133c0.398,0,0.795,0.133,1.325,0.133c0.265,0,0.53-0.133,0.663-0.133c0.53,0,2.915,0,3.048,0.265
			c0,0.265,0,0.53,0,0.795c0,0.265,0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.253,0.133c-0.133,0-1.193,0-1.325,0.133
			c-0.132,0.265-0.132,3.976-0.132,4.638c0,0.795,0.795,1.193,1.458,1.193c0.265,0,0.663-0.133,0.928-0.133s0.53,0.133,0.663,0.265
			c0,0.133,0.133,0,0.133,0v0.265c0,0.398,0.132,0.795,0.132,1.193c0,0.133,0,0.265-0.132,0.265c-0.53,0-0.928-0.133-1.458-0.265
			c-1.988,0-3.976,0-5.964,0c-0.663,0-1.193,0-1.193-0.795c0-1.06,0.663-0.928,1.458-0.928c0.53,0,0.928,0,1.325,0
			c0.132,0,0.398,0,0.53,0c0.133,0,0.265-0.133,0.398-0.265c0.132-0.398,0.132-0.795,0.132-1.193
			C819.914,417.842,819.914,417.71,819.914,417.577z"
          />
          <path
            fill={textColor}
            d="M833.697,416.517c-0.398,0-1.06,0.133-1.458,0c-0.133,0-0.398,0.133-0.53,0.133
			c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.133-1.193
			c0.132-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
			c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
			c0.133,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
			c-0.132,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.915-0.133
			c-0.133,0.133-0.265,0.133-0.398,0.133s-0.398,0-0.53,0c-0.133,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
			c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
			c0.133,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795c0,0.133,0.133,0.265,0.133,0.398c0,0.133-1.193,1.723-1.458,2.12
			c-0.133,0.133-0.265,0.133-0.398,0.133h-0.132c-0.133-0.133-0.133-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.53,0.133
			c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.132,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.132
			c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133
			c0-0.133,0.132-0.265,0.132-0.398c0-0.133,0-0.265,0-0.398c0-0.795-0.132-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
			c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.916-0.398c0.132-0.133,0.265-0.265,0.265-0.398
			c0-0.398-0.132-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.133-0.133h-0.132c-0.133,0-0.265,0-0.265,0
			c-0.133,0-0.398,0-0.53-0.133h-0.132c-0.265,0-0.53,0.133-0.795,0.133C834.227,416.65,833.962,416.517,833.697,416.517z"
          />
          <path
            fill={textColor}
            d="M846.286,417.577c0-0.663,0-3.446-0.265-3.843c-0.133-0.398-0.398-0.398-0.663-0.398
			c-0.53,0-1.06,0-1.59,0c-0.398,0-0.663-0.265-1.06-0.398v-0.133c0-0.53,0-0.928,0-1.458c0-0.133,0.132-0.265,0.132-0.265
			c0.795,0,1.723,0.133,2.518,0.133c0.265,0,0.53,0,0.795,0.133c0.265,0,0.53,0,0.663,0c0.398,0,0.795,0.133,1.193,0.133
			c0.265,0,0.53-0.133,0.795-0.133c0.928,0,1.855,0,2.783,0c0.132,0,0.265,0,0.265,0.133c0,0.265,0,0.53,0,0.795
			c0,0.265,0,0.53-0.132,0.663c-0.663,0.265-1.59,0.133-2.385,0.133c-0.398,0-1.06-0.133-1.193,0.398
			c-0.265,0.928-0.265,4.373-0.265,5.301c0,0.133-0.133,0.265-0.133,0.53s0.133,0.53,0.133,0.795c0,0.663,0,0.795-0.663,0.795
			c-0.398,0-0.795-0.133-1.193-0.133c-0.132-0.398-0.132-0.795-0.132-1.193C846.154,418.902,846.286,418.24,846.286,417.577z"
          />
          <path
            fill={textColor}
            d="M858.876,417.577L858.876,417.577c0-1.06-0.132-2.12-0.132-3.181c0-1.325-0.398-0.928-1.59-0.928
			c-0.53,0-1.06,0.133-1.325,0.133c-0.133,0-0.133,0-0.265-0.133c-0.132-0.133-0.265-0.133-0.53-0.133
			c-0.133-0.265-0.133-1.193-0.133-1.458c0-0.133,0-0.265,0.133-0.265c0.265,0,0.398,0,0.663,0c0.928,0,1.855,0,2.783,0.265
			c0.265-0.133,0.53-0.133,0.795-0.133c0.398,0,0.795,0.133,1.325,0.133c0.265,0,0.53-0.133,0.663-0.133c0.53,0,2.916,0,3.048,0.265
			c0,0.265,0,0.53,0,0.795c0,0.265,0,0.53-0.132,0.663c-0.663,0.265-1.59,0.133-2.253,0.133c-0.132,0-1.193,0-1.325,0.133
			c-0.133,0.265-0.133,3.976-0.133,4.638c0,0.795,0.795,1.193,1.458,1.193c0.265,0,0.663-0.133,0.928-0.133
			c0.265,0,0.53,0.133,0.663,0.265c0,0.133,0.132,0,0.132,0v0.265c0,0.398,0.133,0.795,0.133,1.193c0,0.133,0,0.265-0.133,0.265
			c-0.53,0-0.928-0.133-1.458-0.265c-1.988,0-3.976,0-5.964,0c-0.663,0-1.193,0-1.193-0.795c0-1.06,0.663-0.928,1.458-0.928
			c0.53,0,0.928,0,1.325,0c0.133,0,0.398,0,0.53,0c0.132,0,0.265-0.133,0.398-0.265c0.133-0.398,0.133-0.795,0.133-1.193
			C859.008,417.842,858.876,417.71,858.876,417.577z"
          />
          <path
            fill={textColor}
            d="M868.682,411.614c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
			c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
			c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
			c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.132,1.855,0.132,2.783
			c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.132,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
			c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.398-0.663-1.06-1.06-1.458-1.723
			c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
			c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
			C868.55,411.879,868.55,411.746,868.682,411.614L868.682,411.614z"
          />
          <path
            fill={textColor}
            d="M888.693,421.023c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.132-0.398-0.132-0.795
			c0-0.53-0.133-1.193-0.133-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
			c-0.133,0.133-0.133,1.193-0.133,1.458c0,0.265,0.133,0.398,0.133,0.663c0,0.663,0,1.06-0.795,1.06
			c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
			c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
			c0.132-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
			c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.132,3.313-0.132,4.903c0,0.265-0.133,0.398-0.398,0.398H888.693z M887.766,415.987
			c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.132-0.663-0.132-1.06c0-0.133,0-0.265,0-0.398c0.132-0.133,0.265,0,0.265-0.133
			c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
			c-0.398,0.265-1.193,1.06-1.458,1.325l-0.132,0.398c0.132,0.265,0.265,0.265,0.53,0.265L887.766,415.987L887.766,415.987z"
          />
        </g>
        <g id="Lake">
          <path
            fill={textColor}
            d="M808.252,433.347c0-1.59,0.133-3.048,0.133-4.638c0-0.265,0.132-0.398,0.132-0.663
			c0-0.398-0.132-0.795-0.132-1.193v-0.133c0.132-0.133,0.795-0.133,1.06-0.133c0.398,0,0.663,0.133,1.06,0.265
			c0,0.133,0,0.133,0.133,0.265c-0.133,0.398,0,0.928,0,1.325c0,0.133,0.132,0.265,0.132,0.53c0,0.133-0.132,0.398-0.132,0.53
			s0,0.265,0.132,0.398v0.133c-0.132,0.265-0.132,0.398-0.132,0.663c0,0-0.133,0.133-0.133,0.265c0,0.133,0.133,0.265,0.133,0.398
			s0,0.53,0.132,0.663c-0.132,0.398,0,0.795,0,1.193c0,0.265-0.132,0.53-0.132,0.663c0,0.928,0.53,0.663,1.193,0.663
			c0.795,0,1.59,0.133,2.385,0.133c0.133,0,0.398,0,0.53-0.133c0.398,0.133,0.795,0.133,1.325,0.133
			c0.928,0,1.458-0.265,1.458,0.663c0,0.265-0.132,1.325-0.398,1.325c-0.398,0-0.663-0.265-1.06-0.265c-0.132,0-0.398,0-0.53,0
			c-0.265-0.133-0.53,0-0.795,0c-0.133,0-0.265-0.133-0.53-0.133c-0.663,0-1.325,0.133-1.988,0.133c-0.265,0-0.663,0-0.928,0
			c-0.133,0-0.53,0.133-0.53,0.133c-0.265,0-0.53-0.133-0.795-0.133c-0.53,0-0.928,0.133-1.325,0.265
			c-0.398-0.133-0.398-0.398-0.398-0.663c0-0.663,0.133-1.193,0.133-1.855C808.252,433.877,808.252,433.612,808.252,433.347z"
          />
          <path
            fill={textColor}
            d="M828.528,436.395c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.133-0.398-0.133-0.795
			c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
			c-0.133,0.133-0.133,1.193-0.133,1.458c0,0.265,0.133,0.398,0.133,0.663c0,0.663,0,1.06-0.795,1.06
			c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
			c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
			c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
			c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.133,3.313-0.133,4.903c0,0.265-0.132,0.398-0.398,0.398H828.528z M827.601,431.359
			c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
			c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
			c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398c0.133,0.265,0.265,0.265,0.53,0.265L827.601,431.359L827.601,431.359z"
          />
          <path
            fill={textColor}
            d="M836.48,433.48c-0.133,0-0.265,0-0.265,0.133l0.133,0.133c-0.265,0.53,0.133-0.265-0.133,1.06
			c0,0.53,0,1.06,0,1.59c0,0.398-0.928,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.133-0.133,0.133-0.265
			v-0.133c-0.133-0.265-0.133-0.53-0.133-0.928v-1.193c0-0.265,0.133-0.53,0.133-0.795c0-0.795-0.133-1.723-0.133-2.65
			c0-0.398,0-0.663,0.133-1.193c-0.133-0.265-0.265-0.53-0.265-0.795v-0.133c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
			c0.133,0.133,0.133,0.133,0.133,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.133,0.53-0.133,1.06
			c0,0.133-0.132,0.265-0.132,0.53c0,0.265,0.132,0.398,0.132,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
			c0.398-0.398,1.193-1.06,1.458-1.458c0.133-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.928-0.133h0.663l0.133,0.133
			c0,0.133-0.795,0.928-0.928,1.06c-0.53,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
			c0.795,1.06,2.916,3.843,3.843,5.168l-0.133,0.133c-0.133,0-0.398,0-0.53,0c-0.53,0-0.928,0-1.458,0h-0.398
			c-0.133-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.133-0.265
			C837.407,432.552,836.745,433.347,836.48,433.48L836.48,433.48z"
          />
          <path
            fill={textColor}
            d="M856.225,434.938c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
			c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.132-1.193-0.132-1.723
			c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
			c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
			c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663
			c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265
			c-0.132,0.133-0.132,0.795-0.132,0.928c0.132,0.133,0,0.398,0.132,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
			c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133c0.133,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
			c0,0.265,0.132,0.53,0.132,0.795v0.133l-0.132,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
			c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265c0,0.133-0.132,0.398-0.132,0.53c0,0.265,0.132,0.53,0.265,0.795
			c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C854.238,434.805,855.961,434.805,856.225,434.938L856.225,434.938
			z"
          />
        </g>
      </g>
      <g id="Midway">
        <path
          fill={textColor}
          d="M664.068,421.288c-0.133-0.265-0.265-0.53-0.133-0.795c-0.132-0.265-0.132-0.53-0.132-0.663
		c0-0.663,0.132-1.325,0.132-1.988c0-0.663-0.132-1.325-0.132-1.988c0-0.133,0-0.398,0.132-0.53
		c-0.132-0.133-0.265-0.398-0.265-0.53c0-0.265,0.663-0.265,0.795-0.265c0.53,0,1.06,0.133,1.723,0.133
		c0.133,0,0.265,0.133,0.265,0.265c0.265,1.06,0.928,1.988,1.59,2.783v0.265v0.133l0.133,0.133c0.133-0.133,0.398-0.398,0.53-0.398
		c0.265-0.53,0.663-1.855,1.193-1.988v-0.133c0-0.265,0.265-0.53,0.265-0.795c0-0.133,0.133-0.133,0.265-0.133
		c0.663,0,1.59,0.133,2.385,0.133c0.133,0,0.398,0,0.398,0.265v0.265c-0.132,1.06-0.132,2.12-0.132,3.181
		c0,0.53-0.133,0.928,0,1.458c0,0.133-0.133,0.265-0.133,0.398c0,0.795,0.133,1.59,0.133,2.518c0,1.458,0.132,1.06-1.723,1.325
		c-0.133,0-0.265-0.133-0.265-0.265c0-0.133,0-0.398,0.133-0.663c0.132-0.928,0-2.12,0-3.048c0-0.53-0.133-1.06-0.133-1.59l0,0
		v-0.133c0-0.133,0-0.928-0.133-1.06h-0.132c-0.133,0-0.398,0.663-0.398,0.795l-0.398,0.928c0,0.265-1.325,2.915-1.458,3.048
		c-0.132,0-0.132-0.133-0.265-0.265c-0.133-0.53-1.325-2.518-1.723-3.048c-0.133-0.265-0.265-0.53-0.398-0.795
		c-0.132-0.133-0.265-0.53-0.398-0.663h-0.133l-0.133,0.133c0,0.133,0,0.398,0,0.53c0,0.133-0.132,0.265-0.132,0.398
		c0,0.53,0.132,0.928,0.132,1.458c0,0.53,0.133,1.193,0.133,1.723c0,0.53,0,1.193,0,1.855c0,0.133-0.133,0.398-0.133,0.398
		c-0.132,0-0.265,0-0.398,0c-0.265,0-0.398-0.133-0.53-0.133c-0.265,0-0.53,0.133-0.795,0.133c0-0.133-0.133-0.265-0.133-0.398
		c0-0.795,0-1.458,0-2.253L664.068,421.288z"
        />
        <path
          fill={textColor}
          d="M680.236,420.758L680.236,420.758c0-1.06-0.133-2.12-0.133-3.181c0-1.325-0.398-0.928-1.59-0.928
		c-0.53,0-1.06,0.133-1.325,0.133c-0.133,0-0.133,0-0.265-0.133c-0.133-0.133-0.265-0.133-0.53-0.133
		c-0.132-0.265-0.132-1.193-0.132-1.458c0-0.133,0-0.265,0.132-0.265c0.265,0,0.398,0,0.663,0c0.928,0,1.855,0,2.783,0.265
		c0.265-0.133,0.53-0.133,0.795-0.133c0.398,0,0.795,0.133,1.325,0.133c0.265,0,0.53-0.133,0.663-0.133c0.53,0,2.915,0,3.048,0.265
		c0,0.265,0,0.53,0,0.795c0,0.265,0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.253,0.133c-0.133,0-1.193,0-1.325,0.133
		c-0.132,0.265-0.132,3.976-0.132,4.638c0,0.795,0.795,1.193,1.458,1.193c0.265,0,0.663-0.133,0.928-0.133s0.53,0.133,0.663,0.265
		c0,0.133,0.133,0,0.133,0v0.265c0,0.398,0.132,0.795,0.132,1.193c0,0.133,0,0.265-0.132,0.265c-0.53,0-0.928-0.133-1.458-0.265
		c-1.988,0-3.976,0-5.964,0c-0.663,0-1.193,0-1.193-0.795c0-1.06,0.663-0.928,1.458-0.928c0.53,0,0.928,0,1.325,0
		c0.132,0,0.398,0,0.53,0c0.133,0,0.265-0.133,0.398-0.265c0.132-0.398,0.132-0.795,0.132-1.193
		C680.236,421.023,680.236,420.89,680.236,420.758z"
        />
        <path
          fill={textColor}
          d="M689.645,421.42c0-0.398,0.133-0.663,0.133-1.06c-0.133-0.133-0.133-0.398-0.133-0.53
		c0-1.458-0.265-2.915-0.265-4.373c0-0.133,0.133-0.265,0.265-0.265c0.133,0,0.265,0,0.398,0c0.53,0,1.06,0,1.59-0.133
		c0.928-0.133,2.12-0.133,3.048-0.133c1.06,0,1.988,0.398,2.783,0.928c0.663,0.398,0.663,0.928,1.193,1.458
		c0.398,0.398,0.53,1.193,0.53,1.723s-0.133,1.06,0,1.59c-0.133,0.265-0.133,0.663-0.133,0.928c0,0.133,0,0.133-0.133,0.265
		s-0.265,0.398-0.398,0.53c-0.398,0.663-1.59,1.06-2.385,1.458c-0.795,0-1.723,0.265-2.518,0.265c-0.265,0-0.398,0.133-0.663,0.133
		c-0.398,0-0.795-0.133-1.193-0.133c-0.53,0.265-1.193,0.265-1.855,0.265c-0.133,0-0.398,0-0.398-0.133
		C689.513,423.011,689.645,422.215,689.645,421.42z M694.548,422.215c1.59,0,2.518-1.193,2.518-2.65c0-1.06-0.398-1.59-1.06-2.385
		c-0.265,0-0.398-0.133-0.663-0.265h-0.133c-0.265,0-0.53-0.133-0.795-0.133c-0.132,0-0.132,0-0.265-0.133l-0.265-0.398
		c-0.133,0-0.53-0.133-0.663-0.133c-1.06,0-1.855,0.265-1.855,1.458c0,0.398,0.132,2.385,0.265,2.783
		c0,0.265-0.133,0.398-0.133,0.663c0,0.398,0,0.663,0.133,1.06c0.132,0,0.132,0.133,0.265,0.133
		C692.826,422.215,693.753,422.215,694.548,422.215z"
        />
        <path
          fill={textColor}
          d="M704.753,415.059c0,0.663-0.133,1.458-0.133,2.253c0,0.53,0,1.06,0,1.723c0,0.398,0,1.06,0,1.325
		c0,0.133,0,0.265,0.133,0.265c0,0,0.132,0,0.132-0.133c0.398-0.928,1.06-2.253,1.458-3.181c0.132-0.133,0.398-0.795,0.53-1.06
		l0.133-0.133c0,0,0,0,0.132,0c0.133,0,0.133,0.133,0.133,0.265c0.265,0.663,0.795,1.325,1.06,2.12
		c0.133,0.265,0.265,0.398,0.398,0.663c0.265,0.265,0.398,0.663,0.795,0.795c-0.132-0.795-0.132-1.988-0.132-2.783
		c0-0.663-0.133-1.458-0.133-2.12c0-0.53,1.325-0.53,1.723-0.53c0.265,0,0.795,0,0.795,0.398c0,0.663,0,1.325,0,1.988
		c0,0.663,0,3.313,0,3.711c-0.133,0.398,0,1.723,0,2.12c0,0.398,0,1.06-0.53,1.193c-0.53,0-1.06,0.133-1.59,0.133
		c-0.795,0-1.458-2.253-2.253-2.783c0-0.133-0.265-0.53-0.265-0.663c0-0.133,0-0.133,0-0.133c0-0.133,0-0.133-0.133-0.133
		c-0.133,0.265-0.265,0.53-0.265,0.663c0,0.265-0.132,0.398-0.398,0.53c-0.398,0.928-0.928,2.783-2.253,2.783
		c-0.265,0-0.53,0.133-0.928,0.133c-0.398,0-0.53,0-0.53-0.398s0.133-0.795,0-1.325c0.133-0.398,0-0.795,0-1.193
		c0-0.133,0.133-0.265,0.133-0.53c-0.133-0.133-0.265-0.398-0.265-0.663c0-0.133,0.133-0.265,0.133-0.398
		c0-0.398-0.133-0.795-0.133-1.193c0.133-0.133,0.133-0.265,0.133-0.398c0-0.398-0.133-0.795,0-1.193
		c-0.133-0.795-0.133-1.59-0.265-2.385c0-0.53,1.325-0.398,1.59-0.53C704.488,414.397,704.753,414.927,704.753,415.059z"
        />
        <path
          fill={textColor}
          d="M723.306,424.203c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.133-0.398-0.133-0.795
		c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.133,0.133-0.133,1.193-0.133,1.458c0,0.265,0.133,0.398,0.133,0.663c0,0.663,0,1.06-0.795,1.06
		c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
		c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
		c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
		c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.133,3.313-0.133,4.903c0,0.265-0.132,0.398-0.398,0.398H723.306z M722.378,419.167
		c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
		c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
		c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398c0.133,0.265,0.265,0.265,0.53,0.265L722.378,419.167L722.378,419.167z"
        />
        <path
          fill={textColor}
          d="M732.45,420.228c-0.265-0.53-0.53-1.06-0.795-1.59c-0.663-0.928-1.458-1.855-1.988-2.915
		c-0.132-0.265-0.265-0.663-0.663-0.795c0-0.398,0.133-0.265,0.398-0.265c0.663,0,1.193-0.133,1.855,0l0.265-0.265h0.133
		c0.663,0.795,0.928,2.12,1.59,2.783c0.265,0.265,0.398,0.53,0.53,0.795c0.132-0.398,0.398-0.795,0.663-1.193
		c0-0.265,0-0.398,0.133-0.53c0.398-0.53,1.06-1.723,1.193-1.723c0.265,0,1.59,0,1.855,0.133v0.265
		c-0.265,0.398-0.398,1.06-0.663,1.458c-0.53,0.398-0.53,1.193-0.928,1.59c-0.265,0.265-0.663,0.663-0.795,0.928
		c-0.133,0.53-0.398,0.53-0.795,1.325c0.133,0.663,0.265,1.855,0.265,2.518c0,0.265,0,0.663-0.133,0.928l-0.132,0.133
		c-0.265,0-0.53-0.265-0.928-0.265c-0.398,0-0.663,0.265-0.928,0.265c-0.53,0-0.398-0.795-0.398-1.193
		C732.317,421.95,732.317,421.023,732.45,420.228z"
        />
      </g>
      <g id="Osoyoos">
        <path
          fill={textColor}
          d="M454.418,410.421c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.133,0-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C454.286,411.349,454.418,410.951,454.418,410.421z M459.322,414.662c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.133-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.133,0.265-0.133,0.53c0,0.398,0.53,1.59,0.795,1.723C458.261,413.999,458.394,414.662,459.322,414.662z"
        />
        <path
          fill={textColor}
          d="M472.309,411.481c-0.398,0-1.06,0.133-1.458,0c-0.133,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.133-1.193
		c0.133-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
		c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.133,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
		c-0.132,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.915-0.133
		c-0.133,0.133-0.265,0.133-0.398,0.133s-0.398,0-0.53,0c-0.133,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.133,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795c0,0.133,0.133,0.265,0.133,0.398c0,0.133-1.193,1.723-1.458,2.12
		c-0.133,0.133-0.265,0.133-0.398,0.133h0.265c-0.133-0.133-0.133-0.133-0.265-0.133s-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.133
		c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.133-0.265,0.133-0.398c0-0.133,0-0.265,0-0.398c0-0.795-0.133-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.915-0.398c0.133-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.133-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.133-0.133h-0.133c-0.133,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h-0.133c-0.265,0-0.53,0.133-0.795,0.133C472.839,411.614,472.574,411.481,472.309,411.481z"
        />
        <path
          fill={textColor}
          d="M480.923,410.421c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.133,0-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C480.658,411.349,480.923,410.951,480.923,410.421z M485.826,414.662c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.133-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.133,0.265-0.133,0.53c0,0.398,0.53,1.59,0.795,1.723C484.766,413.999,484.898,414.662,485.826,414.662z"
        />
        <path
          fill={textColor}
          d="M497.621,411.879c-0.265-0.53-0.53-1.06-0.795-1.59c-0.663-0.928-1.458-1.855-1.988-2.915
		c-0.133-0.265-0.265-0.663-0.663-0.795c0-0.398,0.133-0.265,0.398-0.265c0.663,0,1.193-0.133,1.855,0l0.265-0.265h0.133
		c0.663,0.795,0.928,2.12,1.59,2.783c0.265,0.265,0.398,0.53,0.53,0.795c0.133-0.398,0.398-0.795,0.663-1.193
		c0-0.265,0-0.398,0.133-0.53c0.398-0.53,1.06-1.723,1.193-1.723c0.265,0,1.59,0,1.855,0.133v0.265
		c-0.265,0.398-0.398,1.06-0.663,1.458c-0.53,0.398-0.53,1.193-0.928,1.59c-0.265,0.265-0.663,0.663-0.795,0.928
		c-0.133,0.53-0.398,0.53-0.795,1.325c0.133,0.663,0.265,1.855,0.265,2.518c0,0.265,0,0.663-0.133,0.928l-0.133,0.133
		c-0.265,0-0.53-0.265-0.928-0.265c-0.398,0-0.663,0.265-0.928,0.265c-0.53,0-0.398-0.795-0.398-1.193
		C497.488,413.602,497.488,412.806,497.621,411.879z"
        />
        <path
          fill={textColor}
          d="M506.367,410.421c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.133,0-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C506.102,411.349,506.367,410.951,506.367,410.421z M511.27,414.662c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.133-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.133,0.265-0.133,0.53c0,0.398,0.53,1.59,0.795,1.723C510.21,413.999,510.343,414.662,511.27,414.662z"
        />
        <path
          fill={textColor}
          d="M519.354,410.421c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.132,0.133-0.132,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53s-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C519.089,411.349,519.354,410.951,519.354,410.421z M524.257,414.662c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.133-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193s-0.398,1.855-0.398,2.385c0,0.133-0.133,0.265-0.133,0.53
		c0,0.398,0.53,1.59,0.795,1.723C523.197,413.999,523.33,414.662,524.257,414.662z"
        />
        <path
          fill={textColor}
          d="M537.245,411.481c-0.398,0-1.06,0.133-1.458,0c-0.133,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.132-1.193
		c0.133-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0s0.663,0,1.06-0.133
		c0.132,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.133,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133s-0.265,0.133-0.398,0.133
		c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.916-0.133c-0.132,0.133-0.265,0.133-0.398,0.133s-0.398,0-0.53,0
		c-0.132,0-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398
		c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59c0.133,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795
		c0,0.133,0.133,0.265,0.133,0.398c0,0.133-1.193,1.723-1.458,2.12c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133
		c-0.132-0.133-0.132-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.53,0.133c-1.06,0-1.988-0.133-3.048-0.133h-0.265
		c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.132c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133
		c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133c0-0.133,0.133-0.265,0.133-0.398c0-0.133,0-0.265,0-0.398
		c0-0.795-0.133-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.915-0.398
		c0.133-0.133,0.265-0.265,0.265-0.398c0-0.398-0.133-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.133-0.133h-0.133
		c-0.132,0-0.265,0-0.265,0c-0.133,0-0.398,0-0.53-0.133h-0.132c-0.265,0-0.53,0.133-0.795,0.133
		C537.642,411.614,537.51,411.481,537.245,411.481z"
        />
      </g>
      <g id="Keremeos">
        <path
          fill={textColor}
          d="M392.531,315.8c-0.133,0-0.265,0-0.265,0.133l0.133,0.133c-0.265,0.53,0.133-0.265-0.133,1.06
		c0,0.53,0,1.06,0,1.59c0,0.398-0.928,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.133-0.133,0.133-0.265
		v-0.133c-0.133-0.265-0.133-0.53-0.133-0.928v-1.193c0-0.265,0.133-0.53,0.133-0.795c0-0.795-0.133-1.723-0.133-2.65
		c0-0.398,0-0.663,0.133-1.193c-0.133-0.265-0.265-0.53-0.265-0.795v-0.133c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
		c0.133,0.133,0.133,0.133,0.133,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.133,0.53-0.133,1.06
		c0,0.133-0.133,0.265-0.133,0.53c0,0.265,0.133,0.398,0.133,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
		c0.398-0.398,1.193-1.06,1.458-1.458c0.133-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.928-0.133h0.663l0.133,0.133
		c0,0.133-0.795,0.928-0.928,1.06c-0.53,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
		c0.795,1.06,2.915,3.843,3.843,5.168l-0.133,0.133c-0.133,0-0.398,0-0.53,0c-0.53,0-0.928,0-1.458,0h-0.398
		c-0.133-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.133-0.265
		C393.458,314.74,392.796,315.535,392.531,315.8L392.531,315.8z"
        />
        <path
          fill={textColor}
          d="M412.276,317.125c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133c0.133,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.133,0.53,0.133,0.795v0.133l-0.133,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C410.288,316.993,412.011,316.993,412.276,317.125L412.276,317.125z
		"
        />
        <path
          fill={textColor}
          d="M418.505,318.053c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06
		c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H418.505z M423.938,311.294c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.915,0,2.915-0.663
		C424.071,311.957,423.938,311.56,423.938,311.294z"
        />
        <path
          fill={textColor}
          d="M438.648,317.125c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133c0.133,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.133,0.53,0.133,0.795v0.133l-0.133,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C436.66,316.993,438.383,316.993,438.648,317.125L438.648,317.125z"
        />
        <path
          fill={textColor}
          d="M443.021,315.8c-0.133-0.265-0.265-0.53-0.133-0.795c-0.133-0.265-0.133-0.53-0.133-0.663
		c0-0.663,0.133-1.325,0.133-1.988c0-0.663-0.133-1.325-0.133-1.988c0-0.133,0-0.398,0.133-0.53
		c-0.133-0.133-0.265-0.398-0.265-0.53c0-0.265,0.663-0.265,0.795-0.265c0.53,0,1.06,0.133,1.723,0.133
		c0.133,0,0.265,0.133,0.265,0.265c0.265,1.06,0.928,1.988,1.59,2.783v0.265v0.133l0.133,0.133c0.133-0.133,0.398-0.398,0.53-0.398
		c0.265-0.53,0.663-1.855,1.193-1.988v-0.133c0-0.265,0.265-0.53,0.265-0.795c0-0.133,0.133-0.133,0.265-0.133
		c0.663,0,1.59,0.133,2.385,0.133c0.133,0,0.398,0,0.398,0.265v0.265c-0.133,1.06-0.133,2.12-0.133,3.181
		c0,0.53-0.133,0.928,0,1.458c0,0.133-0.133,0.265-0.133,0.398c0,0.795,0.133,1.59,0.133,2.518c0,1.458,0.133,1.06-1.723,1.325
		c-0.133,0-0.265-0.133-0.265-0.265c0-0.133,0-0.398,0.133-0.663c0.133-0.928,0-2.12,0-3.048c0-0.53-0.133-1.06-0.133-1.59l0,0
		v-0.133c0-0.133,0-0.928-0.133-1.06h-0.133c-0.133,0-0.398,0.663-0.398,0.795l-0.398,0.928c0,0.265-1.325,2.915-1.458,3.048
		c-0.133,0-0.133-0.133-0.265-0.265c-0.133-0.53-1.325-2.518-1.723-3.048c-0.133-0.265-0.265-0.53-0.398-0.795
		c-0.133-0.133-0.265-0.53-0.398-0.663h-0.133l-0.133,0.133c0,0.133,0,0.398,0,0.53c0,0.133-0.133,0.265-0.133,0.398
		c0,0.53,0.133,0.928,0.133,1.458s0.133,1.193,0.133,1.723c0,0.53,0,1.193,0,1.855c0,0.133-0.133,0.398-0.133,0.398
		c-0.133,0-0.265,0-0.398,0c-0.265,0-0.398-0.133-0.53-0.133c-0.265,0-0.53,0.133-0.795,0.133c0-0.133-0.133-0.265-0.133-0.398
		c0-0.795,0-1.458,0-2.253L443.021,315.8z"
        />
        <path
          fill={textColor}
          d="M464.888,317.125c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.133,0,0.265,0,0.53,0s0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		c0,0.265,0.133,0.53,0.133,0.795v0.133l-0.133,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C462.9,316.993,464.623,316.993,464.888,317.125L464.888,317.125z"
        />
        <path
          fill={textColor}
          d="M468.598,313.15c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.133,0-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C468.466,314.077,468.598,313.68,468.598,313.15z M473.502,317.39c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.133-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.133,0.265-0.133,0.53c0,0.398,0.53,1.59,0.795,1.723C472.441,316.728,472.574,317.39,473.502,317.39z"
        />
        <path
          fill={textColor}
          d="M486.489,314.21c-0.398,0-1.06,0.133-1.458,0c-0.133,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.133-1.193
		c0.133-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
		c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.133,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
		c-0.133,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.915-0.133
		c-0.133,0.133-0.265,0.133-0.398,0.133c-0.133,0-0.398,0-0.53,0s-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.133,0.265,0.265,0.795,0.265,1.06c0,0.265-0.133,0.53-0.265,0.795c0,0.133,0.133,0.265,0.133,0.398s-1.193,1.723-1.458,2.12
		c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133c-0.133-0.133-0.133-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.133
		c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.133-0.265,0.133-0.398s0-0.265,0-0.398c0-0.795-0.133-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.916-0.398c0.133-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.133-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.133-0.133h-0.133c-0.133,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h-0.133c-0.265,0-0.53,0.133-0.795,0.133C487.019,314.342,486.754,314.21,486.489,314.21z"
        />
      </g>
      <g id="Cawston">
        <path
          fill={textColor}
          d="M350.786,364.436c-0.663,0.398-0.795,1.325-0.795,1.988c0,0.928,1.06,1.723,1.988,1.723
		c0.398,0,1.06,0.265,1.59,0.265c1.325,0,2.783,0,3.976-0.265c0.265,0.133,0.398,0.265,0.398,0.53c0,0.663,0,1.325,0,1.988h-0.133
		c-0.133,0-0.265-0.133-0.398-0.133s-0.265,0-0.265,0.133h-0.133c-0.265-0.133-0.398,0-0.663,0c-0.133,0-0.265-0.133-0.398-0.133
		c-0.398,0-0.795,0.133-1.193,0.133c-0.663,0-1.59-0.133-2.253-0.265c-0.133-0.133-0.133-0.133-0.265-0.133
		s-0.265,0.133-0.398,0.133c-0.133,0-0.398-0.133-0.663-0.133h-0.133c-1.855-1.06-3.048-2.253-3.048-4.771
		c0-2.915,2.253-3.578,4.506-4.506c0.663,0.133,1.59,0,2.253,0.133c0.398-0.133,0.795-0.133,1.325-0.133s1.193,0,1.59-0.265
		c0.133,0.133,0.133,0.265,0.133,0.398c0,0.265-0.133,0.53-0.133,0.795c0,0.398,0,0.663,0.133,1.06v0.265
		c-0.265,0-0.265-0.133-0.663-0.133h-0.795c-0.663,0-1.193,0.133-1.193,0.133c-0.265,0-0.53,0-0.795,0c-0.133,0-1.458,0-1.458,0
		l-0.795,0.398c-1.193,0.398-0.398,0-0.928,0.53C351.051,364.303,350.919,364.436,350.786,364.436z"
        />
        <path
          fill={textColor}
          d="M369.074,370.664c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.133-0.398-0.133-0.795
		c0-0.53-0.133-1.193-0.133-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.133,0.133-0.133,1.193-0.133,1.458c0,0.265,0.133,0.398,0.133,0.663c0,0.663,0,1.06-0.795,1.06
		c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
		c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
		c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
		c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.133,3.313-0.133,4.903c0,0.265-0.133,0.398-0.398,0.398H369.074z M368.146,365.629
		c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
		c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
		c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398c0.133,0.265,0.265,0.265,0.53,0.265L368.146,365.629L368.146,365.629z"
        />
        <path
          fill={textColor}
          d="M376.76,361.52c0,0.663-0.133,1.458-0.133,2.253c0,0.53,0,1.06,0,1.723c0,0.398,0,1.06,0,1.325
		c0,0.133,0,0.265,0.133,0.265c0,0,0.133,0,0.133-0.133c0.398-0.928,1.06-2.253,1.458-3.181c0.133-0.133,0.398-0.795,0.53-1.06
		l0.133-0.133c0,0,0,0,0.133,0s0.133,0.133,0.133,0.265c0.265,0.663,0.795,1.325,1.06,2.12c0.133,0.265,0.265,0.398,0.398,0.663
		c0.265,0.265,0.398,0.663,0.795,0.795c-0.133-0.795-0.133-1.988-0.133-2.783c0-0.663-0.133-1.458-0.133-2.12
		c0-0.53,1.325-0.53,1.723-0.53c0.265,0,0.795,0,0.795,0.398c0,0.663,0,1.325,0,1.988c0,0.663,0,3.313,0,3.711
		c-0.133,0.398,0,1.723,0,2.12c0,0.398,0,1.06-0.53,1.193c-0.53,0-1.06,0.133-1.59,0.133c-0.795,0-1.458-2.253-2.253-2.783
		c0-0.133-0.265-0.53-0.265-0.663s0-0.133,0-0.133c0-0.133,0-0.133-0.133-0.133c-0.133,0.265-0.265,0.53-0.265,0.663
		c0,0.265-0.133,0.398-0.398,0.53c-0.398,0.928-0.928,2.783-2.253,2.783c-0.265,0-0.53,0.133-0.928,0.133
		c-0.398,0-0.53,0-0.53-0.398s0.133-0.795,0-1.325c0.133-0.398,0-0.795,0-1.193c0-0.133,0.133-0.265,0.133-0.53
		c-0.133-0.133-0.265-0.398-0.265-0.663c0-0.133,0.133-0.265,0.133-0.398c0-0.398-0.133-0.795-0.133-1.193
		c0.133-0.133,0.133-0.265,0.133-0.398c0-0.398-0.133-0.795,0-1.193c-0.133-0.795-0.133-1.59-0.265-2.385
		c0-0.53,1.325-0.398,1.59-0.53C376.495,360.99,376.76,361.388,376.76,361.52z"
        />
        <path
          fill={textColor}
          d="M392.663,366.159c-0.398,0-1.06,0.133-1.458,0c-0.133,0-0.398,0.133-0.53,0.133
		c-1.06,0-1.06-0.795-1.723-0.795c-0.53-0.53-0.928-1.06-0.928-1.855c0-0.265,0.265-1.06,0.133-1.193
		c0.133-0.398,0.53-0.663,0.795-1.06c0-0.398,0.928-0.398,1.458-0.53c0.398,0,0.795,0,1.06,0c0.265,0,0.663,0,1.06-0.133
		c0.133,0,0.398,0.133,0.663,0.133c0.53-0.133,0.663-0.133,1.193-0.133c0.398,0,0.795,0,1.325,0c0.265,0,0.53-0.133,0.663-0.133
		c0.133,0.133,0.265,0,0.53,0c0.398,0.53,0.265,1.06,0.53,1.723h-0.265c-0.133,0-0.265-0.133-0.398-0.133
		c-0.133,0-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.928,0-1.988,0-2.915-0.133
		c-0.133,0.133-0.265,0.133-0.398,0.133c-0.133,0-0.398,0-0.53,0s-0.265,0-0.398,0c-0.53,0-1.458,0.53-1.458,1.06
		c0,0.133,0,0.53,0.133,0.53c0.663,0.398,4.506,0.398,5.566,0.398c0.265,0,0.53,0,0.928,0c0.53,0,1.193,1.06,1.458,1.59
		c0.133,0.265,0.265,0.795,0.265,1.06s-0.133,0.53-0.265,0.795c0,0.133,0.133,0.265,0.133,0.398c0,0.133-1.193,1.723-1.458,2.12
		c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133c-0.133-0.133-0.133-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.53,0.133
		c-1.06,0-1.988-0.133-3.048-0.133h-0.265c-0.133,0.133-0.265,0.133-0.398,0.133h-0.133h-0.265h-0.133
		c-0.398,0-0.928,0.133-1.325,0.133c-0.265,0-0.53,0-0.663,0.133c-0.133-0.133-0.265-0.265-0.53-0.398v-0.133
		c0-0.133,0.133-0.265,0.133-0.398c0-0.133,0-0.265,0-0.398c0-0.795-0.133-0.928,0.795-0.928c0.795,0,1.59-0.133,2.385-0.133
		c0.398,0,0.663,0.133,1.06,0.133c1.59,0,2.65-0.133,2.916-0.398c0.133-0.133,0.265-0.265,0.265-0.398
		c0-0.398-0.133-1.193-0.398-1.458c-0.133,0-0.133,0.133-0.265,0.133l-0.133-0.133h-0.133c-0.133,0-0.265,0-0.265,0
		c-0.133,0-0.398,0-0.53-0.133h-0.133c-0.265,0-0.53,0.133-0.795,0.133C393.193,366.291,392.928,366.159,392.663,366.159z"
        />
        <path
          fill={textColor}
          d="M405.253,367.351c0-0.663,0-3.446-0.265-3.843c-0.133-0.398-0.398-0.398-0.663-0.398
		c-0.53,0-1.06,0-1.59,0c-0.398,0-0.663-0.265-1.06-0.398v-0.133c0-0.53,0-0.928,0-1.458c0-0.133,0.133-0.265,0.133-0.265
		c0.795,0,1.723,0.133,2.518,0.133c0.265,0,0.53,0,0.795,0.133c0.265,0,0.53,0,0.663,0c0.398,0,0.795,0.133,1.193,0.133
		c0.265,0,0.53-0.133,0.795-0.133c0.928,0,1.855,0,2.783,0c0.133,0,0.265,0,0.265,0.133c0,0.265,0,0.53,0,0.795
		c0,0.265,0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.385,0.133c-0.398,0-1.06-0.133-1.193,0.398
		c-0.265,0.928-0.265,4.373-0.265,5.301c0,0.133-0.133,0.265-0.133,0.53c0,0.265,0.133,0.53,0.133,0.795
		c0,0.663,0,0.795-0.663,0.795c-0.398,0-0.795-0.133-1.193-0.133c-0.133-0.398-0.133-0.795-0.133-1.193
		C405.12,368.544,405.253,367.881,405.253,367.351z"
        />
        <path
          fill={textColor}
          d="M414.132,365.098c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.133,0-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C413.999,366.159,414.132,365.629,414.132,365.098z M419.035,369.472c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.133-1.325
		c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
		c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385
		c0,0.133-0.133,0.265-0.133,0.53c0,0.398,0.53,1.59,0.795,1.723C418.107,368.677,418.107,369.472,419.035,369.472z"
        />
        <path
          fill={textColor}
          d="M427.914,361.255c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
		c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.53-0.663-1.06-1.06-1.458-1.723
		c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.916,0-5.831,0-8.614
		C427.781,361.52,427.914,361.52,427.914,361.255L427.914,361.255z"
        />
      </g>
      <g id="RockCreek">
        <g id="Rock">
          <path
            fill={textColor}
            d="M594.097,352.906c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
			c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
			c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
			c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
			c-0.132,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
			s-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
			c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H594.097z
			 M599.53,346.148c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133
			c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
			c1.06,0,2.915,0,2.915-0.663C599.663,346.81,599.53,346.413,599.53,346.148z"
          />
          <path
            fill={textColor}
            d="M604.698,348.003c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
			c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
			c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
			c-0.53,0-1.59-0.53-1.723-0.53c-0.132,0-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
			C604.566,348.931,604.698,348.401,604.698,348.003z M609.602,352.244c0.53,0,1.193-0.265,1.723-0.398
			c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59c0-0.398,0-0.928-0.132-1.325
			c-0.265-0.265-0.53-1.325-0.795-1.458c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265
			c-0.53,0-1.325,0.133-1.855,0.265c-0.265,0.398-0.663,0.795-0.928,1.193s-0.398,1.855-0.398,2.385c0,0.133-0.132,0.265-0.132,0.53
			c0,0.398,0.53,1.59,0.795,1.723C608.674,351.449,608.674,352.244,609.602,352.244z"
          />
          <path
            fill={textColor}
            d="M620.203,347.208c-0.663,0.398-0.795,1.325-0.795,1.988c0,0.928,1.06,1.723,1.988,1.723
			c0.398,0,1.06,0.265,1.59,0.265c1.325,0,2.783,0,3.976-0.265c0.265,0.133,0.398,0.265,0.398,0.53c0,0.663,0,1.325,0,1.988h-0.133
			c-0.132,0-0.265-0.133-0.398-0.133s-0.265,0-0.265,0.133h-0.133c-0.265-0.133-0.398,0-0.663,0c-0.133,0-0.265-0.133-0.398-0.133
			c-0.398,0-0.795,0.133-1.193,0.133c-0.663,0-1.59-0.133-2.253-0.265c-0.133-0.133-0.133-0.133-0.265-0.133
			c-0.133,0-0.265,0.133-0.398,0.133c-0.132,0-0.398-0.133-0.663-0.133h-0.133c-1.855-1.06-3.048-2.253-3.048-4.771
			c0-2.915,2.253-3.578,4.506-4.506c0.663,0.133,1.59,0,2.253,0.133c0.398-0.133,0.795-0.133,1.325-0.133c0.53,0,1.193,0,1.59-0.265
			c0.133,0.133,0.133,0.265,0.133,0.398c0,0.265-0.133,0.53-0.133,0.795c0,0.398,0,0.663,0.133,1.06v0.265
			c-0.265,0-0.265-0.133-0.663-0.133h-0.795c-0.663,0-1.193,0.133-1.193,0.133c-0.265,0-0.53,0-0.795,0c-0.133,0-1.458,0-1.458,0
			l-0.795,0.398c-1.193,0.398-0.398,0-0.928,0.53C620.469,347.075,620.336,347.208,620.203,347.208z"
          />
          <path
            fill={textColor}
            d="M633.588,350.521c-0.132,0-0.265,0-0.265,0.133l0.132,0.133c-0.265,0.53,0.133-0.265-0.132,1.06
			c0,0.53,0,1.06,0,1.59c0,0.398-0.928,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.133-0.133,0.133-0.265
			v-0.133c-0.133-0.265-0.133-0.53-0.133-0.928v-1.193c0-0.265,0.133-0.53,0.133-0.795c0-0.795-0.133-1.723-0.133-2.65
			c0-0.398,0-0.663,0.133-1.193c-0.133-0.265-0.265-0.53-0.265-0.795v-0.133c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
			c0.133,0.133,0.133,0.133,0.133,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.132,0.53-0.132,1.06
			c0,0.133-0.133,0.265-0.133,0.53c0,0.265,0.133,0.398,0.133,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
			c0.398-0.398,1.193-1.06,1.458-1.458c0.133-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.928-0.133h0.663l0.133,0.133
			c0,0.133-0.795,0.928-0.928,1.06c-0.53,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
			c0.795,1.06,2.916,3.843,3.843,5.168l-0.133,0.133c-0.133,0-0.398,0-0.53,0c-0.53,0-0.928,0-1.458,0h-0.398
			c-0.133-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.133-0.265
			C634.516,349.593,633.721,350.388,633.588,350.521L633.588,350.521z"
          />
        </g>
        <g id="Creek">
          <path
            fill={textColor}
            d="M587.736,362.713c-0.663,0.398-0.795,1.325-0.795,1.988c0,0.928,1.06,1.723,1.988,1.723
			c0.398,0,1.06,0.265,1.59,0.265c1.325,0,2.783,0,3.976-0.265c0.265,0.133,0.398,0.265,0.398,0.53c0,0.663,0,1.325,0,1.988h-0.133
			c-0.133,0-0.265-0.133-0.398-0.133c-0.132,0-0.265,0-0.265,0.133h-0.133c-0.265-0.133-0.398,0-0.663,0
			c-0.133,0-0.265-0.133-0.398-0.133c-0.398,0-0.795,0.133-1.193,0.133c-0.663,0-1.59-0.133-2.253-0.265
			c-0.133-0.133-0.133-0.133-0.265-0.133c-0.132,0-0.265,0.133-0.398,0.133s-0.398-0.133-0.663-0.133h-0.133
			c-1.855-1.06-3.048-2.253-3.048-4.771c0-2.915,2.253-3.578,4.506-4.506c0.663,0.133,1.59,0,2.253,0.133
			c0.398-0.133,0.795-0.133,1.325-0.133c0.53,0,1.193,0,1.59-0.265c0.133,0.133,0.133,0.265,0.133,0.398
			c0,0.265-0.133,0.53-0.133,0.795c0,0.398,0,0.663,0.133,1.06v0.265c-0.265,0-0.265-0.133-0.663-0.133h-0.795
			c-0.663,0-1.193,0.133-1.193,0.133c-0.265,0-0.53,0-0.795,0c-0.132,0-1.458,0-1.458,0l-0.795,0.398
			c-1.193,0.398-0.398,0-0.928,0.53C588.001,362.448,587.868,362.713,587.736,362.713z"
          />
          <path
            fill={textColor}
            d="M600.988,368.411c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
			c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
			c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
			c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
			c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
			s-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
			c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H600.988z
			 M606.421,361.653c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133
			c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
			c1.06,0,2.916,0,2.916-0.663C606.554,362.183,606.421,361.918,606.421,361.653z"
          />
          <path
            fill={textColor}
            d="M620.999,367.484c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
			c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.133-1.193-0.133-1.723
			c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.132-0.133-0.132-0.265
			c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133s0.663-0.133,0.928-0.133
			c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663c-0.265,0-0.53,0-0.795,0.265
			c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265c-0.133,0.133-0.133,0.795-0.133,0.928
			c0.133,0.133,0,0.398,0.133,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265c0.663,0,1.458,0,2.12-0.133
			c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663c0,0.265,0.132,0.53,0.132,0.795v0.133l-0.132,0.133
			c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265
			c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0
			C619.011,367.351,620.734,367.351,620.999,367.484L620.999,367.484z"
          />
          <path
            fill={textColor}
            d="M634.251,367.484c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
			c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.132-1.193-0.132-1.723
			c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
			c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
			c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663
			c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265
			c-0.132,0.133-0.132,0.795-0.132,0.928c0.132,0.133,0,0.398,0.132,0.663c0.133,0,0.265,0,0.53,0s0.53,0.265,0.928,0.265
			c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133c0.133,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
			c0,0.265,0.132,0.53,0.132,0.795v0.133l-0.132,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
			c-0.265,0-0.53-0.133-0.795-0.133s-0.663,0-0.795,0.265c0,0.133-0.132,0.398-0.132,0.53c0,0.265,0.132,0.53,0.265,0.795
			c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C632.263,367.351,633.986,367.351,634.251,367.484L634.251,367.484
			z"
          />
          <path
            fill={textColor}
            d="M640.479,366.026c-0.133,0-0.265,0-0.265,0.133l0.133,0.133c-0.265,0.53,0.133-0.265-0.133,1.06
			c0,0.53,0,1.06,0,1.59c0,0.398-0.928,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.133-0.133,0.133-0.265
			v-0.133c-0.133-0.265-0.133-0.53-0.133-0.928v-1.193c0-0.265,0.133-0.53,0.133-0.795c0-0.795-0.133-1.723-0.133-2.65
			c0-0.398,0-0.663,0.133-1.193c-0.133-0.265-0.265-0.53-0.265-0.795V359.4c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
			c0.133,0.133,0.133,0.133,0.133,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.133,0.53-0.133,1.06
			c0,0.133-0.132,0.265-0.132,0.53c0,0.265,0.132,0.398,0.132,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
			c0.398-0.398,1.193-1.06,1.458-1.458c0.133-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.928-0.133h0.663l0.133,0.133
			c0,0.133-0.795,0.928-0.928,1.06c-0.53,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
			c0.795,1.06,2.916,3.843,3.843,5.168l-0.133,0.133c-0.133,0-0.398,0-0.53,0c-0.53,0-0.928,0-1.458,0h-0.398
			c-0.133-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.133-0.265
			C641.407,364.966,640.612,365.761,640.479,366.026L640.479,366.026z"
          />
        </g>
      </g>
      <g id="Princeton">
        <path
          fill={textColor}
          d="M191.494,216.276L191.494,216.276c-0.265,0.133-0.398,0.133-0.663,0.133c-0.398,0-0.795,0-1.325,0
		c-0.133-0.265-0.133-0.663-0.133-1.06s0.133-0.795,0.133-1.06c0-0.133-0.133-0.265-0.133-0.53v-1.193
		c0-1.06,0.133-2.12,0.133-3.181c0-0.398,0-0.663,0-1.06s-0.133-1.06,0.133-1.325h0.133c0.265,0,0.265,0,0.53-0.133
		c0.133,0,0.265,0.133,0.53,0.133c0.398,0,0.795-0.133,1.325-0.133c0.133,0,1.59,0,1.59,0c0.133,0.133,0.398,0.133,0.53,0.133
		c0.398,0,0.795-0.133,1.193-0.133c0.133,0,0.265,0.133,0.53,0.133c0.133,0,0.133,0,0.265,0c0.265,0,0.398,0.133,0.663,0.133
		c0.398,0.398,1.06,0.265,1.458,0.663c0.133,0.133,0.265,0.133,0.398,0.265c0.265,0.53,0.398,1.193,0.398,1.723
		s-0.133,0.928-0.265,1.325c0,0.133-0.133,0.265-0.133,0.398c-0.133,0.795-1.325,1.06-1.988,1.06c-0.398,0-0.928,0-1.325,0
		c-0.53,0.265-2.385,0.133-3.048,0.133c-0.265,0-0.398,0.133-0.663,0.265c-0.133,0.265-0.133,1.06-0.265,1.325
		c0.133,0,0.133,0.133,0.133,0.265v1.723H191.494z M191.627,211.24L191.627,211.24c0.133,0,0.398-0.133,0.398-0.265h0.398
		c0.265,0,0.53,0,0.663,0.133h0.133c0.133-0.133,2.12-0.133,2.518-0.133c0.133,0,0.265,0,0.398,0.133h0.133
		c0.398-0.398,1.193-0.265,1.193-1.193c0-1.193-2.12-1.458-3.048-1.458c-0.53,0-1.193,0.133-1.855,0.133
		c-1.59,0-0.928,0.53-1.193,1.59L191.627,211.24z"
        />
        <path
          fill={textColor}
          d="M204.614,216.011c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.663,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		s-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
		c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H204.614z
		 M210.048,209.252c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133
		c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
		c1.06,0,2.915,0,2.915-0.663C210.18,209.783,210.048,209.517,210.048,209.252z"
        />
        <path
          fill={textColor}
          d="M219.059,213.096L219.059,213.096c0-1.06-0.133-2.12-0.133-3.181c0-1.325-0.398-0.928-1.59-0.928
		c-0.53,0-1.06,0.133-1.325,0.133c-0.133,0-0.133,0-0.265-0.133c-0.133-0.133-0.265-0.133-0.53-0.133
		c-0.133-0.265-0.133-1.193-0.133-1.458c0-0.133,0-0.265,0.133-0.265c0.265,0,0.398,0,0.53,0c0.928,0,1.855,0,2.783,0.265
		c0.265-0.133,0.53-0.133,0.795-0.133c0.398,0,0.795,0.133,1.325,0.133c0.265,0,0.53-0.133,0.663-0.133c0.53,0,2.916,0,3.048,0.265
		c0,0.265,0,0.53,0,0.795s0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.253,0.133c-0.133,0-1.193,0-1.325,0.133
		c-0.133,0.265-0.133,3.976-0.133,4.638c0,0.795,0.795,1.193,1.458,1.193c0.265,0,0.663-0.133,0.928-0.133s0.53,0.133,0.663,0.265
		c0,0.133,0.133,0,0.133,0v0.265c0,0.398,0.133,0.795,0.133,1.193c0,0.133,0,0.265-0.133,0.265c-0.53,0-0.928-0.133-1.458-0.265
		c-1.988,0-3.976,0-5.964,0c-0.663,0-1.193,0-1.193-0.795c0-1.06,0.663-0.928,1.458-0.928c0.53,0,0.928,0,1.325,0
		c0.133,0,0.398,0,0.53,0c0.133,0,0.265-0.133,0.398-0.265c0.133-0.398,0.133-0.795,0.133-1.193
		C219.059,213.361,219.059,213.228,219.059,213.096z"
        />
        <path
          fill={textColor}
          d="M228.733,207.132c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
		c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0s0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.398-0.663-1.06-1.06-1.458-1.723
		c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
		C228.601,207.397,228.601,207.265,228.733,207.132L228.733,207.132z"
        />
        <path
          fill={textColor}
          d="M243.841,210.313c-0.663,0.398-0.795,1.325-0.795,1.988c0,0.928,1.06,1.723,1.988,1.723
		c0.398,0,1.06,0.265,1.59,0.265c1.325,0,2.783,0,3.976-0.265c0.265,0.133,0.398,0.265,0.398,0.53c0,0.663,0,1.325,0,1.988h-0.133
		c-0.133,0-0.265-0.133-0.398-0.133c-0.132,0-0.265,0-0.265,0.133h-0.133c-0.265-0.133-0.398,0-0.663,0
		c-0.133,0-0.265-0.133-0.398-0.133c-0.398,0-0.795,0.133-1.193,0.133c-0.663,0-1.59-0.133-2.253-0.265
		c-0.133-0.133-0.133-0.133-0.265-0.133c-0.133,0-0.265,0.133-0.398,0.133c-0.133,0-0.398-0.133-0.663-0.133h-0.133
		c-1.855-1.06-3.048-2.253-3.048-4.771c0-2.915,2.253-3.578,4.506-4.506c0.663,0.133,1.59,0,2.253,0.133
		c0.398-0.133,0.795-0.133,1.325-0.133c0.53,0,1.193,0,1.59-0.265c0.133,0.133,0.133,0.265,0.133,0.398
		c0,0.265-0.133,0.53-0.133,0.795c0,0.398,0,0.663,0.133,1.06v0.265c-0.265,0-0.265-0.133-0.663-0.133h-0.795
		c-0.663,0-1.193,0.133-1.193,0.133c-0.265,0-0.53,0-0.795,0c-0.133,0-1.458,0-1.458,0l-0.795,0.398
		c-1.193,0.398-0.398,0-0.928,0.53C243.973,210.048,243.841,210.313,243.841,210.313z"
        />
        <path
          fill={textColor}
          d="M263.852,215.083c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.133-1.193-0.133-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398c0,0.398-0.133,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133c-0.663,0-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.133,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133c0.133,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663
		s0.133,0.53,0.133,0.795v0.133l-0.133,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0
		c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795
		c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0C261.864,214.951,263.586,214.951,263.852,215.083L263.852,215.083z
		"
        />
        <path
          fill={textColor}
          d="M271.67,213.096c0-0.663,0-3.446-0.265-3.843c-0.133-0.398-0.398-0.398-0.663-0.398
		c-0.53,0-1.06,0-1.59,0c-0.398,0-0.663-0.265-1.06-0.398v-0.133c0-0.53,0-0.928,0-1.458c0-0.133,0.133-0.265,0.133-0.265
		c0.795,0,1.723,0.133,2.518,0.133c0.265,0,0.53,0,0.795,0.133c0.265,0,0.53,0,0.663,0c0.398,0,0.795,0.133,1.193,0.133
		c0.265,0,0.53-0.133,0.795-0.133c0.928,0,1.855,0,2.783,0c0.133,0,0.265,0,0.265,0.133c0,0.265,0,0.53,0,0.795
		c0,0.265,0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.385,0.133c-0.398,0-1.06-0.133-1.193,0.398
		c-0.265,0.928-0.265,4.373-0.265,5.301c0,0.133-0.133,0.265-0.133,0.53s0.133,0.53,0.133,0.795c0,0.663,0,0.795-0.663,0.795
		c-0.398,0-0.795-0.133-1.193-0.133c-0.133-0.398-0.133-0.795-0.133-1.193C271.405,214.421,271.67,213.758,271.67,213.096z"
        />
        <path
          fill={textColor}
          d="M280.549,210.975c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.915-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.133,0-0.133,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C280.284,212.035,280.549,211.505,280.549,210.975z M285.32,215.216c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59s0-0.928-0.133-1.325c-0.265-0.265-0.53-1.325-0.795-1.458
		c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265c-0.663,0-1.325,0.133-1.855,0.265
		c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385c0,0.133-0.133,0.265-0.133,0.53
		c0,0.398,0.53,1.59,0.795,1.723C284.392,214.553,284.392,215.216,285.32,215.216z"
        />
        <path
          fill={textColor}
          d="M294.332,207.132c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
		c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
		c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
		c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
		c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
		c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.53-0.663-1.06-1.06-1.458-1.723
		c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
		c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
		C294.199,207.397,294.199,207.265,294.332,207.132L294.332,207.132z"
        />
      </g>
      <g id="ManningPark">
        <g id="Manning">
          <path
            fill={textColor}
            d="M143.919,407.505c-0.133-0.265-0.265-0.53-0.133-0.795c-0.133-0.265-0.133-0.53-0.133-0.663
			c0-0.663,0.133-1.325,0.133-1.988c0-0.663-0.133-1.325-0.133-1.988c0-0.133,0-0.398,0.133-0.53
			c-0.133-0.133-0.265-0.398-0.265-0.53c0-0.265,0.663-0.265,0.795-0.265c0.53,0,1.06,0.133,1.723,0.133
			c0.133,0,0.265,0.133,0.265,0.265c0.265,1.06,0.928,1.988,1.59,2.783v0.265v0.133l0.133,0.133c0.133-0.133,0.398-0.398,0.53-0.398
			c0.265-0.53,0.663-1.855,1.193-1.988v-0.133c0-0.265,0.265-0.53,0.265-0.795c0-0.133,0.133-0.133,0.265-0.133
			c0.663,0,1.59,0.133,2.385,0.133c0.133,0,0.398,0,0.398,0.265v0.265c-0.133,1.06-0.133,2.12-0.133,3.181
			c0,0.53-0.133,0.928,0,1.458c0,0.133-0.133,0.265-0.133,0.398c0,0.795,0.133,1.59,0.133,2.518c0,1.458,0.133,1.06-1.723,1.325
			c-0.133,0-0.265-0.133-0.265-0.265s0-0.398,0.133-0.663c0.133-0.928,0-2.12,0-3.048c0-0.53-0.133-1.06-0.133-1.59l0,0v-0.133
			c0-0.133,0-0.928-0.133-1.06h-0.133c-0.133,0-0.398,0.663-0.398,0.795l-0.398,0.928c0,0.265-1.325,2.915-1.458,3.048
			c-0.133,0-0.133-0.133-0.265-0.265c-0.133-0.53-1.325-2.518-1.723-3.048c-0.133-0.265-0.265-0.53-0.398-0.795
			c-0.133-0.133-0.265-0.53-0.398-0.663h-0.133l-0.133,0.133c0,0.133,0,0.398,0,0.53s-0.133,0.265-0.133,0.398
			c0,0.53,0.133,0.928,0.133,1.458c0,0.53,0.133,1.193,0.133,1.723c0,0.53,0,1.193,0,1.855c0,0.133-0.133,0.398-0.133,0.398
			c-0.133,0-0.265,0-0.398,0c-0.265,0-0.398-0.133-0.53-0.133c-0.265,0-0.53,0.133-0.795,0.133c0-0.133-0.133-0.265-0.133-0.398
			c0-0.795,0-1.458,0-2.253L143.919,407.505z"
          />
          <path
            fill={textColor}
            d="M163.93,410.288c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.133-0.398-0.133-0.795
			c0-0.53-0.133-1.193-0.133-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
			c-0.133,0.133-0.133,1.193-0.133,1.458c0,0.265,0.133,0.398,0.133,0.663c0,0.663,0,1.06-0.795,1.06
			c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
			c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
			c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
			c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.133,3.313-0.133,4.903c0,0.265-0.133,0.398-0.398,0.398H163.93z M163.002,405.385
			c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
			c-0.133-0.133-0.265-0.398-0.398-0.53C162.737,403,162.075,403,161.412,403c-0.265,0.133-0.53,0.398-0.928,0.398
			c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398c0.133,0.265,0.265,0.265,0.53,0.265L163.002,405.385L163.002,405.385z"
          />
          <path
            fill={textColor}
            d="M170.158,400.879c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
			c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
			c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
			c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0c0.265,0,0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
			c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
			c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.53-0.663-1.06-1.06-1.458-1.723
			c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
			c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
			C170.026,401.144,170.026,401.144,170.158,400.879L170.158,400.879z"
          />
          <path
            fill={textColor}
            d="M183.411,400.879c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
			c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
			c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
			c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0s0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
			c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
			c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.53-0.663-1.06-1.06-1.458-1.723
			c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
			c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
			C183.278,401.144,183.278,401.144,183.411,400.879L183.411,400.879z"
          />
          <path
            fill={textColor}
            d="M199.578,406.843L199.578,406.843c0-1.06-0.133-2.12-0.133-3.181c0-1.325-0.398-0.928-1.59-0.928
			c-0.53,0-1.06,0.133-1.325,0.133c-0.133,0-0.133,0-0.265-0.133c-0.133-0.133-0.265-0.133-0.53-0.133
			c-0.133-0.265-0.133-1.193-0.133-1.458c0-0.133,0-0.265,0.133-0.265c0.265,0,0.398,0,0.53,0c0.928,0,1.855,0,2.783,0.265
			c0.265-0.133,0.53-0.133,0.795-0.133c0.398,0,0.795,0.133,1.325,0.133c0.265,0,0.53-0.133,0.663-0.133c0.53,0,2.915,0,3.048,0.265
			c0,0.265,0,0.53,0,0.795c0,0.265,0,0.53-0.133,0.663c-0.663,0.265-1.59,0.133-2.253,0.133c-0.133,0-1.193,0-1.325,0.133
			c-0.133,0.265-0.133,3.976-0.133,4.638c0,0.795,0.795,1.193,1.458,1.193c0.265,0,0.663-0.133,0.928-0.133s0.53,0.133,0.663,0.265
			c0,0.133,0.133,0,0.133,0v0.265c0,0.398,0.133,0.795,0.133,1.193c0,0.133,0,0.265-0.133,0.265c-0.53,0-0.928-0.133-1.458-0.265
			c-1.988,0-3.976,0-5.964,0c-0.663,0-1.193,0-1.193-0.795c0-1.06,0.663-0.928,1.458-0.928c0.53,0,0.928,0,1.325,0
			c0.133,0,0.398,0,0.53,0c0.133,0,0.265-0.133,0.398-0.265c0.133-0.398,0.133-0.795,0.133-1.193
			C199.578,407.108,199.578,406.975,199.578,406.843z"
          />
          <path
            fill={textColor}
            d="M209.385,400.879c0.265,0,0.53,0,0.928,0c0.265,0,0.53,0,0.795,0
			c0.398,0.398,0.663,1.06,0.928,1.458c0.795,0.928,1.458,1.855,2.253,2.783c0.398,0.398,0.663,0.928,1.193,1.325
			c0.265,0.265,0.53,0.398,0.663,0.663l0.133,0.133c0.398,0,0.265-0.928,0.265-1.193c0-1.06,0.133-2.253,0.133-3.313
			c0-0.53,0-1.193-0.133-1.723v-0.133c0.265,0,0.663,0,0.928,0s0.53,0.133,0.663,0.398c0.265,0.795,0.133,1.855,0.133,2.783
			c0,1.723,0,3.578,0,5.301c0,0.398,0,0.928,0,1.325c-0.133,0.133-0.265,0.133-0.398,0.133c-0.398,0-0.795-0.265-1.193-0.265
			c-0.265,0-0.53-0.133-0.663-0.398c-0.398-0.663-0.928-1.193-1.458-1.855c-0.398-0.663-1.06-1.06-1.458-1.723
			c-0.133-0.265-1.193-1.723-1.325-1.723c-0.398,0.265-0.53,3.181-0.53,3.711c0,0.265,0,0.53,0,0.795l0,0l0,0v0.133
			c0,0.265,0,0.53-0.265,0.663c-0.133,0-0.928,0-1.193,0c-0.53,0-0.928-0.133-0.928-0.663c0-2.915,0-5.831,0-8.614
			C209.252,401.144,209.252,401.144,209.385,400.879L209.385,400.879z"
          />
          <path
            fill={textColor}
            d="M221.444,405.385c0-0.53,0.265-1.723,0.795-1.988c0-0.398,0.398-0.53,0.53-0.795
			c0.265-0.398,0.53-0.53,0.795-0.795c1.325-0.795,2.65-0.795,4.108-0.795c1.06,0,2.253-0.133,3.313-0.133
			c0.265,0,0.265,0.398,0.265,0.53c0,0.398-0.133,0.928-0.133,1.325c0,0.133-0.133,0.265-0.265,0.398c-0.133,0-0.398,0-0.53,0
			c-0.398,0-0.795,0-1.193,0c-0.398,0-0.795-0.133-1.325-0.133c-0.133,0-0.398,0-0.53-0.133c-0.398,0.133-0.795,0-1.193,0
			c-1.458,0-3.048,0.663-3.048,2.12c0,0.265,0,1.193,0.133,1.458c0.133,0.265,0.53,1.06,0.795,1.325
			c0.53,0.663,1.325,0.795,2.12,0.795c0.53,0,1.193-0.265,1.723-0.265c0.265,0,0.53,0.133,0.795,0.133s0.928-0.133,0.928-0.53
			c0-0.265-0.133-0.663-0.265-0.928c-0.133-0.53-0.795-0.53-1.193-0.53c-0.53,0-0.928,0.265-1.458,0.265
			c-0.133,0-0.265-0.133-0.265-0.265c0-0.133,0.133-1.193,0.265-1.325c0.133-0.133,0.53-0.133,0.795-0.133
			c0.795,0,1.723,0.133,2.65,0.133c0.265,0,0.53-0.133,0.663-0.133h0.133c0.133,0.398,0.265,1.06,0.265,1.59
			c0,0.265-0.133,0.663-0.133,0.928c0,0.398,0.133,0.795,0.133,1.193c0,0.265,0,0.398,0,0.53c0,0.265,0.133,0.398,0.133,0.663
			c0,0.53-1.193,0.398-1.458,0.398s-0.53,0.133-0.663,0.133c-0.663-0.133-1.325,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133
			c-0.663,0-1.988-0.265-2.65-0.53c-0.53-0.265-0.928-0.795-1.458-1.193C221.975,407.505,221.444,406.445,221.444,405.385z"
          />
        </g>
        <g id="Park">
          <path
            fill={textColor}
            d="M164.592,425.529L164.592,425.529c-0.265,0.133-0.398,0.133-0.663,0.133c-0.398,0-0.795,0-1.325,0
			c-0.133-0.265-0.133-0.663-0.133-1.06s0.133-0.795,0.133-1.06c0-0.133-0.133-0.265-0.133-0.53v-1.193
			c0-1.06,0.133-2.12,0.133-3.181c0-0.398,0-0.663,0-1.06s-0.133-1.06,0.133-1.325h0.133c0.265,0,0.265,0,0.53-0.133
			c0.133,0,0.265,0.133,0.53,0.133c0.398,0,0.795-0.133,1.325-0.133c0.133,0,1.59,0,1.59,0c0.133,0.133,0.398,0.133,0.53,0.133
			c0.398,0,0.795-0.133,1.193-0.133c0.133,0,0.265,0.133,0.53,0.133c0.133,0,0.133,0,0.265,0c0.265,0,0.398,0.133,0.663,0.133
			c0.398,0.398,1.06,0.265,1.458,0.663c0.133,0.133,0.265,0.133,0.398,0.265c0.265,0.53,0.398,1.193,0.398,1.723
			c0,0.53-0.133,0.928-0.265,1.325c0,0.133-0.133,0.265-0.133,0.398c-0.133,0.795-1.325,1.06-1.988,1.06c-0.398,0-0.928,0-1.325,0
			c-0.53,0.265-2.385,0.133-3.048,0.133c-0.265,0-0.398,0.133-0.53,0.265c-0.133,0.265-0.133,1.06-0.265,1.325
			c0.133,0,0.133,0.133,0.133,0.265v1.723H164.592z M164.725,420.493L164.725,420.493c0.133,0,0.398-0.133,0.398-0.265h0.398
			c0.265,0,0.53,0,0.663,0.133h0.133c0.133-0.133,2.12-0.133,2.518-0.133c0.133,0,0.265,0,0.398,0.133h0.133
			c0.398-0.398,1.193-0.265,1.193-1.193c0-1.193-2.12-1.458-3.048-1.458c-0.53,0-1.193,0.133-1.855,0.133
			c-1.59,0-0.928,0.53-1.193,1.59L164.725,420.493z"
          />
          <path
            fill={textColor}
            d="M182.748,425.794c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.133-0.398-0.133-0.795
			c0-0.53-0.133-1.193-0.133-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
			c-0.133,0.133-0.133,1.193-0.133,1.458c0,0.265,0.133,0.398,0.133,0.663c0,0.663,0,1.06-0.795,1.06
			c-0.398,0-0.663-0.133-0.928-0.133c-0.133,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06
			c0-0.265-0.133-0.53-0.133-0.663c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855
			c0.133-0.398,0.265-1.325,0.53-1.723c1.458-2.518,5.301-3.181,7.951-3.181c0.53,0,0.398,0.663,0.398,1.06
			c0,1.06-0.133,2.253-0.133,3.313c0,1.723-0.133,3.313-0.133,4.903c0,0.265-0.133,0.398-0.398,0.398H182.748z M181.82,420.758
			c0.265,0,0.398-0.133,0.663-0.398c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133
			c-0.133-0.133-0.265-0.398-0.398-0.53c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.928,0.398
			c-0.398,0.265-1.193,1.06-1.458,1.325l-0.133,0.398c0.133,0.265,0.265,0.265,0.53,0.265L181.82,420.758L181.82,420.758z"
          />
          <path
            fill={textColor}
            d="M190.699,425.263c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
			c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
			c0.398,0,0.795,0,1.325,0c0.53,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.133,0,0.133,0,0.265,0
			c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
			c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
			s-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
			c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.133,0.663-0.133,0.795l0.265,0.398v1.06H190.699z
			 M196.133,418.505c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.133
			c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
			c1.06,0,2.915,0,2.915-0.663C196.265,419.035,196.133,418.77,196.133,418.505z"
          />
          <path
            fill={textColor}
            d="M203.952,422.878c-0.133,0-0.265,0-0.265,0.133l0.133,0.133c-0.265,0.53,0.133-0.265-0.133,1.06
			c0,0.53,0,1.06,0,1.59c0,0.398-0.928,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.133-0.133,0.133-0.265
			v-0.133c-0.133-0.265-0.133-0.53-0.133-0.928v-1.193c0-0.265,0.133-0.53,0.133-0.795c0-0.795-0.133-1.723-0.133-2.65
			c0-0.398,0-0.663,0.133-1.193c-0.133-0.265-0.265-0.53-0.265-0.795v-0.133c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
			c0.133,0.133,0.133,0.133,0.133,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.133,0.53-0.133,1.06
			c0,0.133-0.133,0.265-0.133,0.53c0,0.265,0.133,0.398,0.133,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
			c0.398-0.398,1.193-1.06,1.458-1.458c0.133-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.928-0.133h0.663l0.133,0.133
			c0,0.133-0.795,0.928-0.928,1.06c-0.53,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
			c0.795,1.06,2.915,3.843,3.843,5.168l-0.133,0.133c-0.133,0-0.398,0-0.53,0c-0.53,0-0.928,0-1.458,0h-0.398
			c-0.133-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.133-0.265
			C204.879,421.818,204.084,422.613,203.952,422.878L203.952,422.878z"
          />
        </g>
      </g>
      <g id="Elkford">
        <path
          fill={textColor}
          d="M1666.333,8.481c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.783-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.132-0.928-0.132-2.12-0.132-3.048c0-0.53-0.132-1.193-0.132-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.133-0.133-0.133-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133
		c0.265,0,0.663-0.133,0.928-0.133c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.132,1.193-0.265,1.458h-0.663
		c-0.265,0-0.53,0-0.795,0.265c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265
		c-0.133,0.133-0.133,0.795-0.133,0.928c0.133,0.133,0,0.398,0.133,0.663c0.132,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265
		c0.663,0,1.458,0,2.12-0.133c0.265,0,0.53-0.133,0.663-0.133s0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663s0.132,0.53,0.132,0.795
		v0.133l-0.132,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133
		s-0.663,0-0.795,0.265c0,0.133-0.133,0.398-0.133,0.53c0,0.265,0.133,0.53,0.265,0.795c0.53,0.398,0.928,0.265,1.59,0.265
		c0.928,0,1.59,0.133,2.385,0C1664.345,8.349,1666.068,8.349,1666.333,8.481L1666.333,8.481z"
        />
        <path
          fill={textColor}
          d="M1670.309,6.891c0-1.59,0.132-3.048,0.132-4.638c0-0.265,0.132-0.398,0.132-0.663
		c0-0.398-0.132-0.795-0.132-1.193V0.265c0.132-0.133,0.795-0.133,1.06-0.133c0.398,0,0.663,0.133,1.06,0.265
		c0,0.133,0,0.133,0.132,0.265c-0.132,0.398,0,0.928,0,1.325c0,0.133,0.133,0.265,0.133,0.53c0,0.133-0.133,0.398-0.133,0.53
		c0,0.133,0,0.265,0.133,0.398v0.133c-0.133,0.265-0.133,0.398-0.133,0.663c0,0-0.132,0.133-0.132,0.265
		c0,0.133,0.132,0.265,0.132,0.398s0,0.53,0.133,0.663c-0.133,0.398,0,0.795,0,1.193c0,0.265-0.133,0.53-0.133,0.663
		c0,0.928,0.53,0.663,1.193,0.663c0.795,0,1.59,0.133,2.385,0.133c0.132,0,0.398,0,0.53-0.133c0.398,0.133,0.795,0.133,1.325,0.133
		c0.928,0,1.458-0.265,1.458,0.663c0,0.265-0.133,1.325-0.398,1.325c-0.398,0-0.663-0.265-1.06-0.265c-0.133,0-0.398,0-0.53,0
		c-0.265-0.133-0.53,0-0.795,0c-0.132,0-0.265-0.133-0.53-0.133c-0.663,0-1.325,0.133-1.988,0.133c-0.265,0-0.663,0-0.927,0
		c-0.133,0-0.53,0.133-0.53,0.133c-0.265,0-0.53-0.133-0.795-0.133c-0.53,0-0.927,0.133-1.325,0.265
		c-0.398-0.133-0.398-0.398-0.398-0.663c0-0.663,0.132-1.193,0.132-1.855C1670.441,7.421,1670.309,7.156,1670.309,6.891z"
        />
        <path
          fill={textColor}
          d="M1685.681,7.024c-0.133,0-0.265,0-0.265,0.133l0.132,0.133c-0.265,0.53,0.133-0.265-0.132,1.06
		c0,0.53,0,1.06,0,1.59c0,0.398-0.928,0.265-1.325,0.265h-0.53c-0.53,0-0.398-1.325-0.398-1.723c0-0.133,0.133-0.133,0.133-0.265
		V8.084c-0.133-0.265-0.133-0.53-0.133-0.928V5.963c0-0.265,0.133-0.53,0.133-0.795c0-0.795-0.133-1.723-0.133-2.65
		c0-0.398,0-0.663,0.133-1.193c-0.133-0.265-0.265-0.53-0.265-0.795V0.398c0.265-0.133,0.53-0.133,0.663-0.133l1.723,0.133
		c0.132,0.133,0.132,0.133,0.132,0.265c0,0.398-0.132,0.795-0.132,1.193c0,0.133,0,0.398,0,0.53c0,0.53-0.133,0.53-0.133,1.06
		c0,0.133-0.132,0.265-0.132,0.53s0.132,0.398,0.132,0.795v0.133l0.795-0.795c0.398-0.53,1.325-1.458,1.855-1.855
		c0.398-0.398,1.193-1.06,1.458-1.458c0.133-0.265,0.663-0.133,1.06-0.133c0.265,0,0.663-0.133,0.928-0.133h0.663l0.132,0.133
		c0,0.133-0.795,0.928-0.928,1.06c-0.663,0.398-1.06,1.06-1.458,1.458c-0.265,0.265-1.458,1.193-1.723,1.59
		c0.795,1.06,2.915,3.843,3.843,5.168l-0.133,0.133c-0.132,0-0.398,0-0.53,0c-0.53,0-0.927,0-1.458,0h-0.398
		c-0.132-0.265-1.59-2.518-1.723-2.65c-0.265-0.265-0.663-0.795-0.795-1.193l-0.132-0.265
		C1686.609,6.096,1685.814,6.891,1685.681,7.024L1685.681,7.024z"
        />
        <path
          fill={textColor}
          d="M1698.138,3.181v0.133c0,0.53,0.133,1.06,0.265,1.59c0.398,0,0.132,0,0.928,0
		c0.132,0,0.663,0.133,0.795,0.133c0.133,0,0.265-0.133,0.53-0.133c0.265,0,0.398-0.133,0.663-0.133c0.53,0,1.06,0,1.59-0.133
		c0.132,0.133,0.132,0.133,0.132,0.265c0,0.663,0,1.193-0.398,1.723l-0.265,0.133c-0.265,0-0.398-0.133-0.663-0.133
		s-0.928,0.133-1.458,0.133c-0.53,0-2.253-0.133-2.12,0.53c0,0.133,0,0.265,0,0.398c0,0.398-0.132,0.795-0.132,1.325
		c0,0.265,0.132,0.398,0.132,0.663c0,0.133,0,0.398,0,0.53c-0.398,0.133-0.928,0.133-1.193,0.133c-0.265,0-0.398-0.133-0.53-0.133
		c-0.132-0.265,0-0.53,0-0.928c0-0.133,0-0.265,0-0.398c0.133-1.325,0.133-2.783,0.133-4.108c0-0.795-0.133-1.59-0.133-2.385
		l0.133-0.265c-0.133-0.133-0.265-0.398-0.265-0.53c0-0.265,0-0.53,0.132-0.795c0.133,0,0.265,0,0.53,0c0.398,0,0.928,0,1.325,0.133
		c0.265-0.133,0.663-0.133,0.928-0.133h1.59c0.132,0,0.398,0.133,0.53,0.133c0.53,0,1.193-0.133,1.723-0.133
		c0.133,0,0.265,0.133,0.398,0.133c0.132-0.133,0.265-0.133,0.398-0.133c0.132,0,0.265,0,0.53,0.133
		c0.132,0,0.398-0.133,0.663-0.133c0.398,0,0.398,1.193,0.398,1.458c0,0.265-0.132,0.53-0.398,0.663h-0.132
		c-0.398-0.265-1.06-0.133-1.59-0.133C1702.379,2.65,1699.066,2.915,1698.138,3.181L1698.138,3.181z"
        />
        <path
          fill={textColor}
          d="M1708.74,4.506c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.783-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.132,0.133-0.132,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.132,0-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C1708.608,5.433,1708.74,4.903,1708.74,4.506z M1713.643,8.746c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59s0-0.928-0.133-1.325c-0.265-0.265-0.53-1.325-0.795-1.458
		c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265c-0.53,0-1.325,0.133-1.855,0.265
		c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385c0,0.133-0.132,0.265-0.132,0.53
		c0,0.398,0.53,1.59,0.795,1.723C1712.583,8.084,1712.716,8.746,1713.643,8.746z"
        />
        <path
          fill={textColor}
          d="M1724.245,9.409c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.132-3.313,0.132-5.036c0-1.06-0.132-2.12-0.132-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0c0.663,0,1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.928,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.133,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		c-0.265,0-0.53,0.133-0.663,0.133c-0.133,0-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048
		c-1.325-1.325-1.723-1.325-2.253-1.325c-0.132,0.133,0,0.265,0,0.398c-0.132,0.398-0.132,0.663-0.132,1.06
		c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H1724.245z M1729.679,2.65c-0.265-0.133-0.53-0.398-0.795-0.53
		c-0.398-0.133-2.12-0.133-2.651-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06
		c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133c1.06,0,2.916,0,2.916-0.663
		C1729.811,3.313,1729.679,2.915,1729.679,2.65z"
        />
        <path
          fill={textColor}
          d="M1735.377,7.156c0-0.398,0.132-0.663,0.132-1.06c-0.132-0.133-0.132-0.398-0.132-0.53
		c0-1.458-0.265-2.915-0.265-4.373c0-0.133,0.132-0.265,0.265-0.265c0.132,0,0.265,0,0.398,0c0.53,0,1.06,0,1.59-0.133
		c0.928-0.133,2.12-0.133,3.048-0.133c1.06,0,1.988,0.398,2.783,0.928c0.663,0.398,0.663,0.928,1.193,1.458
		c0.398,0.398,0.53,1.193,0.53,1.723s-0.132,1.06,0,1.59c-0.132,0.265-0.132,0.663-0.132,0.928c0,0.133,0,0.133-0.133,0.265
		c-0.132,0.133-0.265,0.398-0.398,0.53c-0.398,0.663-1.59,1.06-2.385,1.458c-0.795,0-1.723,0.265-2.518,0.265
		c-0.265,0-0.398,0.133-0.663,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.53,0.265-1.193,0.265-1.855,0.265
		c-0.132,0-0.398,0-0.398-0.133C1735.377,8.879,1735.377,8.084,1735.377,7.156z M1740.28,8.084c1.59,0,2.518-1.193,2.518-2.65
		c0-1.06-0.398-1.59-1.06-2.385c-0.265,0-0.398-0.133-0.663-0.265h-0.132c-0.265,0-0.53-0.133-0.795-0.133
		c-0.133,0-0.133,0-0.265-0.133l-0.265-0.398c-0.132,0-0.663-0.133-0.663-0.133c-1.06,0-1.855,0.265-1.855,1.458
		c0,0.398,0.132,2.385,0.265,2.783c0,0.265-0.133,0.398-0.133,0.663c0,0.398,0,0.663,0.133,1.06c0.132,0,0.132,0.133,0.265,0.133
		C1738.558,8.084,1739.485,8.084,1740.28,8.084z"
        />
      </g>
      <g id="AlbertaBorder">
        <path
          fill={textColor}
          d="M2041.901,63.081c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.132-0.398-0.132-0.795
		c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.132,0.133-0.132,1.193-0.132,1.458s0.132,0.398,0.132,0.663c0,0.663,0,1.06-0.795,1.06c-0.398,0-0.663-0.133-0.928-0.133
		c-0.132,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06c0-0.265-0.133-0.53-0.133-0.663
		c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855c0.133-0.398,0.265-1.325,0.53-1.723
		c1.458-2.518,5.301-3.181,7.951-3.181c0.663,0,0.398,0.53,0.398,1.06c0,1.06-0.133,2.253-0.133,3.313
		c0,1.723-0.132,3.313-0.132,4.903c0,0.265-0.132,0.398-0.398,0.398H2041.901z M2040.973,58.045c0.265,0,0.398-0.133,0.663-0.398
		c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133c-0.132-0.133-0.265-0.398-0.398-0.53
		c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.927,0.398c-0.398,0.265-1.193,1.06-1.458,1.325l-0.132,0.398
		c0.132,0.265,0.265,0.265,0.53,0.265L2040.973,58.045L2040.973,58.045z"
        />
        <path
          fill={textColor}
          d="M2034.612,78.586c0-1.59,0.132-3.048,0.132-4.638c0-0.265,0.132-0.398,0.132-0.663
		c0-0.398-0.132-0.795-0.132-1.193V71.96c0.132-0.133,0.795-0.133,1.06-0.133c0.398,0,0.663,0.133,1.06,0.265
		c0,0.133,0,0.133,0.132,0.265c-0.132,0.398,0,0.928,0,1.325c0,0.133,0.133,0.265,0.133,0.53c0,0.133-0.133,0.398-0.133,0.53
		s0,0.265,0.133,0.398v0.133c-0.133,0.265-0.133,0.398-0.133,0.663c0,0-0.132,0.133-0.132,0.265s0.132,0.265,0.132,0.398
		c0,0.133,0,0.53,0.133,0.663c-0.133,0.398,0,0.795,0,1.193c0,0.265-0.133,0.53-0.133,0.663c0,0.928,0.53,0.663,1.193,0.663
		c0.795,0,1.59,0.133,2.385,0.133c0.132,0,0.398,0,0.53-0.133c0.398,0.133,0.795,0.133,1.325,0.133c0.928,0,1.458-0.265,1.458,0.663
		c0,0.265-0.132,1.325-0.398,1.325c-0.398,0-0.663-0.265-1.06-0.265c-0.133,0-0.398,0-0.53,0c-0.265-0.133-0.53,0-0.795,0
		c-0.132,0-0.265-0.133-0.53-0.133c-0.663,0-1.325,0.133-1.988,0.133c-0.265,0-0.663,0-0.928,0c-0.132,0-0.53,0.133-0.53,0.133
		c-0.265,0-0.53-0.133-0.795-0.133c-0.53,0-0.928,0.133-1.325,0.265c-0.398-0.133-0.398-0.398-0.398-0.663
		c0-0.663,0.132-1.193,0.132-1.855C2034.745,79.116,2034.612,78.851,2034.612,78.586z"
        />
        <path
          fill={textColor}
          d="M2034.877,100.584L2034.877,100.584c-0.132-0.928-0.132-1.723-0.132-2.518
		c0-1.988-0.132-4.108-0.132-6.096c0-0.928,0.265-0.795,1.06-0.795c0.265,0,0.53,0.133,0.663,0.133c0.663,0,1.193-0.133,1.855-0.133
		c0.928,0,4.108,0.265,4.771,0.928c0.265,0.398,0.53,0.795,0.795,1.193c0.265,0.265,0.265,0.663,0.265,1.06
		c0,0.795-0.398,1.06-0.398,1.325c0,0.398,0.795,1.06,0.795,2.385c0,2.518-1.988,2.783-4.108,2.783
		c-0.795,0-1.723-0.265-2.518-0.265c-0.663,0-1.325,0.133-1.988,0.133h-0.928V100.584z M2041.901,94.886
		c0.398,0,0.53-0.265,0.53-0.663c0-0.265,0-0.53-0.265-0.663c-0.53-0.398-2.783-0.53-3.446-0.53s-1.458,0-2.12,0.133
		c0,0.133,0,0.265-0.132,0.53c0.398,0.928-0.663,1.59,1.06,1.59C2038.72,95.151,2040.708,95.018,2041.901,94.886z M2037.263,98.862
		c0.53,0,1.193,0,1.723,0c1.06,0,2.253,0.133,3.313,0c0.265-0.265,0.53-0.265,0.53-0.663c-0.132-0.265,0-0.663-0.132-0.795
		c-1.193-0.928-0.928-0.663-2.518-0.663c-0.928,0-1.988,0.133-2.916,0.133c-0.265,0-0.398,0.133-0.53,0.265
		c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0.133,0.53C2036.865,98.862,2037.13,98.862,2037.263,98.862z"
        />
        <path
          fill={textColor}
          d="M2043.756,117.415c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.916-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.132-1.193-0.132-1.723
		c0-0.265,0-0.663,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.132-0.133-0.132-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133s0.663-0.133,0.928-0.133
		c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398c0,0.398-0.133,1.193-0.265,1.458h-0.663c-0.265,0-0.53,0-0.795,0.265
		c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265c-0.132,0.133-0.132,0.795-0.132,0.928
		c0.132,0.133,0,0.398,0.132,0.663c0.132,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265c0.663,0,1.458,0,2.12-0.133
		c0.265,0,0.53-0.133,0.663-0.133c0.133,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663c0,0.265,0.132,0.53,0.132,0.795v0.133
		l-0.132,0.133c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133
		c-0.265,0-0.663,0-0.795,0.265c0,0.133-0.132,0.398-0.132,0.53c0,0.265,0.132,0.53,0.265,0.795c0.53,0.398,0.928,0.265,1.59,0.265
		c0.928,0,1.59,0.133,2.385,0C2041.768,117.282,2043.491,117.282,2043.756,117.415L2043.756,117.415z"
        />
        <path
          fill={textColor}
          d="M2036.732,137.028c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0s1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.927,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.132,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		s-0.53,0.133-0.663,0.133s-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
		c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H2036.732z
		 M2042.166,130.269c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132
		c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
		c1.06,0,2.915,0,2.915-0.663C2042.431,130.799,2042.298,130.534,2042.166,130.269z"
        />
        <path
          fill={textColor}
          d="M2038.455,152.798c0-0.663,0-3.446-0.265-3.843c-0.132-0.398-0.398-0.398-0.663-0.398
		c-0.53,0-1.06,0-1.59,0c-0.398,0-0.663-0.265-1.06-0.398v-0.133c0-0.53,0-0.928,0-1.458c0-0.133,0.133-0.265,0.133-0.265
		c0.795,0,1.723,0.133,2.518,0.133c0.265,0,0.53,0,0.795,0.133c0.265,0,0.53,0,0.663,0c0.398,0,0.795,0.133,1.193,0.133
		c0.265,0,0.53-0.133,0.795-0.133c0.928,0,1.855,0,2.783,0c0.133,0,0.265,0,0.265,0.133c0,0.265,0,0.53,0,0.795
		c0,0.265,0,0.53-0.132,0.663c-0.663,0.265-1.59,0.133-2.385,0.133c-0.398,0-1.06-0.133-1.193,0.398
		c-0.265,0.928-0.265,4.373-0.265,5.301c0,0.133-0.133,0.265-0.133,0.53s0.133,0.53,0.133,0.795c0,0.663,0,0.795-0.663,0.795
		c-0.398,0-0.795-0.133-1.193-0.133c-0.133-0.398-0.133-0.795-0.133-1.193C2038.323,153.991,2038.455,153.328,2038.455,152.798z"
        />
        <path
          fill={textColor}
          d="M2041.901,174.797c-0.133,0-0.265,0-0.398,0c-0.265-0.398-0.132-0.398-0.132-0.795
		c0-0.53-0.132-1.193-0.132-1.723c0-0.795-0.133-1.06-0.928-1.06c-0.53,0-3.711,0.265-3.976,0.53
		c-0.132,0.133-0.132,1.193-0.132,1.458s0.132,0.398,0.132,0.663c0,0.663,0,1.06-0.795,1.06c-0.398,0-0.663-0.133-0.928-0.133
		c-0.132,0-0.265-0.133-0.265-0.265c0-0.398,0.133-0.663,0.133-1.06c0-0.265-0.133-0.53-0.133-0.663
		c0-0.265,0.265-0.663,0.133-0.928c0-0.663,0.265-1.193,0.265-1.855c0.133-0.398,0.265-1.325,0.53-1.723
		c1.458-2.518,5.301-3.181,7.951-3.181c0.663,0,0.398,0.663,0.398,1.06c0,1.06-0.133,2.253-0.133,3.313
		c0,1.723-0.132,3.313-0.132,4.903c0,0.265-0.132,0.398-0.398,0.398H2041.901z M2040.973,169.761c0.265,0,0.398-0.133,0.663-0.398
		c0-0.398-0.133-0.663-0.133-1.06c0-0.133,0-0.265,0-0.398c0.133-0.133,0.265,0,0.265-0.133c-0.132-0.133-0.265-0.398-0.398-0.53
		c-0.663,0.133-1.325,0.133-1.988,0.133c-0.265,0.133-0.53,0.398-0.927,0.398c-0.398,0.265-1.193,1.06-1.458,1.325l-0.132,0.398
		c0.132,0.265,0.265,0.265,0.53,0.265H2040.973z"
        />
        <path
          fill={textColor}
          d="M2034.877,204.614L2034.877,204.614c-0.132-0.928-0.132-1.723-0.132-2.518
		c0-1.988-0.132-4.108-0.132-6.096c0-0.928,0.265-0.795,1.06-0.795c0.265,0,0.53,0.133,0.663,0.133c0.663,0,1.193-0.133,1.855-0.133
		c0.928,0,4.108,0.265,4.771,0.928c0.265,0.398,0.53,0.795,0.795,1.193c0.265,0.265,0.265,0.663,0.265,1.06
		c0,0.795-0.398,1.06-0.398,1.325c0,0.398,0.795,1.06,0.795,2.385c0,2.518-1.988,2.783-4.108,2.783
		c-0.795,0-1.723-0.265-2.518-0.265c-0.663,0-1.325,0.133-1.988,0.133h-0.928V204.614z M2041.901,198.916
		c0.398,0,0.53-0.265,0.53-0.663c0-0.265,0-0.53-0.265-0.663c-0.53-0.398-2.783-0.53-3.446-0.53s-1.458,0-2.12,0.133
		c0,0.133,0,0.265-0.132,0.53c0.398,0.928-0.663,1.59,1.06,1.59C2038.72,199.181,2040.708,199.048,2041.901,198.916z
		 M2037.263,202.891c0.53,0,1.193,0,1.723,0c1.06,0,2.253,0.133,3.313,0c0.265-0.265,0.53-0.265,0.53-0.663
		c-0.132-0.265,0-0.663-0.132-0.795c-1.193-0.928-0.928-0.663-2.518-0.663c-0.928,0-1.988,0.133-2.916,0.133
		c-0.265,0-0.398,0.133-0.53,0.265c0,0.398-0.133,0.795-0.133,1.193c0,0.133,0,0.398,0.133,0.53
		C2036.865,202.891,2037.13,202.891,2037.263,202.891z"
        />
        <path
          fill={textColor}
          d="M2034.347,217.336c0-0.398,0.53-1.988,0.663-2.385c1.06-0.928,2.915-2.12,4.241-2.12
		c1.723,0,2.783,0.53,3.843,1.723c0.53,1.59,1.193,2.12,1.193,3.976v0.795c0,0.398-0.265,0.663-0.398,1.06
		c0,0.133-0.133,0.133-0.133,0.265c-0.265,0.398-0.53,0.795-0.795,1.193c-0.663,0.928-2.385,1.59-3.578,1.59
		c-0.53,0-1.59-0.53-1.723-0.53c-0.132,0-0.132,0-0.265,0c-0.53,0-1.458-0.53-1.855-0.795c-0.663-0.398-1.458-2.65-1.458-3.446
		C2034.214,218.396,2034.347,217.866,2034.347,217.336z M2039.25,221.71c0.53,0,1.193-0.265,1.723-0.398
		c0.265-0.398,0.795-0.663,0.928-1.193c0.265-0.265,0.53-1.193,0.53-1.59s0-0.928-0.133-1.325c-0.265-0.265-0.663-1.325-0.795-1.458
		c-0.265-0.265-0.663-0.398-0.928-0.663c-0.398-0.133-0.663-0.265-0.928-0.265c-0.53,0-1.325,0.133-1.855,0.265
		c-0.265,0.398-0.663,0.795-0.928,1.193c-0.265,0.398-0.398,1.855-0.398,2.385c0,0.133-0.132,0.265-0.132,0.53
		c0,0.398,0.53,1.59,0.795,1.723C2038.19,220.914,2038.323,221.71,2039.25,221.71z"
        />
        <path
          fill={textColor}
          d="M2036.732,241.058c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0s1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.927,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.132,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		s-0.53,0.133-0.663,0.133s-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
		c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H2036.732z
		 M2042.166,234.299c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132
		c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
		c1.06,0,2.915,0,2.915-0.663C2042.431,234.829,2042.298,234.432,2042.166,234.299z"
        />
        <path
          fill={textColor}
          d="M2034.745,257.358c0-0.398,0.132-0.663,0.132-1.06c-0.132-0.133-0.132-0.398-0.132-0.53
		c0-1.458-0.265-2.915-0.265-4.373c0-0.133,0.133-0.265,0.265-0.265s0.265,0,0.398,0c0.53,0,1.06,0,1.59-0.133
		c0.928-0.133,2.12-0.133,3.048-0.133c1.06,0,1.988,0.398,2.783,0.928c0.663,0.398,0.663,0.928,1.193,1.458
		c0.398,0.398,0.53,1.193,0.53,1.723c0,0.53-0.133,1.06,0,1.59c-0.133,0.265-0.133,0.663-0.133,0.928c0,0.133,0,0.133-0.132,0.265
		c-0.133,0.133-0.265,0.398-0.398,0.53c-0.398,0.663-1.59,1.06-2.385,1.458c-0.795,0-1.723,0.265-2.518,0.265
		c-0.265,0-0.398,0.133-0.663,0.133c-0.398,0-0.795-0.133-1.193-0.133c-0.53,0.265-1.193,0.265-1.855,0.265
		c-0.133,0-0.398,0-0.398-0.133C2034.612,259.081,2034.745,258.153,2034.745,257.358z M2039.648,258.153
		c1.59,0,2.518-1.193,2.518-2.65c0-1.06-0.398-1.59-1.06-2.385c-0.265,0-0.398-0.133-0.663-0.265h-0.132
		c-0.265,0-0.53-0.133-0.795-0.133c-0.132,0-0.132,0-0.265-0.133l-0.265-0.398c-0.133,0-0.663-0.133-0.663-0.133
		c-1.06,0-1.855,0.265-1.855,1.458c0,0.398,0.132,2.385,0.265,2.783c0,0.265-0.132,0.398-0.132,0.663c0,0.398,0,0.663,0.132,1.06
		c0.133,0,0.133,0.133,0.265,0.133C2037.925,258.153,2038.72,258.153,2039.648,258.153z"
        />
        <path
          fill={textColor}
          d="M2043.756,277.236c0,1.59,0,1.59-1.855,1.59c-1.325-0.133-2.916-0.133-4.241-0.133
		c-0.795,0-1.723,0-2.518,0c-0.265,0-0.265-0.265-0.398-0.398c-0.133-0.928-0.133-2.12-0.133-3.048c0-0.53-0.132-1.193-0.132-1.723
		c0-0.265,0-0.53,0-0.928c0-0.795,0-1.59,0-2.385c0-0.133,0-0.133,0-0.265c0,0-0.132-0.133-0.132-0.265
		c0-0.265,0.53-0.265,0.663-0.265c0.265,0,1.458,0.133,1.59,0h0.265c0.265,0,0.53,0.133,0.795,0.133s0.663-0.133,0.928-0.133
		c1.59,0,3.048,0,4.638,0c0.265,0,0.398,0,0.398,0.398s-0.133,1.193-0.265,1.458h-0.663c-0.265,0-0.53,0-0.795,0.265
		c-0.398,0-0.663,0-1.06,0c-0.663,0-1.325-0.133-1.988-0.133s-1.458,0-2.12,0.265c-0.132,0.133-0.132,0.795-0.132,0.928
		c0.132,0.133,0,0.398,0.132,0.663c0.132,0,0.265,0,0.53,0c0.265,0,0.53,0.265,0.928,0.265c0.663,0,1.458,0,2.12-0.133
		c0.265,0,0.53-0.133,0.663-0.133c0.133,0,0.398,0,0.53,0.265c0,0.265,0,0.398,0,0.663s0.132,0.53,0.132,0.795v0.133l-0.132,0.133
		c-0.265,0.133-0.663,0.133-0.928,0.133c-0.663,0-1.59,0-1.988,0c-0.265,0-0.53-0.133-0.795-0.133c-0.265,0-0.663,0-0.795,0.265
		c0,0.133-0.132,0.398-0.132,0.53c0,0.265,0.132,0.53,0.265,0.795c0.53,0.398,0.928,0.265,1.59,0.265c0.928,0,1.59,0.133,2.385,0
		C2041.768,277.104,2043.491,277.104,2043.756,277.236L2043.756,277.236z"
        />
        <path
          fill={textColor}
          d="M2036.732,296.85c0,0.398-0.53,0.663-0.795,0.663c-0.663,0-1.06,0-1.06-0.663
		c0-1.723,0.133-3.313,0.133-5.036c0-1.06-0.133-2.12-0.133-3.048c0-0.265,0-0.53,0-0.795c0.53-0.133,0.928-0.133,1.458-0.133
		c0.398,0,0.795,0,1.325,0s1.193,0.133,1.855,0.133c0.795,0,1.59,0,2.385,0c0.132,0,0.132,0,0.265,0
		c0.398,0.265,0.927,0.398,1.325,0.53c0.53,0.53,0.928,0.928,0.928,1.723c0,0.265,0,0.398,0,0.53c0,0.795-0.795,1.723-1.458,2.12
		c-0.132,0.133-0.398,0.133-0.53,0.133c-0.53,0-1.06,0-1.59,0h-0.398c0,0,3.446,3.578,3.446,4.108c0,0.398-0.663,0.265-0.928,0.265
		s-0.53,0.133-0.663,0.133s-0.663,0-0.663,0c-0.265-0.133-1.988-2.518-2.518-3.048c-1.325-1.325-1.723-1.325-2.253-1.325
		c-0.133,0.133,0,0.265,0,0.398c-0.133,0.398-0.133,0.663-0.133,1.06c0,0.133-0.132,0.663-0.132,0.795l0.265,0.398v1.06H2036.732z
		 M2042.166,290.091c-0.265-0.133-0.53-0.398-0.795-0.53c-0.398-0.133-2.12-0.133-2.65-0.133c-0.663,0-1.325,0-1.988,0.133h-0.132
		c0,0.133,0,0.398,0,0.663c0,0.398,0,0.795,0,1.06c0.398,0.133,0.928,0.133,1.325,0.133c0.53,0,0.928,0.133,1.458,0.133
		c1.06,0,2.915,0,2.915-0.663C2042.431,290.621,2042.298,290.356,2042.166,290.091z"
        />
      </g>
    </>
  );
};
// export const RoadPath = ({
//   roadColor = "#f5d522",
//   textColor = "black",
// }: RoadPathProps) => {
//   return (
//     <>
//       <g id="Roads">
//         <path
//           fill={roadColor}
//           d="M1237.7,81.1c-2.4,1.9-4,3.2-5.6,4.4c-1.6,1.3-3.1,2.7-5.3,5.1c-0.5,0.6-1.1,1.2-1.6,1.8
// 						c-0.5,0.6-1,1.3-1.5,1.9c-0.5,0.6-0.9,1.3-1.4,2c-0.2,0.3-0.4,0.7-0.7,1c-0.2,0.3-0.4,0.7-0.6,1.1c-0.3,0.8-0.7,1.8-0.9,2.9
// 						s-0.5,2.2-0.4,3.1l-0.2,0.7c0,0.6,0,1.3-0.1,1.9l0,0c0.1,0.9,0,1.9,0,2.3l0,0l0,1l-0.1-0.2c0,1.5-0.1,3.1-0.2,4.4l0.2-0.1
// 						c-0.4,2.9-0.1,4-0.5,6.7l0,0c0.1,1.7,0,2.9-0.4,4c-0.2,0.5-0.5,1-0.8,1.3c-0.3,0.4-0.8,0.8-1.3,1c0.2-0.1-0.1-0.1-0.1-0.1
// 						c0,0,0,0-0.1,0l-0.2,0c-0.1,0-0.2,0-0.4,0c-0.3-0.1-0.7-0.2-1-0.2c-0.7-0.2-1.3-0.4-1.7-0.4c0.2,0.6,0.4,1.1,0.6,1.6
// 						c0.2,0.4,0.3,0.8,0.4,1.1c0,0.1,0,0.1,0,0.2c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.1,0.1c-0.2,0.4-0.3,0.5-0.4,0.5
// 						c-0.1,0-0.1,0-0.2,0c0.1,0.1-0.4,0.7-1.1,0.9c0.1,0,0.2-0.1,0.3-0.1c-0.5,0.2-1.3,0.2-2.1,0.1c0.1,0.1,0.3,0.2,0.4,0.4
// 						c-1.2-0.3-0.2-0.4-1.5-0.6l-0.1,0.2c-1.8-0.6-2.9-1.3-4.8-1.7c-4-0.5-8.2,0.3-12,1.3c-1.9,0.5-3.8,1-5.6,1.6
// 						c-0.9,0.3-1.9,0.6-2.8,1.3c-0.4,0.3-0.9,0.7-1.3,1.1c-0.4,0.4-0.7,0.9-0.8,1.5c-0.1,0.1-0.2,0.2-0.3,0.4
// 						c-0.2,0.1-0.2,0.8-0.1,1.5c0.2,0.7,0.6,1.4,0.6,1.6c0.3,0.3,0.6,0.6,0.8,0.8c0.2,0.2,0.6,0.6,0.2,0.4c0.6,0.5,0.6,0.4,0.9,0.6
// 						c0.1,0.2,0.1,0.3,0.1,0.5c0.1,0.4,0.1,1,0.1,1.6c0,0.7-0.1,1.4-0.1,2.3c0.1,0.9,0.4,2,1.2,2.7c0.4,0.4,0.9,0.6,1.3,0.8
// 						c0.5,0.2,0.9,0.3,1.3,0.4c1,0.2,2.2,0.3,3.1,0.6c0.4,0.1,0.8,0.4,1.2,0.7c0.4,0.4,0.8,0.9,1.2,1.5c0.8,1.2,2,2.4,3.2,3.2
// 						c1.2,0.8,2.3,1.4,3.3,1.8c2.8,1.1,5.9,2,8.9,3c3,1,5.8,2.2,8,3.7c0.1,0.1,0.5,0.4,0.5,0.4c0.6,0.6,1.2,1.2,1.6,1.8
// 						c0.4,0.7,0.8,1.4,1.2,2.3c0,0,0.2,0.4,0.5,1.1c0.4,0.7,1.1,1.6,2.1,2.3c0.9,0.7,2,1.4,2.8,2c0.8,0.6,1.4,1.4,1.7,2.2
// 						c0.7,1.6-0.3,2.5,0.2,2.5c0.1,0,0.3-0.1,0.5-0.3c0.2-0.2,0.5-0.6,0.6-1.3c0.1-0.6,0-1.5-0.4-2.4c-0.2-0.5-0.5-0.9-0.8-1.4
// 						c-0.4-0.5-0.8-0.9-1.3-1.4l0.2,0c-1.2-1-2.2-1.6-2.8-2.3c-0.7-0.6-1.1-1.3-1.6-2.2c0.1-0.2-0.1-0.8-0.5-1.4
// 						c-0.4-0.6-0.9-1.2-1.1-1.6l0.1,0c-0.7-0.7-1.4-1.4-2.2-2.1c-0.8-0.7-1.6-1.2-2.4-1.6c0.1,0,0.2-0.1,0.5,0.1
// 						c-1.2-0.6-2.9-1.2-2.9-1.5c-1.5-0.6-3.3-1.1-5-1.5c-1.7-0.4-3.1-0.8-4.1-0.9l0.1,0c-1.1-0.1-1.4-0.4-2.5-0.7
// 						c-0.1-0.1-0.1-0.1-0.1-0.1c-0.6-0.1-1.4-0.5-2.1-0.9c-0.7-0.4-1.4-0.8-1.8-1.1l0-0.1c-0.6-0.4-1.2-0.9-1.7-1.5
// 						c0.2,0.2,0.4,0.4,0.6,0.6c-0.2,0-0.5-0.3-0.8-0.8c-0.3-0.5-0.7-1.2-1.3-1.9c-0.5-0.7-1.3-1.4-2.2-1.6c-0.9-0.3-1.8-0.2-2.7-0.4
// 						c-0.8,0.1,0.7-0.2-0.5-0.2c0-0.2,0.6-0.1,0.3-0.2c-0.6,0-1.1-0.1-1.5-0.2c-0.4-0.1-0.9-0.3-1.3-0.6l0.1-0.1
// 						c-0.6-0.3-0.7-1.1-0.6-1.8c0.1-0.7,0.2-1.5,0.1-2.2c0-1.1-0.1-1.6-0.2-2c0-0.2-0.1-0.5-0.3-0.8c-0.2-0.3-0.6-0.7-1.1-0.9
// 						c-0.2-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.2-0.2-0.4-0.3c-0.1-0.1-0.2-0.1-0.2-0.4c0.1,0,0.1-0.2,0.1-0.3c0-0.2,0.1-0.3,0.1-0.1
// 						c0.1-0.7,0.4-1.4,1.1-2c0.7-0.6,1.8-1.1,3.2-1.3c0.5-0.4,2-0.6,2.4-0.9c0.1,0.1,2.8-0.7,1.3-0.1c-0.1,0.1-0.4,0.1-0.4,0.1
// 						l-0.2,0.2c1.7-0.1,2.9-0.9,4.4-1.1c-0.5,0-1.1,0.2-0.7,0c0.5-0.1,1-0.2,1.5-0.2l-0.3,0.2c0.7-0.1,1.5-0.2,2.2-0.2l-0.3-0.1
// 						c1.1,0,1.5,0.2,2.7-0.1c0.5-0.1-1-0.2,0.1-0.3c1.4,0.1,3.6,0.3,5.2,0.8c-0.1-0.2,0.3-0.1,0.8,0l0,0.2c1,0.2,1.2,0.3,1.3,0.4
// 						c0.2,0.1,0.3,0.3,1.4,0.4l-0.2,0.1c0.2-0.1,0.4-0.1,0.7-0.2c1.1,0,1.4,0,1.7,0c0.3,0,0.9,0.1,2-0.8c-0.4,0.3-0.6,0.3-0.5,0.1
// 						c0.1-0.1,0.5-0.4,0.9-0.8c0,0.1-0.1,0.2-0.1,0.3c0.5-0.5,0.6-0.7,0.9-1.1c-0.1,0.2-0.1,0.3,0,0.2c0.1-0.1,0.3-0.4,0.5-0.9
// 						c0,0.3-0.1,0.6-0.2,0.8c0.1-0.2,0.1-0.4,0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.2c0-0.1-0.1-0.2-0.1-0.3c0,0,0,0,0-0.1
// 						c0-0.1,0,0,0.1,0c0.1,0,0.1,0,0.1,0c0.1,0,0.1,0,0.5-0.1c0.8-0.2,1.3-0.6,1.6-0.6c0,0,0.7-0.4,1.2-1.2c0.6-0.7,0.9-1.6,0.9-2
// 						c-0.1,0.6,0.5-0.9,0.5-0.4c0-0.4,0.1-1.1,0.2-1.8c0.1-0.7,0.1-1.4,0.2-1.8l0,0.2c0.3-2.8,0.1-4.8,0.4-7.4
// 						c-0.2,0.1,0-1.2-0.2-0.9c-0.1-2.6,0.7-4.8,0.1-5.8c0-1.2,0.2-1.4,0.3-2l0,0.3c0.1-2.2,0.2-2.8,0.6-4.3c0.2-0.8-0.2-1,0.5-2.2
// 						c-0.1,0.5,0.1-0.1,0.4-0.5c-0.2,0.3-0.2,0.4,0.2-0.1c0-0.1,0.1-0.2,0.1-0.4c0,0,0,0,0,0c0.5-0.8,0.3-0.7,0.1-0.3
// 						c0,0,0,0-0.2,0.1c0.7-0.7,1.5-2.2,1.7-2.2c1.6-2.2,2.8-3.8,4.3-5.5c0.3-0.1-0.1,0.3-0.5,0.8l1.1-1.2c0.1,0.1-0.6,0.7-0.9,1.1
// 						c1.5-1.2,2.6-3,4.5-4.4l0.3-0.4l0.1-0.1c-0.1-0.1,0.3-0.4,0.5-0.7c0.3-0.1,0.2,0.2,1.1-0.5c0.8-0.5,1.9-1.7,2.8-2.5
// 						c-0.5,0.4-0.4,0.2-0.2,0c0.4-0.4,0.8-0.5,1.1-0.8l0.2,0.1c-0.3,0,1-1.1,1.1-1.3c0.3-0.1-0.6,0.7-0.7,0.8
// 						c1.8-1.5,0.9-0.7,2.6-2.2c0.8-0.9-0.3,0,0.1-0.5c0.8-0.7,1.5-1.5,2.5-2.2l-0.4,0.1c1.4-1.5,2.3-2.3,3.4-3.2c2.4-3,3-5.1,3.2-6
// 						c0.2-1,0-1-0.4-0.6c-0.4,0.4-0.8,1.2-1.3,1.8c-0.5,0.6-0.9,1.2-0.9,1.2c-1.8,2.1-3.4,3.6-5.1,5.2
// 						C1242.3,77.1,1240.4,78.8,1237.7,81.1z"
//         />
//         <path
//           fill={roadColor}
//           d="M1388.8,91.7l0.1,0.7c0.3,0.5,0.6,1.2,0.7,1.8l-0.1,0c0.4,0.8,0.7,1.7,0.9,2.1l-0.1,0l0.4,1l-0.1-0.1
// 						c0.6,1.3,1.2,2.8,1.6,4.1l0.1-0.2c0.9,2.8,1.6,3.7,2.3,6.3l-0.1,0c0.8,1.5,1.2,2.7,1.2,3.8c0,0.6,0,1.1-0.2,1.6
// 						c-0.1,0.4-0.3,0.8-0.6,1.1c0.4,0.7,0.8,1.4,1.3,2.1c0.6-0.5,0.9-1,1.1-1.2c0,0,0.5-0.7,0.7-1.6c0.2-0.9,0.1-1.8,0-2.2
// 						c0.2,0.6,0.1-1,0.3-0.6c-0.1-0.4-0.4-1.1-0.6-1.7c-0.2-0.7-0.5-1.3-0.5-1.7l0.1,0.1c-0.8-2.7-1.9-4.5-2.6-6.9
// 						c-0.1,0.2-0.5-1.1-0.6-0.7c-1.1-2.4-1.3-4.7-2.3-5.3c-0.5-1.1-0.3-1.4-0.5-1.9l0.1,0.2c-0.8-2-1-2.6-1.2-4.2
// 						c-0.2-0.7-0.5-0.9-0.5-2.3c0.1,0.4,0.1-0.1,0.1-0.6c0,0.3,0,0.4,0.2-0.2c0-0.1,0-0.2,0-0.4c0,0,0,0,0,0c0.1-0.9,0-0.8,0-0.3
// 						c0,0,0,0-0.1,0.1c0.3-0.9,0.5-2.6,0.7-2.7c0.6-2.7,0.9-4.6,1.7-6.8c0.2-0.2,0.1,0.3-0.1,1l0.5-1.5c0.1,0.1-0.2,0.9-0.4,1.4
// 						c0.9-1.7,1.2-3.8,2.3-5.8l0.1-0.5l0.1-0.1c-0.1,0,0.1-0.5,0.2-0.9c0.3-0.2,0.3,0.2,0.8-0.9c0.5-0.8,1-2.3,1.5-3.4
// 						c-0.3,0.6-0.3,0.3-0.2,0.1c0.3-0.5,0.5-0.7,0.7-1.2l0.2,0c-0.3,0.1,0.5-1.4,0.5-1.6c0.3-0.2-0.3,0.9-0.3,1c1-2.1,0.5-1,1.5-3.1
// 						c0.4-1.2-0.3,0.1-0.1-0.5c0.4-1,0.8-2,1.4-3.1l-0.3,0.3c0.7-1.9,1.2-3,1.8-4.3c0.9-3.7,0.7-5.9,0.5-6.8c-0.2-1-0.4-0.9-0.6-0.4
// 						c-0.2,0.5-0.3,1.4-0.5,2.2c-0.2,0.8-0.4,1.5-0.4,1.5c-0.8,2.7-1.7,4.7-2.5,6.8c-0.9,2.2-1.9,4.5-3.4,7.7
// 						c-1.4,2.8-2.4,4.5-3.3,6.3c-0.9,1.8-1.8,3.7-2.7,6.9c-0.2,0.8-0.5,1.5-0.7,2.3c-0.2,0.8-0.4,1.6-0.6,2.4
// 						c-0.2,0.8-0.3,1.6-0.4,2.4c-0.1,0.4-0.1,0.8-0.2,1.2c0,0.4-0.1,0.9-0.1,1.3c0.1,0.8,0.1,1.9,0.3,3S1388.5,90.9,1388.8,91.7z"
//         />
//         <path
//           fill={roadColor}
//           d="M933.7,201.3c0.3,0.3,0.6,0.6,0.7,0.8c0.1,0.2,0.2,0.4,0.2,0.6c0.1,0.4,0,1,0.1,1.8c0.2,1.6,1.1,3.4,3,4.7
// 						c0.9,0.7,1.9,1.2,2.8,1.6c0.9,0.4,1.8,0.8,2.6,1.1c0.9,0.1,1.9,1.1,2.6,1.6c0,0.1,0.1,0.2,0.1,0.3c0.2,0.2,0.4,0.4,0.5,0.7h0
// 						c0.3,0.3,0.3,0.8,0.4,1l0,0c0,0.2,0.1,0.3,0.1,0.5l-0.1-0.1c0.2,0.7,0,1.3-0.4,1.6l0.2,0.1c-0.4,0.3-0.9,0.6-1.4,0.8
// 						c-0.3,0.1-0.5,0.2-0.9,0.4c-0.2,0.1-0.3,0.1-0.5,0.2c-0.2,0.1-0.5,0.2-0.7,0.4v-0.1c-0.9,1-1.4,1.6-2,2.2
// 						c-0.6,0.6-1.2,1.3-1.6,2.4c0.1-0.3-0.1,0.1-0.2,0c-0.5,0.9-1,1.9-0.7,2.1c-0.3,0.7-0.4,0.5-0.5,0.5c0.1,0.1-0.1,0.7-0.3,1.3
// 						l0-0.3c-0.2,0.4-0.3,1-0.2,1.7l0.1,0.4l0.2-0.4c0.1,0.9-0.3,0.3,0,1.2l0.2-0.1c0.3,1.2,0.3,2,0.4,3c0.1,0.5,0.2,1,0.2,1.5
// 						c0,0.5,0,1.1,0,1.7c0,1.2-0.1,2.7,0.6,4.1c0.4,0.7,0.9,1.2,1.3,1.7c0.4,0.4,0.8,0.8,1,1.2c0.5,0.8,0.7,1.9,0.7,2.9l-0.2,0.2
// 						c-0.2,0.1,0.5,1.2,0.3,1.4l0.2,0.5c0,0.2,0,0.5-0.1,0.2c0.1,0.5,0.1,0.5,0.3,0.9c0,0.2,0,0.4,0,0.6c0.2,1.1,0.8,2.1,1.6,2.8
// 						c0.4,0.4,0.7,0.6,1,0.9c0.2,0.3,0.4,0.5,0.5,0.9c0,0.4,0.1,0.9,0.1,1.4s-0.2,1.1-0.5,1.7c-0.3,0.6-0.6,1.1-1.1,1.4
// 						c-0.2,0.2-0.5,0.3-0.7,0.5l-0.1,0c-0.1,0-0.1,0.1-0.2,0.1l-0.4,0.1c-0.3,0.1-0.5,0.1-0.8,0.2c-0.1,0.4-0.1,0.9-0.1,1.2
// 						c0,0.4-0.1,0.8-0.1,1.2c0,0.4-0.1,0.7-0.1,1l-0.1,0.5l-0.1,0.2l-0.1,0.2c0,0,0,0.2-0.1,0.1c-0.2-0.1-0.2-0.1-0.4-0.1l-0.2,0
// 						c0,0-0.2,0-0.3,0c-0.2,0-0.4,0.1-0.6,0.1c-0.8,0.2-1.5,0.7-2,1.2c-1.1,1-1.8,2.1-2.3,3.3c-0.1,0.1-0.2,0.4-0.1,0.4
// 						c-0.5,1-1,2.1-1.5,3.1c0,0-0.4,1-0.9,2.2c-0.5,1.3-1.1,2.9-1.4,4.1c-0.3,1.2-0.5,2.2-0.1,2c0.3-0.1,1.2-1.2,2.8-3.9l0,0.1
// 						c0.8-1.7,1.3-2.7,1.6-4c0.4,0,0.7-1.4,0.9-1.7l0,0c0.1-0.6,0.4-1.1,0.7-1.5c0.3-0.5,0.6-0.9,0.8-1.4c0,0,0.1,0.1,0,0.3
// 						c0.3-0.6,0.9-1.4,1.1-1.3c0.3-0.4,0.8-0.7,1.2-0.9c0.1,0,0.2-0.1,0.3-0.1l0.1,0c0.1,0,0.2,0,0.4,0c0.1,0,0.2,0,0.4,0l0.5,0.1
// 						c0.3,0.1,0.6,0.1,0.9,0.2c0.2-1.1,0.3-2.5,0.3-3l0,0.1c-0.1-0.7,0.1-0.9,0.1-1.7c0,0,0-0.1,0.1-0.1c0-0.1,0-0.2,0-0.3
// 						c0-0.1,0-0.1,0-0.2c0-0.1,0-0.3,0.1-0.4c0.1,0,0.3,0,0.4-0.1c0,0,0.2-0.1,0.3-0.1c0.1,0,0.2-0.1,0.3-0.2l0.1,0.1
// 						c0.6-0.3,1-0.7,1.4-1.1c-0.2,0.1-0.3,0.3-0.5,0.4c0-0.2,0.2-0.4,0.6-0.7c0.3-0.3,0.8-0.7,1-1.4c0.3-0.5,0.3-1.2,0.2-1.8
// 						c-0.1-0.6-0.2-1.1-0.2-1.7c-0.2-0.5,0.3,0.4,0.1-0.4c0.2-0.1,0.2,0.4,0.3,0.1c-0.3-0.8-0.2-1.5-1-2.3l0.1-0.1
// 						c-0.5-0.4-0.9-0.8-1.2-1.2c-0.2-0.4-0.4-0.7-0.6-0.9c-0.3-0.5-0.2-0.7-0.2-0.9c0-0.2,0-0.4-0.2-1l0.1-0.5
// 						c0-0.5-0.3-0.3-0.3-0.9c0.1,0,0-0.8,0.2-0.4c0-0.7-0.1-1.4-0.4-2.3s-0.6-1.7-1.2-2.8c-0.5-0.3-1.1-1-1.4-1.1
// 						c0-0.1-0.2-0.4-0.4-0.6c-0.1-0.2-0.2-0.3,0.1-0.1c0,0,0.1,0.1,0.1,0.2l0.2,0c-0.2-0.4-0.3-0.6-0.4-0.9c-0.2-0.3-0.3-0.6-0.3-1
// 						c0,0.3,0,0.6-0.1,0.4c0-0.2,0-0.5,0-0.8l0.1,0.2c0-0.4,0.1-0.9,0.1-1.3l-0.2,0.2c0.1-0.7,0.4-0.9,0.2-1.8
// 						c-0.1-0.4-0.2,0.7-0.2-0.1c0-0.5,0.1-1.2,0-1.9c-0.1-0.7-0.3-1.4-0.5-2c-0.1,0.2-0.2-0.1-0.4-0.4l0.1-0.1
// 						c-0.3-0.5-0.2-0.6-0.2-0.8c0.1-0.1,0.1-0.2-0.1-0.7l0.1,0.1c-0.1-0.1-0.2-0.1-0.4-0.2c0,0,0,0,0,0c-0.1-0.1,0-0.2,0.1-0.3
// 						c0.1-0.1,0.2-0.2,0.2-0.8c-0.2,0.4-0.4,0.1-0.1-0.4l0.1,0.2c0.1-0.3,0.1-0.4,0.2-0.6c0,0.2,0.1,0.2,0.3-0.2c0,0.1,0,0.2,0,0.4
// 						c0-0.3,0.1-0.6,0.2-0.9c0.5-0.5,0.9-1.6,1.3-1.7c0,0,0.2-0.3,0.5-0.6c0.2-0.3,0.5-0.7,0.5-0.9c-0.1,0.3,0.5-0.3,0.5,0
// 						c0.1-0.4,0.8-1.3,1.2-1.4l0,0.1c0.1-0.1,0.3-0.2,0.4-0.3c0.1,0,0.1-0.1,0.3-0.2c0.3-0.1,0.7-0.3,1-0.4c0.7-0.3,1.5-0.7,2.4-1.5
// 						c-0.2,0,0.6-0.9,0.2-0.8c0.5-1,0.7-2,0.7-2.8s-0.2-1.4-0.6-1.6c-0.3-0.8-0.3-1-0.5-1.4l0.1,0.2c-0.5-0.7-0.9-1.1-1.2-1.4
// 						c-0.3-0.3-0.7-0.6-1.1-0.9c-0.5-0.3-0.8,0-1.6-0.6c0.3,0,0-0.1-0.3-0.3c0.2,0.1,0.3,0.1-0.1-0.2c-0.1,0-0.2,0-0.3,0
// 						c0,0,0,0,0,0c-0.6-0.2-0.5-0.1-0.2,0c0,0,0,0,0,0.1c-0.5-0.4-1.5-0.7-1.5-0.9c-1.6-0.7-2.7-1.1-3.7-2c0-0.2,0.2,0,0.6,0.2
// 						c-0.2-0.2-0.5-0.4-0.7-0.6c0.1-0.1,0.4,0.3,0.7,0.5c-0.3-0.5-0.7-0.8-1.1-1.2c-0.3-0.4-0.6-0.9-0.6-1.6l-0.2-0.3l0-0.1
// 						c-0.1,0-0.2-0.3-0.2-0.5c0.1-0.2,0.4,0,0.2-0.8c0-0.4-0.2-0.9-0.6-1.3c-0.4-0.4-0.8-0.7-1.1-0.9c0.3,0.3,0.2,0.3,0,0.2
// 						c-0.3-0.2-0.4-0.5-0.6-0.6l0.1-0.2c0,0.2-0.8-0.4-0.9-0.5c0-0.2,0.5,0.3,0.6,0.2c-1-0.9-0.5-0.4-1.3-1.4
// 						c-0.5-0.5-0.1,0.2-0.4,0c-0.4-0.5-0.8-1-1.1-1.7l0,0.3c-0.6-1.1-0.8-1.8-0.8-2.7c-0.8-2.2-0.8-3.6-0.9-4.3
// 						c-0.1-0.7-0.3-0.7-0.5-0.4c-0.2,0.3-0.4,1-0.5,1.6c0,0.3-0.1,0.6-0.1,0.8c0,0.2,0,0.3,0,0.3c-0.1,2.1,0.4,3.7,1.2,5.2
// 						c0.4,0.8,0.9,1.5,1.5,2.2c0.3,0.4,0.7,0.7,1.1,1.1C932.8,200.6,933.3,201,933.7,201.3z"
//         />
//         <path
//           fill={roadColor}
//           d="M870.5,236.3c0.4-0.2,0.7-0.4,1-0.5c0.3-0.4,0.7-0.9,1-1.2c0.2-0.3,0.5-0.6,0.7-1c0.5-0.6,0.9-1.2,1.3-1.8
// 						c0.8-1.1,1.4-2.1,2.1-3.2c0.6-1.1,1.3-2.1,1.9-3.4c0.3-0.6,0.7-1.3,1-2.1c0.3-0.8,0.6-1.6,1-2.5c0.7-1.8,1.5-3.6,2.2-5.5
// 						c0.7-1.9,1.3-3.9,1.5-6c0-1,0-2.4-0.4-3.9l-0.2-0.5l-0.1-0.3l0-0.1l0-0.1l0-0.1c0,0,0,0,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3
// 						c0.1-0.2,0.2-0.4,0.3-0.6c0.2-0.2,0.4-0.3,0.5-0.4c0.3-0.5,0.9-1,1.5-1.3l0,0.1c0.7-0.6,1.7-1,2.2-1.1l0,0.1
// 						c0.4-0.1,0.7-0.2,1.1-0.3l-0.2,0.1c0.2-0.1,0.3-0.1,0.6-0.1c0.3-0.1,0.5-0.1,0.8-0.2c0.5-0.1,1.1-0.4,1.6-0.6
// 						c1-0.5,1.9-1.1,2.6-1.8h-0.2c0.4-0.4,0.7-0.7,1-1.1c0.1-0.2,0.2-0.4,0.4-0.6c0.1-0.1,0.2-0.3,0.3-0.4c0.3-0.6,0.7-1.1,1-1.6
// 						c0.3-0.5,0.6-1,1-1.4c0.4-0.5,0.9-1.1,1.3-1.4v0.1c0.2-0.3,0.4-0.4,0.6-0.5c0.2-0.1,0.5,0,0.9,0.1c0.7,0.2,1.6,0.6,2.5,0.8
// 						c1.8,0.4,3.9,0.3,6-0.8c-0.5,0.3,0.3,0,0.2,0c1-0.5,1.9-1.1,2.5-1.5c0.6-0.4,1-0.7,1-0.8c1.2-0.5,1-0.2,1.2-0.1
// 						c0-0.1,1-0.4,2-0.2c-0.2,0-0.3,0-0.5,0c0.9,0.1,1.9,0.4,2.9,0.7c-0.2-0.2-0.3-0.3-0.5-0.5c1.5,0.7,0.2,0.4,1.7,1.1l0.1-0.2
// 						c2.1,1.1,3.2,2.2,5.7,2.8c0.7,0.1,1.4,0.2,2.1,0.1c0.4,0,0.7-0.1,1.1-0.2c0.2-0.1,0.4-0.1,0.5-0.2c0.1,0,0.2-0.1,0.3-0.1
// 						l0.4-0.2l0.7-0.4c0.1-0.1,0.2-0.1,0.3-0.2l0.1-0.4c0.1-0.2,0.1-0.5,0.1-0.8l0.1-0.5l0.3-1.8l-1.9,0.7c0.4,0.3,1,0.5,1.5,0.4
// 						c0.5,0,0.9-0.2,1.2-0.4c0.7-0.5,1.1-1.1,1.3-2c0.2-1.2-0.2-2.1-0.5-2.9c-0.1-0.4-0.3-0.7-0.3-1c-0.1-0.3-0.1-0.6-0.1-0.9
// 						c0-0.3,0.1-0.4,0.2-0.6c0.1-0.1,0.2-0.2,0.6-0.4c0.3-0.2,0.8-0.4,1.2-0.8c0.4-0.4,0.6-0.9,0.8-1.2l0.2-0.6l0.2-0.4l0.5-1
// 						c0.4-0.6,0.8-1.3,1.2-1.9c0.8-1.3,1.6-2.5,2.4-3.7l0.4-0.2c0.3,0,1-2.3,1.4-2.4l0.5-0.9c0.2-0.2,0.6-0.7,0.3-0.2
// 						c0.5-0.8,0.5-0.9,0.8-1.5l0.7-0.8c1-1.6,1.9-3.1,2.8-4.7l0.3-0.6l0.2-0.3l0.1-0.2l0-0.1c0-0.1,0.1-0.2,0.1-0.2l0.1-0.1l0.3-0.2
// 						c0.8-0.4,1.6-0.9,2.5-1.3c1.6-0.7,3.6-1.7,5.8-2.9c1.1-0.6,2.2-1.2,3.3-1.8c1.2-0.6,2.3-1.3,3.4-1.9l0.3-0.4l0.2-0.2
// 						c0.2-0.1,0.3-0.3,0.4-0.4c0.3-0.3,0.6-0.6,0.9-0.9c0.6-0.5,1.2-1,1.7-1.4c2.8-2.2,6.4-3.8,10.1-4.7c1.9-0.4,3.8-0.8,5.6-1.1
// 						c1.9-0.3,3.7-0.4,5.6-0.6c0.2,0,0.9-0.1,0.9-0.1c0.6,0,1.2-0.1,1.7-0.1l1.7-0.2c1.2-0.1,2.3-0.4,3.5-0.6c0,0,0.1,0,0.4-0.1
// 						c0.2-0.1,0.6-0.1,1-0.3c0.4-0.1,0.9-0.2,1.5-0.4c0.6-0.2,1.2-0.4,1.8-0.6c2.6-0.9,5.7-2.6,7.8-4.3c2.1-1.7,3.1-3.4,2.5-3.2
// 						c-0.3,0.1-0.9,0.6-2.1,1.4c-1.2,0.8-3.2,2-6,3l0.1-0.1c-3.3,1.4-5.5,1.9-8,2.7c-0.2-0.1-1,0-1.8,0.2c-0.8,0.1-1.7,0.3-2.1,0.3
// 						l0.1,0c-1.1,0.3-2.2,0.2-3.4,0.3c-1.2,0-2.4,0-3.5,0.2c0.1,0,0.1-0.1,0.6-0.2c-0.8,0.1-1.8,0.2-2.5,0.3
// 						c-0.8,0.1-1.4,0.1-1.4,0.1c-1.9,0.4-4.2,0.9-6.3,1.6c-2.1,0.7-3.9,1.6-4.9,2.3l0.1-0.1c-1.2,0.9-1.6,0.9-2.9,1.8
// 						c-0.1,0-0.2,0.1-0.2,0.1c-0.6,0.5-1.5,1.2-2.4,1.9c-0.9,0.7-1.7,1.4-2.1,1.9l-0.2,0l-0.2,0.2l-0.1,0.1l-2,1.1l1-0.5
// 						c-0.2,0.5-2.1,0.9-4,2.1c-1.8,0.9-3.3,2.6-5.3,3.4c-0.8,0.6,0.7-0.7-0.6,0.2c-0.1-0.1,0.6-0.4,0.2-0.4c-1.3,0.8-2.3,1-3.8,1.9
// 						l-0.1-0.1c-0.1,0.1-0.2,0.1-0.4,0.3c-0.2,0.1-0.4,0.2-0.6,0.3c-0.2,0.1-0.2,0.4-0.3,0.5c-0.1,0.2-0.2,0.4-0.2,0.5
// 						c-0.3,0.5-0.5,0.9-0.8,1.4c-0.6,0.9-1.1,1.6-1.4,2.4c-1.2,2.2-1.2,1.2-2.4,3.5l-0.7,0.7c-0.6,0.9-0.2,0.8-0.9,1.8
// 						c-0.1-0.1-0.9,1.3-0.6,0.6c-0.8,1.1-1.5,2.4-2.3,3.7c-0.4,0.7-0.8,1.4-1.2,2.1c-0.5,0.7-0.9,1.5-1.4,2.3
// 						c-0.1,0.5-0.3,1.1-0.5,1.4c-0.1,0.2-0.2,0.2-0.3,0.4c-0.1,0.1-0.3,0.2-0.4,0.3c0,0-0.2,0.1-0.6,0.3c-0.4,0.2-0.9,0.7-1.1,1.2
// 						c-0.2,0.5-0.3,0.8-0.3,0.9c0,0.1-0.1-0.2,0.2-1c0.1-0.3,0.5-0.7,0.5-0.7c0-0.1,0.1-0.3,0.2-0.4c-0.8,0.6-1.2,1.5-1.2,2.2
// 						c-0.1,0.7,0.1,1.4,0.2,1.9c0.2,0.6,0.4,1,0.6,1.4c0.2,0.4,0.3,0.8,0.2,1c0.1-0.3-0.2-1,0.1-0.6c0.1,0.3,0.1,0.6,0,0.8
// 						c0,0.1,0,0.1-0.1,0.2c0-0.1,0-0.1-0.1-0.1c0,0.1-0.2,0.2-0.3,0.3c-0.1,0-0.1-0.1,0,0.2c0,0.2-0.1,0.2,0,0.1l-2,0.6l0,0l0,0
// 						l2-0.6c0,0,0,0,0,0.1l0,0l0,0c0,0,0-0.1,0-0.1l-1.9,0.6c-0.2,0.5-0.3,1-0.4,1.4c-0.1,0.2-0.1,0.4-0.2,0.7c0,0.1,0,0.1,0,0.1
// 						l0,0c0,0,0,0.1,0,0.1c0,0.1,0.1,0.1,0.2,0.1c0,0,0,0,0.1,0s0,0,0,0.1c0,0,0,0.1-0.1,0.1c0,0-0.1,0-0.1,0.1c-0.1,0,0,0-0.1,0
// 						c-0.2,0.1-0.4,0.1-0.6,0.1c-0.4,0.1-0.9,0.1-1.4,0c-1-0.1-2.1-0.5-3.1-1c0.1,0.2-0.4,0-1-0.2V188c-1.2-0.5-1.3-0.6-1.5-0.8
// 						c-0.2-0.2-0.3-0.4-1.6-0.8h0.3l-0.8,0c-1.2-0.3-1.5-0.4-1.9-0.6c-0.4-0.1-0.8-0.4-2.3-0.1c0.6-0.1,0.7,0.1,0.5,0.1
// 						c-0.2,0.1-0.7,0.1-1.4,0.2l0.3-0.2c-0.8,0.1-1,0.3-1.5,0.4c0.4-0.2,0.1-0.3-0.9,0.2c0.2-0.2,0.4-0.3,0.6-0.5
// 						c-0.7,0.4-1.2,0.8-1.7,1.1c-0.7,0.3-1.5,0.8-2.1,1.1c-0.7,0.3-1.3,0.5-1.6,0.4c0,0-0.7,0.1-1.4,0.2c-0.8,0.1-1.6,0-1.9,0.1
// 						c0.5-0.1-0.9-0.4-0.5-0.4c-0.4,0-1-0.1-1.9-0.4c-0.5-0.1-1-0.2-1.6-0.2s-1.1,0.2-1.5,0.3c0.1-0.1,0.2-0.1,0.3-0.1
// 						c-0.7,0.3-1.2,0.7-1.5,1c-0.4,0.4-0.6,0.7-0.9,1c-0.5,0.6-1,1.3-1.4,1.9s-0.8,1.3-1.2,1.9c-0.2,0.4-0.4,0.6-0.5,0.9
// 						c-0.2,0.2-0.4,0.5-0.7,0.7c0.1,0-0.1,0.3-0.3,0.5c-0.2,0.2-0.4,0.4-0.2,0.4c-0.5,0.5-1.2,0.8-1.8,0.9c-0.6,0.2-1.1,0.2-1.9,0.3
// 						c-1.4,0.2-2.4,0.7-2.8,1.3c-1.4,0.8-1.7,0.9-2.4,1.5l0.3-0.3c-1.2,1.1-1.7,1.9-2.1,2.7c-0.1,0.2-0.3,0.5-0.4,0.8
// 						c-0.1,0.3-0.4,0.6-0.1,0.9c0.1,0.1,0.1,0.3,0.2,0.4c0.1,0.2,0.1,0.3,0.2,0.4c0,0.2,0.1,0.4,0.2,0.6c0.2,0.4,0.3,0.7,0.4,1
// 						c0.1,0.4,0.2,0.8,0.1,1.7c-0.1-0.6-0.1,0.1-0.2,0.8c0-0.4,0-0.6-0.2,0.3c0,0.1,0,0.3,0,0.5c0,0,0,0,0,0c-0.2,1.1-0.1,0.9,0,0.4
// 						c0,0,0,0,0.1-0.2c-0.4,1.1-0.9,3.1-1.2,3.2c-1.2,3.2-2,5.6-3,8.3c-0.2,0.2-0.1-0.4,0.2-1.2c-0.2,0.6-0.5,1.3-0.8,1.9
// 						c-0.1-0.1,0.4-1.1,0.6-1.7c-1.1,2.1-1.9,4.6-3.5,7l-0.2,0.6l-0.1,0.1c0.1,0-0.2,0.6-0.4,1c-0.3,0.3-0.2-0.2-1,1
// 						c-0.3,0.4-0.7,1-1.1,1.6c-0.4,0.6-0.8,1.2-1.1,1.8c-0.1,0-0.1,0.1-0.2,0.1c0.1,0,0.1-0.1,0.2-0.1c0.1-0.1,0.1,0,0.1,0.1
// 						c0,0,0,0.1-0.1,0.1c-0.1,0-0.2,0.1-0.4,0.2s-0.3,0.1-0.4,0.2c-0.2,0.1-0.5,0.2-0.7,0.4l-0.2-0.1c0.3,0-1.5,1-1.8,1.2
// 						c-0.4,0.1,0.9-0.6,1-0.8c-2.3,1.3-1.7,0.9-3.5,1.9c0.1-0.1-0.1,0.3-0.3,0.9c-0.4,1.5,0.3-0.2,0.2,0.6c-0.5,1.3-0.8,2.5-1.5,3.9
// 						l0.3-0.4c-0.4,1.2-0.7,2.1-1.1,3c-0.3,0.9-0.6,1.5-1.1,2.5c0,0.1-0.1,0.2,0,0.2c0.3,0.2,0.5,0.5,0.7,0.7
// 						c0.2,0.2,0.3,0.4,0.5,0.6c0.3,0.4,0.5,0.9,0.6,1.3c0.3,0.9,0.3,1.7,0.3,2.4c-0.1,1.4-0.7,2.4-1,2.9c-0.1,0.1-0.2,0.3-0.2,0.4
// 						c-0.2-0.1-0.3-0.3-0.5-0.4c0.2,0.2,0.5,0.5,0.8,0.8c0.7-0.5,1.7-1.7,2.1-3.1c0.2-0.7,0.3-1.3,0.3-1.8c0-0.5,0-0.8,0-0.8
// 						c-0.1-0.9-0.3-1.7-0.6-2.4c-0.2-0.4-0.4-0.7-0.6-1c-0.1-0.1,0,0,0-0.1h0h0v0c0,0,0,0,0,0l0.1-0.3c0.2-0.4,0.3-0.8,0.5-1.2
// 						c0.3-0.8,0.6-1.6,0.9-2.4c0.3-0.8,0.6-1.6,0.9-2.5c0.2-0.4,0.3-0.9,0.5-1.3c0.1-0.2,0.2-0.4,0.2-0.7c0.1-0.2,0-0.1,0-0.1l0-0.1
// 						c0,0,0,0,0.1-0.1c0.2-0.1,0.4-0.2,0.6-0.3c0.4-0.2,0.8-0.4,1.2-0.7C868.7,237.3,869.5,236.8,870.5,236.3z"
//         />
//         <path
//           fill={roadColor}
//           d="M889.3,284.7c-2.4,2.1-3.6,3.9-4.4,5.8c-0.4,1-0.7,2-0.9,3.3c-0.2,1.2-0.1,2.5-0.2,3.8
// 						c-0.1,0.6-0.2,1.1-0.4,1.5c-0.2,0.4-0.5,0.6-1.1,1c-0.6,0.3-1.4,0.9-2,1.4c-0.6,0.5-1.2,1.1-1.7,1.7c-0.8,1.2-2.4,3.2-3.1,4.6
// 						l-0.5,0.4c-0.2,0.5-0.5,1-0.9,1.4l0,0c-0.4,0.7-1,1.3-1.2,1.6l0,0l-0.6,0.7l0.1-0.2c-0.4,0.5-0.9,0.9-1.4,1.3
// 						c-0.5,0.3-1.1,0.7-1.4,0.7c0,0,0.1,0.1,0.1,0.2c-0.2,0-0.3,0-0.4,0c-0.1,0-0.2-0.1-0.3-0.2c-0.2-0.2-0.5-0.5-0.8-0.8
// 						c-0.3-0.4-0.5-0.7-0.9-1.2c-0.4-0.5-0.9-1-1.6-1.4l0.1,0c-0.9-0.4-1.7-0.5-2.4-0.7c-0.7-0.2-1.3-0.4-1.8-0.5
// 						c-0.5-0.2-0.9-0.5-1.3-0.8c-0.2-0.2-0.4-0.3-0.5-0.5c-0.1-0.1-0.2-0.1-0.2-0.3c-0.1-0.3-0.2-0.7-0.3-1c0.2,0.7,0-0.4,0.1-0.4
// 						l0-0.1c-0.6,0-1.3,0-1.8,0c-0.7,0-0.9,0-1.2,0c-0.6,0-1,0.1-1,0.3c-1.1,0-0.9-0.1-1-0.2c0,0.1-1,0.2-1.8,0.3l0.4-0.1
// 						c-0.8,0-1.7,0.1-2.5,0.2l0.5,0.2c-1.3,0-0.4-0.3-1.6-0.1l0,0.2c-1.8,0.2-3,0-4.8,0.3c-1.8,0.5-3.7,1.2-5.4,2.4
// 						c-0.8,0.5-1.6,1.2-2.3,1.9c-0.7,0.7-1.3,1.3-2,1.9c-1.2,1.1-2.9,1.4-3.8,0.6c-1-0.8-1.3-2.7-1.7-4.3l0.1-0.3
// 						c0.2-0.2-0.9-1.6-0.7-1.9l-0.3-0.7c0-0.2,0-0.7,0.1-0.3c-0.1-0.7-0.2-0.7-0.3-1.2c0-0.2,0-0.5,0.1-0.8
// 						c-0.1-1.3-0.1-2.7-0.5-4.2c-0.2-0.7-0.5-1.5-1.1-2.2c-0.5-0.7-1.2-1.3-1.9-1.7c-0.6-0.5-1.4-0.9-2.2-1.3
// 						c-0.4-0.2-0.8-0.4-1.2-0.6l-0.3-0.2l-0.2-0.1c-0.1-0.1,0-0.2,0-0.3c0,0,0.1-0.1,0.1-0.2c0.1-0.1,0.3-0.3,0.5-0.4
// 						c0.3-0.3,0.8-0.6,1.2-1c0.2-0.2,0.5-0.5,0.7-0.8c0.1-0.2,0.2-0.4,0.2-0.5c0-0.2,0.1-0.4,0.1-0.6c0-0.5-0.1-1-0.3-1.3
// 						c-0.2-0.4-0.4-0.7-0.6-0.9c-0.5-0.5-1.1-0.9-1.7-1c-0.2,0-0.3,0-0.4,0c-0.1,0.1-0.1-0.1-0.1-0.1l-0.1-0.2l-0.1-0.4l-0.2-0.5
// 						c-0.3-0.7-0.6-1.3-0.9-2c-0.6-1.2-1.3-2.6-2-3.8c-0.4-0.6-0.8-1.3-1.2-1.9c-0.2-0.3-0.5-0.6-0.7-0.9c-0.2-0.3-0.6-0.6-1-0.9
// 						c-0.8-0.5-1.7-0.8-2.4-1c-0.8-0.1-1.5-0.1-2.3-0.1c-0.2,0-0.7,0.1-0.7,0.1c-0.4,0-0.8-0.3-0.9-0.6c0-0.1,0-0.2,0-0.3l0-0.6
// 						c0-0.5,0-0.9,0-1.4c0,0,0-0.4-0.1-1.1c-0.1-0.7-0.3-1.6-0.7-2.7c-0.2-0.5-0.4-1.1-0.8-1.7c-0.3-0.5-0.6-0.9-0.9-1.4
// 						c-0.5-0.9-1-1.9-1.1-2.7c-0.4-1.6,0.2-2.7-0.2-2.6c-0.2,0.1-0.7,0.4-1,1.6c-0.3,1.2,0,3.1,1.1,5.4l-0.1-0.1
// 						c0.4,0.7,0.6,1.1,0.8,1.6c0.2,0.5,0.3,1,0.4,1.4c0.2,0.9,0.4,1.8,0.6,2.7c-0.2,0.1-0.2,0.7-0.2,1.3c0,0.4,0.1,1,0.2,1.4
// 						c0.2,0.4,0.4,0.7,0.5,0.9l-0.1,0c0.5,0.4,1,0.8,1.7,0.9c0.2,0,0.3,0.1,0.5,0.1l0.4,0l0.6,0c0.8,0.1,1.6,0.2,2.3,0.3
// 						c-0.1,0-0.1,0.1-0.4,0c0.5,0.1,1,0.3,1.4,0.5c0.2,0.1,0.3,0.2,0.4,0.4c0.1,0.1,0.2,0.2,0.1,0.3c0.9,1,1.8,2.3,2.7,3.7
// 						c0.8,1.3,1.6,2.6,2.1,3.3l-0.1-0.1c0.3,0.4,0.4,0.7,0.5,1c0.1,0.2,0.2,0.9,0.4,1.8c0,0.1,0,0.3,0,0.3c0,0.1,0.1,0.1,0.1,0.2
// 						c0.5-0.1,1.2-0.3,1.5-0.2c0.2,0,0.4,0.2,0.6,0.4c0.2,0.2,0.3,0.4,0.4,0.6c0.1,0.2,0.1,0.3,0.1,0.5c0,0,0,0.1,0,0.1h-0.1
// 						c0,0.1-0.1,0.2-0.1,0.2c0,0,0,0.1-0.1,0.1c0,0-0.1,0.1-0.2,0.2c-0.2,0.2-0.5,0.5-0.8,0.7l0.7-0.5c0,0.2-0.3,0.4-0.9,0.8
// 						c-0.1,0.1-0.3,0.2-0.4,0.3c-0.2,0.1-0.3,0.2-0.5,0.5c-0.4,0.4-0.7,1-0.7,1.6c0,0.1,0,0.3,0,0.4s0,0.3,0.1,0.4
// 						c0,0.1,0,0.3,0.2,0.4c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.2,0.5,0.3,0.8,0.4c0.3,0.1,0.5,0.2,0.7,0.2c0.8,0.2,1.6,0.4,2.3,0.8
// 						c0.8,0.2-0.7,0,0.3,0.3c-0.1,0.2-0.5-0.1-0.3,0.1c1,0.4,1.5,1,2.3,1.8L825,299c0.5,0.5,0.7,1.3,0.8,2s0.1,1.4,0.3,2
// 						c0.3,1.9-0.4,1.3,0.2,3.3l0,0.8c0.2,0.9,0.4,0.5,0.5,1.5c-0.1,0,0.2,1.2,0,0.7c0.2,1.1,0.6,2,1,3.2c0.2,0.6,0.5,1.2,0.9,1.9
// 						c0.4,0.6,1,1.4,2,1.9c0.5,0.1,1.1,0.2,1.8,0.1c0.6,0,1.2-0.2,1.4-0.4c0.1,0,0.9-0.2,1.4-0.5c0.5-0.3,0.8-0.5,0.1,0.1
// 						c-0.1,0.1-0.4,0.2-0.4,0.2l-0.2,0.2c2-0.7,2.6-2.1,3.7-3.1c-0.3,0.2-0.8,0.7-0.6,0.3c0.3-0.3,0.7-0.6,1-0.9l-0.1,0.3
// 						c0.5-0.4,1.1-0.8,1.6-1.1l-0.3,0c0.4-0.2,0.8-0.3,1.1-0.5c0.3-0.1,0.6-0.3,1.1-0.6c0.4-0.3-0.9,0.2,0-0.3
// 						c1.2-0.5,3.2-0.9,4.9-1.1c-0.2-0.1,0.3-0.2,0.7-0.3l0.1,0.1c1.8-0.5,0.7,0.2,2.5-0.1l-0.2,0.1l0.5-0.4c1.7-0.4,0.9,0.2,2.8-0.2
// 						c-0.7,0-0.4-0.3,0.6-0.3l-0.2,0.1c0.5,0,0.7-0.1,1-0.1c-0.3,0.1-0.2,0.2,0.6,0.2l-0.5,0.2l1.4-0.3c0.1,0,0.3,0,0.4,0l0.2,0
// 						c0.1,0,0.3,0,0.3,0c0,0.1,0,0.1,0.1,0.2c0.1,0.3,0.2,0.5,0.3,0.7c0,0,0.5,0.7,1.2,1.2c0.7,0.5,1.5,0.8,1.8,0.8
// 						c-0.6,0,0.9,0.5,0.4,0.5c0.7,0,2.5,0.3,2.9,0.7l-0.1,0c1,0.5,1.3,0.8,2,1.8c0.4,0.5,0.7,1,1.4,1.5c0.3,0.3,0.8,0.5,1.3,0.6
// 						c0.5,0.1,1.1,0.1,1.5,0c-0.1-0.1,0.3-0.2,0.6-0.4c0.3-0.2,0.5-0.3,0.3-0.3c2.4-1.5,4.2-2.9,4.2-4c0.7-1,0.9-1,1.3-1.5l-0.1,0.2
// 						c1.2-1.8,1.5-2.4,2.4-3.6c0.4-0.6,0.2-1,1.1-2c-0.2,0.4,0.1,0,0.5-0.4c-0.2,0.2-0.2,0.3,0.3-0.1c0-0.1,0.1-0.2,0.2-0.3
// 						c0,0,0,0,0,0c0.6-0.6,0.5-0.5,0.2-0.3c0,0,0,0-0.2,0c0.7-0.5,1.9-1.4,2.1-1.3c0.6-0.3,1.2-0.7,1.7-1.3c0.5-0.6,0.8-1.2,0.9-1.8
// 						c0.3-1.2,0.2-2.3,0.2-3.4c0.1-0.2,0.2,0.3,0.2,0.9v-1.6c0.2,0,0.1,0.9,0.1,1.4c0.2-1,0.1-1.9,0.1-2.8c0-0.9,0.2-1.9,0.7-2.8
// 						l0.1-0.4l0.1-0.1c-0.1,0,0-0.5,0.2-0.8c0.3-0.2,0.2,0.2,0.8-0.7c0.5-0.6,1.3-1.8,2.1-2.6c-0.4,0.4-0.4,0.2-0.2,0
// 						c0.4-0.4,0.7-0.4,1-0.7l0.2,0.1c-0.3,0,1-1,1.1-1.1c0.3,0-0.6,0.5-0.6,0.7c0.8-0.6,1.1-0.7,1.3-0.9c0.2-0.1,0.4-0.3,1.4-0.7
// 						c1-0.6-0.3-0.1,0.2-0.5c0.9-0.4,1.8-0.9,2.9-1.1l-0.4-0.1c1.8-0.6,3-0.7,4.2-0.7c3.5-0.6,5.5-0.7,6.5-0.9
// 						c1-0.2,0.9-0.4,0.4-0.5c-0.5-0.2-1.5-0.4-2.3-0.5s-1.6-0.1-1.6-0.1c-2.9-0.2-5.2,0.1-7.5,0.9
// 						C894.5,281.2,892,282.3,889.3,284.7z"
//         />
//         <path
//           fill={roadColor}
//           d="M1631.2,154.3c-1.5,1.3-3,2.6-4.6,3.9c-1.3,1-2.6,2-3.8,3c-1.2,0.9-2.5,1.7-4,2.4c-2.8,1.3-6,2.2-9.2,3.1
// 						c-1.6,0.5-3.2,0.9-4.8,1.5c-1.5,0.5-3,0.8-4.7,1.1c-0.8,0.2-1.7,0.4-2.6,0.7c-0.4,0.1-0.9,0.3-1.3,0.5l-1.1,0.6
// 						c-0.7,0.4-1.4,0.8-2.2,1.1l-2.3,0.9c-3.1,1.1-6.3,1.8-9.6,1.9c-3.3,0.1-6.6-0.3-9.8-1.3c-3.2-0.9-6.3-2.3-9.2-4.1
// 						c-0.7-0.4-1.4-0.9-2.2-1.3c-0.7-0.5-1.4-0.9-2.1-1.4c-1.4-1-2.7-2-4.2-3c-1.4-0.9-2.3-1.4-2.8-1.7c-0.5-0.3-0.7-0.4-0.6-0.4
// 						c0.2,0.1,1.3,0.6,2.2,1.1c0.9,0.5,1.6,0.9,1.1,0.5c-0.3-0.2-0.8-0.6-1.9-1.2c-0.5-0.3-1.2-0.7-2-1.1c-0.4-0.2-0.8-0.4-1.3-0.7
// 						c-0.5-0.2-1-0.4-1.6-0.7c-0.2-0.3,1.9,0.6,3.5,1.4c1.6,0.8,2.6,1.4,0.4,0c-8.6-4.6-17.2-5.8-25.6-4c-2.1,0.5-4.2,1-6.4,1.9
// 						c-2.1,0.9-4.1,2.1-6.1,3.4c-2,1.2-4.1,2.5-6.3,3c-2.3,0.6-4.6,0.1-6.9-1.5l-0.6-0.7c-0.7-0.5-1.4-1.1-2.1-1.8
// 						c-0.7-0.7-1.4-1.3-2.1-1.9c-1.4-1.3-2.9-2.5-4.4-3.7c-2.9-2.3-5.9-4.5-8.9-6.6c-1.5-1-3-2-4.4-3c-0.7-0.5-1.5-1-2.2-1.4
// 						c-0.8-0.5-1.4-1-2.4-1.4c-1.8-0.9-3.7-1.2-5.5-1.3c-1.8-0.1-3.6,0.1-5.4,0.5c-6.2,1.9-12.3,2.3-17.9,1.5
// 						c-5.6-0.8-10.7-2.8-15.2-5c-6.9-3.6-11.1-6.8-14.2-9.1c-1.6-1.2-2.9-2.2-4.1-3.1c-0.6-0.5-1.2-0.9-1.7-1.3l-0.4-0.3
// 						c-0.1-0.1-0.2-0.2-0.3-0.2c-0.2-0.2-0.4-0.4-0.6-0.6c-0.8-0.9-1.5-2.1-2.4-3.3c-0.4-0.6-0.9-1.3-1.3-2.1
// 						c-0.2-0.4-0.5-0.8-0.8-1.2c-0.1-0.2-0.3-0.4-0.4-0.6c-0.2-0.2-0.2-0.4-0.5-0.7c-0.9-1.2-2.6-2.2-4.5-2
// 						c-0.9,0.1-1.8,0.4-2.7,0.9c-0.8,0.6-1.5,1.4-1.9,2.5c-0.1,0.3-0.2,0.5-0.2,0.8c-0.1,0.2-0.1,0.6-0.1,0.7c0,0.4-0.1,0.8-0.1,1.1
// 						c-0.1,0.8-0.2,1.6-0.3,2.4c-0.2,1.7-0.5,3.5-1,5.5c-1.1,4-2.6,7.8-4.6,11.4c-1.6,3.1-2.7,5.1-3.6,6.7c-0.9,1.6-1.5,2.7-2.2,3.9
// 						c-1.4,2.3-2.8,4.8-7.4,11c0.2-0.4-2.7,3.5-5.1,6.6c-2.4,3.1-4.2,5.3-1.5,1.7l-2,2.6c-0.7,0.9-1.4,1.7-2.1,2.7
// 						c-1.4,1.9-2.6,4-3.6,6.2c-0.9,2.2-1.6,4.6-1.9,7.1c-0.3,2.4-0.2,4.9,0.1,7.3c0.3,2.4,1,4.8,1.8,7.1c0.8,2.2,1.6,4.3,2.4,6.5
// 						c1.6,4.3,3.1,8.8,4.4,13.4c0.3,1.2,0.7,2.3,1,3.5c0.3,1.2,0.6,2.2,0.7,3.3c0.2,2.1-0.2,4.1-1.3,5.7c-0.6,0.8-1.4,1.5-2.3,2.1
// 						c-0.9,0.6-1.9,1-3.1,1.5c-2.4,1-4.7,2.1-7,2.9c-2.3,0.7-4.9,1.3-7.1,1.2c-2.2-0.1-4.1-1-5.5-3c-0.7-0.9-1.3-2-1.8-3.2
// 						c-0.3-0.6-0.5-1.3-0.8-1.9c-0.3-0.7-0.6-1.4-0.9-2.1c-1.4-2.7-3.3-5.3-5.6-7.5c-2.1-1.8-5.2-3.8-9-5.1
// 						c-1.9-0.7-3.9-1.2-5.9-1.6c-1.9-0.4-3.7-0.8-5.5-1.5c-7.2-2.6-13.2-7.5-16.1-9.8c-0.2-0.2-2.5-2.2-5.4-5
// 						c-2.9-2.7-6.6-6.3-10.2-8.8c-1.8-1.2-3.5-2.2-4.9-2.8c-1.4-0.6-2.5-0.9-2.8-1c-0.3-0.1,0.1-0.1,1.4,0.4
// 						c1.3,0.4,3.6,1.3,6.4,3.3c2,1.3,5.1,4,4.8,3.9c0.9,0.6,1.7,1.3,2.6,2c-2.4-2.3-4.9-4.6-7.8-6.5c-1.4-1-2.9-1.8-4.5-2.5
// 						c-0.8-0.4-1.6-0.7-2.5-0.9c-0.7-0.2-1.4-0.4-2.2-0.6c-2.6-0.8-5-2.3-7.2-4c-2.2-1.7-4.1-3.9-6.1-6.1c-2-2.2-4-4.5-6.3-6.7
// 						c-1.1-1-2.3-2.1-3.4-3.1c-1.2-1-2.3-2.1-4-3.1c-0.9-0.5-1.9-0.9-3.1-1c-1.2-0.1-2.4,0.2-3.3,0.6c-1.8,0.9-3.1,2-4.4,3.1
// 						c-2.4,2.2-4.7,4.4-7.1,6.8c1.4-1.4,2.9-2.8,4.4-4.2c1.4-1.3,2.8-2.6,4-3.5c0.6-0.5,1.1-0.8,1.5-1.1c0.4-0.2,0.7-0.4,0.7-0.4
// 						c0.2,0-0.6,0.3-2.1,1.5c-1.7,1.4-3.2,2.8-4.7,4.2c-1.5,1.4-2.9,2.8-4.4,4.3c-2.9,2.9-5.7,5.9-8.4,8.9l3.3-3.9
// 						c-5.7,6.1-11.1,12.3-16.4,18.6c-0.6,0.7-0.9,1.6-1.1,2.3s-0.2,1.3-0.2,1.9c0,1.2,0.2,2.3,0.4,3.4s0.6,2.1,1,3.1l1.2,2.7
// 						l-0.9-2.3c-0.4-0.9-0.6-1.8-0.8-2.7c0.2,0.6,0.3,1.2,0.6,1.7c0.1,0.3,0.2,0.6,0.3,0.8c0.1,0.3,0.2,0.5,0.3,0.8
// 						c0.4,0.9,0.8,1.8,1.1,2.6c0.7,1.7,1.2,3.3,1.7,4.9c0.9,3.1,1.5,6.1,1.9,9.1c0.7,6,0.9,12.4-0.6,22.1c-0.4,2-0.4,2.1-0.2,1.3
// 						c0.1-0.4,0.3-0.9,0.4-1.6c0.1-0.7,0.3-1.5,0.4-2.2c0.2-1.5,0.4-2.6,0.4-2.3c0,0.2-0.1,0.7-0.2,1.8c-0.1,0.5-0.2,1.2-0.3,2
// 						c-0.1,0.9-0.3,1.7-0.6,2.8c-0.8,2.6-2.2,5.3-4,7.8c-1.6,2.1-4.7,3.7-7.7,5.8c-5.9,4-12.5,8.2-18.2,14.1
// 						c-2.8,2.9-5.4,6.4-7.2,10.2c-0.9,1.9-1.7,3.9-2.3,5.9l-0.4,1.5l-0.2,0.8l-0.2,0.5c-0.2,0.7-0.6,1.4-1.1,2c-2,2.7-5.3,5-8.4,5.9
// 						c-1.5,0.4-2.8,0.3-3.8-0.4c-0.6-0.3-1-0.8-1.5-1.4l-0.8-1.1c-0.3-0.5-0.7-0.9-1.1-1.3c0.5,0.7,0.5,0.7,0.1,0.3
// 						c-0.4-0.4-1.2-1.2-2.3-2.1c-0.5-0.5-1.1-0.9-1.6-1.5c-0.6-0.6-1.2-1.2-1.8-1.9c-1.3-1.5-3.1-2.9-4.8-4.2l-0.7-0.8
// 						c-1.7-1.3-3.5-2.1-5-2.9c-0.8-0.3-1.6-0.6-2.3-0.9c-0.4-0.1-0.7-0.3-1.1-0.4c-0.3-0.1-0.7-0.2-1-0.3c-1.3-0.4-2.5-0.8-3.6-0.9
// 						c-1.1-0.2-2-0.4-2.8-0.5c-3.3-0.4-4.9-0.3-6.4-0.3c-1.6,0.1-3.1,0.1-6.4,0.9c-0.8,0.1-1.7,0.5-2.7,0.8
// 						c-0.5,0.2-1.1,0.4-1.6,0.5c-0.6,0.2-1.1,0.5-1.8,0.8c-0.6,0.3-1.3,0.6-2,0.9c-0.7,0.4-1.4,0.8-2.1,1.2
// 						c-0.8,0.4-1.5,0.9-2.3,1.5c-0.4,0.3-0.8,0.6-1.2,0.9c-0.2,0.2-0.4,0.3-0.6,0.5c-0.2,0.2-0.4,0.3-0.6,0.5l0.5-0.5l0.6-0.5l1.2-1
// 						l-1.8,1.4l-1.6,1.6l-0.8,0.8l-0.7,0.7c-0.4,0.5-0.9,0.9-1.4,1.3c-2.6,2-5.1,3.1-7.2,3.7c-2.1,0.6-3.9,0.6-5.3,0.5
// 						c-1.4-0.1-2.5-0.5-3.4-0.9c-0.2-0.1-0.5-0.2-0.7-0.3c-0.3-0.2-0.6-0.3-0.9-0.4c-0.6-0.2-1.1-0.4-1.6-0.6
// 						c-2.1-0.8-4.3-1.4-7.6-2.6c-1.8-0.7-2.9-1.2-4.5-3.3c-0.4-0.5-0.9-1.2-1.7-1.9c-0.2-0.2-0.4-0.4-0.7-0.5
// 						c-0.3-0.2-0.6-0.3-0.9-0.5c-0.3-0.1-0.6-0.2-1-0.3c-0.4-0.1-0.6-0.1-0.9-0.1c-1-0.1-2.2,0-3.3,0.3c-1.2,0.3-2.2,0.8-3.1,1.3
// 						c0.7-0.4,1.5-0.8,2.2-1c0.8-0.3,1.5-0.4,2.1-0.5c0.6-0.1,1.2,0,1.6,0c0.2,0,0.4,0,0.6,0.1c0.2,0,0.3,0,0.5,0.1
// 						c0.7,0.2,1,0.4,0.9,0.3c-0.1,0-0.6-0.2-1.3-0.2c-0.6,0-1.5-0.1-2.6,0.1c-0.6,0.1-1.2,0.3-1.8,0.5c-0.6,0.2-1.2,0.6-1.8,0.9
// 						c-1.2,0.7-2.3,1.3-3.6,1.5c-0.3,0-0.7,0.1-1.1,0.1c-0.2,0-0.3,0-0.4,0c-0.1,0-0.3-0.1-0.4-0.1c-0.6-0.2-1.4-0.6-2.2-1.1
// 						c1.1,0.6,2.3,1.1,2.9,1c-0.5,0-1.3-0.3-2-0.7c-0.7-0.4-1.5-0.9-2.2-1.2c-0.8-0.4-1.6-0.8-2.6-0.9c-0.2,0-0.5-0.1-0.7-0.1
// 						c-0.2,0-0.5,0-0.8,0l-0.4,0c-0.1,0-0.2,0-0.3,0.1l-0.5,0.1c-2.6,0.6-4.7,1.3-7.5,2.4c2.4-1.1,3.1-1.3,1.6-0.9
// 						c-1.5,0.5-5.3,1.9-10.7,5.6c2.4-1.7,4.9-3.3,7.5-4.7c-1.9,0.9-3.7,2-5.5,3.1c-0.9,0.6-1.7,1.1-2.6,1.7
// 						c-0.8,0.6-1.6,1.1-2.4,1.6c-1.6,1-3.2,1.9-4.9,2.5c-0.9,0.3-1.6,0.5-2.6,0.7l-2.9,0.5c-2.9,0.4-6.2,0.7-9.5,1.2
// 						c-3.4,0.6-7,1.3-10.5,2.7c-0.4,0.2-0.9,0.3-1.3,0.5l-0.8,0.4l-0.2,0.1c0,0.1-0.2,0.1-0.2,0.2c-0.2,0.1-0.3,0.2-0.4,0.4
// 						c-0.1,0.1-0.3,0.3-0.4,0.4l-0.3,0.5l-0.4,0.7c-0.2,0.4-0.5,0.8-0.7,1.2c-0.4,0.8-0.8,1.6-1.2,2.4l-0.6,1.1l-0.3,0.5l-0.2,0.3
// 						c-0.1,0.1-0.1,0.2-0.2,0.2c-0.1,0-0.2,0-0.3,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.3-0.1-0.5-0.1c-0.3-0.1-0.7-0.2-1.1-0.3
// 						c-0.8-0.2-1.6-0.5-2.5-0.7c-0.2,0-0.5-0.1-0.8-0.1c-0.3,0-0.6,0-1,0c-0.3,0-0.7,0.1-1,0.2c-0.3,0.1-0.6,0.2-0.9,0.4
// 						c-0.4,0.3-0.8,0.5-1.2,0.8c-0.4,0.2-0.7,0.5-1,0.7c-0.7,0.5-1.3,1-2,1.5c-1.3,1-2.4,1.9-3.4,2.7c-2,1.7-3.6,3-4.7,3.8
// 						c0,0-0.1,0.1-0.3,0.3c-0.2,0.2-0.6,0.5-0.9,0.7c-0.7,0.5-1.8,1.1-3.2,1.2c-1.4,0.1-3.2-0.3-4.6-1.5c-0.3-0.3-0.6-0.7-0.9-1
// 						c-0.4-0.5-0.8-1.1-1.2-1.7c-0.8-1.1-1.8-2.2-2.7-3.3c-1.9-2.1-4-4.2-6-6.1c-1-1-2-1.9-2.9-2.8c-0.3-0.3-0.6-0.6-0.9-0.9
// 						c-0.1-0.1,0-0.2-0.1-0.2l0-0.2l0-0.4c0-2.3,0.4-4.5,0.7-6.4c0.2-1.4,0.4-2.6,0.5-3.7c-0.1,1-0.1,0.9,0.1,0.2
// 						c0.2-0.8,0.5-2.7,0.6-4.7c0-1,0-2.1,0-3c-0.1-1-0.2-1.7-0.3-2.3c-0.1-0.6-0.1-0.9-0.1-0.9c0,0,0.2,0.4,0.3,1.2
// 						c-0.3-1.4-0.7-3-1.4-5c-0.7-1.9-1.9-4.2-4-6c-1-1-2.3-1.7-3.7-2.2c-0.7-0.2-1.4-0.5-2.1-0.6c-0.7-0.2-1.4-0.2-2.2-0.2
// 						c-0.7,0-1.4,0-2.1,0c-0.7,0.1-1.4,0.1-2.1,0.2c-0.7,0.1-1.3,0.3-2,0.4c-0.6,0.1-1.2,0.2-1.8,0.3c-2.4,0.4-4.8,0.4-7,0
// 						c-2.3-0.4-4.4-1.2-6.3-2.3c-1.9-1.1-3.5-2.3-5-3.6c-0.7-0.6-1.3-1.2-2.1-1.9c-0.7-0.6-1.4-1.2-2-1.7c0.7,0.5,1.3,1,1.9,1.5
// 						c-2.3-2-4.7-3.8-7.5-5c-1.4-0.6-3-1.1-4.7-1.2c-0.7,0-1.3,0-2,0c-0.6,0-1.2-0.1-1.8-0.2c-4.7-0.6-8.9-2.9-12.7-5.8
// 						c-1-0.7-1.9-1.5-2.8-2.3l-0.7-0.6c-0.4-0.3-0.8-0.6-1.2-0.8c-0.8-0.4-1.6-0.7-2.3-0.9c-1.5-0.3-2.8-0.4-4-0.5
// 						c-1.2-0.1-2.4-0.1-3.4-0.3c-0.2,0-0.5-0.1-0.8-0.2l-0.8-0.2l-1.7-0.4c-1.1-0.3-2.3-0.5-3.4-0.8c-1.1-0.2-2.3-0.6-3.5-0.9
// 						c-1.2-0.2-2.4-0.5-3.6-0.7c-4.8-0.8-9.7-1.1-14.5-0.6c-4.8,0.5-9.7,1.7-14.2,4.2c-1.1,0.6-2.2,1.3-3.2,2
// 						c-0.5,0.3-1,0.8-1.5,1.2c-0.5,0.4-1,0.8-1.5,1.3l-1.4,1.4l-0.3,0.4c-0.1,0-0.1,0.1-0.2,0.2c-0.1,0.1-0.3,0.2-0.4,0.3
// 						c-0.7,0.4-1.6,0.8-2.6,1c-1,0.2-2.1,0.4-3.3,0.5c-1.2,0.1-2.4,0.2-3.7,0.2c-2.6,0.2-5.3,0.8-7.9,1.8c0.5-0.1-0.5,0.3-2.1,1.1
// 						c-1.6,0.9-3.8,2.4-5.5,4.2c-0.9,0.9-1.6,1.9-2.2,2.8c-0.5,0.9-1,1.7-1.3,2.3c-0.5,1.2-0.7,1.6,0,0.3c-1.2,2.6-1.9,5.3-2.3,8
// 						c0,0.7-0.1,1.4-0.2,2c0,0.6-0.1,1.2-0.1,1.8c-0.1,1.2-0.3,2.3-0.6,3.4c-0.3,1-0.7,2-1.3,2.9c-0.6,0.8-1.3,1.5-2.1,2.2
// 						c-1.7,1.2-3.8,1.9-6,2.4c-2.2,0.5-4.7,1-7,2.1c-1.2,0.6-2.3,1.3-3.3,2.3c-1,1.1-1.6,2.5-1.8,3.8c-0.9,5.2,1,9.2,1,12.8
// 						c0.1,1.9,0,3.7-0.1,5.4c-0.1,0.9-0.2,1.8-0.4,2.5c-0.2,0.8-0.4,1.5-0.7,2.1c-1.2,2.7-3.4,4.6-5.8,5.5c-1.2,0.5-2.4,0.6-3.5,0.7
// 						c-1.2,0-2.2-0.2-3.2-0.5c-2.4-0.8-4.5-1.4-6.4-1.9c-9.2-2.9-15.2-3.3-19.8-3.3c-2.3,0.1-4.4,0.2-6.4,0.5
// 						c-1,0.1-2.1,0.4-3.2,0.6c-0.5,0.1-1.1,0.3-1.7,0.5c-0.5,0.2-1.1,0.3-1.7,0.5l2.2-0.7c0.3-0.1,0.6-0.2,1-0.3
// 						c-0.8,0.2-1.6,0.5-2.4,0.7c-1.1,0.3-2.1,0.6-3,0.7c-1.9,0.1-2.7-0.5-3.4-2.1c-0.6-1.6-0.9-3.6-1-5.3c-0.1-1.8,0-3.6,0-5.4
// 						c0-1.8,0-3.6-0.1-5.3c-0.1-1.6-0.2-3.2-0.2-4.7c-0.1-1.6-0.1-3.1-0.3-4.8c-0.1-0.8-0.2-1.6-0.6-2.5c-0.1-0.4-0.4-1-0.8-1.5
// 						c-0.3-0.5-0.9-0.8-1.4-1.1l-0.6-0.2c-0.1,0-0.3-0.1-0.4-0.2l-0.9-0.3c-0.6-0.1-1.2-0.3-1.7-0.3c-1.2-0.2-2.3-0.2-3.5-0.2
// 						c-2.3,0-4.6,0.4-7,1c-1.2,0.3-2.3,0.6-3.6,1.2c-1.2,0.5-2.4,1.2-3.6,2.1c-2.2,1.8-4.1,4.2-5.3,7.1c-0.7,1.7-1.1,3.2-1.3,4.7
// 						c-0.1,0.7-0.2,1.4-0.3,2.1c-0.1,0.6-0.1,1.2-0.2,1.8c-0.3,2.3-0.9,4.3-1.9,6.5c-0.5,1.1-1.2,2.2-2.1,3.3
// 						c-0.9,1.1-2,2.2-3.4,3.2c-1.4,1-3.2,1.9-5.3,2.5c-2.1,0.6-4.6,0.9-7.4,0.5c0.9,0.1,1.5,0.1,2,0.1s0.6,0,0.6,0c0,0-0.2,0-0.5,0
// 						c-0.3,0-0.8,0-1.4-0.1c-1.2-0.1-2.9-0.4-4.7-0.9c-1.6-0.5-3.2-1.2-4.8-2.1c1.9,1,3.1,1.4,2.9,1.3c-0.1-0.1-0.7-0.3-1.9-1
// 						c-1.2-0.6-2.8-1.6-5-3.4c-1.4-1-3.8-2.5-6.2-3.2c-0.1,0-0.6-0.2-0.3-0.1c-3.9-1.2-6.7-1-8.5-1c-1.8,0.1-2.7,0.2-2.8,0.2
// 						c-0.3,0.1,2-0.2,5.6-0.1c-0.4,0,0.2,0,2.5,0.4c-4-0.7-8.9-0.1-13.5,0.3c-2.3,0.2-4.6,0.1-7-0.1c-1.2-0.1-2.3-0.2-3.5-0.4
// 						c-1.2-0.1-2.4-0.2-3.6-0.2c-4.8,0-9,1.4-11.9,2.6c-2.9,1.2-4.8,2.1-5.2,2.2c-5,2.5-9.7,4.7-14.7,5.9c-2.5,0.6-4.9,1.1-7.4,1.3
// 						c-2.4,0.3-4.9,0.4-7.3,0.4c-4.8,0-9.5-0.6-13.9-1.7c-1.1-0.3-2.2-0.6-3.3-0.9c-1.1-0.3-2.2-0.6-3.3-1c-2.2-0.6-4.4-1.3-6.5-2
// 						c-4.3-1.4-8.4-3-12.3-5c-1.9-1-3.8-2.1-5.5-3.4c-0.9-0.7-1.8-1.3-2.6-2l-1.3-1.1c-0.5-0.4-0.9-0.8-1.4-1.1
// 						c-1-0.7-2.1-1.3-3.4-1.6c-1.2-0.3-2.6-0.3-3.9,0.1c-1.3,0.4-2.3,1.1-3.3,1.9c-0.9,0.8-1.7,1.7-2.4,2.6
// 						c-0.7,0.9-1.4,1.7-2.1,2.6l-1.1,1.3l-0.5,0.6l-0.5,0.5c-1.4,1.3-3.6,1.7-5.5,1.1c-0.4-0.2-0.7-0.3-0.7-0.4
// 						c-0.1-0.1,0-0.1,0.1,0c0.3,0.1,1.1,0.5,2.3,0.5c0.6,0,1.3-0.1,2-0.4c0.7-0.3,1.4-0.8,2-1.5c1.4-1.8,3-3.8,4.7-5.9l-4.2,5.1
// 						l-0.5,0.6c-0.2,0.2-0.3,0.3-0.5,0.5c-0.1,0.2-0.3,0.3-0.5,0.4c-0.2,0.1-0.3,0.3-0.5,0.3c-0.8,0.4-1.6,0.6-2.5,0.6
// 						c-1.8,0-3.5-1-4.4-2.4c-0.2-0.4-0.4-0.8-0.6-1.2l-0.1-0.6c-0.1-0.2-0.1-0.5-0.1-0.8l-0.6-3.3c0.1,0,0.3,1,0.6,2.5
// 						c0.1,0.4,0.1,0.8,0.2,1.2c0,0.2,0.1,0.4,0.1,0.6c0,0.2,0.1,0.4,0.2,0.5c0.3,0.7,0.8,1.5,1.5,2c1.4,1.2,3.7,1.5,5.5,0.6
// 						c0.9-0.5,1.5-1,2.3-2c0.7-0.9,1.4-1.7,2-2.5l-1.8,2.2l-0.9,1.1c-0.3,0.3-0.5,0.5-0.9,0.7c-1.2,0.9-2.8,1.1-4.2,0.7
// 						c-1.4-0.5-2.7-1.5-3.2-2.9c-0.2-0.3-0.2-0.7-0.3-1.1l-0.2-1.5c-0.2-1-0.4-2-0.5-3c-0.4-2-0.8-4.1-1.4-6.1
// 						c-0.2-0.5-0.3-1-0.6-1.6l-0.6-1.4c-0.4-0.9-0.7-1.9-1.1-2.8c-1.4-3.8-2.5-7.9-4.3-11.8c-0.9-2-1.9-4-3.1-5.8
// 						c-1.3-1.9-2.7-3.5-4.4-5.1c-0.8-0.8-1.7-1.5-2.7-2.2c-1-0.7-2-1.3-3.2-1.7c-0.6-0.2-1.3-0.3-2-0.4l-1.6-0.1
// 						c-1-0.1-2.1-0.1-3.2-0.3c-8.5-1-16.6-4.9-25.4-7.7c-1.1-0.3-2.2-0.8-3.3-1.3c-1-0.5-2-1.2-2.9-1.9l-0.7-0.7
// 						c-0.2-0.2-0.4-0.5-0.6-0.8c0,0-0.3-0.3-0.8-0.9c-0.2-0.3-0.5-0.7-0.8-1.1c-0.2-0.5-0.5-1-0.8-1.6c-0.5-1.2-1-2.6-1.2-4
// 						c-0.2-0.7-0.2-1.4-0.3-2.2c-0.1-0.8-0.2-1.5-0.3-2.2c-0.4-2.6-0.2-2.6-0.5-4.1c-0.1-0.4-0.2-0.9-0.4-1.5
// 						c-0.1-0.3-0.2-0.7-0.3-1.1c-0.2-0.4-0.3-0.9-0.5-1.4c-0.2-0.5-0.4-1.1-0.7-1.7c-0.3-0.6-0.6-1.3-1-2c-0.8-1.5-1.9-3.2-3.5-5
// 						c-1.2-1.5-2.8-2.9-4.7-4.2c-1.9-1.3-4.2-2.3-6.6-3c-0.6-0.2-1.2-0.3-1.9-0.5c-0.6-0.1-1.2-0.3-1.8-0.4
// 						c-0.6-0.1-1.2-0.3-1.8-0.5c-0.6-0.2-1.2-0.3-1.8-0.5c-2.4-0.8-4.8-1.7-7.1-2.9c-4.6-2.4-9-5.6-12.9-9.2c-1-0.9-1.9-1.8-2.8-2.7
// 						c-0.5-0.5-0.9-0.9-1.4-1.4c-0.5-0.5-1-0.9-1.5-1.4c-1.7-1.5-3.6-2.8-5.5-3.8c1.8,1,3,1.9,3.7,2.5c0.9,0.7,1.1,1,1.1,1
// 						c-0.1,0-1.6-1.3-3.6-2.5c-4-2.5-7.7-3.5-11.1-4c-3.4-0.5-6.6-0.5-10.2,0l-0.7-0.2c-0.3,0.1-1.2,0.2-2.4,0.4
// 						c-1,0.2-2.3,0.4-3.7,0.5c-0.7,0-1.5,0.1-2.3,0.1c-0.8,0-1.7,0.2-2.6,0.3c-3.5,0.5-7.3,1.9-9.7,4c-2.5,2.1-3.5,4.5-3.9,5.3
// 						c-0.6,1.2-0.5,1-0.1-0.1c0.5-1.1,1.5-3.1,3.3-4.8c1.7-1.7,4.2-3,6.2-3.7c0.5-0.2,1-0.3,1.5-0.5c0.5-0.1,0.9-0.2,1.3-0.3
// 						c0.8-0.2,1.3-0.3,1.6-0.3c-2.4,0.3-4.5,0.9-6.4,1.7c-1.9,0.9-3.4,2-4.6,3.2c-2.4,2.5-3.4,5-4,7c-1.1,4.1-1.1,6.5-1.1,8.8
// 						c0,2.3,0.1,4.6,0,8.5c-0.1,2-0.1,4.3-0.7,7.2c-0.2,0.7-0.3,1.5-0.5,2.3c-0.2,0.8-0.4,1.7-0.5,2.7c-0.3,1.9-0.6,4-0.8,6.3
// 						c-0.3,5,0.1,7.2,0.2,8c0.1,0.7,0,0,0-1.1c0-1.1,0-2.5,0-3.1c0-0.5,0.1-0.2,0.2,2.1c0.2,4.4,1,8.7,2.3,12.8
// 						c0.7,2.1,1.4,4.1,2.3,6.1c0.8,1.9,1.1,4,1,6.1c-0.1,2.1-0.5,4.3-1.4,6.2c-0.4,1-1,1.8-1.8,2.7c-0.7,0.9-1.5,1.7-2.2,2.6
// 						c-3,3.5-6.2,6.9-9.7,10.4c1.5-1.4,3-2.8,4.5-4.3c-5.4,5.9-10.7,11-15.6,15.5c-2.5,2.2-4.8,4.5-7.5,5.8
// 						c-1.4,0.7-2.8,1.1-4.2,1.2c-1.5,0.1-2.9-0.2-4.2-0.7c-1.3-0.5-2.6-1.2-3.7-2c-1.2-0.8-2.2-1.7-3.2-2.6c-2.1-1.8-4-3.7-5.9-5.6
// 						c-3.8-3.8-7.4-7.6-11.2-11.5c-5.3-5.3-10.3-10.4-15.7-14.5c-5.3-4.1-11-7.1-16.6-8.9c-5.7-1.7-10.9-3.5-15.6-5.5
// 						c-4.8-2-9-4.3-12.7-6.8c-7.4-5.1-12.4-11-15.7-16.5c-0.9-1.4-1.6-2.6-2.5-3.6c-0.9-1-1.9-1.9-2.8-2.7c-1.9-1.6-3.8-2.9-5.4-4
// 						c-1.6-1.1-3.1-2.2-4.3-3.1c-1.2-0.9-2.1-1.7-2.9-2.3c-1.6-1.2-2.5-1.9-2.7-2c-0.6-0.3,1.2,1.4,4.4,4.4c1.7,1.5,3.9,3.2,6.2,4.9
// 						c2.3,1.8,4.8,3.7,6.7,6.1c0.2,0.3,0.4,0.6,0.6,0.9l0.6,1c0.5,0.8,0.9,1.6,1.4,2.4c0.5,0.8,1,1.6,1.5,2.4c0.5,0.8,1,1.6,1.6,2.3
// 						c2.2,3,4.9,5.8,7.7,8.2c1.4,1.2,2.8,2.3,4.2,3.3c1.4,1,2.8,2,4.2,2.8c2.7,1.7,5.3,3,7.5,4c2.2,1,4,1.8,5.3,2.3
// 						c1.3,0.5,2,0.8,2,0.8c2.8,1.1,5.6,2,8.4,2.9l1,0.3l1,0.3c0.6,0.2,1.3,0.4,1.9,0.7c1.2,0.5,2.5,1,3.6,1.6
// 						c4.7,2.3,9,5.4,12.8,8.8c3.9,3.4,7.4,7.1,11,10.7c3.6,3.6,7.1,7.2,10.5,10.7c1.8,1.8,3.5,3.5,5.3,5.2c0.9,0.8,1.8,1.7,2.7,2.5
// 						c1,0.8,2,1.7,3.1,2.4c2.2,1.5,4.7,2.9,7.7,3.3c3,0.5,6.1-0.4,8.5-1.7c0.6-0.3,1.2-0.7,1.8-1c0.5-0.4,1.1-0.8,1.6-1.2l1.5-1.2
// 						l1.3-1.2c1.8-1.6,3.5-3.2,5.2-4.8c3.5-3.3,6.9-6.6,10.2-10.2c3.3-3.5,6.5-7.1,9.7-11c0.2-0.2,0.4-0.5,0.6-0.7
// 						c0.2-0.3,0.5-0.7,0.7-1c0.4-0.7,0.7-1.3,1-2c0.5-1.4,0.9-2.7,1.1-4.1c0.4-2.8,0.5-5.5-0.3-8.5c-0.5-1.7-1-2.6-1.5-3.7
// 						c-0.4-1.1-0.9-2.3-1.2-3.4c-0.7-2.3-1.3-4.7-1.7-7.2c-0.8-4.9-0.8-10,0-15.2c0.1-0.6,0.2-1.3,0.3-1.9c0.1-0.7,0.2-1.3,0.4-2
// 						c0.3-1.2,0.7-2.8,0.8-4.3c0.4-3,0.5-5.9,0.5-8.9c0-2.9-0.1-5.9,0.1-8.6c0.2-2.8,0.8-5.5,2.1-7.8c0.6-1.1,1.4-2.2,2.4-3
// 						c0.5-0.5,1-0.8,1.6-1.2c0.3-0.2,0.6-0.3,0.9-0.5c0.3-0.2,0.6-0.3,0.9-0.4c2.6-1.1,5.5-1.5,8.7-1.6c3.6-0.1,6.9-0.9,10-1.1
// 						c3.3-0.2,6.5-0.1,9.7,0.6c2.8,0.7,5.4,1.8,7.6,3.2c1.2,0.7,2.2,1.6,3.2,2.4c0.5,0.4,1,0.9,1.4,1.4c0.5,0.5,1,1,1.5,1.6
// 						c4.1,4.1,8.4,7.7,13,10.5c4.6,2.9,9.5,4.9,14.3,6.1c0.6,0.2,1.2,0.3,1.8,0.4c0.6,0.2,1.1,0.3,1.6,0.4l0.8,0.2
// 						c0.2,0.1,0.5,0.2,0.8,0.3c0.5,0.2,1,0.3,1.5,0.6c1.9,0.8,3.6,2,5.1,3.3c0.8,0.7,1.4,1.4,2.1,2.1c0.6,0.8,1.2,1.6,1.8,2.4
// 						c1.1,1.7,2,3.5,2.6,5.3c0.4,0.9,0.6,1.9,0.9,2.8c0.2,1,0.5,1.9,0.6,2.9c0.2,1,0.3,1.9,0.4,2.9l0.2,1.6c0.1,0.5,0.2,1.1,0.3,1.7
// 						c0.4,2.2,1.1,4.4,2.3,6.4c1.1,2,2.7,3.8,4.5,5.3c1.8,1.4,3.9,2.4,5.9,3.1c1.1,0.4,1.9,0.6,2.9,1l2.8,1c3.7,1.3,7.5,2.8,11.4,4
// 						c4,1.2,8.2,2.2,12.5,2.5l1.7,0.1l0.4,0l0.2,0c0.1,0,0.3,0,0.4,0.1c0.6,0.2,1.4,0.5,2.1,0.9c1.5,0.9,2.9,2.3,4.2,3.8
// 						c0.7,0.8,1.3,1.5,1.9,2.4c0.6,0.8,1.1,1.6,1.6,2.6c1,1.8,1.8,3.8,2.6,5.9c0.8,2.1,1.5,4.3,2.2,6.6c0.4,1.1,0.8,2.3,1.2,3.5
// 						c0.2,0.6,0.5,1.2,0.7,1.8c0.2,0.5,0.5,1.1,0.7,1.7c0.7,2,1.2,4.4,1.7,6.9c0.2,1.3,0.4,2.5,0.6,3.8c0,0.3,0.2,0.8,0.3,1.2
// 						c0.1,0.5,0.3,0.9,0.5,1.3c0.4,0.9,0.9,1.7,1.5,2.4c2.5,2.9,6.8,4,10.5,2.4c0.4-0.2,0.9-0.4,1.3-0.7c0.4-0.3,0.8-0.5,1.2-0.9
// 						l0.5-0.5l0.3-0.3c0.1-0.1,0.1-0.1,0.2-0.2l0.7-0.9c1-1.2,1.9-2.3,2.9-3.5c0.8-1,1.6-2,2.5-2.6c0.9-0.6,1.8-1,2.6-1
// 						c0.8,0,1.8,0.4,2.9,1.1c0.2,0.2,0.5,0.4,0.8,0.6l0.8,0.7c0.6,0.5,1.3,1.1,1.9,1.6c5.1,4.1,11,6.9,17,9.1c3,1.1,6,2,9,2.9
// 						l4.5,1.3c1.5,0.4,3.1,0.9,4.7,1.2c12.7,2.8,26.5,1.9,38.5-3.4c6-2.6,11.2-5.9,16.8-7.1c1.4-0.3,2.8-0.5,4.2-0.5
// 						c0.7,0,1.4,0,2.1,0c0.7,0.1,1.3,0.1,2.2,0.2c3.2,0.4,6.4,0.6,9.7,0.5c0.8,0,1.7-0.1,2.5-0.2l2.3-0.3c1.5-0.2,3-0.3,4.4-0.3
// 						c2.9-0.1,5.6,0.2,8,1.2c1.2,0.5,2.4,1.2,3.5,1.9l1.8,1.3c0.7,0.5,1.3,0.9,2,1.4c2.8,1.7,5.8,3.1,9.1,3.8c2.4,0.5,5,0.7,7.8,0.4
// 						c1.4-0.1,2.8-0.4,4.2-0.9c1.4-0.4,2.8-1,4.2-1.8c1.3-0.7,2.6-1.7,3.8-2.8c1.2-1.1,2.3-2.3,3.2-3.7c1.8-2.7,3.1-5.8,3.7-9.1
// 						c0.2-0.8,0.3-1.6,0.4-2.5l0.1-1.1c0-0.3,0.1-0.7,0.2-1c0.2-1.4,0.7-2.7,1.2-4c1.1-2.5,2.8-4.7,5.1-6.1c1.1-0.7,2.4-1.2,3.8-1.6
// 						c1.4-0.4,2.8-0.7,4.2-0.9s2.8-0.3,4.2-0.2c0.7,0,1.3,0.1,2,0.3c0.3,0.1,0.6,0.1,0.9,0.2c0.2,0,0.3,0.1,0.4,0.1l0.2,0.1l0,0
// 						c0.1,0,0.1,0.1,0.2,0.2c0,0,0,0,0.1,0.1c0,0.1,0.1,0.2,0.1,0.4c0.2,0.6,0.3,1.4,0.4,2.2c0.2,1.6,0.2,3.2,0.3,4.9
// 						c0,0.8,0.1,1.7,0.1,2.5c0.1,0.8,0.1,1.6,0.2,2.4c0.1,1.5,0.1,3,0,4.5c-0.1,1.7-0.2,3.5,0,5.4c0,1,0.2,1.9,0.3,2.8
// 						c0.1,0.9,0.3,1.9,0.7,2.9c0.1,0.3,0.3,0.7,0.5,1c0.2,0.3,0.4,0.7,0.6,1c0.2,0.4,0.6,0.7,0.8,1c0.3,0.3,0.7,0.6,1.1,0.8
// 						c1.7,1,3.5,1.1,5,0.9c0.4,0,0.8-0.1,1.1-0.2c0.3-0.1,0.7-0.1,1.1-0.2c0.6-0.2,1.3-0.4,1.9-0.6c0.6-0.2,1.1-0.4,1.7-0.5l1.7-0.4
// 						c2.3-0.5,4.7-0.8,7-0.9c2.3,0,4.7,0.1,6.9,0.4c-0.2,0-0.4,0-0.6,0c3.3,0.3,6.7,1,10,1.9c1.7,0.5,3.3,1,4.9,1.5
// 						c0.8,0.3,1.6,0.5,2.3,0.8c0.4,0.1,0.8,0.3,1.2,0.4c0.5,0.2,0.9,0.3,1.4,0.4c3.8,1,7.6,0.3,10.3-1.2c2.8-1.5,4.5-3.7,5.5-5.4
// 						l-0.3,0.6c0.9-1.4,1.5-3,1.9-4.6c0.2-0.8,0.3-1.5,0.4-2.3c0.1-0.7,0.2-1.5,0.2-2.2c0.2-3,0.1-6-0.5-9c0.2,1,0.4,2,0.5,3.1
// 						c-0.1-0.8-0.2-1.6-0.3-2.4l-0.5-2.2c-0.3-1.4-0.5-2.8-0.5-4.1c0-1.3,0.1-2.5,0.6-3.3c0.4-0.8,1.2-1.5,2.4-2
// 						c1.2-0.5,2.5-1,3.9-1.3l2.2-0.5c0.8-0.2,1.6-0.4,2.3-0.6c1.6-0.5,3.1-1.2,4.6-2.1c1.5-1,2.8-2.3,3.8-3.8c1-1.5,1.5-3.2,1.9-4.8
// 						c0.4-1.6,0.5-3.2,0.6-4.8c0.1-2.7,0.7-5.4,1.7-7.8c1-2.4,2.5-4.5,4.4-6.1c1.9-1.6,4.1-2.8,6.5-3.5c-0.5,0.1-1.1,0.1-1.7,0.3
// 						c2.5-1,5.1-1.5,7.6-1.6c0.6-0.1,1.3-0.1,2-0.1c0.7,0,1.4-0.1,2.1-0.2c1.4-0.2,2.8-0.4,4.1-0.9c1.4-0.5,2.8-1.1,4-2.4
// 						c0.4-0.4,0.8-0.8,1.2-1.2l0.6-0.6l0.6-0.5c0.4-0.3,0.8-0.7,1.2-1c0.5-0.3,0.9-0.6,1.3-0.9c0.8-0.6,1.8-1,2.7-1.6
// 						c3.7-1.8,7.6-2.7,11.4-3.1c3.8-0.4,7.6-0.3,11.3,0.2c0.9,0.1,1.8,0.3,2.8,0.4c0.9,0.2,1.8,0.4,2.7,0.6c0.9,0.2,1.8,0.5,2.8,0.7
// 						c1,0.2,1.9,0.5,2.9,0.7s1.9,0.5,2.9,0.7l1.5,0.3c0.5,0.1,1.1,0.2,1.6,0.3c2.1,0.3,4.2,0.2,5.9,0.6c0.9,0.2,1.6,0.5,2,0.8
// 						c0.8,0.7,1.6,1.4,2.5,2.1c1.7,1.4,3.6,2.8,5.7,4c2.1,1.2,4.5,2.3,7,3c1.3,0.3,2.6,0.6,4,0.7c0.7,0.1,1.4,0.1,2.1,0.1l0.5,0
// 						l0.3,0l0.2,0c0.2,0,0.5,0,0.8,0.1c-0.2,0-0.3,0-0.5,0c1.2,0.1,2.6,0.5,3.8,1.1c1.3,0.6,2.5,1.4,3.7,2.2
// 						c0.6,0.4,1.2,0.9,1.7,1.3c0.5,0.5,1,0.9,1.7,1.5c1.2,1.1,2.4,2,3.7,3c2.5,1.8,5.3,3.3,8.3,4.1c3,0.9,6.1,1,9,0.8
// 						c0.7-0.1,1.5-0.2,2.2-0.3c0.7-0.1,1.4-0.3,2.1-0.4c1.2-0.2,2.5-0.4,3.7-0.4c2.4-0.1,4.6,0.5,6.2,1.9c1.6,1.4,2.7,3.5,3.3,5.8
// 						c0.3,1.1,0.6,2.3,0.8,3.5c0.2,1.2,0.3,2.3,0.2,3.5c-0.1,2.4-0.5,4.8-1,7.4c-0.2,1.3-0.5,2.6-0.7,4c-0.1,0.7-0.2,1.4-0.2,2.1
// 						c0,0.3,0,0.7-0.1,1.1l0,0.5l0,0.3l0,0.4c0,0.5,0,1.1,0.1,1.7l1.1,1.2l1.5,1.5c1,0.9,1.9,1.9,2.9,2.8c1.9,1.9,3.8,3.8,5.5,5.8
// 						c0.9,1,1.7,2,2.5,3.1c0.4,0.5,0.7,1,1.3,1.8c0.3,0.4,0.6,0.7,0.9,1c0.3,0.3,0.7,0.6,1.1,0.8c3,2.1,7,2.4,10.4,0.8
// 						c0.8-0.4,1.6-0.9,2.3-1.5c0.6-0.5,1.2-1,1.7-1.5c1.1-1,2.3-2,3.5-3c1.2-1,2.3-2,3.6-3c0.6-0.5,1.2-1,1.9-1.4l1-0.7l0.9-0.6
// 						c0.1-0.1,0.2-0.1,0.3-0.1c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.4,0c0.4,0.1,0.9,0.2,1.4,0.3c-1.1-0.3-1.4-0.3-1.4-0.3
// 						c0,0,0.3,0.1,1,0.3c0.7,0.2,1.6,0.5,2.4,0.7c0.7,0.2,1.8,0.3,1.5,0.3c1.4,0.2,2.8-0.2,3.7-1.3c0.2-0.3,0.4-0.5,0.6-0.9l0.2-0.4
// 						l0.2-0.3l0.6-1.1c0.7-1.5,1.4-2.9,2.1-4.1c0.2-0.4,0.2-0.3,0.3-0.4c0.1-0.1,0.1,0,0.5-0.2c0.7-0.3,1.4-0.5,2-0.8
// 						c1.4-0.5,2.8-0.9,4.2-1.2c5.8-1.4,11.5-1.8,16.5-2.7c0.3,0,0.6-0.1,0.9-0.1c0.3-0.1,0.7-0.1,1-0.2c0.7-0.1,1.3-0.3,1.9-0.5
// 						c1.2-0.4,2.3-0.9,3.2-1.4c1.9-1,3.5-2.1,4.7-3c2.4-1.7,3.8-2.6,3.8-2.8c3.9-2.2,6.9-3.3,9-4c1.1-0.3,1.9-0.5,2.6-0.7
// 						c0.3-0.1,0.7-0.1,0.9-0.2c0.2,0,0.3,0,0.4,0c0.5,0.1,0.8,0.2,1,0.3c0.2,0.1,0.4,0.3,0.8,0.5c0-0.1,0.7,0.3,2.4,1.2
// 						c0.4,0.2,0.9,0.5,1.5,0.7c0.6,0.2,1.4,0.4,2.2,0.4c1.4,0,3-0.3,4.5-1.1c1.5-0.7,2.7-1.5,3.9-1.8c0.6-0.1,1.3-0.2,2-0.1
// 						c0.4,0,0.5,0.1,0.7,0.2c0.2,0.1,0.4,0.3,0.7,0.5c0.5,0.5,1,1.2,1.6,2c0.6,0.8,1.4,1.5,2.2,2.1c0.9,0.6,1.8,1.1,2.7,1.4
// 						c0.9,0.4,1.8,0.7,2.6,1c-1.1-0.4-2.1-0.7-3.2-1.2c-0.8-0.3-1.6-0.8-2.3-1.3c0.5,0.4,1,0.7,1.6,1c0.9,0.4,1.7,0.8,2.5,1.1
// 						c1.7,0.6,3.3,1.1,5,1.7c0.8,0.3,1.6,0.5,2.4,0.8c0.4,0.1,0.8,0.3,1.1,0.4c0.2,0.1,0.4,0.1,0.5,0.2l0.6,0.3
// 						c1.7,0.8,3.6,1.2,5.5,1.3c3.8,0.2,7.5-0.8,10.7-2.5c1.6-0.9,3.2-1.9,4.6-3l2-1.8l1.9-1.6l-3.1,2.4c-1.1,0.9-2.3,1.7-3.5,2.4
// 						c1.2-0.8,2.2-1.5,3.1-2.3c0.9-0.8,1.5-1.3,2.2-1.9c1.2-1.1,2.3-1.7,3-2.2c1.5-1,2.1-1.2,2.7-1.5c0.3-0.1,0.6-0.3,0.9-0.5
// 						c0.2-0.1,0.4-0.2,0.7-0.4c0.3-0.1,0.6-0.3,1-0.4c0.4-0.2,0.8-0.4,1.4-0.6c0.3-0.1,0.5-0.3,0.9-0.4s0.7-0.2,1-0.3
// 						c0.7-0.2,1.5-0.6,2.5-0.8c0.5-0.1,1-0.2,1.5-0.4c0.6-0.1,1.1-0.3,1.8-0.3l-0.3-0.4c3.6-0.6,7.2-0.5,10.4,0.1
// 						c0.8,0.2,1.6,0.3,2.4,0.5c0.8,0.2,1.6,0.5,2.3,0.7s1.5,0.6,2.2,0.9l1.1,0.5c0.3,0.2,0.7,0.4,1,0.6c0.7,0.4,1.3,0.7,2,1.1
// 						c0.6,0.4,1.2,0.9,1.8,1.3c0.3,0.2,0.6,0.4,0.9,0.7l0.8,0.7c0.6,0.5,1.1,1,1.7,1.5c0.5,0.5,1,1.1,1.5,1.6l0.7,0.8l0.8,0.9
// 						c1.1,1.2,2.3,2.1,3.2,3.1c0.5,0.5,0.9,1,1.3,1.5l0.7,1c0.2,0.4,0.5,0.7,0.8,1c1.2,1.3,2.9,2.3,4.8,2.6c1.9,0.3,3.7-0.1,5.3-0.7
// 						c1.6-0.6,3-1.4,4.4-2.4c1.4-1,2.7-2.1,3.9-3.4c1.2-1.3,2.3-3,2.8-5c0.8-3.1,2-6.2,3.7-9.2c2-3.5,4.7-6.7,7.7-9.5
// 						c3.1-2.8,6.5-5.4,10-7.8c1.8-1.2,3.6-2.4,5.4-3.7c1.7-1.2,3.6-2.3,5.5-3.7c0.9-0.7,1.9-1.6,2.7-2.6c0.8-1,1.4-1.9,2-2.9
// 						c1.2-2,2.2-4.1,2.8-6.5c0.2-0.6,0.3-1.2,0.4-1.8c0.1-0.6,0.2-1.1,0.3-1.7c0.2-1.1,0.3-2.2,0.5-3.3c0.3-2.2,0.5-4.5,0.5-6.7
// 						c0.2-4.5-0.1-9-0.8-13.5c-0.7-4.5-2-8.9-3.8-13.1c-0.4-1.1-0.9-2.1-1.4-3.1l-0.3-0.7c-0.1-0.2-0.2-0.4-0.3-0.7
// 						c-0.2-0.5-0.3-0.9-0.4-1.4c-0.3-0.9-0.4-1.9-0.5-2.8c-0.1-0.9,0.1-1.7,0.3-1.8c7.2-8.5,14.5-17.1,22.4-24.9c2-2,4-3.9,6-5.8
// 						c1-0.9,2-1.8,3-2.3c0.5-0.3,1-0.5,1.4-0.6c0.4,0,0.8-0.1,1.2,0.1c0.9,0.3,1.9,1.1,2.9,1.9c1,0.8,2,1.8,3.1,2.8
// 						c1.1,1,2,1.9,2.9,2.9c0.9,1,1.9,2,2.8,3c1.8,2.1,3.7,4.2,5.8,6.3c2.1,2,4.4,3.9,7.1,5.3c1.3,0.7,2.7,1.3,4.1,1.7
// 						c1.5,0.5,2.7,0.7,3.8,1.2c2.4,1,4.6,2.5,6.7,4.1c2.1,1.7,4.1,3.5,6.2,5.4c2,1.9,4.1,3.8,6.2,5.6c4.2,3.7,8.8,7.1,14,9.6
// 						c2.6,1.3,5.3,2.3,8.1,3l4,0.9c1.3,0.3,2.5,0.7,3.7,1.2c2.4,0.9,4.7,2.1,6.7,3.8c1.9,1.6,3.7,3.5,4.9,5.7
// 						c0.3,0.5,0.6,1.1,0.9,1.7c0.3,0.6,0.5,1.1,0.8,1.8l0.8,1.9c0.3,0.7,0.6,1.3,1,2c0.8,1.3,1.7,2.5,2.9,3.6c1.2,1,2.7,1.8,4.2,2.1
// 						c1.5,0.4,3.1,0.4,4.5,0.2c1.5-0.1,2.9-0.4,4.2-0.8c2.8-0.7,5.3-1.8,7.8-2.9c1.2-0.6,2.3-1,3.8-1.7c1.3-0.7,2.6-1.6,3.7-2.7
// 						c1.1-1.1,1.9-2.5,2.4-4c0.5-1.5,0.7-3,0.6-4.5c0-0.8-0.1-1.5-0.3-2.2l-0.2-1.1l-0.3-1c-0.3-1.3-0.7-2.6-1.1-3.8
// 						c-0.7-2.5-1.5-5.1-2.3-7.6c-0.8-2.5-1.7-5-2.6-7.4c-0.5-1.2-0.9-2.5-1.4-3.7l-0.6-1.8l-0.6-1.8c-1.4-4.7-1.6-9.6-0.2-14.1
// 						c0.5-1.5,1.2-2.8,2-4.2c0.3-0.4,1.3-2.2,3.1-4.6c1.9-2.5,4.2-5.7,6.6-9c4.8-6.6,9.3-13.8,10.6-15.5c2.3-3.8,4.4-7.7,6.4-11.8
// 						c1.9-3.4,4.3-11.2,2.2-4.6c1.1-3,1.7-5.3,2.1-7.2c0.4-1.9,0.7-3.4,0.8-4.7c0.2-1.3,0.3-2.7,0.5-4.1c0-0.4,0.2-0.7,0.5-1
// 						c0.3-0.3,0.8-0.5,1.3-0.5c0.3,0,0.5,0.1,0.7,0.3c0.2,0.1,0.3,0.3,0.6,0.6l1.8,2.8c0.6,0.9,1.2,1.9,1.8,2.8
// 						c0.7,0.9,1.3,1.9,2.2,2.8c0.2,0.2,0.3,0.3,0.5,0.5c0.2,0.2,0.5,0.4,0.6,0.5l2.7,2c1.2,0.9,2.4,1.8,3.6,2.7
// 						c2.4,1.7,4.8,3.5,7.3,5.1c5,3.2,10.2,6.1,15.9,7.9c5.7,1.8,11.7,2.6,17.6,2c1.5-0.1,2.9-0.5,4.4-0.7c1.4-0.3,2.8-0.7,4.3-1.1
// 						l0.9-0.3c0.3-0.1,0.6-0.1,0.9-0.2c0.6-0.1,1.2-0.2,1.8-0.2c1.2-0.1,2.5,0,3.7,0.3c0.6,0.1,1.1,0.3,1.7,0.5
// 						c0.2,0.1,0.5,0.2,0.7,0.4c0.2,0.1,0.6,0.4,0.9,0.5c1.2,0.8,2.4,1.5,3.6,2.3c2.4,1.5,4.7,3.1,7,4.8c2.3,1.6,4.6,3.4,6.7,5.2
// 						c1.1,0.9,2.2,1.8,3.2,2.8c1.1,0.9,2,1.9,3.3,3c1.2,1,2.6,2,4.2,2.6c1.6,0.7,3.5,1,5.2,0.8c1.8-0.2,3.4-0.8,4.9-1.5
// 						c1.5-0.7,2.8-1.5,4.1-2.3c1.3-0.8,2.6-1.6,3.9-2.2c1.3-0.7,2.5-1.2,4-1.7c2.8-0.9,5.7-1.5,8.7-1.7c0.4,0,0.9,0,1.4,0
// 						c0.4,0,0.9,0,1.4,0c0.9,0,1.8,0.1,2.8,0.2c1.9,0.3,3.8,0.6,5.7,1.3c1,0.3,1.9,0.7,2.8,1c0.9,0.4,1.9,0.8,2.8,1.3
// 						c1.9,0.9,3.7,2.1,5.5,3.3c0.9,0.7,1.8,1.3,2.8,2c0.9,0.7,1.9,1.4,3,2c1,0.7,2.1,1.3,3.2,1.9c1.1,0.6,2.2,1.2,3.4,1.7
// 						c2.3,1.1,4.8,1.9,7.3,2.6c2.6,0.6,5.3,0.9,8,0.9c2.7,0,5.4-0.4,8.1-1.1c1.3-0.4,2.7-0.7,3.9-1.3c0.6-0.2,1.3-0.5,1.9-0.8
// 						l1.9-0.9l0.9-0.4c0.2-0.1,0.4-0.2,0.7-0.3c0.5-0.2,1-0.3,1.6-0.4c1.2-0.2,2.5-0.4,4-0.7c0.4-0.1,0.7-0.2,1.1-0.3l1-0.3l1.9-0.6
// 						c1.3-0.4,2.6-0.7,3.9-1.1c2.6-0.7,5.3-1.5,8-2.7c1.3-0.6,2.7-1.3,3.9-2.1l0.9-0.7l0.8-0.6l1.7-1.3c1.4-1.1,2.7-2.2,4-3.3V154.3
// 						z"
//         />
//       </g>
//       <g id="Hope">
//         <path
//           fill={textColor}
//           d="M126.7,217.9c0,0.2,0,0.4,0,0.6c0,0-0.1,0.1-0.1,0.1c-0.3,0.1-0.7,0.1-1,0.1c-0.2,0-0.3,0-0.5-0.1
// 		c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.3-0.1-0.5c0-0.3,0.1-0.5,0.1-0.7c0-0.4-0.3-0.3-0.1-0.8c-0.2-0.2-0.2-0.2-0.4-0.2
// 		c-0.2,0-0.4-0.1-0.5-0.1c-0.3,0.1-1.1,0.1-1.4,0.1c-0.3,0-0.5-0.1-0.7-0.1c-0.2,0-0.4,0.2-0.5,0.3c-0.1,0.3,0,0.6,0,0.9
// 		c0,0.5-0.2,1-0.3,1.5c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.6,0-0.9,0c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.4,0.1-0.6,0.1-1
// 		c0-0.3-0.1-0.6-0.1-0.9c0-1.3,0.1-2.5,0.1-3.7c0-0.2,0-0.5,0-0.7c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,1,0,1.1,0.1
// 		c0.2,0.3,0.2,0.6,0.2,0.9l0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2l0,0.2c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.6,0.2,0.9l0.2,0.4
// 		c0,0,0.1,0,0.1,0l0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.1h0.1c0.4,0,2-0.1,2.2-0.2c0.1,0,0.2-0.1,0.3-0.3c0.1-0.4,0-0.7,0-1.1
// 		c0-0.2,0.1-0.5,0-0.7c0.1-0.4-0.1-0.8,0.2-1c0.1,0,0.4,0,0.5,0c0.2,0,0.9,0,1,0c0,0.1,0.1,0.1,0.1,0.2c0,0.3,0,0.5,0,0.8
// 		c0,0.3-0.1,0.5-0.1,0.8c0,0.3,0.1,0.6,0.1,1c0,0.9,0,1.8,0,2.7C126.6,217.4,126.7,217.7,126.7,217.9z"
//         />
//         <path
//           fill={textColor}
//           d="M129.3,214.6c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C129.2,215.3,129.3,215,129.3,214.6z M133,217.8
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C132.2,217.3,132.3,217.8,133,217.8z"
//         />
//         <path
//           fill={textColor}
//           d="M141,218.6L141,218.6c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6,0-1,0c-0.1-0.2-0.1-0.5-0.1-0.8
// 		c0-0.3,0.1-0.6,0.1-0.8c0-0.1-0.1-0.2-0.1-0.4v-0.9c0-0.8,0.1-1.6,0.1-2.4c0-0.3,0-0.5,0-0.8s-0.1-0.8,0.1-1h0.1
// 		c0.2,0,0.2,0,0.4-0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.6-0.1,1-0.1c0.1,0,1.2,0,1.2,0c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.6-0.1,0.9-0.1
// 		c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.1c0.3,0.3,0.8,0.2,1.1,0.5c0.1,0.1,0.2,0.1,0.3,0.2
// 		c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4-0.1,0.7-0.2,1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-1,0.8-1.5,0.8c-0.3,0-0.7,0-1,0
// 		c-0.4,0.2-1.8,0.1-2.3,0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.1,0.8-0.2,1c0.1,0,0.1,0.1,0.1,0.2V218.6z M141.1,214.7L141.1,214.7
// 		c0.1,0,0.3-0.1,0.3-0.2h0.3c0.2,0,0.4,0,0.5,0.1l0.1,0c0.1-0.1,1.6-0.1,1.9-0.1c0.1,0,0.2,0,0.3,0.1l0.1,0c0.3-0.3,0.9-0.2,0.9-0.9
// 		c0-0.9-1.6-1.1-2.3-1.1c-0.4,0-0.9,0.1-1.4,0.1c-1.2,0-0.7,0.4-0.9,1.2L141.1,214.7z"
//         />
//         <path
//           fill={textColor}
//           d="M156.2,217.6c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L156.2,217.6z"
//         />
//       </g>
//       <g id="Cranbrook">
//         <path
//           fill={textColor}
//           d="M1124.9,175.3c-0.5,0.3-0.6,1-0.6,1.5c0,0.7,0.8,1.3,1.5,1.3c0.3,0,0.8,0.2,1.2,0.2c1,0,2.1,0,3-0.2
// 		c0.2,0.1,0.3,0.2,0.3,0.4c0,0.5,0,1,0,1.5l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.2,0.1l-0.1,0c-0.2-0.1-0.3,0-0.5,0
// 		c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-1.2-0.1-1.7-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
// 		c-0.1,0-0.3-0.1-0.5-0.1h-0.1c-1.4-0.8-2.3-1.7-2.3-3.6c0-2.2,1.7-2.7,3.4-3.4c0.5,0.1,1.2,0,1.7,0.1c0.3-0.1,0.6-0.1,1-0.1
// 		c0.4,0,0.9,0,1.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.8l0,0.2c-0.2,0-0.2-0.1-0.5-0.1l-0.6,0
// 		c-0.5,0-0.9,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-1.1,0-1.1,0c0,0-0.6,0.3-0.6,0.3c-0.9,0.3-0.3,0-0.7,0.4
// 		C1125.1,175.2,1125,175.3,1124.9,175.3z"
//         />
//         <path
//           fill={textColor}
//           d="M1134.9,179.6c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.5,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V179.6z
// 		 M1139,174.5c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1139.1,174.9,1139,174.7,1139,174.5z"
//         />
//         <path
//           fill={textColor}
//           d="M1148.6,180c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 		c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 		c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 		c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 		c0,0.2-0.1,0.3-0.3,0.3H1148.6z M1147.9,176.2c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3
// 		c0.1-0.1,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1
// 		l-0.1,0.3c0.1,0.2,0.2,0.2,0.4,0.2H1147.9z"
//         />
//         <path
//           fill={textColor}
//           d="M1153.3,172.9c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 		c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 		c0.2,0,0.5,0,0.7,0s0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 		c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4c-0.3-0.5-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 		c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 		c0-2.2,0-4.4,0-6.5C1153.2,173.1,1153.2,173,1153.3,172.9L1153.3,172.9z"
//         />
//         <path
//           fill={textColor}
//           d="M1163.3,180.2L1163.3,180.2c-0.1-0.7-0.1-1.3-0.1-1.9c0-1.5-0.1-3.1-0.1-4.6c0-0.7,0.2-0.6,0.8-0.6
// 		c0.2,0,0.4,0.1,0.5,0.1c0.4,0,0.9-0.1,1.4-0.1c0.7,0,3.1,0.2,3.6,0.7c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.2,0.2,0.5,0.2,0.8
// 		c0,0.6-0.3,0.8-0.3,1c0,0.3,0.6,0.8,0.6,1.8c0,1.9-1.5,2.1-3.1,2.1c-0.6,0-1.3-0.2-1.9-0.2c-0.5,0-1,0.1-1.5,0.1H1163.3z
// 		 M1168.5,175.9c0.3,0,0.4-0.2,0.4-0.5c0-0.2,0-0.4-0.2-0.5c-0.4-0.3-2.1-0.4-2.6-0.4c-0.5,0-1.1,0-1.6,0.1c0,0.1,0,0.2-0.1,0.4
// 		c0.3,0.7-0.5,1.2,0.8,1.2C1166.2,176.2,1167.6,176.1,1168.5,175.9z M1165.1,178.9c0.4,0,0.9,0,1.3,0c0.8,0,1.7,0.1,2.5,0
// 		c0.2-0.2,0.4-0.2,0.4-0.5c-0.1-0.2,0-0.5-0.1-0.6c-0.9-0.7-0.7-0.5-1.9-0.5c-0.7,0-1.5,0.1-2.2,0.1c-0.2,0-0.3,0.1-0.4,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0.1,0.4C1164.8,178.9,1164.9,178.9,1165.1,178.9z"
//         />
//         <path
//           fill={textColor}
//           d="M1174.7,179.6c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.5,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V179.6z
// 		 M1178.8,174.5c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1178.9,174.9,1178.8,174.7,1178.8,174.5z"
//         />
//         <path
//           fill={textColor}
//           d="M1182.8,175.8c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.2-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C1182.6,176.6,1182.8,176.2,1182.8,175.8z M1186.5,179.1
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.5,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C1185.7,178.5,1185.7,179.1,1186.5,179.1z"
//         />
//         <path
//           fill={textColor}
//           d="M1192.6,175.8c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.2-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C1192.4,176.6,1192.6,176.2,1192.6,175.8z M1196.3,179.1
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.5,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C1195.5,178.5,1195.6,179.1,1196.3,179.1z"
//         />
//         <path
//           fill={textColor}
//           d="M1204.3,177.8c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 		h-0.4c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6
// 		c0-0.6-0.1-1.3-0.1-2c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6V173c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6
// 		c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1
// 		c0,0.1-0.6,0.7-0.7,0.8c-0.4,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0
// 		c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C1205,177.1,1204.5,177.7,1204.3,177.8
// 		L1204.3,177.8z"
//         />
//       </g>
//       <g id="Creston">
//         <path
//           fill={textColor}
//           d="M1090.3,271.8c-0.5,0.3-0.6,1-0.6,1.5c0,0.7,0.8,1.3,1.5,1.3c0.3,0,0.8,0.2,1.2,0.2c1,0,2.1,0,3-0.2
// 		c0.2,0.1,0.3,0.2,0.3,0.4c0,0.5,0,1,0,1.5l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.2,0.1l-0.1,0c-0.2-0.1-0.3,0-0.5,0
// 		c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-1.2-0.1-1.7-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
// 		c-0.1,0-0.3-0.1-0.5-0.1h-0.1c-1.4-0.8-2.3-1.7-2.3-3.6c0-2.2,1.7-2.7,3.4-3.4c0.5,0.1,1.2,0,1.7,0.1c0.3-0.1,0.6-0.1,1-0.1
// 		c0.4,0,0.9,0,1.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.8l0,0.2c-0.2,0-0.2-0.1-0.5-0.1l-0.6,0
// 		c-0.5,0-0.9,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-1.1,0-1.1,0c0,0-0.6,0.3-0.6,0.3c-0.9,0.3-0.3,0-0.7,0.4
// 		C1090.5,271.6,1090.4,271.8,1090.3,271.8z"
//         />
//         <path
//           fill={textColor}
//           d="M1100.3,276.1c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.5,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V276.1z
// 		 M1104.4,271c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1104.5,271.4,1104.4,271.2,1104.4,271z"
//         />
//         <path
//           fill={textColor}
//           d="M1115.5,275.4c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L1115.5,275.4z"
//         />
//         <path
//           fill={textColor}
//           d="M1121.9,273.1c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C1122.3,273.2,1122.1,273.1,1121.9,273.1z"
//         />
//         <path
//           fill={textColor}
//           d="M1131.4,273.9c0-0.5,0-2.6-0.2-2.9c-0.1-0.3-0.3-0.3-0.5-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.5-0.2-0.8-0.3l0-0.1
// 		c0-0.4,0-0.7,0-1.1c0-0.1,0.1-0.2,0.1-0.2c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0.1,0.9,0.1
// 		c0.2,0,0.4-0.1,0.6-0.1c0.7,0,1.4,0,2.1,0c0.1,0,0.2,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.8,0.1
// 		c-0.3,0-0.8-0.1-0.9,0.3c-0.2,0.7-0.2,3.3-0.2,4c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.5,0,0.6-0.5,0.6
// 		c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.3-0.1-0.6-0.1-0.9C1131.3,274.9,1131.4,274.4,1131.4,273.9z"
//         />
//         <path
//           fill={textColor}
//           d="M1138.1,272.3c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C1138,273.1,1138.1,272.7,1138.1,272.3z M1141.8,275.5
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C1141,275,1141.1,275.5,1141.8,275.5z"
//         />
//         <path
//           fill={textColor}
//           d="M1148.5,269.4c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 		c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 		c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 		c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 		c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 		c0-2.2,0-4.4,0-6.5C1148.4,269.6,1148.4,269.5,1148.5,269.4L1148.5,269.4z"
//         />
//       </g>
//       <g id="Sparwood">
//         <path
//           fill={textColor}
//           d="M1363.3,102.1c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C1363.7,102.2,1363.5,102.1,1363.3,102.1z"
//         />
//         <path
//           fill={textColor}
//           d="M1371.7,105.4L1371.7,105.4c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6,0-1,0c-0.1-0.2-0.1-0.5-0.1-0.8
// 		c0-0.3,0.1-0.6,0.1-0.8c0-0.1-0.1-0.2-0.1-0.4v-0.9c0-0.8,0.1-1.6,0.1-2.4c0-0.3,0-0.5,0-0.8s-0.1-0.8,0.1-1h0.1
// 		c0.2,0,0.2,0,0.4-0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.6-0.1,1-0.1c0.1,0,1.2,0,1.2,0c0.1,0.1,0.3,0.1,0.4,0.1c0.3,0,0.6-0.1,0.9-0.1
// 		c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.1c0.3,0.3,0.8,0.2,1.1,0.5c0.1,0.1,0.2,0.1,0.3,0.2
// 		c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4-0.1,0.7-0.2,1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-1,0.8-1.5,0.8c-0.3,0-0.7,0-1,0
// 		c-0.4,0.2-1.8,0.1-2.3,0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.1,0.8-0.2,1c0.1,0,0.1,0.1,0.1,0.2V105.4z M1371.8,101.5
// 		L1371.8,101.5c0.1,0,0.3-0.1,0.3-0.2h0.3c0.2,0,0.4,0,0.5,0.1l0.1,0c0.1-0.1,1.6-0.1,1.9-0.1c0.1,0,0.2,0,0.3,0.1l0.1,0
// 		c0.3-0.3,0.9-0.2,0.9-0.9c0-0.9-1.6-1.1-2.3-1.1c-0.4,0-0.9,0.1-1.4,0.1c-1.2,0-0.7,0.4-0.9,1.2L1371.8,101.5z"
//         />
//         <path
//           fill={textColor}
//           d="M1385.4,105.6c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 		c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 		c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 		c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 		c0,0.2-0.1,0.3-0.3,0.3H1385.4z M1384.7,101.8c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3
// 		c0.1-0.1,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1
// 		l-0.1,0.3c0.1,0.2,0.2,0.2,0.4,0.2H1384.7z"
//         />
//         <path
//           fill={textColor}
//           d="M1391.4,105.1c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.5,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V105.1z
// 		 M1395.5,100c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1395.6,100.4,1395.5,100.2,1395.5,100z"
//         />
//         <path
//           fill={textColor}
//           d="M1401.2,98.6c0,0.5-0.1,1.1-0.1,1.7c0,0.4,0,0.8,0,1.3c0,0.3,0,0.8,0,1c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0,0.1-0.1
// 		c0.3-0.7,0.8-1.7,1.1-2.4c0.1-0.1,0.3-0.6,0.4-0.8c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.1,0.2c0.2,0.5,0.6,1,0.8,1.6
// 		c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.2,0.3,0.5,0.6,0.6c-0.1-0.6-0.1-1.5-0.1-2.1c0-0.5-0.1-1.1-0.1-1.6c0-0.4,1-0.4,1.3-0.4
// 		c0.2,0,0.6,0,0.6,0.3c0,0.5,0,1,0,1.5c0,0.5,0,2.5,0,2.8c-0.1,0.3,0,1.3,0,1.6c0,0.3,0,0.8-0.4,0.9c-0.4,0-0.8,0.1-1.2,0.1
// 		c-0.6,0-1.1-1.7-1.7-2.1c0-0.1-0.2-0.4-0.2-0.5c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0.2-0.2,0.4-0.2,0.5
// 		c0,0.2-0.1,0.3-0.3,0.4c-0.3,0.7-0.7,2.1-1.7,2.1c-0.2,0-0.4,0.1-0.7,0.1c-0.3,0-0.4,0-0.4-0.3c0-0.3,0.1-0.6,0-1
// 		c0.1-0.3,0-0.6,0-0.9c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.3-0.1-0.6-0.1-0.9
// 		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.1-0.6,0-0.9c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.4,1-0.3,1.2-0.4C1401,98.2,1401.2,98.5,1401.2,98.6z"
//         />
//         <path
//           fill={textColor}
//           d="M1409.5,101.3c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C1409.4,102.1,1409.5,101.7,1409.5,101.3z M1413.2,104.6
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C1412.5,104,1412.5,104.6,1413.2,104.6z"
//         />
//         <path
//           fill={textColor}
//           d="M1419.3,101.3c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C1419.2,102.1,1419.3,101.7,1419.3,101.3z M1423,104.6
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C1422.3,104,1422.3,104.6,1423,104.6z"
//         />
//         <path
//           fill={textColor}
//           d="M1429.5,103.4c0-0.3,0.1-0.5,0.1-0.8c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1-0.2-2.2-0.2-3.3c0-0.1,0.1-0.2,0.2-0.2
// 		c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.6-0.1,2.3-0.1c0.8,0,1.5,0.3,2.1,0.7c0.5,0.3,0.5,0.7,0.9,1.1
// 		c0.3,0.3,0.4,0.9,0.4,1.3s-0.1,0.8,0,1.2c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4
// 		c-0.3,0.5-1.2,0.8-1.8,1.1c-0.6,0-1.3,0.2-1.9,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0.2-0.9,0.2-1.4,0.2
// 		c-0.1,0-0.3,0-0.3-0.1C1429.5,104.7,1429.5,104,1429.5,103.4z M1433.2,104c1.2,0,1.9-0.9,1.9-2c0-0.8-0.3-1.2-0.8-1.8
// 		c-0.2,0-0.3-0.1-0.5-0.2l-0.1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1,0-0.2-0.1l-0.2-0.3c-0.1,0-0.5-0.1-0.5-0.1
// 		c-0.8,0-1.4,0.2-1.4,1.1c0,0.3,0.1,1.8,0.2,2.1c0,0.2-0.1,0.3-0.1,0.5c0,0.3,0,0.5,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1
// 		C1431.9,104.1,1432.6,104,1433.2,104z"
//         />
//       </g>
//       <g id="Fernie">
//         <path
//           fill={textColor}
//           d="M1376.6,184.3v0.1c0,0.4,0.1,0.8,0.2,1.2c0.3,0,0.1,0,0.7,0c0.1,0,0.5,0.1,0.6,0.1c0.1,0,0.2-0.1,0.4-0.1
// 		c0.2,0,0.3-0.1,0.5-0.1c0.4,0,0.8,0,1.2-0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.5,0,0.9-0.3,1.3l-0.2,0.1c-0.2,0-0.3-0.1-0.5-0.1
// 		c-0.2,0-0.7,0.1-1.1,0.1c-0.4,0-1.7-0.1-1.6,0.4c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.1,1c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.3,0,0.4
// 		c-0.3,0.1-0.7,0.1-0.9,0.1c-0.2,0-0.3-0.1-0.4-0.1c-0.1-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3c0.1-1,0.1-2.1,0.1-3.1
// 		c0-0.6-0.1-1.2-0.1-1.8l0.1-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0-0.4,0.1-0.6c0.1,0,0.2,0,0.4,0c0.3,0,0.7,0,1,0.1
// 		c0.2-0.1,0.5-0.1,0.7-0.1l1.2,0c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.9-0.1,1.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.2-0.1,0.3-0.1
// 		c0.1,0,0.2,0,0.4,0.1c0.1,0,0.3-0.1,0.5-0.1c0.3,0,0.3,0.9,0.3,1.1c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3-0.2-0.8-0.1-1.2-0.1
// 		C1379.7,183.9,1377.2,184,1376.6,184.3L1376.6,184.3z"
//         />
//         <path
//           fill={textColor}
//           d="M1391.7,188.3c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L1391.7,188.3z"
//         />
//         <path
//           fill={textColor}
//           d="M1396.3,189c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V189z
// 		 M1400.4,183.9c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1400.6,184.3,1400.5,184.1,1400.4,183.9z"
//         />
//         <path
//           fill={textColor}
//           d="M1405,182.3c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 		c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 		c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 		c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 		c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 		c0-2.2,0-4.4,0-6.5C1404.9,182.5,1404.9,182.4,1405,182.3L1405,182.3z"
//         />
//         <path
//           fill={textColor}
//           d="M1417.2,186.8L1417.2,186.8c0-0.8-0.1-1.6-0.1-2.4c0-1-0.3-0.7-1.2-0.7c-0.4,0-0.8,0.1-1,0.1
// 		c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.2-0.1-0.9-0.1-1.1c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.4,0
// 		c0.7,0,1.4,0,2.1,0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.4,0,2.2,0,2.3,0.2c0,0.2,0,0.4,0,0.6
// 		s0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.7,0.1c-0.1,0-0.9,0-1,0.1c-0.1,0.2-0.1,3-0.1,3.5c0,0.6,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.7-0.1
// 		c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0,0.1,0v0.2c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.7-0.1-1.1-0.2c-1.5,0-3,0-4.5,0
// 		c-0.5,0-0.9,0-0.9-0.6c0-0.8,0.5-0.7,1.1-0.7c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.3,0.1-0.6,0.1-0.9
// 		C1417.2,187,1417.2,186.9,1417.2,186.8z"
//         />
//         <path
//           fill={textColor}
//           d="M1431.1,188.3c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L1431.1,188.3z"
//         />
//       </g>
//       <g id="Kimberly">
//         <path
//           fill={textColor}
//           d="M1087.6,138.4c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 		h-0.4c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6
// 		c0-0.6-0.1-1.3-0.1-2c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6v-0.1c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6
// 		c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1
// 		c0,0.1-0.6,0.7-0.7,0.8c-0.5,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0
// 		c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C1088.3,137.6,1087.7,138.2,1087.6,138.4
// 		L1087.6,138.4z"
//         />
//         <path
//           fill={textColor}
//           d="M1098.2,138L1098.2,138c0-0.8-0.1-1.6-0.1-2.4c0-1-0.3-0.7-1.2-0.7c-0.4,0-0.8,0.1-1,0.1c-0.1,0-0.1,0-0.2-0.1
// 		c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.2-0.1-0.9-0.1-1.1c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.4,0c0.7,0,1.4,0,2.1,0.2
// 		c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.4,0,2.2,0,2.3,0.2c0,0.2,0,0.4,0,0.6s0,0.4-0.1,0.5
// 		c-0.5,0.2-1.2,0.1-1.7,0.1c-0.1,0-0.9,0-1,0.1c-0.1,0.2-0.1,3-0.1,3.5c0,0.6,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.7-0.1
// 		c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0,0.1,0v0.2c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.7-0.1-1.1-0.2c-1.5,0-3,0-4.5,0
// 		c-0.5,0-0.9,0-0.9-0.6c0-0.8,0.5-0.7,1.1-0.7c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.3,0.1-0.6,0.1-0.9
// 		C1098.2,138.1,1098.2,138,1098.2,138z"
//         />
//         <path
//           fill={textColor}
//           d="M1105.5,138.4c-0.1-0.2-0.2-0.4-0.1-0.6c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.5,0.1-1,0.1-1.5c0-0.5-0.1-1-0.1-1.5
// 		c0-0.1,0-0.3,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.5-0.2,0.6-0.2c0.4,0,0.8,0.1,1.3,0.1c0.1,0,0.2,0.1,0.2,0.2
// 		c0.2,0.8,0.7,1.5,1.2,2.1l0,0.2l0,0.1c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.3-0.3,0.4-0.3c0.2-0.4,0.5-1.4,0.9-1.5c0,0,0-0.1,0-0.1
// 		c0-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.1,0.2-0.1c0.5,0,1.2,0.1,1.8,0.1c0.1,0,0.3,0,0.3,0.2v0.2c-0.1,0.8-0.1,1.6-0.1,2.4
// 		c0,0.4-0.1,0.7,0,1.1c0,0.1-0.1,0.2-0.1,0.3c0,0.6,0.1,1.2,0.1,1.9c0,1.1,0.1,0.8-1.3,1c-0.1,0-0.2-0.1-0.2-0.2
// 		c0-0.1,0-0.3,0.1-0.5c0.1-0.7,0-1.6,0-2.3c0-0.4-0.1-0.8-0.1-1.2l0,0v-0.1c0-0.1,0-0.7-0.1-0.8h-0.1c-0.1,0-0.3,0.5-0.3,0.6
// 		l-0.3,0.7c0,0.2-1,2.2-1.1,2.3c-0.1,0-0.1-0.1-0.2-0.2c-0.1-0.4-1-1.9-1.3-2.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.2-0.4-0.3-0.5
// 		l-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.4,0.1,0.7,0.1,1.1c0,0.4,0.1,0.9,0.1,1.3
// 		c0,0.4,0,0.9,0,1.4c0,0.1-0.1,0.3-0.1,0.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		c0-0.1-0.1-0.2-0.1-0.3c0-0.6,0-1.1,0-1.7L1105.5,138.4z"
//         />
//         <path
//           fill={textColor}
//           d="M1115.4,140.8L1115.4,140.8c-0.1-0.7-0.1-1.3-0.1-1.9c0-1.5-0.1-3.1-0.1-4.6c0-0.7,0.2-0.6,0.8-0.6
// 		c0.2,0,0.4,0.1,0.5,0.1c0.4,0,0.9-0.1,1.4-0.1c0.7,0,3.1,0.2,3.6,0.7c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.2,0.2,0.5,0.2,0.8
// 		c0,0.6-0.3,0.8-0.3,1c0,0.3,0.6,0.8,0.6,1.8c0,1.9-1.5,2.1-3.1,2.1c-0.6,0-1.3-0.2-1.9-0.2c-0.5,0-1,0.1-1.5,0.1H1115.4z
// 		 M1120.7,136.4c0.3,0,0.4-0.2,0.4-0.5c0-0.2,0-0.4-0.2-0.5c-0.4-0.3-2.1-0.4-2.6-0.4c-0.5,0-1.1,0-1.6,0.1c0,0.1,0,0.2-0.1,0.4
// 		c0.3,0.7-0.5,1.2,0.8,1.2C1118.3,136.7,1119.8,136.6,1120.7,136.4z M1117.2,139.5c0.4,0,0.9,0,1.3,0c0.8,0,1.7,0.1,2.5,0
// 		c0.2-0.2,0.4-0.2,0.4-0.5c-0.1-0.2,0-0.5-0.1-0.6c-0.9-0.7-0.7-0.5-1.9-0.5c-0.7,0-1.5,0.1-2.2,0.1c-0.2,0-0.3,0.1-0.4,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0.1,0.4C1116.9,139.4,1117.1,139.5,1117.2,139.5z"
//         />
//         <path
//           fill={textColor}
//           d="M1132.1,139.4c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.2-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1s-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L1132.1,139.4z"
//         />
//         <path
//           fill={textColor}
//           d="M1136.8,140.1c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V140.1z
// 		 M1140.9,135c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1141,135.5,1140.9,135.2,1140.9,135z"
//         />
//         <path
//           fill={textColor}
//           d="M1145.1,138.2c0-1.2,0.1-2.3,0.1-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.6-0.1-0.9l0-0.1
// 		c0.1-0.1,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.4
// 		c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
// 		c0,0.1,0,0.4,0.1,0.5c-0.1,0.3,0,0.6,0,0.9c0,0.2-0.1,0.4-0.1,0.5c0,0.7,0.4,0.5,0.9,0.5c0.6,0,1.2,0.1,1.8,0.1
// 		c0.1,0,0.3,0,0.4-0.1c0.3,0.1,0.6,0.1,1,0.1c0.7,0,1.1-0.2,1.1,0.5c0,0.2-0.1,1-0.3,1c-0.3,0-0.5-0.2-0.8-0.2c-0.1,0-0.3,0-0.4,0
// 		c-0.2-0.1-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.4,0.1-0.4,0.1
// 		c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.5,0.1-0.9,0.1-1.4
// 		C1145.1,138.6,1145.1,138.4,1145.1,138.2z"
//         />
//         <path
//           fill={textColor}
//           d="M1161.8,139.4c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L1161.8,139.4z"
//         />
//         <path
//           fill={textColor}
//           d="M1167.4,137.5c-0.2-0.4-0.4-0.8-0.6-1.2c-0.5-0.7-1.1-1.4-1.5-2.2c-0.1-0.2-0.2-0.5-0.5-0.6
// 		c0-0.3,0.1-0.2,0.3-0.2c0.5,0,0.9-0.1,1.4,0l0.2-0.2l0.1,0c0.5,0.6,0.7,1.6,1.2,2.1c0.2,0.2,0.3,0.4,0.4,0.6
// 		c0.1-0.3,0.3-0.6,0.5-0.9c0-0.2,0-0.3,0.1-0.4c0.3-0.4,0.8-1.3,0.9-1.3c0.2,0,1.2,0,1.4,0.1c0,0,0,0.2,0,0.2
// 		c-0.2,0.3-0.3,0.8-0.5,1.1c-0.4,0.3-0.4,0.9-0.7,1.2c-0.2,0.2-0.5,0.5-0.6,0.7c-0.1,0.4-0.3,0.4-0.6,1c0.1,0.5,0.2,1.4,0.2,1.9
// 		c0,0.2,0,0.5-0.1,0.7l-0.1,0.1c-0.2,0-0.4-0.2-0.7-0.2s-0.5,0.2-0.7,0.2c-0.4,0-0.3-0.6-0.3-0.9
// 		C1167.2,138.8,1167.3,138.1,1167.4,137.5z"
//         />
//       </g>
//       <g id="Nelson">
//         <path
//           fill={textColor}
//           d="M943.9,181.8c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 			c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 			c0.2,0,0.5,0,0.7,0s0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 			c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4c-0.3-0.5-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 			c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 			c0-2.2,0-4.4,0-6.5C943.8,181.9,943.8,181.9,943.9,181.8L943.9,181.8z"
//         />
//         <path
//           fill={textColor}
//           d="M960.5,187.7c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.2-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 			c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 			c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 			c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1s-1.1,0-1.6,0.2
// 			c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 			c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 			c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 			c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L960.5,187.7z"
//         />
//         <path
//           fill={textColor}
//           d="M963.5,186.5c0-1.2,0.1-2.3,0.1-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.6-0.1-0.9l0-0.1
// 			c0.1-0.1,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.4
// 			c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
// 			c0,0.1,0,0.4,0.1,0.5c-0.1,0.3,0,0.6,0,0.9c0,0.2-0.1,0.4-0.1,0.5c0,0.7,0.4,0.5,0.9,0.5c0.6,0,1.2,0.1,1.8,0.1
// 			c0.1,0,0.3,0,0.4-0.1c0.3,0.1,0.6,0.1,1,0.1c0.7,0,1.1-0.2,1.1,0.5c0,0.2-0.1,1-0.3,1c-0.3,0-0.5-0.2-0.8-0.2c-0.1,0-0.3,0-0.4,0
// 			c-0.2-0.1-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.4,0.1-0.4,0.1
// 			c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.5,0.1-0.9,0.1-1.4
// 			C963.5,186.9,963.5,186.7,963.5,186.5z"
//         />
//         <path
//           fill={textColor}
//           d="M976.8,185.5c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 			c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 			c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 			c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 			c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 			c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 			c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 			c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 			c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 			c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 			c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 			C977.1,185.5,977,185.5,976.8,185.5z"
//         />
//         <path
//           fill={textColor}
//           d="M983.2,184.7c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.2-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 			l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 			c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C983.1,185.4,983.2,185.1,983.2,184.7z M986.9,187.9
// 			c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 			c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.5,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 			c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C986.2,187.4,986.2,187.9,986.9,187.9z"
//         />
//         <path
//           fill={textColor}
//           d="M993.7,181.8c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 			c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 			c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 			c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 			c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 			c0-2.2,0-4.4,0-6.5C993.5,181.9,993.6,181.9,993.7,181.8L993.7,181.8z"
//         />
//       </g>
//       <g id="Castlegar">
//         <path
//           fill={textColor}
//           d="M823.3,234.7c-0.5,0.3-0.6,1-0.6,1.5c0,0.7,0.8,1.3,1.5,1.3c0.3,0,0.8,0.2,1.2,0.2c1,0,2.1,0,3-0.2
// 			c0.2,0.1,0.3,0.2,0.3,0.4c0,0.5,0,1,0,1.5l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.2,0.1l-0.1,0c-0.2-0.1-0.3,0-0.5,0
// 			c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-1.2-0.1-1.7-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
// 			c-0.1,0-0.3-0.1-0.5-0.1h-0.1c-1.4-0.8-2.3-1.7-2.3-3.6c0-2.2,1.7-2.7,3.4-3.4c0.5,0.1,1.2,0,1.7,0.1c0.3-0.1,0.6-0.1,1-0.1
// 			c0.4,0,0.9,0,1.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.8l0,0.2c-0.2,0-0.2-0.1-0.5-0.1l-0.6,0
// 			c-0.5,0-0.9,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-1.1,0-1.1,0c0,0-0.6,0.3-0.6,0.3c-0.9,0.3-0.3,0-0.7,0.4
// 			C823.5,234.6,823.4,234.7,823.3,234.7z"
//         />
//         <path
//           fill={textColor}
//           d="M837.1,239.4c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 			c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 			c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 			c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 			c0,0.2-0.1,0.3-0.3,0.3H837.1z M836.3,235.6c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3
// 			c0.1-0.1,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1
// 			l-0.1,0.3c0.1,0.2,0.2,0.2,0.4,0.2H836.3z"
//         />
//         <path
//           fill={textColor}
//           d="M844.8,236c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 			c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 			c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 			c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 			c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 			c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 			c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 			c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 			c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 			c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 			c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1H846c-0.2,0-0.4,0.1-0.6,0.1
// 			C845.2,236.1,845,236,844.8,236z"
//         />
//         <path
//           fill={textColor}
//           d="M854.4,236.8c0-0.5,0-2.6-0.2-2.9c-0.1-0.3-0.3-0.3-0.5-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.5-0.2-0.8-0.3l0-0.1
// 			c0-0.4,0-0.7,0-1.1c0-0.1,0.1-0.2,0.1-0.2c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0.1,0.9,0.1
// 			c0.2,0,0.4-0.1,0.6-0.1c0.7,0,1.4,0,2.1,0c0.1,0,0.2,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.8,0.1
// 			c-0.3,0-0.8-0.1-0.9,0.3c-0.2,0.7-0.2,3.3-0.2,4c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.5,0,0.6-0.5,0.6
// 			c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.3-0.1-0.6-0.1-0.9C854.2,237.8,854.4,237.3,854.4,236.8z"
//         />
//         <path
//           fill={textColor}
//           d="M861.3,237.1c0-1.2,0.1-2.3,0.1-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.6-0.1-0.9l0-0.1
// 			c0.1-0.1,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.4
// 			c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
// 			c0,0.1,0,0.4,0.1,0.5c-0.1,0.3,0,0.6,0,0.9c0,0.2-0.1,0.4-0.1,0.5c0,0.7,0.4,0.5,0.9,0.5c0.6,0,1.2,0.1,1.8,0.1
// 			c0.1,0,0.3,0,0.4-0.1c0.3,0.1,0.6,0.1,1,0.1c0.7,0,1.1-0.2,1.1,0.5c0,0.2-0.1,1-0.3,1c-0.3,0-0.5-0.2-0.8-0.2c-0.1,0-0.3,0-0.4,0
// 			c-0.2-0.1-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.4,0.1-0.4,0.1
// 			c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.5,0.1-0.9,0.1-1.4
// 			C861.4,237.4,861.3,237.3,861.3,237.1z"
//         />
//         <path
//           fill={textColor}
//           d="M878,238.3c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 			c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 			c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 			c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1
// 			c-0.5,0-1.1,0-1.6,0.2c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2
// 			c0.5,0,1.1,0,1.6-0.1c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1
// 			c-0.2,0.1-0.5,0.1-0.7,0.1c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4
// 			c0,0.2,0.1,0.4,0.2,0.6c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L878,238.3z"
//         />
//         <path
//           fill={textColor}
//           d="M880.5,235.6c0-0.4,0.2-1.3,0.6-1.5c0-0.3,0.3-0.4,0.4-0.6c0.2-0.3,0.4-0.4,0.6-0.6c1-0.6,2-0.6,3.1-0.6
// 			c0.8,0,1.7-0.1,2.5-0.1c0.2,0,0.2,0.3,0.2,0.4c0,0.3-0.1,0.7-0.1,1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.9,0
// 			c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3,0-0.4-0.1c-0.3,0.1-0.6,0-0.9,0c-1.1,0-2.3,0.5-2.3,1.6c0,0.2,0,0.9,0.1,1.1
// 			c0.1,0.2,0.4,0.8,0.6,1c0.4,0.5,1,0.6,1.6,0.6c0.4,0,0.9-0.2,1.3-0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.7-0.1,0.7-0.4
// 			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.4-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.2-1.1,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.9,0.2-1
// 			c0.1-0.1,0.4-0.1,0.6-0.1c0.6,0,1.3,0.1,2,0.1c0.2,0,0.4-0.1,0.5-0.1c0,0,0.1,0,0.1,0c0.1,0.3,0.2,0.8,0.2,1.2
// 			c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.3,0.1,0.5c0,0.4-0.9,0.3-1.1,0.3
// 			c-0.2,0-0.4,0.1-0.5,0.1c-0.4-0.1-1,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.5,0-1.5-0.2-2-0.4c-0.4-0.2-0.7-0.6-1.1-0.9
// 			C880.9,237.3,880.5,236.5,880.5,235.6z"
//         />
//         <path
//           fill={textColor}
//           d="M896.4,239.4c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 			c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 			c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 			c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 			c0,0.2-0.1,0.3-0.3,0.3H896.4z M895.7,235.6c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3
// 			c0.1-0.1,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1
// 			l-0.1,0.3c0.1,0.2,0.2,0.2,0.4,0.2H895.7z"
//         />
//         <path
//           fill={textColor}
//           d="M902.4,239c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 			c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 			c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 			c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 			c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V239z
// 			 M906.5,233.9c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 			c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C906.6,234.3,906.5,234.1,906.5,233.9z"
//         />
//       </g>
//       <g id="Rossland">
//         <path
//           fill={textColor}
//           d="M797.5,333.7c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V333.7z
// 		 M801.6,328.6c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C801.7,329.1,801.6,328.8,801.6,328.6z"
//         />
//         <path
//           fill={textColor}
//           d="M805.5,330c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3l0,0.6
// 		c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C805.4,330.7,805.5,330.3,805.5,330z M809.2,333.2
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C808.5,332.7,808.5,333.2,809.2,333.2z"
//         />
//         <path
//           fill={textColor}
//           d="M819,330.7c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C819.4,330.8,819.2,330.7,819,330.7z"
//         />
//         <path
//           fill={textColor}
//           d="M829.2,330.7c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C829.6,330.8,829.4,330.7,829.2,330.7z"
//         />
//         <path
//           fill={textColor}
//           d="M835.9,331.8c0-1.2,0.1-2.3,0.1-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.6-0.1-0.9l0-0.1
// 		c0.1-0.1,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.4
// 		c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
// 		c0,0.1,0,0.4,0.1,0.5c-0.1,0.3,0,0.6,0,0.9c0,0.2-0.1,0.4-0.1,0.5c0,0.7,0.4,0.5,0.9,0.5c0.6,0,1.2,0.1,1.8,0.1
// 		c0.1,0,0.3,0,0.4-0.1c0.3,0.1,0.6,0.1,1,0.1c0.7,0,1.1-0.2,1.1,0.5c0,0.2-0.1,1-0.3,1c-0.3,0-0.5-0.2-0.8-0.2c-0.1,0-0.3,0-0.4,0
// 		c-0.2-0.1-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.4,0.1-0.4,0.1
// 		c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.5,0.1-0.9,0.1-1.4C836,332.2,835.9,332,835.9,331.8z"
//         />
//         <path
//           fill={textColor}
//           d="M851.2,334.2c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 		c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 		c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 		c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 		c0,0.2-0.1,0.3-0.3,0.3H851.2z M850.5,330.4c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2,0,0.2-0.1
// 		c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1l-0.1,0.3
// 		c0.1,0.2,0.2,0.2,0.4,0.2H850.5z"
//         />
//         <path
//           fill={textColor}
//           d="M855.9,327c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 		c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 		c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 		c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4c-0.3-0.5-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 		c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 		c0-2.2,0-4.4,0-6.5C855.8,327.2,855.8,327.2,855.9,327L855.9,327z"
//         />
//         <path
//           fill={textColor}
//           d="M865.7,332c0-0.3,0.1-0.5,0.1-0.8c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1-0.2-2.2-0.2-3.3c0-0.1,0.1-0.2,0.2-0.2
// 		c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.6-0.1,2.3-0.1c0.8,0,1.5,0.3,2.1,0.7c0.5,0.3,0.5,0.7,0.9,1.1
// 		c0.3,0.3,0.4,0.9,0.4,1.3s-0.1,0.8,0,1.2c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4
// 		c-0.3,0.5-1.2,0.8-1.8,1.1c-0.6,0-1.3,0.2-1.9,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0.2-0.9,0.2-1.4,0.2
// 		c-0.1,0-0.3,0-0.3-0.1C865.6,333.3,865.7,332.7,865.7,332z M869.4,332.7c1.2,0,1.9-0.9,1.9-2c0-0.8-0.3-1.2-0.8-1.8
// 		c-0.2,0-0.3-0.1-0.5-0.2l-0.1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1,0-0.2-0.1l-0.2-0.3c-0.1,0-0.4-0.1-0.5-0.1
// 		c-0.8,0-1.4,0.2-1.4,1.1c0,0.3,0.1,1.8,0.2,2.1c0,0.2-0.1,0.3-0.1,0.5c0,0.3,0,0.5,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1
// 		C868.1,332.7,868.7,332.7,869.4,332.7z"
//         />
//       </g>
//       <g id="Greenwood">
//         <path
//           fill={textColor}
//           d="M618.5,296.4c0-0.4,0.2-1.3,0.6-1.5c0-0.3,0.3-0.4,0.4-0.6c0.2-0.3,0.4-0.4,0.6-0.6c1-0.6,2-0.6,3.1-0.6
// 		c0.8,0,1.7-0.1,2.5-0.1c0.2,0,0.2,0.3,0.2,0.4c0,0.3-0.1,0.7-0.1,1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.9,0
// 		c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3,0-0.4-0.1c-0.3,0.1-0.6,0-0.9,0c-1.1,0-2.3,0.5-2.3,1.6c0,0.2,0,0.9,0.1,1.1
// 		c0.1,0.2,0.4,0.8,0.6,1c0.4,0.5,1,0.6,1.6,0.6c0.4,0,0.9-0.2,1.3-0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.7-0.1,0.7-0.4
// 		c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.4-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.2-1.1,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.9,0.2-1
// 		c0.1-0.1,0.4-0.1,0.6-0.1c0.6,0,1.3,0.1,2,0.1c0.2,0,0.4-0.1,0.5-0.1c0,0,0.1,0,0.1,0c0.1,0.3,0.2,0.8,0.2,1.2
// 		c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.3,0.1,0.5c0,0.4-0.9,0.3-1.1,0.3
// 		c-0.2,0-0.4,0.1-0.5,0.1c-0.5-0.1-1,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.5,0-1.5-0.2-2-0.4c-0.4-0.2-0.7-0.6-1.1-0.9
// 		C618.9,298.1,618.5,297.3,618.5,296.4z"
//         />
//         <path
//           fill={textColor}
//           d="M630.6,299.7c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V299.7z
// 		 M634.7,294.6c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C634.8,295.1,634.7,294.8,634.7,294.6z"
//         />
//         <path
//           fill={textColor}
//           d="M645.8,299c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.2-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1s-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L645.8,299z"
//         />
//         <path
//           fill={textColor}
//           d="M655.7,299c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L655.7,299z"
//         />
//         <path
//           fill={textColor}
//           d="M659.1,293.1c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 		c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 		c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 		c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 		c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 		c0-2.2,0-4.4,0-6.5C659,293.2,659,293.2,659.1,293.1L659.1,293.1z"
//         />
//         <path
//           fill={textColor}
//           d="M670.2,293.2c0,0.5-0.1,1.1-0.1,1.7c0,0.4,0,0.8,0,1.3c0,0.3,0,0.8,0,1c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0,0.1-0.1
// 		c0.3-0.7,0.8-1.7,1.1-2.4c0.1-0.1,0.3-0.6,0.4-0.8c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.1,0.2c0.2,0.5,0.6,1,0.8,1.6
// 		c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.2,0.3,0.5,0.6,0.6c-0.1-0.6-0.1-1.5-0.1-2.1c0-0.5-0.1-1.1-0.1-1.6c0-0.4,1-0.4,1.3-0.4
// 		c0.2,0,0.6,0,0.6,0.3c0,0.5,0,1,0,1.5c0,0.5,0,2.5,0,2.8c-0.1,0.3,0,1.3,0,1.6c0,0.3,0,0.8-0.4,0.9c-0.4,0-0.8,0.1-1.2,0.1
// 		c-0.6,0-1.1-1.7-1.7-2.1c0-0.1-0.2-0.4-0.2-0.5c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0.2-0.2,0.4-0.2,0.5
// 		c0,0.2-0.1,0.3-0.3,0.4c-0.3,0.7-0.7,2.1-1.7,2.1c-0.2,0-0.4,0.1-0.7,0.1c-0.3,0-0.4,0-0.4-0.3c0-0.3,0.1-0.6,0-1
// 		c0.1-0.3,0-0.6,0-0.9c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.3-0.1-0.6-0.1-0.9
// 		c0.1-0.1,0.1-0.2,0.1-0.3c0-0.3-0.1-0.6,0-0.9c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.4,1-0.3,1.2-0.4C670,292.8,670.2,293.1,670.2,293.2z"
//         />
//         <path
//           fill={textColor}
//           d="M678.6,296c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3l0,0.6
// 		c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C678.4,296.7,678.6,296.4,678.6,296z M682.2,299.2
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C681.5,298.7,681.5,299.2,682.2,299.2z"
//         />
//         <path
//           fill={textColor}
//           d="M688.4,296c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.2-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3l0,0.6
// 		c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C688.2,296.7,688.4,296.4,688.4,296z M692.1,299.2
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.5,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C691.3,298.7,691.4,299.2,692.1,299.2z"
//         />
//         <path
//           fill={textColor}
//           d="M698.6,298c0-0.3,0.1-0.5,0.1-0.8c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1-0.2-2.2-0.2-3.3c0-0.1,0.1-0.2,0.2-0.2
// 		c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.6-0.1,2.3-0.1c0.8,0,1.5,0.3,2.1,0.7c0.5,0.3,0.5,0.7,0.9,1.1
// 		c0.3,0.3,0.4,0.9,0.4,1.3s-0.1,0.8,0,1.2c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4
// 		c-0.3,0.5-1.2,0.8-1.8,1.1c-0.6,0-1.3,0.2-1.9,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0.2-0.9,0.2-1.4,0.2
// 		c-0.1,0-0.3,0-0.3-0.1C698.5,299.3,698.6,298.7,698.6,298z M702.3,298.7c1.2,0,1.9-0.9,1.9-2c0-0.8-0.3-1.2-0.8-1.8
// 		c-0.2,0-0.3-0.1-0.5-0.2l-0.1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1,0-0.2-0.1l-0.2-0.3c-0.1,0-0.5-0.1-0.5-0.1
// 		c-0.8,0-1.4,0.2-1.4,1.1c0,0.3,0.1,1.8,0.2,2.1c0,0.2-0.1,0.3-0.1,0.5c0,0.3,0,0.5,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1
// 		C700.9,298.7,701.6,298.7,702.3,298.7z"
//         />
//       </g>
//       <g id="GrandForks">
//         <line
//           fill="none"
//           stroke={textColor}
//           strokeLinecap="round"
//           strokeMiterlimit={10}
//           x1="690.2"
//           y1="334.8"
//           x2="690.2"
//           y2="364.7"
//         />
//         <path
//           fill={textColor}
//           d="M640.2,373.3c0-0.4,0.2-1.3,0.6-1.5c0-0.3,0.3-0.4,0.4-0.6c0.2-0.3,0.4-0.4,0.6-0.6c1-0.6,2-0.6,3.1-0.6
// 		c0.8,0,1.7-0.1,2.5-0.1c0.2,0,0.2,0.3,0.2,0.4c0,0.3-0.1,0.7-0.1,1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.9,0
// 		c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3,0-0.4-0.1c-0.3,0.1-0.6,0-0.9,0c-1.1,0-2.3,0.5-2.3,1.6c0,0.2,0,0.9,0.1,1.1
// 		c0.1,0.2,0.4,0.8,0.6,1c0.4,0.5,1,0.6,1.6,0.6c0.4,0,0.9-0.2,1.3-0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.7-0.1,0.7-0.4
// 		c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.4-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.2-1.1,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.9,0.2-1
// 		c0.1-0.1,0.4-0.1,0.6-0.1c0.6,0,1.3,0.1,2,0.1c0.2,0,0.4-0.1,0.5-0.1c0,0,0.1,0,0.1,0c0.1,0.3,0.2,0.8,0.2,1.2
// 		c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.3,0.1,0.5c0,0.4-0.9,0.3-1.1,0.3
// 		c-0.2,0-0.4,0.1-0.5,0.1c-0.5-0.1-1,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.5,0-1.5-0.2-2-0.4c-0.4-0.2-0.7-0.6-1.1-0.9
// 		C640.6,375,640.2,374.2,640.2,373.3z"
//         />
//         <path
//           fill={textColor}
//           d="M652.3,376.6c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V376.6z
// 		 M656.4,371.5c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C656.5,372,656.4,371.7,656.4,371.5z"
//         />
//         <path
//           fill={textColor}
//           d="M666.1,377.1c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 		c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 		c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 		c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 		c0,0.2-0.1,0.3-0.3,0.3H666.1z M665.3,373.3c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2,0,0.2-0.1
// 		c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1l-0.1,0.3
// 		c0.1,0.2,0.2,0.2,0.4,0.2H665.3z"
//         />
//         <path
//           fill={textColor}
//           d="M670.8,370c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 		c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 		c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 		c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 		c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 		c0-2.2,0-4.4,0-6.5C670.6,370.1,670.7,370.1,670.8,370L670.8,370z"
//         />
//         <path
//           fill={textColor}
//           d="M680.5,374.9c0-0.3,0.1-0.5,0.1-0.8c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1-0.2-2.2-0.2-3.3c0-0.1,0.1-0.2,0.2-0.2
// 		c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.6-0.1,2.3-0.1c0.8,0,1.5,0.3,2.1,0.7c0.5,0.3,0.5,0.7,0.9,1.1
// 		c0.3,0.3,0.4,0.9,0.4,1.3s-0.1,0.8,0,1.2c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4
// 		c-0.3,0.5-1.2,0.8-1.8,1.1c-0.6,0-1.3,0.2-1.9,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0.2-0.9,0.2-1.4,0.2
// 		c-0.1,0-0.3,0-0.3-0.1C680.5,376.2,680.5,375.6,680.5,374.9z M684.2,375.6c1.2,0,1.9-0.9,1.9-2c0-0.8-0.3-1.2-0.8-1.8
// 		c-0.2,0-0.3-0.1-0.5-0.2l-0.1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1,0-0.2-0.1l-0.2-0.3c-0.1,0-0.5-0.1-0.5-0.1
// 		c-0.8,0-1.4,0.2-1.4,1.1c0,0.3,0.1,1.8,0.2,2.1c0,0.2-0.1,0.3-0.1,0.5c0,0.3,0,0.5,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1
// 		C682.9,375.6,683.6,375.6,684.2,375.6z"
//         />
//         <path
//           fill={textColor}
//           d="M697.9,371.9v0.1c0,0.4,0.1,0.8,0.2,1.2c0.3,0,0.1,0,0.7,0c0.1,0,0.5,0.1,0.6,0.1c0.1,0,0.2-0.1,0.4-0.1
// 		c0.2,0,0.3-0.1,0.5-0.1c0.4,0,0.8,0,1.2-0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.5,0,0.9-0.3,1.3l-0.2,0.1c-0.2,0-0.3-0.1-0.5-0.1
// 		c-0.2,0-0.7,0.1-1.1,0.1c-0.4,0-1.7-0.1-1.6,0.4c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.1,1c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.3,0,0.4
// 		c-0.3,0.1-0.7,0.1-0.9,0.1c-0.2,0-0.3-0.1-0.4-0.1c-0.1-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3c0.1-1,0.1-2.1,0.1-3.1
// 		c0-0.6-0.1-1.2-0.1-1.8l0.1-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0-0.4,0.1-0.6c0.1,0,0.2,0,0.4,0c0.3,0,0.7,0,1,0.1
// 		c0.2-0.1,0.5-0.1,0.7-0.1l1.2,0c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.9-0.1,1.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.2-0.1,0.3-0.1
// 		c0.1,0,0.2,0,0.4,0.1c0.1,0,0.3-0.1,0.5-0.1c0.3,0,0.3,0.9,0.3,1.1c0,0.2-0.1,0.4-0.3,0.5H703c-0.3-0.2-0.8-0.1-1.2-0.1
// 		C701,371.6,698.6,371.7,697.9,371.9L697.9,371.9z"
//         />
//         <path
//           fill={textColor}
//           d="M705.9,372.9c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C705.7,373.6,705.9,373.3,705.9,372.9z M709.6,376.1
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C708.8,375.6,708.9,376.1,709.6,376.1z"
//         />
//         <path
//           fill={textColor}
//           d="M717.6,376.6c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V376.6z
// 		 M721.7,371.5c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C721.8,372,721.7,371.7,721.7,371.5z"
//         />
//         <path
//           fill={textColor}
//           d="M727.6,374.9c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 		H726c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6c0-0.6-0.1-1.3-0.1-2
// 		c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6V370c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6
// 		c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1
// 		c0,0.1-0.6,0.7-0.7,0.8c-0.4,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0
// 		c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C728.3,374.1,727.7,374.7,727.6,374.9
// 		L727.6,374.9z"
//         />
//         <path
//           fill={textColor}
//           d="M739,373.6c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C739.4,373.7,739.2,373.6,739,373.6z"
//         />
//       </g>
//       <g id="Trail">
//         <path
//           fill={textColor}
//           d="M899.6,300.7c0-0.5,0-2.6-0.2-2.9c-0.1-0.3-0.3-0.3-0.5-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.5-0.2-0.8-0.3l0-0.1
// 		c0-0.4,0-0.7,0-1.1c0-0.1,0.1-0.2,0.1-0.2c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0.1,0.9,0.1
// 		c0.2,0,0.4-0.1,0.6-0.1c0.7,0,1.4,0,2.1,0c0.1,0,0.2,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.8,0.1
// 		c-0.3,0-0.8-0.1-0.9,0.3c-0.2,0.7-0.2,3.3-0.2,4c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.5,0,0.6-0.5,0.6
// 		c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.3-0.1-0.6-0.1-0.9C899.5,301.7,899.6,301.2,899.6,300.7z"
//         />
//         <path
//           fill={textColor}
//           d="M908.2,302.8c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V302.8z
// 		 M912.3,297.7c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C912.4,298.2,912.3,297.9,912.3,297.7z"
//         />
//         <path
//           fill={textColor}
//           d="M922,303.3c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 		c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 		c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 		c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 		c0,0.2-0.1,0.3-0.3,0.3H922z M921.2,299.5c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2,0,0.2-0.1
// 		c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1l-0.1,0.3
// 		c0.1,0.2,0.2,0.2,0.4,0.2H921.2z"
//         />
//         <path
//           fill={textColor}
//           d="M928.9,300.7L928.9,300.7c0-0.8-0.1-1.6-0.1-2.4c0-1-0.3-0.7-1.2-0.7c-0.4,0-0.8,0.1-1,0.1
// 		c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.2-0.1-0.9-0.1-1.1c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.5,0
// 		c0.7,0,1.4,0,2.1,0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.4,0,2.2,0,2.3,0.2c0,0.2,0,0.4,0,0.6
// 		s0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.7,0.1c-0.1,0-0.9,0-1,0.1c-0.1,0.2-0.1,3-0.1,3.5c0,0.6,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.7-0.1
// 		c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0,0.1,0v0.2c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.7-0.1-1.1-0.2c-1.5,0-3,0-4.5,0
// 		c-0.5,0-0.9,0-0.9-0.6c0-0.8,0.5-0.7,1.1-0.7c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.3,0.1-0.6,0.1-0.9
// 		C928.9,300.8,928.9,300.8,928.9,300.7z"
//         />
//         <path
//           fill={textColor}
//           d="M935.9,300.9c0-1.2,0.1-2.3,0.1-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.6-0.1-0.9l0-0.1
// 		c0.1-0.1,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.4
// 		c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
// 		c0,0.1,0,0.4,0.1,0.5c-0.1,0.3,0,0.6,0,0.9c0,0.2-0.1,0.4-0.1,0.5c0,0.7,0.4,0.5,0.9,0.5c0.6,0,1.2,0.1,1.8,0.1
// 		c0.1,0,0.3,0,0.4-0.1c0.3,0.1,0.6,0.1,1,0.1c0.7,0,1.1-0.2,1.1,0.5c0,0.2-0.1,1-0.3,1c-0.3,0-0.5-0.2-0.8-0.2c-0.1,0-0.3,0-0.4,0
// 		c-0.2-0.1-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.4,0.1-0.4,0.1
// 		c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.5,0.1-0.9,0.1-1.4
// 		C935.9,301.3,935.9,301.1,935.9,300.9z"
//         />
//       </g>
//       <g id="ChristinaLake">
//         <g id="Christina">
//           <path
//             fill={textColor}
//             d="M707.9,344.9c-0.5,0.3-0.6,1-0.6,1.5c0,0.7,0.8,1.3,1.5,1.3c0.3,0,0.8,0.2,1.2,0.2c1,0,2.1,0,3-0.2
// 			c0.2,0.1,0.3,0.2,0.3,0.4c0,0.5,0,1,0,1.5l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.2,0.1l-0.1,0c-0.2-0.1-0.3,0-0.5,0
// 			c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-1.2-0.1-1.7-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
// 			c-0.1,0-0.3-0.1-0.5-0.1h-0.1c-1.4-0.8-2.3-1.7-2.3-3.6c0-2.2,1.7-2.7,3.4-3.4c0.5,0.1,1.2,0,1.7,0.1c0.3-0.1,0.6-0.1,1-0.1
// 			c0.4,0,0.9,0,1.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.8l0,0.2c-0.2,0-0.2-0.1-0.5-0.1l-0.6,0
// 			c-0.5,0-0.9,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-1.1,0-1.1,0c0,0-0.6,0.3-0.6,0.3c-0.9,0.3-0.3,0-0.7,0.4
// 			C708.1,344.8,708,344.9,707.9,344.9z"
//           />
//           <path
//             fill={textColor}
//             d="M723.3,348.8c0,0.2,0,0.4,0,0.6c0,0-0.1,0.1-0.1,0.1c-0.3,0.1-0.7,0.1-1,0.1c-0.2,0-0.3,0-0.4-0.1
// 			c0-0.1,0-0.3,0-0.4c0-0.2-0.1-0.3-0.1-0.5c0-0.3,0.1-0.5,0.1-0.7c0-0.4-0.3-0.3-0.1-0.8c-0.2-0.2-0.2-0.2-0.5-0.2
// 			c-0.2,0-0.4-0.1-0.5-0.1c-0.3,0.1-1.1,0.1-1.4,0.1c-0.3,0-0.5-0.1-0.7-0.1c-0.2,0-0.4,0.2-0.5,0.3c-0.1,0.3,0,0.6,0,0.9
// 			c0,0.5-0.2,1-0.3,1.5c-0.1,0-0.1,0.1-0.2,0.1c-0.2,0.1-0.6,0-0.9,0c-0.1-0.1-0.1-0.2-0.1-0.3c0-0.4,0.1-0.6,0.1-1
// 			c0-0.3-0.1-0.6-0.1-0.9c0-1.3,0.1-2.5,0.1-3.7c0-0.2,0-0.5,0-0.7c0-0.1-0.1-0.3-0.1-0.4c0-0.1,0.1-0.2,0.2-0.2c0.1,0,1,0,1.1,0.1
// 			c0.2,0.3,0.2,0.6,0.2,0.9l0,0.3c0,0.1,0,0.1,0,0.2c0,0.1,0,0.1,0.1,0.2l0,0.2c0,0.1,0,0.2,0,0.3c0,0.3,0.1,0.6,0.2,0.9l0.2,0.4
// 			c0,0,0.1,0,0.1,0l0.2,0c0,0,0.1,0,0.1,0c0.1,0,0.3,0,0.4-0.1h0.1c0.4,0,2-0.1,2.2-0.2c0.1,0,0.2-0.1,0.3-0.3c0.1-0.4,0-0.7,0-1.1
// 			c0-0.2,0.1-0.5,0-0.7c0.1-0.4-0.1-0.8,0.2-1c0.1,0,0.4,0,0.5,0c0.2,0,0.9,0,1,0c0,0.1,0.1,0.1,0.1,0.2c0,0.3,0,0.5,0,0.8
// 			c0,0.3-0.1,0.5-0.1,0.8c0,0.3,0.1,0.6,0.1,1c0,0.9,0,1.8,0,2.7C723.2,348.3,723.3,348.5,723.3,348.8z"
//           />
//           <path
//             fill={textColor}
//             d="M727.8,349.2c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 			c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 			c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 			c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 			c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V349.2z
// 			 M731.9,344.1c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 			c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C732,344.5,731.9,344.3,731.9,344.1z"
//           />
//           <path
//             fill={textColor}
//             d="M738.7,347L738.7,347c0-0.8-0.1-1.6-0.1-2.4c0-1-0.3-0.7-1.2-0.7c-0.4,0-0.8,0.1-1,0.1c-0.1,0-0.1,0-0.2-0.1
// 			c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.2-0.1-0.9-0.1-1.1c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.5,0c0.7,0,1.4,0,2.1,0.2
// 			c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.4,0,2.2,0,2.3,0.2c0,0.2,0,0.4,0,0.6s0,0.4-0.1,0.5
// 			c-0.5,0.2-1.2,0.1-1.7,0.1c-0.1,0-0.9,0-1,0.1c-0.1,0.2-0.1,3-0.1,3.5c0,0.6,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.7-0.1
// 			c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0,0.1,0v0.2c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.7-0.1-1.1-0.2
// 			c-1.5,0-3,0-4.5,0c-0.5,0-0.9,0-0.9-0.6c0-0.8,0.5-0.7,1.1-0.7c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2
// 			c0.1-0.3,0.1-0.6,0.1-0.9C738.7,347.2,738.7,347.1,738.7,347z"
//           />
//           <path
//             fill={textColor}
//             d="M749.1,346.2c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 			c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 			c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 			c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 			c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 			c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 			c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 			c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 			c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 			c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 			c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 			C749.5,346.3,749.3,346.2,749.1,346.2z"
//           />
//           <path
//             fill={textColor}
//             d="M758.6,347c0-0.5,0-2.6-0.2-2.9c-0.1-0.3-0.3-0.3-0.5-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.5-0.2-0.8-0.3l0-0.1
// 			c0-0.4,0-0.7,0-1.1c0-0.1,0.1-0.2,0.1-0.2c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0.1,0.9,0.1
// 			c0.2,0,0.4-0.1,0.6-0.1c0.7,0,1.4,0,2.1,0c0.1,0,0.2,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.8,0.1
// 			c-0.3,0-0.8-0.1-0.9,0.3c-0.2,0.7-0.2,3.3-0.2,4c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.5,0,0.6-0.5,0.6
// 			c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.3-0.1-0.6-0.1-0.9C758.5,348,758.6,347.5,758.6,347z"
//           />
//           <path
//             fill={textColor}
//             d="M768.1,347L768.1,347c0-0.8-0.1-1.6-0.1-2.4c0-1-0.3-0.7-1.2-0.7c-0.4,0-0.8,0.1-1,0.1c-0.1,0-0.1,0-0.2-0.1
// 			c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.2-0.1-0.9-0.1-1.1c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.5,0c0.7,0,1.4,0,2.1,0.2
// 			c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.4,0,2.2,0,2.3,0.2c0,0.2,0,0.4,0,0.6s0,0.4-0.1,0.5
// 			c-0.5,0.2-1.2,0.1-1.7,0.1c-0.1,0-0.9,0-1,0.1c-0.1,0.2-0.1,3-0.1,3.5c0,0.6,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.7-0.1
// 			c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0,0.1,0v0.2c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.7-0.1-1.1-0.2
// 			c-1.5,0-3,0-4.5,0c-0.5,0-0.9,0-0.9-0.6c0-0.8,0.5-0.7,1.1-0.7c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2
// 			c0.1-0.3,0.1-0.6,0.1-0.9C768.2,347.2,768.1,347.1,768.1,347z"
//           />
//           <path
//             fill={textColor}
//             d="M775.5,342.5c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 			c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 			c0.2,0,0.5,0,0.7,0s0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 			c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4c-0.3-0.5-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 			c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 			c0-2.2,0-4.4,0-6.5C775.4,342.7,775.4,342.6,775.5,342.5L775.5,342.5z"
//           />
//           <path
//             fill={textColor}
//             d="M790.6,349.6c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 			c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 			c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 			c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 			c0,0.2-0.1,0.3-0.3,0.3H790.6z M789.9,345.8c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3
// 			c0.1-0.1,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1
// 			l-0.1,0.3c0.1,0.2,0.2,0.2,0.4,0.2H789.9z"
//           />
//         </g>
//         <g id="Lake">
//           <path
//             fill={textColor}
//             d="M729.9,358.9c0-1.2,0.1-2.3,0.1-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.6-0.1-0.9l0-0.1
// 			c0.1-0.1,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.4
// 			c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
// 			c0,0.1,0,0.4,0.1,0.5c-0.1,0.3,0,0.6,0,0.9c0,0.2-0.1,0.4-0.1,0.5c0,0.7,0.4,0.5,0.9,0.5c0.6,0,1.2,0.1,1.8,0.1
// 			c0.1,0,0.3,0,0.4-0.1c0.3,0.1,0.6,0.1,1,0.1c0.7,0,1.1-0.2,1.1,0.5c0,0.2-0.1,1-0.3,1c-0.3,0-0.5-0.2-0.8-0.2c-0.1,0-0.3,0-0.4,0
// 			c-0.2-0.1-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.4,0.1-0.4,0.1
// 			c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.5,0.1-0.9,0.1-1.4
// 			C729.9,359.3,729.9,359.1,729.9,358.9z"
//           />
//           <path
//             fill={textColor}
//             d="M745.2,361.2c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 			c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 			c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 			c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 			c0,0.2-0.1,0.3-0.3,0.3H745.2z M744.5,357.4c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3
// 			c0.1-0.1,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1
// 			l-0.1,0.3c0.1,0.2,0.2,0.2,0.4,0.2H744.5z"
//           />
//           <path
//             fill={textColor}
//             d="M751.2,359c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 			h-0.4c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6
// 			c0-0.6-0.1-1.3-0.1-2c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6v-0.1c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1
// 			c0.1,0.1,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4
// 			c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1
// 			c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1c0,0.1-0.6,0.7-0.7,0.8c-0.4,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2
// 			c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2
// 			c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C751.9,358.3,751.4,358.9,751.2,359L751.2,359z"
//           />
//           <path
//             fill={textColor}
//             d="M766.1,360.1c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 			c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 			c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 			c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1
// 			c-0.5,0-1.1,0-1.6,0.2c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2
// 			c0.5,0,1.1,0,1.6-0.1c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1
// 			c-0.2,0.1-0.5,0.1-0.7,0.1c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4
// 			c0,0.2,0.1,0.4,0.2,0.6c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L766.1,360.1z"
//           />
//         </g>
//       </g>
//       <g id="Midway">
//         <path
//           fill={textColor}
//           d="M621.1,349.8c-0.1-0.2-0.2-0.4-0.1-0.6c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.5,0.1-1,0.1-1.5c0-0.5-0.1-1-0.1-1.5
// 		c0-0.1,0-0.3,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.5-0.2,0.6-0.2c0.4,0,0.8,0.1,1.3,0.1c0.1,0,0.2,0.1,0.2,0.2
// 		c0.2,0.8,0.7,1.5,1.2,2.1l0,0.2l0,0.1c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.3-0.3,0.4-0.3c0.2-0.4,0.5-1.4,0.9-1.5c0,0,0-0.1,0-0.1
// 		c0-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.1,0.2-0.1c0.5,0,1.2,0.1,1.8,0.1c0.1,0,0.3,0,0.3,0.2v0.2c-0.1,0.8-0.1,1.6-0.1,2.4
// 		c0,0.4-0.1,0.7,0,1.1c0,0.1-0.1,0.2-0.1,0.3c0,0.6,0.1,1.2,0.1,1.9c0,1.1,0.1,0.8-1.3,1c-0.1,0-0.2-0.1-0.2-0.2
// 		c0-0.1,0-0.3,0.1-0.5c0.1-0.7,0-1.6,0-2.3c0-0.4-0.1-0.8-0.1-1.2l0,0v-0.1c0-0.1,0-0.7-0.1-0.8h-0.1c-0.1,0-0.3,0.5-0.3,0.6
// 		l-0.3,0.7c0,0.2-1,2.2-1.1,2.3c-0.1,0-0.1-0.1-0.2-0.2c-0.1-0.4-1-1.9-1.3-2.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.2-0.4-0.3-0.5
// 		l-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.4,0.1,0.7,0.1,1.1c0,0.4,0.1,0.9,0.1,1.3
// 		c0,0.4,0,0.9,0,1.4c0,0.1-0.1,0.3-0.1,0.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		c0-0.1-0.1-0.2-0.1-0.3c0-0.6,0-1.1,0-1.7L621.1,349.8z"
//         />
//         <path
//           fill={textColor}
//           d="M633.3,349.4L633.3,349.4c0-0.8-0.1-1.6-0.1-2.4c0-1-0.3-0.7-1.2-0.7c-0.4,0-0.8,0.1-1,0.1
// 		c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.2-0.1-0.9-0.1-1.1c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.5,0
// 		c0.7,0,1.4,0,2.1,0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.4,0,2.2,0,2.3,0.2c0,0.2,0,0.4,0,0.6
// 		s0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.7,0.1c-0.1,0-0.9,0-1,0.1c-0.1,0.2-0.1,3-0.1,3.5c0,0.6,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.7-0.1
// 		c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0,0.1,0v0.2c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.7-0.1-1.1-0.2c-1.5,0-3,0-4.5,0
// 		c-0.5,0-0.9,0-0.9-0.6c0-0.8,0.5-0.7,1.1-0.7c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2c0.1-0.3,0.1-0.6,0.1-0.9
// 		C633.3,349.6,633.3,349.5,633.3,349.4z"
//         />
//         <path
//           fill={textColor}
//           d="M640.4,349.9c0-0.3,0.1-0.5,0.1-0.8c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1-0.2-2.2-0.2-3.3c0-0.1,0.1-0.2,0.2-0.2
// 		c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.6-0.1,2.3-0.1c0.8,0,1.5,0.3,2.1,0.7c0.5,0.3,0.5,0.7,0.9,1.1
// 		c0.3,0.3,0.4,0.9,0.4,1.3s-0.1,0.8,0,1.2c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4
// 		c-0.3,0.5-1.2,0.8-1.8,1.1c-0.6,0-1.3,0.2-1.9,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0.2-0.9,0.2-1.4,0.2
// 		c-0.1,0-0.3,0-0.3-0.1C640.3,351.1,640.4,350.5,640.4,349.9z M644.1,350.5c1.2,0,1.9-0.9,1.9-2c0-0.8-0.3-1.2-0.8-1.8
// 		c-0.2,0-0.3-0.1-0.5-0.2l-0.1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1,0-0.2-0.1l-0.2-0.3c-0.1,0-0.4-0.1-0.5-0.1
// 		c-0.8,0-1.4,0.2-1.4,1.1c0,0.3,0.1,1.8,0.2,2.1c0,0.2-0.1,0.3-0.1,0.5c0,0.3,0,0.5,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1
// 		C642.8,350.5,643.5,350.5,644.1,350.5z"
//         />
//         <path
//           fill={textColor}
//           d="M651.8,345.1c0,0.5-0.1,1.1-0.1,1.7c0,0.4,0,0.8,0,1.3c0,0.3,0,0.8,0,1c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0,0.1-0.1
// 		c0.3-0.7,0.8-1.7,1.1-2.4c0.1-0.1,0.3-0.6,0.4-0.8c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.1,0.2c0.2,0.5,0.6,1,0.8,1.6
// 		c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.2,0.3,0.5,0.6,0.6c-0.1-0.6-0.1-1.5-0.1-2.1c0-0.5-0.1-1.1-0.1-1.6c0-0.4,1-0.4,1.3-0.4
// 		c0.2,0,0.6,0,0.6,0.3c0,0.5,0,1,0,1.5c0,0.5,0,2.5,0,2.8c-0.1,0.3,0,1.3,0,1.6c0,0.3,0,0.8-0.4,0.9c-0.4,0-0.8,0.1-1.2,0.1
// 		c-0.6,0-1.1-1.7-1.7-2.1c0-0.1-0.2-0.4-0.2-0.5c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0.2-0.2,0.4-0.2,0.5
// 		c0,0.2-0.1,0.3-0.3,0.4c-0.3,0.7-0.7,2.1-1.7,2.1c-0.2,0-0.4,0.1-0.7,0.1s-0.4,0-0.4-0.3c0-0.3,0.1-0.6,0-1c0.1-0.3,0-0.6,0-0.9
// 		c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.1-0.2,0.1-0.3
// 		c0-0.3-0.1-0.6,0-0.9c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.4,1-0.3,1.2-0.4C651.6,344.6,651.8,345,651.8,345.1z"
//         />
//         <path
//           fill={textColor}
//           d="M665.8,352c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 		c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 		c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 		c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 		c0,0.2-0.1,0.3-0.3,0.3H665.8z M665.1,348.2c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2,0,0.2-0.1
// 		c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1l-0.1,0.3
// 		c0.1,0.2,0.2,0.2,0.4,0.2H665.1z"
//         />
//         <path
//           fill={textColor}
//           d="M672.7,349c-0.2-0.4-0.4-0.8-0.6-1.2c-0.5-0.7-1.1-1.4-1.5-2.2c-0.1-0.2-0.2-0.5-0.5-0.6
// 		c0-0.3,0.1-0.2,0.3-0.2c0.5,0,0.9-0.1,1.4,0l0.2-0.2l0.1,0c0.5,0.6,0.7,1.6,1.2,2.1c0.2,0.2,0.3,0.4,0.4,0.6
// 		c0.1-0.3,0.3-0.6,0.5-0.9c0-0.2,0-0.3,0.1-0.4c0.3-0.4,0.8-1.3,0.9-1.3c0.2,0,1.2,0,1.4,0.1c0,0,0,0.2,0,0.2
// 		c-0.2,0.3-0.3,0.8-0.5,1.1c-0.4,0.3-0.4,0.9-0.7,1.2c-0.2,0.2-0.5,0.5-0.6,0.7c-0.1,0.4-0.3,0.4-0.6,1c0.1,0.5,0.2,1.4,0.2,1.9
// 		c0,0.2,0,0.5-0.1,0.7l-0.1,0.1c-0.2,0-0.4-0.2-0.7-0.2c-0.3,0-0.5,0.2-0.7,0.2c-0.4,0-0.3-0.6-0.3-0.9
// 		C672.6,350.3,672.6,349.6,672.7,349z"
//         />
//       </g>
//       <g id="Osoyoos">
//         <path
//           fill={textColor}
//           d="M462.9,341.6c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C462.8,342.3,462.9,342,462.9,341.6z M466.6,344.8
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C465.8,344.3,465.9,344.8,466.6,344.8z"
//         />
//         <path
//           fill={textColor}
//           d="M476.4,342.4c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1H479c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C476.8,342.5,476.6,342.4,476.4,342.4z"
//         />
//         <path
//           fill={textColor}
//           d="M482.9,341.6c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C482.7,342.3,482.9,342,482.9,341.6z M486.6,344.8
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C485.8,344.3,485.9,344.8,486.6,344.8z"
//         />
//         <path
//           fill={textColor}
//           d="M495.5,342.7c-0.2-0.4-0.4-0.8-0.6-1.2c-0.5-0.7-1.1-1.4-1.5-2.2c-0.1-0.2-0.2-0.5-0.5-0.6
// 		c0-0.3,0.1-0.2,0.3-0.2c0.5,0,0.9-0.1,1.4,0l0.2-0.2l0.1,0c0.5,0.6,0.7,1.6,1.2,2.1c0.2,0.2,0.3,0.4,0.4,0.6
// 		c0.1-0.3,0.3-0.6,0.5-0.9c0-0.2,0-0.3,0.1-0.4c0.3-0.4,0.8-1.3,0.9-1.3c0.2,0,1.2,0,1.4,0.1c0,0,0,0.2,0,0.2
// 		c-0.2,0.3-0.3,0.8-0.5,1.1c-0.4,0.3-0.4,0.9-0.7,1.2c-0.2,0.2-0.5,0.5-0.6,0.7c-0.1,0.4-0.3,0.4-0.6,1c0.1,0.5,0.2,1.4,0.2,1.9
// 		c0,0.2,0,0.5-0.1,0.7l-0.1,0.1c-0.2,0-0.4-0.2-0.7-0.2s-0.5,0.2-0.7,0.2c-0.4,0-0.3-0.6-0.3-0.9C495.4,344,495.4,343.4,495.5,342.7
// 		z"
//         />
//         <path
//           fill={textColor}
//           d="M502.1,341.6c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C501.9,342.3,502.1,342,502.1,341.6z M505.8,344.8
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C505,344.3,505.1,344.8,505.8,344.8z"
//         />
//         <path
//           fill={textColor}
//           d="M511.9,341.6c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C511.7,342.3,511.9,342,511.9,341.6z M515.6,344.8
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C514.8,344.3,514.9,344.8,515.6,344.8z"
//         />
//         <path
//           fill={textColor}
//           d="M525.4,342.4c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C525.7,342.5,525.6,342.4,525.4,342.4z"
//         />
//       </g>
//       <g id="Keremeos">
//         <path
//           fill={textColor}
//           d="M416.2,270.2c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 		h-0.4c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6
// 		c0-0.6-0.1-1.3-0.1-2c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6v-0.1c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6
// 		c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1
// 		c0,0.1-0.6,0.7-0.7,0.8c-0.4,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0
// 		c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C416.9,269.4,416.4,270,416.2,270.2
// 		L416.2,270.2z"
//         />
//         <path
//           fill={textColor}
//           d="M431.1,271.2c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L431.1,271.2z"
//         />
//         <path
//           fill={textColor}
//           d="M435.8,271.9c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V271.9z
// 		 M439.9,266.8c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C440,267.3,439.9,267,439.9,266.8z"
//         />
//         <path
//           fill={textColor}
//           d="M451,271.2c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L451,271.2z"
//         />
//         <path
//           fill={textColor}
//           d="M454.3,270.2c-0.1-0.2-0.2-0.4-0.1-0.6c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.5,0.1-1,0.1-1.5c0-0.5-0.1-1-0.1-1.5
// 		c0-0.1,0-0.3,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.5-0.2,0.6-0.2c0.4,0,0.8,0.1,1.3,0.1c0.1,0,0.2,0.1,0.2,0.2
// 		c0.2,0.8,0.7,1.5,1.2,2.1l0,0.2l0,0.1c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.3-0.3,0.4-0.3c0.2-0.4,0.5-1.4,0.9-1.5c0,0,0-0.1,0-0.1
// 		c0-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.1,0.2-0.1c0.5,0,1.2,0.1,1.8,0.1c0.1,0,0.3,0,0.3,0.2v0.2c-0.1,0.8-0.1,1.6-0.1,2.4
// 		c0,0.4-0.1,0.7,0,1.1c0,0.1-0.1,0.2-0.1,0.3c0,0.6,0.1,1.2,0.1,1.9c0,1.1,0.1,0.8-1.3,1c-0.1,0-0.2-0.1-0.2-0.2
// 		c0-0.1,0-0.3,0.1-0.5c0.1-0.7,0-1.6,0-2.3c0-0.4-0.1-0.8-0.1-1.2l0,0v-0.1c0-0.1,0-0.7-0.1-0.8h-0.1c-0.1,0-0.3,0.5-0.3,0.6
// 		l-0.3,0.7c0,0.2-1,2.2-1.1,2.3c-0.1,0-0.1-0.1-0.2-0.2c-0.1-0.4-1-1.9-1.3-2.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.2-0.4-0.3-0.5
// 		l-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.4,0.1,0.7,0.1,1.1c0,0.4,0.1,0.9,0.1,1.3
// 		c0,0.4,0,0.9,0,1.4c0,0.1-0.1,0.3-0.1,0.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		c0-0.1-0.1-0.2-0.1-0.3c0-0.6,0-1.1,0-1.7L454.3,270.2z"
//         />
//         <path
//           fill={textColor}
//           d="M470.8,271.2c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L470.8,271.2z"
//         />
//         <path
//           fill={textColor}
//           d="M473.6,268.2c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C473.5,268.9,473.6,268.6,473.6,268.2z M477.3,271.4
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C476.5,270.9,476.6,271.4,477.3,271.4z"
//         />
//         <path
//           fill={textColor}
//           d="M487.1,269c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C487.5,269.1,487.3,269,487.1,269z"
//         />
//       </g>
//       <g id="Cawston">
//         <path
//           fill={textColor}
//           d="M384.7,306.9c-0.5,0.3-0.6,1-0.6,1.5c0,0.7,0.8,1.3,1.5,1.3c0.3,0,0.8,0.2,1.2,0.2c1,0,2.1,0,3-0.2
// 		c0.2,0.1,0.3,0.2,0.3,0.4c0,0.5,0,1,0,1.5l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.2,0.1l-0.1,0c-0.2-0.1-0.3,0-0.5,0
// 		c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-1.2-0.1-1.7-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
// 		c-0.1,0-0.3-0.1-0.5-0.1h-0.1c-1.4-0.8-2.3-1.7-2.3-3.6c0-2.2,1.7-2.7,3.4-3.4c0.5,0.1,1.2,0,1.7,0.1c0.3-0.1,0.6-0.1,1-0.1
// 		c0.4,0,0.9,0,1.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.8l0,0.2c-0.2,0-0.2-0.1-0.5-0.1l-0.6,0
// 		c-0.5,0-0.9,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-1.1,0-1.1,0c0,0-0.6,0.3-0.6,0.3c-0.9,0.3-0.3,0-0.7,0.4
// 		C384.9,306.8,384.8,306.9,384.7,306.9z"
//         />
//         <path
//           fill={textColor}
//           d="M398.5,311.6c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 		c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 		c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 		c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 		c0,0.2-0.1,0.3-0.3,0.3H398.5z M397.8,307.8c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2,0,0.2-0.1
// 		c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1l-0.1,0.3
// 		c0.1,0.2,0.2,0.2,0.4,0.2H397.8z"
//         />
//         <path
//           fill={textColor}
//           d="M404.3,304.7c0,0.5-0.1,1.1-0.1,1.7c0,0.4,0,0.8,0,1.3c0,0.3,0,0.8,0,1c0,0.1,0,0.2,0.1,0.2c0,0,0.1,0,0.1-0.1
// 		c0.3-0.7,0.8-1.7,1.1-2.4c0.1-0.1,0.3-0.6,0.4-0.8c0,0,0.1-0.1,0.1-0.1c0,0,0,0,0.1,0c0.1,0,0.1,0.1,0.1,0.2c0.2,0.5,0.6,1,0.8,1.6
// 		c0.1,0.2,0.2,0.3,0.3,0.5c0.2,0.2,0.3,0.5,0.6,0.6c-0.1-0.6-0.1-1.5-0.1-2.1c0-0.5-0.1-1.1-0.1-1.6c0-0.4,1-0.4,1.3-0.4
// 		c0.2,0,0.6,0,0.6,0.3c0,0.5,0,1,0,1.5c0,0.5,0,2.5,0,2.8c-0.1,0.3,0,1.3,0,1.6c0,0.3,0,0.8-0.4,0.9c-0.4,0-0.8,0.1-1.2,0.1
// 		c-0.6,0-1.1-1.7-1.7-2.1c0-0.1-0.2-0.4-0.2-0.5c0-0.1,0-0.1,0-0.1c0-0.1,0-0.1-0.1-0.1c-0.1,0.2-0.2,0.4-0.2,0.5
// 		c0,0.2-0.1,0.3-0.3,0.4c-0.3,0.7-0.7,2.1-1.7,2.1c-0.2,0-0.4,0.1-0.7,0.1s-0.4,0-0.4-0.3c0-0.3,0.1-0.6,0-1c0.1-0.3,0-0.6,0-0.9
// 		c0-0.1,0.1-0.2,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.5c0-0.1,0.1-0.2,0.1-0.3c0-0.3-0.1-0.6-0.1-0.9c0.1-0.1,0.1-0.2,0.1-0.3
// 		c0-0.3-0.1-0.6,0-0.9c-0.1-0.6-0.1-1.2-0.2-1.8c0-0.4,1-0.3,1.2-0.4C404.1,304.3,404.3,304.6,404.3,304.7z"
//         />
//         <path
//           fill={textColor}
//           d="M416.3,308.2c-0.3,0-0.8,0.1-1.1,0c-0.1,0-0.3,0.1-0.4,0.1c-0.8,0-0.8-0.6-1.3-0.6c-0.4-0.4-0.7-0.8-0.7-1.4
// 		c0-0.2,0.2-0.8,0.1-0.9c0.1-0.3,0.4-0.5,0.6-0.8c0-0.3,0.7-0.3,1.1-0.4c0.3,0,0.6,0,0.8,0c0.2,0,0.5,0,0.8-0.1
// 		c0.1,0,0.3,0.1,0.5,0.1c0.4-0.1,0.5-0.1,0.9-0.1c0.3,0,0.6,0,1,0c0.2,0,0.4-0.1,0.5-0.1c0.1,0.1,0.2,0,0.4,0
// 		c0.3,0.4,0.2,0.8,0.4,1.3l-0.2,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0.1-0.3,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.7,0-1.5,0-2.2-0.1
// 		c-0.1,0.1-0.2,0.1-0.3,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.2,0-0.3,0c-0.4,0-1.1,0.4-1.1,0.8c0,0.1,0,0.4,0.1,0.4
// 		c0.5,0.3,3.4,0.3,4.2,0.3c0.2,0,0.4,0,0.7,0c0.4,0,0.9,0.8,1.1,1.2c0.1,0.2,0.2,0.6,0.2,0.8s-0.1,0.4-0.2,0.6
// 		c0,0.1,0.1,0.2,0.1,0.3c0,0.1-0.9,1.3-1.1,1.6c-0.1,0.1-0.2,0.1-0.3,0.1h-0.1c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.4,0.1
// 		c-0.8,0-1.5-0.1-2.3-0.1l-0.2,0c-0.1,0.1-0.2,0.1-0.3,0.1c0,0-0.1,0-0.1,0l-0.2,0c0,0-0.1,0-0.1,0c-0.3,0-0.7,0.1-1,0.1
// 		c-0.2,0-0.4,0-0.5,0.1c-0.1-0.1-0.2-0.2-0.4-0.3l0-0.1c0-0.1,0.1-0.2,0.1-0.3c0-0.1,0-0.2,0-0.3c0-0.6-0.1-0.7,0.6-0.7
// 		c0.6,0,1.2-0.1,1.8-0.1c0.3,0,0.5,0.1,0.8,0.1c1.2,0,2-0.1,2.2-0.3c0.1-0.1,0.2-0.2,0.2-0.3c0-0.3-0.1-0.9-0.3-1.1
// 		c-0.1,0-0.1,0.1-0.2,0.1l-0.1-0.1l-0.1,0c-0.1,0-0.2,0-0.2,0c-0.1,0-0.3,0-0.4-0.1h-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 		C416.7,308.3,416.5,308.2,416.3,308.2z"
//         />
//         <path
//           fill={textColor}
//           d="M425.8,309.1c0-0.5,0-2.6-0.2-2.9c-0.1-0.3-0.3-0.3-0.5-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.5-0.2-0.8-0.3l0-0.1
// 		c0-0.4,0-0.7,0-1.1c0-0.1,0.1-0.2,0.1-0.2c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0.1,0.9,0.1
// 		c0.2,0,0.4-0.1,0.6-0.1c0.7,0,1.4,0,2.1,0c0.1,0,0.2,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.8,0.1
// 		c-0.3,0-0.8-0.1-0.9,0.3c-0.2,0.7-0.2,3.3-0.2,4c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.5,0,0.6-0.5,0.6
// 		c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.3-0.1-0.6-0.1-0.9C425.7,310,425.8,309.5,425.8,309.1z"
//         />
//         <path
//           fill={textColor}
//           d="M432.5,307.4c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C432.4,308.2,432.5,307.8,432.5,307.4z M436.2,310.7
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C435.5,310.1,435.5,310.7,436.2,310.7z"
//         />
//         <path
//           fill={textColor}
//           d="M442.9,304.5c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 		c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 		c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 		c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 		c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 		c0-2.2,0-4.4,0-6.5C442.8,304.7,442.9,304.7,442.9,304.5L442.9,304.5z"
//         />
//       </g>
//       <g id="RockCreek">
//         <g id="Rock">
//           <path
//             fill={textColor}
//             d="M568.3,298.2c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V298.2z
// 		 M572.4,293.1c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C572.5,293.6,572.4,293.3,572.4,293.1z"
//           />
//           <path
//             fill={textColor}
//             d="M576.3,294.5c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C576.2,295.2,576.3,294.8,576.3,294.5z M580,297.7
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C579.3,297.1,579.3,297.7,580,297.7z"
//           />
//           <path
//             fill={textColor}
//             d="M588,293.9c-0.5,0.3-0.6,1-0.6,1.5c0,0.7,0.8,1.3,1.5,1.3c0.3,0,0.8,0.2,1.2,0.2c1,0,2.1,0,3-0.2
// 		c0.2,0.1,0.3,0.2,0.3,0.4c0,0.5,0,1,0,1.5l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.2,0.1l-0.1,0c-0.2-0.1-0.3,0-0.5,0
// 		c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-1.2-0.1-1.7-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
// 		c-0.1,0-0.3-0.1-0.5-0.1h-0.1c-1.4-0.8-2.3-1.7-2.3-3.6c0-2.2,1.7-2.7,3.4-3.4c0.5,0.1,1.2,0,1.7,0.1c0.3-0.1,0.6-0.1,1-0.1
// 		c0.4,0,0.9,0,1.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.8l0,0.2c-0.2,0-0.2-0.1-0.5-0.1l-0.6,0
// 		c-0.5,0-0.9,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-1.1,0-1.1,0c0,0-0.6,0.3-0.6,0.3c-0.9,0.3-0.3,0-0.7,0.4
// 		C588.2,293.8,588.1,293.9,588,293.9z"
//           />
//           <path
//             fill={textColor}
//             d="M598.1,296.4c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 		h-0.4c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6
// 		c0-0.6-0.1-1.3-0.1-2c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6v-0.1c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6
// 		c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1
// 		c0,0.1-0.6,0.7-0.7,0.8c-0.4,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0
// 		c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C598.8,295.7,598.2,296.3,598.1,296.4
// 		L598.1,296.4z"
//           />
//         </g>
//         <g id="Creek">
//           <path
//             fill={textColor}
//             d="M563.5,305.6c-0.5,0.3-0.6,1-0.6,1.5c0,0.7,0.8,1.3,1.5,1.3c0.3,0,0.8,0.2,1.2,0.2c1,0,2.1,0,3-0.2
// 		c0.2,0.1,0.3,0.2,0.3,0.4c0,0.5,0,1,0,1.5l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.2,0.1l-0.1,0c-0.2-0.1-0.3,0-0.5,0
// 		c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-1.2-0.1-1.7-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
// 		c-0.1,0-0.3-0.1-0.5-0.1h-0.1c-1.4-0.8-2.3-1.7-2.3-3.6c0-2.2,1.7-2.7,3.4-3.4c0.5,0.1,1.2,0,1.7,0.1c0.3-0.1,0.6-0.1,1-0.1
// 		c0.4,0,0.9,0,1.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.8l0,0.2c-0.2,0-0.2-0.1-0.5-0.1l-0.6,0
// 		c-0.5,0-0.9,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-1.1,0-1.1,0c0,0-0.6,0.3-0.6,0.3c-0.9,0.3-0.3,0-0.7,0.4
// 		C563.7,305.4,563.6,305.6,563.5,305.6z"
//           />
//           <path
//             fill={textColor}
//             d="M573.5,309.9c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V309.9z
// 		 M577.6,304.8c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C577.7,305.2,577.6,305,577.6,304.8z"
//           />
//           <path
//             fill={textColor}
//             d="M588.6,309.2c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L588.6,309.2z"
//           />
//           <path
//             fill={textColor}
//             d="M598.6,309.2c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L598.6,309.2z"
//           />
//           <path
//             fill={textColor}
//             d="M603.3,308.1c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 		h-0.4c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6
// 		c0-0.6-0.1-1.3-0.1-2c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6v-0.1c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6
// 		c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1
// 		c0,0.1-0.6,0.7-0.7,0.8c-0.4,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0
// 		c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C604,307.3,603.4,307.9,603.3,308.1
// 		L603.3,308.1z"
//           />
//         </g>
//       </g>
//       <g id="Princeton">
//         <path
//           fill={textColor}
//           d="M264.5,195.1L264.5,195.1c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6,0-1,0c-0.1-0.2-0.1-0.5-0.1-0.8
// 			c0-0.3,0.1-0.6,0.1-0.8c0-0.1-0.1-0.2-0.1-0.4v-0.9c0-0.8,0.1-1.6,0.1-2.4c0-0.3,0-0.5,0-0.8s-0.1-0.8,0.1-1h0.1
// 			c0.2,0,0.2,0,0.4-0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.6-0.1,1-0.1c0.1,0,1.2,0,1.2,0c0.1,0.1,0.3,0.1,0.4,0.1
// 			c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.1c0.3,0.3,0.8,0.2,1.1,0.5
// 			c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4-0.1,0.7-0.2,1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-1,0.8-1.5,0.8
// 			c-0.3,0-0.7,0-1,0c-0.4,0.2-1.8,0.1-2.3,0.1c-0.2,0-0.3,0.1-0.5,0.2c-0.1,0.2-0.1,0.8-0.2,1c0.1,0,0.1,0.1,0.1,0.2V195.1z
// 			 M264.6,191.3L264.6,191.3c0.1,0,0.3-0.1,0.3-0.2h0.3c0.2,0,0.4,0,0.5,0.1l0.1,0c0.1-0.1,1.6-0.1,1.9-0.1c0.1,0,0.2,0,0.3,0.1
// 			l0.1,0c0.3-0.3,0.9-0.2,0.9-0.9c0-0.9-1.6-1.1-2.3-1.1c-0.4,0-0.9,0.1-1.4,0.1c-1.2,0-0.7,0.4-0.9,1.2L264.6,191.3z"
//         />
//         <path
//           fill={textColor}
//           d="M274.4,194.9c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 			c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.5,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 			c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 			c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 			c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V194.9z
// 			 M278.5,189.8c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 			c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C278.6,190.2,278.5,190,278.5,189.8z"
//         />
//         <path
//           fill={textColor}
//           d="M285.3,192.7L285.3,192.7c0-0.8-0.1-1.6-0.1-2.4c0-1-0.3-0.7-1.2-0.7c-0.4,0-0.8,0.1-1,0.1
// 			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.2-0.1-0.9-0.1-1.1c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.4,0
// 			c0.7,0,1.4,0,2.1,0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.4,0,2.2,0,2.3,0.2c0,0.2,0,0.4,0,0.6
// 			s0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.7,0.1c-0.1,0-0.9,0-1,0.1c-0.1,0.2-0.1,3-0.1,3.5c0,0.6,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.7-0.1
// 			c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0,0.1,0v0.2c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.7-0.1-1.1-0.2
// 			c-1.5,0-3,0-4.5,0c-0.5,0-0.9,0-0.9-0.6c0-0.8,0.5-0.7,1.1-0.7c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2
// 			c0.1-0.3,0.1-0.6,0.1-0.9C285.3,192.9,285.3,192.8,285.3,192.7z"
//         />
//         <path
//           fill={textColor}
//           d="M292.6,188.2c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 			c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 			c0.2,0,0.5,0,0.7,0s0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 			c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4c-0.3-0.5-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 			c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 			c0-2.2,0-4.4,0-6.5C292.5,188.4,292.5,188.3,292.6,188.2L292.6,188.2z"
//         />
//         <path
//           fill={textColor}
//           d="M304,190.6c-0.5,0.3-0.6,1-0.6,1.5c0,0.7,0.8,1.3,1.5,1.3c0.3,0,0.8,0.2,1.2,0.2c1,0,2.1,0,3-0.2
// 			c0.2,0.1,0.3,0.2,0.3,0.4c0,0.5,0,1,0,1.5l-0.1,0c-0.1,0-0.2-0.1-0.3-0.1s-0.2,0-0.2,0.1l-0.1,0c-0.2-0.1-0.3,0-0.5,0
// 			c-0.1,0-0.2-0.1-0.3-0.1c-0.3,0-0.6,0.1-0.9,0.1c-0.5,0-1.2-0.1-1.7-0.2c-0.1-0.1-0.1-0.1-0.2-0.1c-0.1,0-0.2,0.1-0.3,0.1
// 			c-0.1,0-0.3-0.1-0.5-0.1h-0.1c-1.4-0.8-2.3-1.7-2.3-3.6c0-2.2,1.7-2.7,3.4-3.4c0.5,0.1,1.2,0,1.7,0.1c0.3-0.1,0.6-0.1,1-0.1
// 			c0.4,0,0.9,0,1.2-0.2c0.1,0.1,0.1,0.2,0.1,0.3c0,0.2-0.1,0.4-0.1,0.6c0,0.3,0,0.5,0.1,0.8l0,0.2c-0.2,0-0.2-0.1-0.5-0.1l-0.6,0
// 			c-0.5,0-0.9,0.1-0.9,0.1c-0.2,0-0.4,0-0.6,0c-0.1,0-1.1,0-1.1,0c0,0-0.6,0.3-0.6,0.3c-0.9,0.3-0.3,0-0.7,0.4
// 			C304.1,190.4,304,190.6,304,190.6z"
//         />
//         <path
//           fill={textColor}
//           d="M319.1,194.2c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 			c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 			c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 			c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1
// 			c-0.5,0-1.1,0-1.6,0.2c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2
// 			c0.5,0,1.1,0,1.6-0.1c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1
// 			c-0.2,0.1-0.5,0.1-0.7,0.1c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4
// 			c0,0.2,0.1,0.4,0.2,0.6c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L319.1,194.2z"
//         />
//         <path
//           fill={textColor}
//           d="M325,192.7c0-0.5,0-2.6-0.2-2.9c-0.1-0.3-0.3-0.3-0.5-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.5-0.2-0.8-0.3l0-0.1
// 			c0-0.4,0-0.7,0-1.1c0-0.1,0.1-0.2,0.1-0.2c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0.1,0.9,0.1
// 			c0.2,0,0.4-0.1,0.6-0.1c0.7,0,1.4,0,2.1,0c0.1,0,0.2,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.8,0.1
// 			c-0.3,0-0.8-0.1-0.9,0.3c-0.2,0.7-0.2,3.3-0.2,4c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.5,0,0.6-0.5,0.6
// 			c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.3-0.1-0.6-0.1-0.9C324.8,193.7,325,193.2,325,192.7z"
//         />
//         <path
//           fill={textColor}
//           d="M331.7,191.1c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.2-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 			l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 			c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C331.5,191.9,331.7,191.5,331.7,191.1z M335.3,194.3
// 			c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 			c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.5,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 			c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C334.6,193.8,334.6,194.3,335.3,194.3z"
//         />
//         <path
//           fill={textColor}
//           d="M342.1,188.2c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 			c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 			c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 			c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 			c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 			c0-2.2,0-4.4,0-6.5C342,188.4,342,188.3,342.1,188.2L342.1,188.2z"
//         />
//       </g>
//       <g id="ManningPark">
//         <g id="Manning">
//           <path
//             fill={textColor}
//             d="M228.6,339.4c-0.1-0.2-0.2-0.4-0.1-0.6c-0.1-0.2-0.1-0.4-0.1-0.5c0-0.5,0.1-1,0.1-1.5c0-0.5-0.1-1-0.1-1.5
// 			c0-0.1,0-0.3,0.1-0.4c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0.5-0.2,0.6-0.2c0.4,0,0.8,0.1,1.3,0.1c0.1,0,0.2,0.1,0.2,0.2
// 			c0.2,0.8,0.7,1.5,1.2,2.1l0,0.2l0,0.1c0,0,0.1,0.1,0.1,0.1c0.1-0.1,0.3-0.3,0.4-0.3c0.2-0.4,0.5-1.4,0.9-1.5c0,0,0-0.1,0-0.1
// 			c0-0.2,0.2-0.4,0.2-0.6c0-0.1,0.1-0.1,0.2-0.1c0.5,0,1.2,0.1,1.8,0.1c0.1,0,0.3,0,0.3,0.2v0.2c-0.1,0.8-0.1,1.6-0.1,2.4
// 			c0,0.4-0.1,0.7,0,1.1c0,0.1-0.1,0.2-0.1,0.3c0,0.6,0.1,1.2,0.1,1.9c0,1.1,0.1,0.8-1.3,1c-0.1,0-0.2-0.1-0.2-0.2
// 			c0-0.1,0-0.3,0.1-0.5c0.1-0.7,0-1.6,0-2.3c0-0.4-0.1-0.8-0.1-1.2l0,0v-0.1c0-0.1,0-0.7-0.1-0.8h-0.1c-0.1,0-0.3,0.5-0.3,0.6
// 			l-0.3,0.7c0,0.2-1,2.2-1.1,2.3c-0.1,0-0.1-0.1-0.2-0.2c-0.1-0.4-1-1.9-1.3-2.3c-0.1-0.2-0.2-0.4-0.3-0.6c-0.1-0.1-0.2-0.4-0.3-0.5
// 			l-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0.1,0,0.3,0,0.4c0,0.1-0.1,0.2-0.1,0.3c0,0.4,0.1,0.7,0.1,1.1c0,0.4,0.1,0.9,0.1,1.3
// 			c0,0.4,0,0.9,0,1.4c0,0.1-0.1,0.3-0.1,0.3c-0.1,0-0.2,0-0.3,0c-0.2,0-0.3-0.1-0.4-0.1c-0.2,0-0.4,0.1-0.6,0.1
// 			c0-0.1-0.1-0.2-0.1-0.3c0-0.6,0-1.1,0-1.7L228.6,339.4z"
//           />
//           <path
//             fill={textColor}
//             d="M243.7,341.5c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 			c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 			c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 			c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 			c0,0.2-0.1,0.3-0.3,0.3H243.7z M243,337.8c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2,0,0.2-0.1
// 			c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1l-0.1,0.3
// 			c0.1,0.2,0.2,0.2,0.4,0.2H243z"
//           />
//           <path
//             fill={textColor}
//             d="M248.4,334.4c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 			c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 			c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 			c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 			c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 			c0-2.2,0-4.4,0-6.5C248.3,334.6,248.3,334.6,248.4,334.4L248.4,334.4z"
//           />
//           <path
//             fill={textColor}
//             d="M258.4,334.4c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 			c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 			c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 			c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4s-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 			c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 			c0-2.2,0-4.4,0-6.5C258.3,334.6,258.3,334.6,258.4,334.4L258.4,334.4z"
//           />
//           <path
//             fill={textColor}
//             d="M270.6,338.9L270.6,338.9c0-0.8-0.1-1.6-0.1-2.4c0-1-0.3-0.7-1.2-0.7c-0.4,0-0.8,0.1-1,0.1
// 			c-0.1,0-0.1,0-0.2-0.1c-0.1-0.1-0.2-0.1-0.4-0.1c-0.1-0.2-0.1-0.9-0.1-1.1c0-0.1,0-0.2,0.1-0.2c0.2,0,0.3,0,0.4,0
// 			c0.7,0,1.4,0,2.1,0.2c0.2-0.1,0.4-0.1,0.6-0.1c0.3,0,0.6,0.1,1,0.1c0.2,0,0.4-0.1,0.5-0.1c0.4,0,2.2,0,2.3,0.2c0,0.2,0,0.4,0,0.6
// 			s0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.7,0.1c-0.1,0-0.9,0-1,0.1c-0.1,0.2-0.1,3-0.1,3.5c0,0.6,0.6,0.9,1.1,0.9c0.2,0,0.5-0.1,0.7-0.1
// 			c0.2,0,0.4,0.1,0.5,0.2c0,0.1,0.1,0,0.1,0v0.2c0,0.3,0.1,0.6,0.1,0.9c0,0.1,0,0.2-0.1,0.2c-0.4,0-0.7-0.1-1.1-0.2
// 			c-1.5,0-3,0-4.5,0c-0.5,0-0.9,0-0.9-0.6c0-0.8,0.5-0.7,1.1-0.7c0.4,0,0.7,0,1,0c0.1,0,0.3,0,0.4,0c0.1,0,0.2-0.1,0.3-0.2
// 			c0.1-0.3,0.1-0.6,0.1-0.9C270.6,339.1,270.6,339,270.6,338.9z"
//           />
//           <path
//             fill={textColor}
//             d="M278,334.4c0.2,0,0.4,0,0.7,0c0.2,0,0.4,0,0.6,0c0.3,0.3,0.5,0.8,0.7,1.1c0.6,0.7,1.1,1.4,1.7,2.1
// 			c0.3,0.3,0.5,0.7,0.9,1c0.2,0.2,0.4,0.3,0.5,0.5l0.1,0.1c0.3,0,0.2-0.7,0.2-0.9c0-0.8,0.1-1.7,0.1-2.5c0-0.4,0-0.9-0.1-1.3l0-0.1
// 			c0.2,0,0.5,0,0.7,0s0.4,0.1,0.5,0.3c0.2,0.6,0.1,1.4,0.1,2.1c0,1.3,0,2.7,0,4c0,0.3,0,0.7,0,1c-0.1,0.1-0.2,0.1-0.3,0.1
// 			c-0.3,0-0.6-0.2-0.9-0.2c-0.2,0-0.4-0.1-0.5-0.3c-0.3-0.5-0.7-0.9-1.1-1.4c-0.3-0.5-0.8-0.8-1.1-1.3c-0.1-0.2-0.9-1.3-1-1.3
// 			c-0.3,0.2-0.4,2.4-0.4,2.8c0,0.2,0,0.4,0,0.6l0,0v0l0,0.1c0,0.2,0,0.4-0.2,0.5c-0.1,0-0.7,0-0.9,0c-0.4,0-0.7-0.1-0.7-0.5
// 			c0-2.2,0-4.4,0-6.5C277.9,334.6,277.9,334.6,278,334.4L278,334.4z"
//           />
//           <path
//             fill={textColor}
//             d="M287.1,337.8c0-0.4,0.2-1.3,0.6-1.5c0-0.3,0.3-0.4,0.4-0.6c0.2-0.3,0.4-0.4,0.6-0.6c1-0.6,2-0.6,3.1-0.6
// 			c0.8,0,1.7-0.1,2.5-0.1c0.2,0,0.2,0.3,0.2,0.4c0,0.3-0.1,0.7-0.1,1c0,0.1-0.1,0.2-0.2,0.3c-0.1,0-0.3,0-0.4,0c-0.3,0-0.6,0-0.9,0
// 			c-0.3,0-0.6-0.1-1-0.1c-0.1,0-0.3,0-0.4-0.1c-0.3,0.1-0.6,0-0.9,0c-1.1,0-2.3,0.5-2.3,1.6c0,0.2,0,0.9,0.1,1.1
// 			c0.1,0.2,0.4,0.8,0.6,1c0.4,0.5,1,0.6,1.6,0.6c0.4,0,0.9-0.2,1.3-0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.7-0.1,0.7-0.4
// 			c0-0.2-0.1-0.5-0.2-0.7c-0.1-0.4-0.6-0.4-0.9-0.4c-0.4,0-0.7,0.2-1.1,0.2c-0.1,0-0.2-0.1-0.2-0.2c0-0.1,0.1-0.9,0.2-1
// 			c0.1-0.1,0.4-0.1,0.6-0.1c0.6,0,1.3,0.1,2,0.1c0.2,0,0.4-0.1,0.5-0.1c0,0,0.1,0,0.1,0c0.1,0.3,0.2,0.8,0.2,1.2
// 			c0,0.2-0.1,0.5-0.1,0.7c0,0.3,0.1,0.6,0.1,0.9c0,0.2,0,0.3,0,0.4c0,0.2,0.1,0.3,0.1,0.5c0,0.4-0.9,0.3-1.1,0.3
// 			c-0.2,0-0.4,0.1-0.5,0.1c-0.5-0.1-1,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.5,0-1.5-0.2-2-0.4c-0.4-0.2-0.7-0.6-1.1-0.9
// 			C287.5,339.4,287.1,338.6,287.1,337.8z"
//           />
//         </g>
//         <g id="Park">
//           <path
//             fill={textColor}
//             d="M244.2,353L244.2,353c-0.2,0.1-0.3,0.1-0.5,0.1c-0.3,0-0.6,0-1,0c-0.1-0.2-0.1-0.5-0.1-0.8
// 			c0-0.3,0.1-0.6,0.1-0.8c0-0.1-0.1-0.2-0.1-0.4v-0.9c0-0.8,0.1-1.6,0.1-2.4c0-0.3,0-0.5,0-0.8s-0.1-0.8,0.1-1h0.1
// 			c0.2,0,0.2,0,0.4-0.1c0.1,0,0.2,0.1,0.4,0.1c0.3,0,0.6-0.1,1-0.1c0.1,0,1.2,0,1.2,0c0.1,0.1,0.3,0.1,0.4,0.1
// 			c0.3,0,0.6-0.1,0.9-0.1c0.1,0,0.2,0.1,0.4,0.1c0.1,0,0.1,0,0.2,0c0.2,0,0.3,0.1,0.5,0.1c0.3,0.3,0.8,0.2,1.1,0.5
// 			c0.1,0.1,0.2,0.1,0.3,0.2c0.2,0.4,0.3,0.9,0.3,1.3c0,0.4-0.1,0.7-0.2,1c0,0.1-0.1,0.2-0.1,0.3c-0.1,0.6-1,0.8-1.5,0.8
// 			c-0.3,0-0.7,0-1,0c-0.4,0.2-1.8,0.1-2.3,0.1c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0.2-0.1,0.8-0.2,1c0.1,0,0.1,0.1,0.1,0.2V353z
// 			 M244.3,349.2L244.3,349.2c0.1,0,0.3-0.1,0.3-0.2h0.3c0.2,0,0.4,0,0.5,0.1l0.1,0c0.1-0.1,1.6-0.1,1.9-0.1c0.1,0,0.2,0,0.3,0.1
// 			l0.1,0c0.3-0.3,0.9-0.2,0.9-0.9c0-0.9-1.6-1.1-2.3-1.1c-0.4,0-0.9,0.1-1.4,0.1c-1.2,0-0.7,0.4-0.9,1.2L244.3,349.2z"
//           />
//           <path
//             fill={textColor}
//             d="M257.9,353.2c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8
// 			c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1
// 			c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4
// 			c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.4,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7
// 			c0,0.2-0.1,0.3-0.3,0.3H257.9z M257.2,349.4c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3
// 			c0.1-0.1,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1
// 			l-0.1,0.3c0.1,0.2,0.2,0.2,0.4,0.2H257.2z"
//           />
//           <path
//             fill={textColor}
//             d="M263.9,352.8c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 			c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 			c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 			c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 			c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V352.8z
// 			 M268,347.7c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 			c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C268.1,348.1,268,347.9,268,347.7z"
//           />
//           <path
//             fill={textColor}
//             d="M273.9,351c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 			h-0.4c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6
// 			c0-0.6-0.1-1.3-0.1-2c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6v-0.1c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1
// 			c0.1,0.1,0.1,0.1,0.1,0.2c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4
// 			c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1
// 			c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1c0,0.1-0.6,0.7-0.7,0.8c-0.4,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2
// 			c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2
// 			c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C274.6,350.2,274,350.8,273.9,351L273.9,351z"
//           />
//         </g>
//       </g>
//       <g id="Elkford">
//         <path
//           fill={textColor}
//           d="M1377.4,38.3c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.1-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3
// 		c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2
// 		c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0
// 		c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2
// 		c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1
// 		c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1
// 		c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6
// 		c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L1377.4,38.3z"
//         />
//         <path
//           fill={textColor}
//           d="M1380.4,37.1c0-1.2,0.1-2.3,0.1-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.6-0.1-0.9l0-0.1
// 		c0.1-0.1,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.4
// 		c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3
// 		c0,0.1,0,0.4,0.1,0.5c-0.1,0.3,0,0.6,0,0.9c0,0.2-0.1,0.4-0.1,0.5c0,0.7,0.4,0.5,0.9,0.5c0.6,0,1.2,0.1,1.8,0.1
// 		c0.1,0,0.3,0,0.4-0.1c0.3,0.1,0.6,0.1,1,0.1c0.7,0,1.1-0.2,1.1,0.5c0,0.2-0.1,1-0.3,1c-0.3,0-0.5-0.2-0.8-0.2c-0.1,0-0.3,0-0.4,0
// 		c-0.2-0.1-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.4,0.1-0.4,0.1
// 		c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.5,0.1-0.9,0.1-1.4
// 		C1380.5,37.5,1380.4,37.3,1380.4,37.1z"
//         />
//         <path
//           fill={textColor}
//           d="M1392,37.2c-0.1,0-0.2,0-0.2,0.1l0.1,0.1c-0.2,0.4,0.1-0.2-0.1,0.8c0,0.4,0,0.8,0,1.2c0,0.3-0.7,0.2-1,0.2
// 		h-0.4c-0.4,0-0.3-1-0.3-1.3c0-0.1,0.1-0.1,0.1-0.2l0-0.1c-0.1-0.2-0.1-0.4-0.1-0.7l0-0.9c0-0.2,0.1-0.4,0.1-0.6
// 		c0-0.6-0.1-1.3-0.1-2c0-0.3,0-0.5,0.1-0.9c-0.1-0.2-0.2-0.4-0.2-0.6v-0.1c0.2-0.1,0.4-0.1,0.5-0.1l1.3,0.1c0.1,0.1,0.1,0.1,0.1,0.2
// 		c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0,0.4c0,0.4-0.1,0.4-0.1,0.8c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.3,0.1,0.6l0,0.1l0.6-0.6
// 		c0.3-0.4,1-1.1,1.4-1.4c0.3-0.3,0.9-0.8,1.1-1.1c0.1-0.2,0.5-0.1,0.8-0.1c0.2,0,0.5-0.1,0.7-0.1l0.5,0c0,0,0.1,0.1,0.1,0.1
// 		c0,0.1-0.6,0.7-0.7,0.8c-0.5,0.3-0.8,0.8-1.1,1.1c-0.2,0.2-1.1,0.9-1.3,1.2c0.6,0.8,2.2,2.9,2.9,3.9l-0.1,0.1c-0.1,0-0.3,0-0.4,0
// 		c-0.4,0-0.7,0-1.1,0l-0.3,0c-0.1-0.2-1.2-1.9-1.3-2c-0.2-0.2-0.5-0.6-0.6-0.9l-0.1-0.2C1392.7,36.5,1392.1,37.1,1392,37.2
// 		L1392,37.2z"
//         />
//         <path
//           fill={textColor}
//           d="M1401.4,34.3v0.1c0,0.4,0.1,0.8,0.2,1.2c0.3,0,0.1,0,0.7,0c0.1,0,0.5,0.1,0.6,0.1c0.1,0,0.2-0.1,0.4-0.1
// 		c0.2,0,0.3-0.1,0.5-0.1c0.4,0,0.8,0,1.2-0.1c0.1,0.1,0.1,0.1,0.1,0.2c0,0.5,0,0.9-0.3,1.3l-0.2,0.1c-0.2,0-0.3-0.1-0.5-0.1
// 		c-0.2,0-0.7,0.1-1.1,0.1c-0.4,0-1.7-0.1-1.6,0.4c0,0.1,0,0.2,0,0.3c0,0.3-0.1,0.6-0.1,1c0,0.2,0.1,0.3,0.1,0.5c0,0.1,0,0.3,0,0.4
// 		c-0.3,0.1-0.7,0.1-0.9,0.1c-0.2,0-0.3-0.1-0.4-0.1c-0.1-0.2,0-0.4,0-0.7c0-0.1,0-0.2,0-0.3c0.1-1,0.1-2.1,0.1-3.1
// 		c0-0.6-0.1-1.2-0.1-1.8l0.1-0.2c-0.1-0.1-0.2-0.3-0.2-0.4c0-0.2,0-0.4,0.1-0.6c0.1,0,0.2,0,0.4,0c0.3,0,0.7,0,1,0.1
// 		c0.2-0.1,0.5-0.1,0.7-0.1l1.2,0c0.1,0,0.3,0.1,0.4,0.1c0.4,0,0.9-0.1,1.3-0.1c0.1,0,0.2,0.1,0.3,0.1c0.1-0.1,0.2-0.1,0.3-0.1
// 		c0.1,0,0.2,0,0.4,0.1c0.1,0,0.3-0.1,0.5-0.1c0.3,0,0.3,0.9,0.3,1.1c0,0.2-0.1,0.4-0.3,0.5h-0.1c-0.3-0.2-0.8-0.1-1.2-0.1
// 		C1404.6,33.9,1402.1,34.1,1401.4,34.3L1401.4,34.3z"
//         />
//         <path
//           fill={textColor}
//           d="M1409.4,35.3c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.1-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3
// 		l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4
// 		c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C1409.3,36,1409.4,35.6,1409.4,35.3z M1413.1,38.5
// 		c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.4-1-0.6-1.1
// 		c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8
// 		c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C1412.3,38,1412.4,38.5,1413.1,38.5z"
//         />
//         <path
//           fill={textColor}
//           d="M1421.1,39c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3
// 		c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.5,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0
// 		c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1
// 		c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2c-0.2,0-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0
// 		c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V39z
// 		 M1425.2,33.9c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8
// 		c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1425.3,34.4,1425.2,34.1,1425.2,33.9z"
//         />
//         <path
//           fill={textColor}
//           d="M1429.5,37.3c0-0.3,0.1-0.5,0.1-0.8c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1-0.2-2.2-0.2-3.3c0-0.1,0.1-0.2,0.2-0.2
// 		c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.6-0.1,2.3-0.1c0.8,0,1.5,0.3,2.1,0.7c0.5,0.3,0.5,0.7,0.9,1.1
// 		c0.3,0.3,0.4,0.9,0.4,1.3s-0.1,0.8,0,1.2c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4
// 		c-0.3,0.5-1.2,0.8-1.8,1.1c-0.6,0-1.3,0.2-1.9,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0.2-0.9,0.2-1.4,0.2
// 		c-0.1,0-0.3,0-0.3-0.1C1429.5,38.6,1429.5,38,1429.5,37.3z M1433.2,38c1.2,0,1.9-0.9,1.9-2c0-0.8-0.3-1.2-0.8-1.8
// 		c-0.2,0-0.3-0.1-0.5-0.2l-0.1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1,0-0.2-0.1l-0.2-0.3c-0.1,0-0.5-0.1-0.5-0.1
// 		c-0.8,0-1.4,0.2-1.4,1.1c0,0.3,0.1,1.8,0.2,2.1c0,0.2-0.1,0.3-0.1,0.5c0,0.3,0,0.5,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1
// 		C1431.9,38,1432.6,38,1433.2,38z"
//         />
//       </g>
//       <g id="AlbertaBorder">
//         <path
//           fill={textColor}
//           d="M1660.8,79.5c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8   c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1   c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4   c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.5,0,0.3,0.4,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7   c0,0.2-0.1,0.3-0.3,0.3H1660.8z M1660.1,75.7c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3   c0.1-0.1,0.2,0,0.2-0.1c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1   l-0.1,0.3c0.1,0.2,0.2,0.2,0.4,0.2H1660.1z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1655.3,91.2c0-1.2,0.1-2.3,0.1-3.5c0-0.2,0.1-0.3,0.1-0.5c0-0.3-0.1-0.6-0.1-0.9l0-0.1   c0.1-0.1,0.6-0.1,0.8-0.1c0.3,0,0.5,0.1,0.8,0.2c0,0.1,0,0.1,0.1,0.2c-0.1,0.3,0,0.7,0,1c0,0.1,0.1,0.2,0.1,0.4   c0,0.1-0.1,0.3-0.1,0.4c0,0.1,0,0.2,0.1,0.3l0,0.1c-0.1,0.2-0.1,0.3-0.1,0.5c0,0-0.1,0.1-0.1,0.2c0,0.1,0.1,0.2,0.1,0.3   c0,0.1,0,0.4,0.1,0.5c-0.1,0.3,0,0.6,0,0.9c0,0.2-0.1,0.4-0.1,0.5c0,0.7,0.4,0.5,0.9,0.5c0.6,0,1.2,0.1,1.8,0.1   c0.1,0,0.3,0,0.4-0.1c0.3,0.1,0.6,0.1,1,0.1c0.7,0,1.1-0.2,1.1,0.5c0,0.2-0.1,1-0.3,1c-0.3,0-0.5-0.2-0.8-0.2c-0.1,0-0.3,0-0.4,0   c-0.2-0.1-0.4,0-0.6,0c-0.1,0-0.2-0.1-0.4-0.1c-0.5,0-1,0.1-1.5,0.1c-0.2,0-0.5,0-0.7,0c-0.1,0-0.4,0.1-0.4,0.1   c-0.2,0-0.4-0.1-0.6-0.1c-0.4,0-0.7,0.1-1,0.2c-0.3-0.1-0.3-0.3-0.3-0.5c0-0.5,0.1-0.9,0.1-1.4   C1655.4,91.6,1655.3,91.4,1655.3,91.2z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1655.5,107.8L1655.5,107.8c-0.1-0.7-0.1-1.3-0.1-1.9c0-1.5-0.1-3.1-0.1-4.6c0-0.7,0.2-0.6,0.8-0.6   c0.2,0,0.4,0.1,0.5,0.1c0.5,0,0.9-0.1,1.4-0.1c0.7,0,3.1,0.2,3.6,0.7c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.2,0.2,0.5,0.2,0.8   c0,0.6-0.3,0.8-0.3,1c0,0.3,0.6,0.8,0.6,1.8c0,1.9-1.5,2.1-3.1,2.1c-0.6,0-1.3-0.2-1.9-0.2c-0.5,0-1,0.1-1.5,0.1H1655.5z   M1660.8,103.5c0.3,0,0.4-0.2,0.4-0.5c0-0.2,0-0.4-0.2-0.5c-0.4-0.3-2.1-0.4-2.6-0.4c-0.5,0-1.1,0-1.6,0.1c0,0.1,0,0.2-0.1,0.4   c0.3,0.7-0.5,1.2,0.8,1.2C1658.4,103.7,1659.9,103.6,1660.8,103.5z M1657.3,106.5c0.4,0,0.9,0,1.3,0c0.8,0,1.7,0.1,2.5,0   c0.2-0.2,0.4-0.2,0.4-0.5c-0.1-0.2,0-0.5-0.1-0.6c-0.9-0.7-0.7-0.5-1.9-0.5c-0.7,0-1.5,0.1-2.2,0.1c-0.2,0-0.3,0.1-0.4,0.2   c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0.1,0.4C1657,106.5,1657.2,106.5,1657.3,106.5z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1662.2,120.5c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.2-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3   c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.5,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2   c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0   c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2   c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1   c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1   c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6   c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L1662.2,120.5z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1656.9,135.3c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3   c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0   c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1   c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0   c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V135.3z   M1661,130.2c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8   c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1661.2,130.6,1661.1,130.4,1661,130.2z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1658.2,147.2c0-0.5,0-2.6-0.2-2.9c-0.1-0.3-0.3-0.3-0.5-0.3c-0.4,0-0.8,0-1.2,0c-0.3,0-0.5-0.2-0.8-0.3l0-0.1   c0-0.4,0-0.7,0-1.1c0-0.1,0.1-0.2,0.1-0.2c0.6,0,1.3,0.1,1.9,0.1c0.2,0,0.4,0,0.6,0.1c0.2,0,0.4,0,0.5,0c0.3,0,0.6,0.1,0.9,0.1   c0.2,0,0.4-0.1,0.6-0.1c0.7,0,1.4,0,2.1,0c0.1,0,0.2,0,0.2,0.1c0,0.2,0,0.4,0,0.6c0,0.2,0,0.4-0.1,0.5c-0.5,0.2-1.2,0.1-1.8,0.1   c-0.3,0-0.8-0.1-0.9,0.3c-0.2,0.7-0.2,3.3-0.2,4c0,0.1-0.1,0.2-0.1,0.4c0,0.2,0.1,0.4,0.1,0.6c0,0.5,0,0.6-0.5,0.6   c-0.3,0-0.6-0.1-0.9-0.1c-0.1-0.3-0.1-0.6-0.1-0.9C1658.1,148.1,1658.2,147.6,1658.2,147.2z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1660.8,163.8c-0.1,0-0.2,0-0.3,0c-0.2-0.3-0.1-0.3-0.1-0.6c0-0.4-0.1-0.9-0.1-1.3c0-0.6-0.1-0.8-0.7-0.8   c-0.4,0-2.8,0.2-3,0.4c-0.1,0.1-0.1,0.9-0.1,1.1s0.1,0.3,0.1,0.5c0,0.5,0,0.8-0.6,0.8c-0.3,0-0.5-0.1-0.7-0.1   c-0.1,0-0.2-0.1-0.2-0.2c0-0.3,0.1-0.5,0.1-0.8c0-0.2-0.1-0.4-0.1-0.5c0-0.2,0.2-0.5,0.1-0.7c0-0.5,0.2-0.9,0.2-1.4   c0.1-0.3,0.2-1,0.4-1.3c1.1-1.9,4-2.4,6-2.4c0.5,0,0.3,0.5,0.3,0.8c0,0.8-0.1,1.7-0.1,2.5c0,1.3-0.1,2.5-0.1,3.7   c0,0.2-0.1,0.3-0.3,0.3H1660.8z M1660.1,160c0.2,0,0.3-0.1,0.5-0.3c0-0.3-0.1-0.5-0.1-0.8c0-0.1,0-0.2,0-0.3c0.1-0.1,0.2,0,0.2-0.1   c-0.1-0.1-0.2-0.3-0.3-0.4c-0.5,0.1-1,0.1-1.5,0.1c-0.2,0.1-0.4,0.3-0.7,0.3c-0.3,0.2-0.9,0.8-1.1,1l-0.1,0.3   c0.1,0.2,0.2,0.2,0.4,0.2H1660.1z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1655.5,186.3L1655.5,186.3c-0.1-0.7-0.1-1.3-0.1-1.9c0-1.5-0.1-3.1-0.1-4.6c0-0.7,0.2-0.6,0.8-0.6   c0.2,0,0.4,0.1,0.5,0.1c0.5,0,0.9-0.1,1.4-0.1c0.7,0,3.1,0.2,3.6,0.7c0.2,0.3,0.4,0.6,0.6,0.9c0.2,0.2,0.2,0.5,0.2,0.8   c0,0.6-0.3,0.8-0.3,1c0,0.3,0.6,0.8,0.6,1.8c0,1.9-1.5,2.1-3.1,2.1c-0.6,0-1.3-0.2-1.9-0.2c-0.5,0-1,0.1-1.5,0.1H1655.5z   M1660.8,182c0.3,0,0.4-0.2,0.4-0.5c0-0.2,0-0.4-0.2-0.5c-0.4-0.3-2.1-0.4-2.6-0.4c-0.5,0-1.1,0-1.6,0.1c0,0.1,0,0.2-0.1,0.4   c0.3,0.7-0.5,1.2,0.8,1.2C1658.4,182.2,1659.9,182.1,1660.8,182z M1657.3,185c0.4,0,0.9,0,1.3,0c0.8,0,1.7,0.1,2.5,0   c0.2-0.2,0.4-0.2,0.4-0.5c-0.1-0.2,0-0.5-0.1-0.6c-0.9-0.7-0.7-0.5-1.9-0.5c-0.7,0-1.5,0.1-2.2,0.1c-0.2,0-0.3,0.1-0.4,0.2   c0,0.3-0.1,0.6-0.1,0.9c0,0.1,0,0.3,0.1,0.4C1657,185,1657.2,185,1657.3,185z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1655.1,195.9c0-0.3,0.4-1.5,0.5-1.8c0.8-0.7,2.2-1.6,3.2-1.6c1.3,0,2.1,0.4,2.9,1.3c0.4,1.2,0.9,1.6,0.9,3   l0,0.6c0,0.3-0.2,0.5-0.3,0.8c0,0.1-0.1,0.1-0.1,0.2c-0.2,0.3-0.4,0.6-0.6,0.9c-0.5,0.7-1.8,1.2-2.7,1.2c-0.4,0-1.2-0.4-1.3-0.4   c-0.1,0-0.1,0-0.2,0c-0.4,0-1.1-0.4-1.4-0.6c-0.5-0.3-1.1-2-1.1-2.6C1655,196.7,1655.1,196.3,1655.1,195.9z M1658.8,199.2   c0.4,0,0.9-0.2,1.3-0.3c0.2-0.3,0.6-0.5,0.7-0.9c0.2-0.2,0.4-0.9,0.4-1.2c0-0.3,0-0.7-0.1-1c-0.2-0.2-0.5-1-0.6-1.1   c-0.2-0.2-0.5-0.3-0.7-0.5c-0.3-0.1-0.5-0.2-0.7-0.2c-0.4,0-1,0.1-1.4,0.2c-0.2,0.3-0.5,0.6-0.7,0.9c-0.2,0.3-0.3,1.4-0.3,1.8   c0,0.1-0.1,0.2-0.1,0.4c0,0.3,0.4,1.2,0.6,1.3C1658,198.6,1658.1,199.2,1658.8,199.2z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1656.9,213.8c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3   c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0   c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1   c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0   c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V213.8z   M1661,208.7c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8   c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1661.2,209.1,1661.1,208.8,1661,208.7z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1655.4,226.1c0-0.3,0.1-0.5,0.1-0.8c-0.1-0.1-0.1-0.3-0.1-0.4c0-1.1-0.2-2.2-0.2-3.3c0-0.1,0.1-0.2,0.2-0.2   c0.1,0,0.2,0,0.3,0c0.4,0,0.8,0,1.2-0.1c0.7-0.1,1.6-0.1,2.3-0.1c0.8,0,1.5,0.3,2.1,0.7c0.5,0.3,0.5,0.7,0.9,1.1   c0.3,0.3,0.4,0.9,0.4,1.3s-0.1,0.8,0,1.2c-0.1,0.2-0.1,0.5-0.1,0.7c0,0.1,0,0.1-0.1,0.2c-0.1,0.1-0.2,0.3-0.3,0.4   c-0.3,0.5-1.2,0.8-1.8,1.1c-0.6,0-1.3,0.2-1.9,0.2c-0.2,0-0.3,0.1-0.5,0.1c-0.3,0-0.6-0.1-0.9-0.1c-0.4,0.2-0.9,0.2-1.4,0.2   c-0.1,0-0.3,0-0.3-0.1C1655.3,227.4,1655.4,226.7,1655.4,226.1z M1659.1,226.7c1.2,0,1.9-0.9,1.9-2c0-0.8-0.3-1.2-0.8-1.8   c-0.2,0-0.3-0.1-0.5-0.2l-0.1,0c-0.2,0-0.4-0.1-0.6-0.1c-0.1,0-0.1,0-0.2-0.1l-0.2-0.3c-0.1,0-0.5-0.1-0.5-0.1   c-0.8,0-1.4,0.2-1.4,1.1c0,0.3,0.1,1.8,0.2,2.1c0,0.2-0.1,0.3-0.1,0.5c0,0.3,0,0.5,0.1,0.8c0.1,0,0.1,0.1,0.2,0.1   C1657.8,226.7,1658.4,226.7,1659.1,226.7z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1662.2,241.1c0,1.2,0,1.2-1.4,1.2c-1-0.1-2.2-0.1-3.2-0.1c-0.6,0-1.3,0-1.9,0c-0.2,0-0.2-0.2-0.3-0.3   c-0.1-0.7-0.1-1.6-0.1-2.3c0-0.4-0.1-0.9-0.1-1.3c0-0.2,0-0.4,0-0.7c0-0.6,0-1.2,0-1.8c0-0.1,0-0.1,0-0.2c0,0-0.1-0.1-0.1-0.2   c0-0.2,0.4-0.2,0.5-0.2c0.2,0,1.1,0.1,1.2,0h0.2c0.2,0,0.4,0.1,0.6,0.1c0.2,0,0.5-0.1,0.7-0.1c1.2,0,2.3,0,3.5,0   c0.2,0,0.3,0,0.3,0.3s-0.1,0.9-0.2,1.1l-0.5,0c-0.2,0-0.4,0-0.6,0.2c-0.3,0-0.5,0-0.8,0c-0.5,0-1-0.1-1.5-0.1c-0.5,0-1.1,0-1.6,0.2   c-0.1,0.1-0.1,0.6-0.1,0.7c0.1,0.1,0,0.3,0.1,0.5c0.1,0,0.2,0,0.4,0c0.2,0,0.4,0.2,0.7,0.2c0.5,0,1.1,0,1.6-0.1   c0.2,0,0.4-0.1,0.5-0.1s0.3,0,0.4,0.2c0,0.2,0,0.3,0,0.5c0,0.2,0.1,0.4,0.1,0.6l0,0.1l-0.1,0.1c-0.2,0.1-0.5,0.1-0.7,0.1   c-0.5,0-1.2,0-1.5,0c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5,0-0.6,0.2c0,0.1-0.1,0.3-0.1,0.4c0,0.2,0.1,0.4,0.2,0.6   c0.4,0.3,0.7,0.2,1.2,0.2c0.7,0,1.2,0.1,1.8,0c0.5,0,1.8,0,2,0.1L1662.2,241.1z"
//         ></path>
//         <path
//           fill={textColor}
//           d="M1656.9,255.9c0,0.3-0.4,0.5-0.6,0.5c-0.5,0-0.8,0-0.8-0.5c0-1.3,0.1-2.5,0.1-3.8c0-0.8-0.1-1.6-0.1-2.3   c0-0.2,0-0.4,0-0.6c0.4-0.1,0.7-0.1,1.1-0.1c0.3,0,0.6,0,1,0c0.4,0,0.9,0.1,1.4,0.1c0.6,0,1.2,0,1.8,0c0.1,0,0.1,0,0.2,0   c0.3,0.2,0.7,0.3,1,0.4c0.4,0.4,0.7,0.7,0.7,1.3c0,0.2,0,0.3,0,0.4c0,0.6-0.6,1.3-1.1,1.6c-0.1,0.1-0.3,0.1-0.4,0.1   c-0.4,0-0.8,0-1.2,0l-0.3,0c0,0,2.6,2.7,2.6,3.1c0,0.3-0.5,0.2-0.7,0.2s-0.4,0.1-0.5,0.1c-0.1,0-0.5,0-0.5,0   c-0.2-0.1-1.5-1.9-1.9-2.3c-1-1-1.3-1-1.7-1c-0.1,0.1,0,0.2,0,0.3c-0.1,0.3-0.1,0.5-0.1,0.8c0,0.1-0.1,0.5-0.1,0.6l0.2,0.3V255.9z   M1661,250.8c-0.2-0.1-0.4-0.3-0.6-0.4c-0.3-0.1-1.6-0.1-2-0.1c-0.5,0-1,0-1.5,0.1l-0.1,0c0,0.1,0,0.3,0,0.5c0,0.3,0,0.6,0,0.8   c0.3,0.1,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1.1,0.1c0.8,0,2.2,0,2.2-0.5C1661.2,251.2,1661.1,251,1661,250.8z"
//         ></path>
//       </g>
//     </>
//   );
// };
