export type Community = {
  id: number;
  name: string;
  slug: string;
  dots: CommunityDot[];
};

export type CommunityDot = {
  cx: number;
  cy: number;
  link: string;
};

export const COMMUNITIES_ORDERED_SLUGS: Community[] = [
  {
    id: 202,
    name: "Hope",
    slug: "hope-cascades-and-canyons",
    dots: [
      {
        cx: 22.661,
        cy: 264.249,
        link: "https://tourismhcc.ca/about/visitor-centre/",
      },
    ],
  },
  {
    id: 201,
    name: "Manning Park",
    slug: "manning-park",
    dots: [
      {
        cx: 185.001,
        cy: 383.519,
        link: "https://manningpark.com/visitor-centre/",
      },
    ],
  },
  {
    id: 199,
    name: "Similkameen Valley",
    slug: "similkameen-valley",
    dots: [
      {
        cx: 241.455,
        cy: 232.444,
        link: "https://similkameenvalley.com/directory-listings/listing/princeton-visitor-centre/",
      },
      {
        cx: 438.383,
        cy: 335.944,
        link: "https://similkameenvalley.com/directory-listings/listing/keremeos-visitor-centre/",
      },
      {
        cx: 451.105,
        cy: 363.508,
        link: "https://similkameenvalley.com/directory-listings/listing/keremeos-visitor-centre/",
      },
    ],
  },
  {
    id: 198,
    name: "Osoyoos",
    slug: "osoyoos",
    dots: [
      {
        cx: 498.151,
        cy: 386.567,
        link: "https://www.destinationosoyoos.com/osoyoos-visitor-centre/",
      },
    ],
  },
  {
    id: 197,
    name: "Boundary Country",
    slug: "boundary-country",
    dots: [
      {
        cx: 699.187,
        cy: 397.699,
        link: "https://midwaybc.ca/visitor-information/",
      },
      {
        cx: 717.21,
        cy: 372.785,
        link: "https://boundarybc.com/directory-listings/listing/greenwood-visitor-centre/",
      },
      {
        cx: 755.641,
        cy: 396.374,
        link: "https://boundarybc.com/directory-listings/listing/grand-forks-visitor-centre/",
      },
      {
        cx: 615.168,
        cy: 385.242,
        link: "https://boundarybc.com/directory-listings/listing/riverside-centre-visitor-info/",
      },
    ],
  },
  {
    id: 196,
    name: "Christina Lake",
    slug: "christina-lake",
    dots: [
      {
        cx: 829.721,
        cy: 395.048,
        link: "https://boundarybc.com/directory-listings/listing/christina-lake-welcome-centre/",
      },
    ],
  },
  {
    id: 195,
    name: "Rossland/Trail",
    slug: "rossland-trail",
    dots: [
      {
        cx: 942.762,
        cy: 372.785,
        link: "https://rossland.ca/community-resources/visitor-centre/",
      },
      {
        cx: 1012.071,
        cy: 353.967,
        link: "https://trail.ca/en/play/trail-tourism-visitor-centre.aspx",
      },
    ],
  },
  {
    id: 194,
    name: "Castlegar",
    slug: "castlegar",
    dots: [
      {
        cx: 984.374,
        cy: 292.741,
        link: "https://www.destinationcastlegar.com/contact/",
      },
    ],
  },
  {
    id: 193,
    name: "Nelson & Kootenay Lake",
    slug: "nelson-kootenay-lake",
    dots: [
      {
        cx: 1073.429,
        cy: 203.819,
        link: "https://www.nelsonkootenaylake.com/listing/nelson-visitor-centre?gad_source=1&gclid=CjwKCAjwydSzBhBOEiwAj0XN4GYCh58FivzK_gPDwJ8RscVLfgdO9mSo9XaCE2iGaRuwfPiruQhNkBoClJ0QAvD_BwE",
      },
    ],
  },
  {
    id: 192,
    name: "Creston Valley",
    slug: "creston-valley",
    dots: [
      {
        cx: 1326.281,
        cy: 340.171,
        link: "https://www.explorecrestonvalley.com/visitors-centre",
      },
    ],
  },
  {
    id: 190,
    name: "Cranbrook",
    slug: "cranbrook",
    dots: [
      {
        cx: 1459.466,
        cy: 190.567,
        link: "https://cranbrooktourism.com/about/visitor-centre?gad_source=1&gclid=CjwKCAjwydSzBhBOEiwAj0XN4Mp7904-_38ggPuX1JhpXIBrH4o9lmmeJj6oLbqO8nGMZXVpXCtuRRoCLzMQAvD_BwE",
      },
    ],
  },
  {
    id: 191,
    name: "Kimberley",
    slug: "kimberley",

    dots: [
      {
        cx: 1408.578,
        cy: 139.678,
        link: "https://tourismkimberley.com/plan/visitor-centre/",
      },
    ],
  },
  {
    id: 189,
    name: "Fernie",
    slug: "fernie",
    dots: [
      {
        cx: 1643.009,
        cy: 204.482,
        link: "https://tourismfernie.com/activities/gear-supplies-maps-books/fernie-chamber-of-commerce?gad_source=1&gclid=CjwKCAjwydSzBhBOEiwAj0XN4OjJAS6WaxNjkw9p7Hwn4pSL89iCk87rC62nQIkqCT-aaMle1aRvJBoC488QAvD_BwE",
      },
    ],
  },
  {
    id: 188,
    name: "Elk Valley",
    slug: "elk-valley",
    dots: [
      {
        cx: 1692.307,
        cy: 113.571,
        link: "https://sparwoodchamber.bc.ca/for-tourists/",
      },
      {
        cx: 1700.789,
        cy: 25.974,
        link: "https://www.elkford.ca/tourism",
      },
    ],
  },
];
