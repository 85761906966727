import { useBreakpointValue } from "@chakra-ui/react";
import { MouseEventHandler, MutableRefObject, useRef, useState } from "react";

export const useIsMobile = () =>
  !!useBreakpointValue({ base: true, md: false, lg: false });
export const useIsDesktop = () =>
  !!useBreakpointValue({ base: false, md: false, lg: true });
export const useNavbarHeight = () =>
  useBreakpointValue({ base: 120, lg: 155 }, { ssr: false }) || 120;

export const useDraggable = (
  ourRef: MutableRefObject<HTMLDivElement | null>
) => {
  const [isMouseDown, setIsMouseDown] = useState(false);
  const mouseCoords = useRef({
    startX: 0,
    startY: 0,
    scrollLeft: 0,
    scrollTop: 0,
  });
  const handleDragStart: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!ourRef.current) return;
    const slider = ourRef.current;
    const startX = e.clientX;
    const startY = e.clientY;
    const scrollLeft = slider.scrollLeft;
    const scrollTop = slider.scrollTop;
    mouseCoords.current = { startX, startY, scrollLeft, scrollTop };
    setIsMouseDown(true);
    document.body.style.cursor = "grabbing";
  };

  const handleDrag: MouseEventHandler<HTMLDivElement> = (e) => {
    if (!isMouseDown || !ourRef.current) return;
    e.preventDefault();
    const slider = ourRef.current;
    const walkX = (e.clientX - mouseCoords.current.startX) * 1.25;
    const walkY = (e.clientY - mouseCoords.current.startY) * 1.25;
    slider.scrollLeft = mouseCoords.current.scrollLeft - walkX;
    slider.scrollTop = mouseCoords.current.scrollTop - walkY;
  };
  const handleDragEnd = () => {
    setIsMouseDown(false);
    if (!ourRef.current) return;
    document.body.style.cursor = "default";
  };
  return {
    onMouseDown: handleDragStart,
    onMouseMove: handleDrag,
    onMouseUp: handleDragEnd,
  };
};
