import {
  Box,
  BoxProps,
  Center,
  Flex,
  Image,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react";
import Link from "next/link";
import { NavLink } from "~/components/Buttons/Link";
import { NewsletterButton, useGlobalContext } from "~/providers";
import { StrapiGlobalFooterComponent, getStrapiMedia } from "~/strapi";

export const Footer = () => {
  const { footer } = useGlobalContext();
  const footerImage = useBreakpointValue({
    base: "/MobileFooter.png",
    md: "/TabletFooterImage.png",
    lg: "/FooterImage.png",
  });
  const shieldImage = useBreakpointValue({
    base: "/ShieldLogo.png",
  });
  const dividerImage = useBreakpointValue({
    base: "/DividerAsset.png",
    lg: "/DesktopDividerAsset.png",
  });
  return (
    <>
      <Image
        src={footerImage}
        alt=""
        h={{ base: "395px", md: "430px" }}
        w="100vw"
      />
      <Box
        minH="maxContent"
        display="flex"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        position="relative"
        bg="yellow.500"
      >
        <Image
          src={shieldImage}
          alt="shield"
          mt={{ base: "52px" }}
          h={{ base: "141px", md: "183px" }}
          w={{ base: "112px", md: "145px" }}
        />
        <Image
          src={dividerImage}
          alt="Divider"
          mt={{ base: "-30px", md: "-160px", lg: "-90px" }}
          w={{ base: "100%" }}
        />
        <Flex
          direction={{ base: "column" }}
          justifyContent={{
            base: "space-evenly",
            md: "center",
            lg: "space-evenly",
          }}
          paddingTop={{ base: 20 }}
          paddingBottom={{ base: 10, lg: 20 }}
          width={{ base: "100%", lg: "30%" }}
          style={{
            alignItems: "center",
            gap: 20,
          }}
        >
          <Link style={{ fontSize: 20 }} color="gray.900" href={"/about-us"}>
            About Us
          </Link>
          <Link style={{ fontSize: 20 }} color="gray.900" href={"/contact-us"}>
            Contact Us
          </Link>
        </Flex>
        <Flex
          direction={{ base: "column", lg: "row" }}
          style={{ alignItems: "center" }}
        >
          <Partners title="Partners" images={footer} />
          <Partners images={footer} title="Supported By" />
        </Flex>
        <FooterLinks />
      </Box>
    </>
  );
};

type PartnerProps = BoxProps & {
  title: string;
  images: StrapiGlobalFooterComponent;
};
export const Partners = ({
  title,
  images,
  children,
  ...props
}: PartnerProps) => {
  let selectImages = images.partnerImageLinks;
  if (title != "Partners") {
    selectImages = images.fundedImageLinks;
  }
  if (!selectImages) return null;
  return (
    <Box
      sx={{ m: "32px 16px" }}
      display="flex"
      flexDirection={{ base: "column" }}
      justifyContent={{ base: "center" }}
      {...props}
    >
      <Text
        fontSize="2xl"
        fontWeight="medium"
        fontStyle="italic"
        noOfLines={1}
        align="center"
        mb="6%"
      >
        {title}
      </Text>
      <Box
        display="flex"
        flexDirection={{ base: "row" }}
        justifyContent={{ base: "center" }}
        gap={{ base: 8 }}
        flexWrap={{ base: "wrap" }}
      >
        {selectImages.map((link) => {
          const { url } = getStrapiMedia(link.image);
          if (!link.href) return null;
          return (
            <Link target="_blank" href={link.href} key={url}>
              <Image
                alt={url}
                src={url}
                h={{ base: 12, lg: 88 }}
                maxW={280}
                objectFit="contain"
              />
            </Link>
          );
        })}
      </Box>
    </Box>
  );
};
export const FooterLinks = () => {
  return (
    <Flex
      p={{ base: "40px 32px 64px 32px" }}
      w="100%"
      gap={{ base: 8, md: 10 }}
      wrap={{ base: "wrap" }}
      justify={{ base: "space-around" }}
    >
      <NewsletterButton />
      <Center flexDirection={"column"}>
        <Text noOfLines={1} fontSize="md">
          {`© ${new Date().getFullYear()} Highway 3`}
        </Text>
        <NavLink fontSize="md" noOfLines={1} href={"/privacy-policy"}>
          Privacy Policy
        </NavLink>
      </Center>
      <NavLink fontSize="md" noOfLines={1} href={"https://thenumber.ca/"}>
        Made by The Number
      </NavLink>
    </Flex>
  );
};
