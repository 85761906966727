import {
  Box,
  Link,
  Image,
  useBreakpointValue,
  DrawerProps,
} from "@chakra-ui/react";
import { Subheading } from "~/components/Text/Subheading";
import { ChevronRight } from "~/images/svgs";
import { SocialIconsNav } from "../NavHeader/SocialIconsNav";
import { MenuDrawer } from "./MenuDrawer";
import { ReactNode } from "react";

type Props = Omit<DrawerProps, "children"> & {
  children?: ReactNode;
  handleRoadTripClick: () => void;
  handleCommunitiesClick: () => void;
};

export const MobileDrawer = ({
  children,
  isOpen,
  handleRoadTripClick,
  handleCommunitiesClick,
  ...props
}: Props) => {
  const navLinkStyleLarge = {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderBottom: "2px solid",
    borderColor: "gray.900",
    color: "gray.900",
    padding: "0px 0px 16px 48px",
    mt: "32px",
  };
  const display = useBreakpointValue({
    base: true,
    lg: false,
  });
  if (!display) return children;
  return (
    <MenuDrawer
      isOpen={isOpen && !!display}
      bgColor="sage.400"
      showBack={false}
      bodyProps={{
        p: 0,
        justifyContent: "space-between",
        display: "flex",
        flexDir: "column",
      }}
      {...props}
    >
      <Box
        flexGrow={1}
        display="flex"
        flexDir="column"
        alignItems="flex-start"
        pr={5}
        // pl={6}
      >
        <Box sx={navLinkStyleLarge} onClick={handleCommunitiesClick}>
          <Subheading color="gray.900">Communities</Subheading>
          <ChevronRight width={8} height={8} color="gray.900" />
        </Box>
        <Box sx={navLinkStyleLarge} onClick={handleRoadTripClick}>
          <Subheading>Road Trips</Subheading>
          <ChevronRight width={8} height={8} color="gray.900" />
        </Box>
        <Box sx={navLinkStyleLarge}>
          <Link href="/stories">
            <Subheading>Stories</Subheading>
          </Link>
        </Box>
        <SocialIconsNav px={10} py={6} />
      </Box>
      <Image
        src="/MobileMapLines.png"
        alt="yellow and blue lines"
        w={{ base: "100%" }}
      />
      {children}
    </MenuDrawer>
  );
};
